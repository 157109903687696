import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import "semantic-ui-less/semantic.less";
import { ApolloProvider } from 'react-apollo';
import { client } from './apollo';

import Error from "./Error";
import App from './App';
import { AppContextProvider } from './Context';
import registerServiceWorker from './registerServiceWorker';
import './styles/index.scss';
import MobileReportContainer from "./Reporting/MobileReportContainer";
import { PrivilegesProvider } from "./Privileges";
import { isMobileOnly } from "react-device-detect";
import { MobileAdvert } from "./MobileAdvert";


ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path="/error" component={Error} />
      </Switch>
      <Switch>
        <Route path="/m/insights/:id" component={MobileReportContainer} />
        <Route path="/complete-signup" render={() => {
          localStorage.setItem("override-mobile-login", "true");
          window.location.href = "/login";
        }} />
        {isMobileOnly && localStorage.getItem("override-mobile-login") !== "true" && (
          <Route path="/" component={MobileAdvert} />
        )}
        <Route path="/" render={() => (
          <AppContextProvider>
            <PrivilegesProvider>
              {/* //todo error message */}
              <App />
            </PrivilegesProvider>
          </AppContextProvider>
        )} />
      </Switch>
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);

registerServiceWorker();
