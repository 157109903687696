import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
// import '../../css/shared/Banner.css';

export class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    if (this.props.show) this.setState({ show: true });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ show: props.show });
  }

  get icon() {
    switch (this.props.type) {
    case 'error':
      return <i className="fal fa-exclamation-triangle" />;
    case 'warning':
      return <i className="fal fa-exclamation-triangle" />;
    default:
      return null;
    }
  }

  render() {
    let cls = this.state.show
      ? `banner show ${this.props.type}`
      : 'banner hide';
    if (this.props.tuck) cls += ' banner-tuck';
    return (
      <div key="banner" className={ cls }>
        <div className="icon">{this.icon}</div>
        <div className="data">{this.props.data}</div>
      </div>
    );
  }
}

Banner.propTypes = {
  show: PropTypes.boolean,
  tuck: PropTypes.boolean,
  error: PropTypes.string,
  warning: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.string
};
