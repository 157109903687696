import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { GetUnitComponent } from "../generated/graphql";
import UnitEditForm from "./UnitEditForm";

interface IParams {
  id: string;
}

const UnitEdit = (props: RouteComponentProps<IParams>) => {
  return (
    <GetUnitComponent variables={{ id: props.match.params.id }}>
      {({ data, loading }) => {
        return (
          loading || !data?.unit ?
          <Loader active inline="centered" content="loading" /> :
          <UnitEditForm loading={loading} unit={data?.unit} />
        );
      }}
    </GetUnitComponent>
  );
};

export default withRouter(UnitEdit);
