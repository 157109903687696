import {get} from "lodash";
export class Logger {

  constructor() {
    this._actor = {};
    this.version = process.env.VERSION;
    this._platform = '';
    this._osName = '';
    this._osVersion = '';

    const tags = [];
    tags.push(`${process.env.NODE_ENV || 'production'}`);
    tags.push('lyra');
    tags.push('browser');
    this._platform = 'browser';

    window._LTracker = window._LTracker || [];
    window._LTracker.push({
      'logglyKey': process.env.REACT_APP_LYRA_LOGGLY_KEY,
      'sendConsoleErrors': false,
      'tag': tags.join(',')
    });

    //capture and send all errors
    window.onerror = (msg) => this.log('error', msg);
  }

  get actor() {
    return this._actor; 
  }

  set actor(k) {
    this._actor = k; 
  }

  get osName() {
    return this._osName;
  }

  set osName(s) {
    this._osName = s;
  }

  get osVersion() {
    return this._osVersion;
  }

  set osVersion(s) {
    this._osVersion = s;
  }

  log(level, msg, details={}) {
    window._LTracker.push({
      level,
      message: msg,
      version: this.version,
      platform: this._platform,
      os_name: this.osName,
      os_version: this.osVersion,
      user_agent: navigator.userAgent,
      unity_actor_id: this.actor.id,
      unity_employee: {
        id: get(this, 'actor.employee.id'),
        name: `${get(this, 'actor.employee.firstName')} ${get(this, 'actor.employee.lastName')}`,
        email: get(this, 'actor.employee.email'),
      },
      details
    });
  }

  debug(msg, details={}) {
    this.log('debug', msg, details); 
    if (process.env.NODE_ENV === 'development') {
      console.info(`%c${msg}`, 'background-color:#ccc', details);
    }
  }

  info(msg, details={}) {
    this.log('info', msg, details); 
    if (process.env.NODE_ENV === 'development') {
      console.info(`%c${msg}`, 'background-color:#cee1ff', details);
    }
  }

  warn(msg, details={}) {
    this.log('warn', msg, details); 
    if (process.env.NODE_ENV === 'development') console.warn(msg, details);
  }

  error(msg, details={}) {
    this.log('error', msg, details); 
    if (process.env.NODE_ENV === 'development') console.error(msg, details);
  }
}

export const logger = new Logger();
