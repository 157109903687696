import moment from "moment";
import React from "react";
import IdleTimer from "react-idle-timer";

interface IProps {
  timeout: number;
  logout: () => void;
  stayLoggedIn: boolean;
}

export const TimeoutProvider = (props: IProps) => {
  let idleTimer;

  const onIdle = () => {
    if (!props.stayLoggedIn) {
      props.logout();
      localStorage.removeItem("lyra_last_active");
    }
  };

  const onAction = () => {
    localStorage.setItem("lyra_last_active", moment().unix().toString());
  };

  return (
    props.stayLoggedIn ? <></> :
    <IdleTimer
      ref={ (r) => idleTimer = r }
      onIdle={ onIdle }
      onAction={ onAction }
      throttle={60 * 1000}
      timeout={props.timeout}
    />
  );
};

export default TimeoutProvider;
