import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import '../styles/components/Shared/BigNumber.scss';
import { QSFA } from "./QSFA";
import AnimatedNumber from "react-animated-number";

export const BigNumber = ({
  value,
  type,
  attributeType,
  attributeLabel,
  attribute,
  className,
  title
}) => {
  let dispVal = '';
  switch (type) {
  case 'currency': {
    dispVal = (
      <span>
        ${value && value.toLocaleString() || 0}
      </span>
    );
    break;
  }
  case 'fromNow': {
    dispVal = <span>{moment.unix(value).fromNow()}</span>;
    break;
  }
  default: {
    dispVal = <span>{value}</span>;
    break;
  }
  }

  let dispAttribute = '';
  switch (attributeType) {
  case 'percent': {
    const icon =
        attribute >= 0 ? (
          <QSFA basic icon="arrow-circle-up" size="xsmall" iconPosition="none" />
        ) : (
          <QSFA basic icon="arrow-circle-down" size="xsmall" iconPosition="none" />
        );
    const cls = attribute > 0 ? 'attribute-positive' : 'attribute-negative';
    const val = Math.abs(attribute * 100).toFixed(1);
    dispAttribute = (
      <span className={ cls }>
        {icon}
        {val}% {attributeLabel}
      </span>
    );
    break;
  }
  case 'number': {
    dispAttribute = <span>{attribute}</span>;
    break;
  }
  case 'currency': {
    dispAttribute = (
      <span>
        ${attribute && attribute.toLocaleString() || 0}
      </span>
    );
    break;
  }
  default: {
    dispAttribute = <span>{attribute}</span>;
    break;
  }
  }

  const percentAnimation = () => {
    const icon =
    value >= 0 ? (
      <QSFA basic icon="arrow-circle-up" size="xsmall" iconPosition="none" />
    ) : (
      <QSFA basic icon="arrow-circle-down" size="xsmall" iconPosition="none" />
    );
    const cls = value > 0 ? "attribute-positive" : "attribute-negative";
    const v = Math.abs(value * 100).toFixed(1);

    if (type === "percent") {
      return (
        <span>
          <AnimatedNumber value={v}
            style={{
              transition: "0.8s ease-out",
              transitionProperty:
                "background-color, color, opacity"
            }}
            duration={750}
            stepPrecision={0}
            formatValue={n => Math.abs(n).toFixed(1)}
          />
          %
        </span>
      );
    } else if (type === "percentChange") {
      return (
        <span className={ cls }>
          {icon}
          <AnimatedNumber value={v}
            style={{
              transition: "0.8s ease-out",
              transitionProperty:
                "background-color, color, opacity"
            }}
            duration={750}
            stepPrecision={0}
            formatValue={n => Math.abs(n).toFixed(1)}
          />
          % <span className="percent-change-attribute">{attributeLabel}</span>
        </span>
      );
    }
  };

  return (
    <div className={ "big-number-container " + className }>
      <div className="big-number-title">{title}</div>
      <div className="big-number-value">
        {type !== "percentChange" && type !== "percent" ? ( 
          dispVal
        ):(
          percentAnimation()
        )}
        <div className="big-number-attribute">
          {dispAttribute}
        </div>
      </div>
    </div>
  );
};

BigNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  attributeType: PropTypes.string,
  attributeLabel: PropTypes.string,
  attribute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.string
};
