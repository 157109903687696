import { Form } from "formsy-semantic-ui-react";
import _ from "lodash";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";
import { CreateConceptComponent, ResourceType } from "../generated/graphql";
import { ErrorLabel, QSFA } from "../Shared";
import { getIcon } from "../Resources";

type IProps = RouteComponentProps;

const ConceptAdd = (props: IProps) => {
  const [name, setName] = React.useState("");
  const [valid, setValid] = React.useState(false);

  return (
    <React.Fragment>
      <QSFA icon={getIcon(ResourceType.Concept)} size="small" />
      <Breadcrumb size="big">
        <Breadcrumb.Section>
          <Link to={"/config/concepts"}> Concepts</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>New</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />

      <Segment basic >
        <Header as="h1" dividing>
          <QSFA icon={getIcon(ResourceType.Concept)} />New Concept
        </Header>
        <Divider hidden />
        <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
          <Header as="h3" content="Details" />
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Name is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <CreateConceptComponent>
            {(query, { loading }) => (
              <Button.Group>
                <Button content="Create Concept" primary
                  loading={loading}
                  onClick={() => {
                    if (valid) {
                      query({
                        variables: {
                          name,
                        },
                      }).then((r) => {
                        props.history.push(`/config/concepts/${r.data.createConcept.id}`);
                      });
                    }
                  }} />
                <Button
                  onClick={() => props.history.push(`/config/concepts`)}
                  content="Cancel" />
              </Button.Group>
            )}
          </CreateConceptComponent>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

export default withRouter(ConceptAdd);
