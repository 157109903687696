import { SignIn as AmplifySignIn } from "aws-amplify-react";
import moment from "moment";
import * as React from "react";
import { Button, Checkbox, Divider, Form, Grid, Header, Input } from "semantic-ui-react";
import { AuthContainer } from "./AuthContainer";

const staySignedIn = "lyra_stay_signed_in";

interface IState {
  loading: boolean;
  username: string;
  password: string;
  staySignedIn: boolean;
}

export default class SignIn extends AmplifySignIn {
  public _validAuthStates: string[];
  public state: IState;

  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut"];
    this.state = {
      loading: false,
      username: "",
      password: "",
      staySignedIn: false,
    };
  }

  public toggleStaySignedIn = () => {
    const s = this.state;
    s.staySignedIn = !s.staySignedIn;
    this.setState(s);
  }

  public showComponent() {
    return (
      <AuthContainer>
        <Header as="h4" textAlign="left">
          { " " }
          Login to your account
        </Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              id="username"
              key="username"
              icon="user"
              iconPosition="left"
              placeholder="Email address"
              type="email"
              name="username"
              autoComplete="on"
              onChange={(e) => {
                e.target.value = e.target.value.toLowerCase();
                this.handleInputChange(e);
              }}
            />
          </Form.Field>
          <Form.Field style={{ marginBottom: "0" }}>
            <Input
              fluid
              id="password"
              key="password"
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              name="password"
              autoComplete="on"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          <p style={{
              marginTop: "5px", color: "#555", fontSize: ".9em" }}>
            Forgot your password?{" "}
            <a style={{ cursor: "pointer" }}
              onClick={() => this.changeState("forgotPassword")}>
              Reset Password
            </a>
          </p>
          <Form.Field>
            <Grid stretched={false} >
              <Grid.Column width={3} >
                <Checkbox
                  fitted
                  toggle
                  name="keepLoggedIn"
                  onClick={ this.toggleStaySignedIn }
                />
              </Grid.Column>
              <Grid.Column width={13} >
                <Header as="h4">
                  <Header.Content>
                    Keep me signed in
                  </Header.Content>
                  <Header.Subheader>
                    Do not check if anyone else uses this device
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Form.Field>
          <Divider hidden />
          <Button loading={ this.state.loading } fluid color="blue" type="submit" onClick={(e) => {
            this.doSignIn(e);
            localStorage.setItem("lyra_last_active", moment().unix().toString());
            localStorage.setItem(staySignedIn, this.state.staySignedIn.toString());
          }}>
            Login
          </Button>
        </Form>
      </AuthContainer>
    );
  }

  private doSignIn = (e) => {
    this.setState({loading: true});
    this.signIn(e);
  }

}
