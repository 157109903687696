import React from "react";
import { Button, Dropdown, Header, Modal } from "semantic-ui-react";
import { AppContextParams, withAppContext } from "../Context";
import {
  GetActorAccessComponent,
  GrantTeamComponent,
  ResourceType,
} from "../generated/graphql";
import { QSFA } from "../Shared";

interface IProps extends AppContextParams {
  teamId: string;
  team?: {
    id: string;
  name: string;
    grants: any;
  };
  targetId: string;
  targetType: ResourceType;
  policy: string;
  onUpdate: () => void;
}

interface IResource {
  id: string;
  type: ResourceType;
}

type SelectedResource = IResource | undefined;

export default withAppContext((props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const selectedResource = { id: props.targetId, type: props.targetType };
  const [selectedPolicy, setSelectedPolicy] = React.useState(props.policy);

  React.useEffect(() => {
    setSelectedPolicy(props.policy);
  }, [props.policy]);

  const handleClose = () => {
    setOpen(false);
    setSelectedPolicy(props.policy);
  };

  return (
    <React.Fragment>
      <Button
        icon={<QSFA icon="edit" />}
        content="Edit Permissions"
        onClick={() => setOpen(true)}
      />
      <Modal size="tiny" open={open} onClose={() => handleClose()} closeOnDimmerClick={false} closeIcon>
        <Modal.Header>Grant Permission</Modal.Header>
        <Modal.Content>
          <GetActorAccessComponent
            fetchPolicy="no-cache"
            variables={{
              actorId: props.appContext.currentUser.id,
              resource: selectedResource,
            }}
          >
            {({ data, loading }) => (
                <>
                  <Header>
                    <Header.Subheader>
                      Select role from the list to add the appropriate permissions.
                    </Header.Subheader>
                  </Header>
                  <Dropdown
                    fluid
                    loading={loading}
                    selection
                    value={selectedPolicy}
                    onChange={(_, { value }) => setSelectedPolicy(value as string)}
                    placeholder="Resource Type"
                    options={data?.actor?.access?.policies?.map((i) => ({
                      text: i,
                      value: i,
                      key: i,
                    })) || []}
                  />
                </>
            )}
          </GetActorAccessComponent>
        </Modal.Content>
        <Modal.Actions>
          <GrantTeamComponent>
            {(query) => (
              <Button
                primary
                content="Grant"
                onClick={() => {
                  query({
                    variables: {
                      policies: [selectedPolicy],
                      targetId: selectedResource.id,
                      targetType: selectedResource.type,
                      teamId: props.teamId,
                    },
                  }).then(() => {
                    setOpen(false);
                    setSelectedPolicy(undefined);
                    if (props.onUpdate) {
                      props.onUpdate();
                    }
                  });
                }}
              />
            )}
          </GrantTeamComponent>
          <Button content="Close" onClick={() => handleClose()} />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
});
