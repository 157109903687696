import { actions } from "@quikserve/unityactions";
import React, { useRef } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Grid,
  Header,
  List,
  Loader,
  Menu,
  Modal,
  Segment,
  Form,
  Table,
  Input,
  Confirm
} from "semantic-ui-react";
import {
  AddEmployeeToTeamComponent,
  DeleteTeamComponent,
  GrantableEmployeesComponent,
  GroupType,
  RemoveEmployeeFromTeamComponent,
  RenameTeamComponent,
  ResourceType,
  SortDirection,
} from "../generated/graphql";
import SearchQuery, { IEmployee } from "../Search/SearchQuery";
import { FormattedName, LoadMore, QSFA } from "../Shared";
import AddPermissionButton from "./AddPermissionButton";

import RenderTeamPermissions from "./RenderTeamPermissions";

import { AppContextParams, withAppContext } from "../Context";

interface ITeam {
  id: string;
  name: string;
  grantedEmployees: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
  grants: Array<{
    target: {
      id: string;
      type: ResourceType;
      name: string;
      group?: {
        type: GroupType;
      };
    };
    actions: string[];
    policies: string[];
  }>;
}

interface IProps extends AppContextParams, RouteComponentProps<{ id: string }> {
  teams?: ITeam[];
  loading?: boolean;
  onUpdate: () => void;
}

const TeamList = (props: IProps) => {
  const [editingTeam, setEditingTeam] = React.useState<ITeam | undefined>();
  const [editingTeamView, setEditingTeamView] = React.useState<"add" | "remove">("add");
  const [searchInput, setSearchInput] = React.useState("");
  const [editingTeamName, setEditingTeamName] = React.useState("")
  const [isEditingName, setIsEditingName] = React.useState(false)
  const [showEditTeamModal, setShowEditTeamModal] = React.useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(null);
  const [searching, setSearching] = React.useState(false);
  const [filter, setFilter] = React.useState<any>();
  const inputRef = useRef<Input>()

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current, isEditingName])

  const mapSearchEmployee = (res: any) => res.source as IEmployee;

  React.useEffect(() => {
    if (editingTeam) {
      setEditingTeam(props.teams.find(t => t.id === editingTeam.id));
    }
  }, [props.teams])

  React.useEffect(() => {
    if (searchInput === "") {
      setSearching(false);
      setFilter(undefined)
      return
    }
    setSearching(true);
    setFilter({
      bool: {
        must: {
          multi_match: {
            fields: ["firstName", "lastName", "email", "phone"],
            query: searchInput,
            type: "phrase_prefix",
          },
        },
        filter: {
          term: {
            appAccessEnabled: true,
          },
        },
      },
    });
  }, [searchInput]);

  return (
    <React.Fragment>
      <List selection>
        {props.teams?.map((team) => (
          <Segment color="blue" key={team.id}>
            <Grid>
              <Grid.Column width={1} verticalAlign="middle">
                <QSFA icon="users" size="large" />
              </Grid.Column>
              <Grid.Column width={8} verticalAlign="middle">
                <Header size="medium">
                  <Header.Content>
                    {team.name}
                    {team.grantedEmployees.length > 0 && (
                      <Header.Subheader>
                        {team.grantedEmployees.map((i) => `${i.firstName} ${i.lastName}`).join(", ")}
                      </Header.Subheader>
                    )}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={7} textAlign="right">
                <Button
                  icon={<QSFA icon="edit" />}
                  content="Edit"
                  onClick={() => {
                    setEditingTeam(team);
                    setShowEditTeamModal(true);
                  }}
                />
                <Button
                  content={<QSFA icon="trash" basic />}
                  negative
                  onClick={() => {
                    setEditingTeam(team);
                    setShowDeleteConfirm(true);
                  }}
                />
              </Grid.Column>
            </Grid>

            <Header size="small">
              <Header.Content>
                Permissions
              </Header.Content>
            </Header>
            {team.grants.map((grant) => {
              return <RenderTeamPermissions
                policies={grant.policies}
                grant={grant.target}
                teamId={team.id}
                onUpdate={props.onUpdate}
                isReport={false}
              />;
            })}
            <AddPermissionButton
              teamId={props.match.params.id}
              team={team}
              onUpdate={props.onUpdate}
            />
          </Segment>
        ))}
      </List>
      {!props.loading && props.teams?.length === 0 && (
        <Segment basic textAlign="center">
          <Header icon>
            <Header.Content>No Teams</Header.Content>
            <Header.Subheader>
              There are no teams defined
            </Header.Subheader>
          </Header>
        </Segment>
      )}
      <Modal
        size="small"
        open={showEditTeamModal}
        onClose={() => { setEditingTeam(undefined); setSearchInput(""); setShowEditTeamModal(false); }}
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Edit Team</Modal.Header>
        <Modal.Content>
          <Header as="h3">Team Name</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={3}>Name</Table.Cell>
                <Table.Cell>
                  <>
                    {isEditingName ? (
                      <Input
                        size="small"
                        value={editingTeamName}
                        onChange={(e, v) => setEditingTeamName(v.value)}
                        ref={(ref) => inputRef.current = ref}
                      />
                    ) : editingTeam?.name}
                  </>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <RenameTeamComponent>
                    {(mutation, { loading }) => (
                      <Button
                        loading={loading}
                        onClick={() => {
                          if (isEditingName) {
                            // Checks the input value to prevent network calls on an unchanged name.
                            if (editingTeam.name === editingTeamName) {
                              setIsEditingName(!isEditingName)
                              return
                            }
                            if (editingTeamName.trim().length === 0) {
                              return
                            }
                            mutation({
                              variables: {
                                id: editingTeam.id,
                                name: editingTeamName.trim(),
                              }
                            }).then((res) => {
                              if (props.onUpdate) {
                                props.onUpdate();
                              }
                            }).catch(err => {
                              console.log("TeamList -> RenameTeam mutation failed", err)
                            });
                          }
                          setIsEditingName(!isEditingName)
                        }}
                      >
                        <QSFA icon={isEditingName ? "check" : "edit"} padded={false} />
                      </Button>

                    )}
                  </RenameTeamComponent>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>


          <GrantableEmployeesComponent
            fetchPolicy="network-only"
            variables={{
              disabled: false,
              sort: [
                { field: "first_name", direction: SortDirection.Asc },
              ],
            }}
          >

            {({ loading, data: empData, fetchMore }) => {
              return (
                <React.Fragment>
                  {loading && <Loader active />}
                  <Menu secondary pointing>
                    <Menu.Item
                      content="Add Employees"
                      active={editingTeamView === "add"}
                      onClick={() => setEditingTeamView("add")}
                    />
                    <Menu.Item
                      content="Remove Employees"
                      active={editingTeamView === "remove"}
                      onClick={() => setEditingTeamView("remove")}
                    />
                  </Menu>
                  <Segment>
                    {editingTeamView === "add" && (
                      <React.Fragment>
                        <Form.Input
                          fluid
                          icon="search"
                          placeholder="Type to filter"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                        {searching && (
                          <SearchQuery
                            namespaces={[
                              {
                                namespace: "employees",
                                action: actions.employees.read,
                              },
                            ]}
                            disabled={false}
                            filter={filter}
                          >
                            {(sResp) => (
                              <AddEmployeeList
                                teamId={editingTeam.id}
                                employees={sResp?.data?.hits?.map(
                                  mapSearchEmployee,
                                ).filter(e => e.appAccessEnabled).filter(e => !editingTeam.grantedEmployees.find(emp => emp.id === e.id))}
                                onAdded={() => {
                                  if (props.onUpdate) {
                                    props.onUpdate();
                                  }
                                }}
                              />
                            )}
                          </SearchQuery>
                        )}
                        {!searching && (
                          <>
                            <AddEmployeeList
                              teamId={editingTeam.id}
                              employees={empData?.employees?.nodes.filter(e => e.appAccessEnabled).filter(e => !editingTeam.grantedEmployees.find(emp => emp.id === e.id))}
                              onAdded={() => {
                                if (props.onUpdate) {
                                  props.onUpdate();
                                }
                              }}
                            />
                            <LoadMore
                              fetchMore={fetchMore}
                              nodeName="employees"
                              customText="Load more..."
                              cursor={empData?.employees?.cursor}
                              hasMore={empData?.employees?.cursor !== ""}
                            />
                          </>
                        )}
                      </React.Fragment>
                    )}
                    {editingTeamView === "remove" && (
                      <React.Fragment>
                        <List selection animated divided>
                          {editingTeam.grantedEmployees.map((g) => (
                            <React.Fragment key={g.id}>
                              <RemoveEmployeeFromTeamComponent>
                                {(query) => (
                                  <List.Item
                                    key={g.id}
                                    onClick={() => {
                                      query({
                                        variables: {
                                          employeeId: g.id,
                                          teamId:
                                            editingTeam.id,
                                        },
                                      }).then(() => {
                                        setSearchInput("");
                                        if (props.onUpdate) {
                                          props.onUpdate();
                                        }
                                      });
                                    }}
                                  >
                                    <List.Icon name="trash" color="red" />
                                    <List.Content><FormattedName employee={g} /></List.Content>
                                  </List.Item>
                                )}
                              </RemoveEmployeeFromTeamComponent>
                            </React.Fragment>
                          ))}
                        </List>
                      </React.Fragment>
                    )}
                  </Segment>
                </React.Fragment>
              )
            }
            }
          </GrantableEmployeesComponent>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={() => { setEditingTeam(undefined); setSearchInput(""); setShowEditTeamModal(false); }}
          />
        </Modal.Actions>
      </Modal>
      <DeleteTeamComponent>
        {(query) => (
          <Confirm
            header={"Delete Team"}
            content={`You are about to delete the ${editingTeam?.name} team. Are you sure?`}
            open={showDeleteConfirm}
            onCancel={() => {
              setShowDeleteConfirm(false);
            }}
            onConfirm={() => {
              query({variables: {teamId: editingTeam.id}}).then(() => {
                setShowDeleteConfirm(false);
                props.onUpdate();
              });
            }}
          />
        )}
      </DeleteTeamComponent>
    </React.Fragment >
  );
};

interface IAddEmployeeListProps {
  employees: Array<{
    id: string;
    firstName: string;
    lastName: string;
  }>;
  teamId: string;
  onAdded: () => void;
}

const AddEmployeeList = (props: IAddEmployeeListProps) => {
  const { employees, onAdded, teamId } = props;

  return (
    <List selection animated style={{ height: 300, overflowY: "scroll" }}>
      {employees?.map((emp) => (
        <AddEmployeeToTeamComponent
          key={emp.id}
          variables={{ employeeId: emp.id, teamId }}
        >
          {(query) => (
            <React.Fragment>
              <List.Item onClick={() => query().then(onAdded)}>
                <List.Icon name="plus" />
                <List.Content>
                  {emp.firstName} {emp.lastName}
                </List.Content>
              </List.Item>
            </React.Fragment>
          )}
        </AddEmployeeToTeamComponent>
      ))}
    </List>
  );
};

export default withRouter(withAppContext(TeamList))