import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IProps {
  icon: string;
  iconPosition?: "left" | "right";
  spin?: boolean;
  padded?: boolean | "none" | "small" | "regular" | "extra" | "regular-left" | "small-left" | "small-both";
  basic?: boolean;
  className?: string;
  onClick?: () => void;
  size?: string;
  location?: "top-nav";
  color?: string;
  text?: string;
  avatar?: boolean;
}

library.add(fal);
import "../styles/components/Shared/QSFA.scss";

export const QSFA = ({
  icon, iconPosition, padded, basic, className, onClick, spin, size, location, color, text, avatar }: IProps) => {
  let iconStr = `qs-icon`;
  if (padded === undefined) {
    padded = "regular";
  } else if (!padded) {
    padded = "none";
  }

  if (!text && !basic) {
    let paddingClass = " qs-icon-padded";

    switch (padded) {
      case "none":
        paddingClass = " qs-icon-none";
        break;
      case "small":
        paddingClass = " qs-icon-smpadded";
        break;
      case "regular":
        paddingClass = " qs-icon-padded";
        break;
      case "extra":
        paddingClass = " qs-icon-xpadded";
        break;
      case "regular-left":
        paddingClass = " qs-icon-paddedlt";
        break;
      case "small-left":
        paddingClass = " qs-icon-paddedsmlt";
        break;
      case "small-both":
        paddingClass = " qs-icon-paddedsmboth";
        break;
    }
    iconStr += paddingClass;
  }

  let outerClass = "qsfa";
  if (avatar) {
    iconStr += " avatar";
    outerClass += " avatar-container";
  }

  if (!iconPosition) { iconPosition = "left"; }

  if (className) { iconStr += (" " + className); }

  let fontSize = "1em";
  let maxWidth = "auto";
  switch (size) {
    case "xsmall":
      fontSize = ".7em";
      break;
    case "small":
      fontSize = "1em";
      break;
    case "medium":
      fontSize = "1.5em";
      maxWidth = "1em";
      break;
    case "large":
      fontSize = "2em";
      break;
    case "big":
      fontSize = "2.5em";
      break;
    case "huge":
      fontSize = "3em";
      break;
    case "giant":
      fontSize = "10em";
      break;
    case "sidebar":
      fontSize = "16px";
      break;
  }

  switch (location) {
    case "top-nav":
      color = "#fff";
      break;
    default:
      // do nothing
  }

  const style = {
    fontSize,
    maxWidth,
    color,
  };

  return (
    <i onClick={onClick} className={outerClass}>
      {text && iconPosition === "right" && <span style={{ marginRight: ".5em", fontStyle: "normal" }}>{text}</span> }
      <FontAwesomeIcon icon={["fal", icon] as IconProp} className={iconStr} spin={spin} style={style} />
      {text && iconPosition === "left" && <span style={{ marginLeft: ".5em", fontStyle: "normal" }}>{text}</span>}
    </i>
  );
};
