import React from "react";
import { RouteComponentProps } from "react-router";
import { ConceptGroupsComponent, ResourceType } from "../generated/graphql";
import { GroupManager } from "../Resources";
import { Button, Segment, Header } from "semantic-ui-react";
import { QSFA } from "../Shared";
import NewGroupButton from "../Resources/NewGroupButton";

export default (props: RouteComponentProps<{ id: string }>) => {
  return (
    <ConceptGroupsComponent variables={{ id: props.match.params.id }}>
      {({ data, loading, refetch }) => (
        <Segment basic vertical>
          <NewGroupButton
            onAdd={refetch}
            parentId={props.match.params.id}
            parentType={ResourceType.Concept} />
          <GroupManager
            loading={loading}
            parentId={props.match.params.id}
            parentType={ResourceType.Concept}
            groups={data && data.concept && data.concept.groups}
            refetch={refetch} />
        </Segment>
      )}
    </ConceptGroupsComponent>
  );

};
