import { Form } from "formsy-semantic-ui-react";
import { UnitCustomAttribute } from "generated/graphql";
import React from "react";
import { Button, Table } from "semantic-ui-react";
import { ErrorLabel, QSFA } from "../Shared";

interface IProps {
  attributes?: Array<{key: string, value: string}>;
  setAttributes?: (attrs: Array<{key: string, value: string}>) => void;
}

export default (props: IProps) => {
  return (
    <Table basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Name" />
          <Table.HeaderCell content="Value" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.attributes?.map((a, i) => (
          <Table.Row key={i}>
            <Table.Cell>
              <Form.Input
                name={`attrname${i}`}
                value={a.key}
                onChange={(e, v) => {
                  props.attributes[i].key = v.value;
                  props.setAttributes(props.attributes);
                }}
                required
                validations="maxLength:150"
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "Name is required",
                  maxLength: "Exceeded the max of 150 characters",
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                name={`attrval${i}`}
                value={a.value}
                onChange={(e, v) => {
                  props.attributes[i].value = v.value;
                  props.setAttributes(props.attributes);
                }}
                validations="maxLength:1024"
                errorLabel={ErrorLabel}
                validationErrors={{
                  maxLength: "Exceeded the max of 1024 characters",
                }}
              />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Button negative content={<QSFA icon="trash" basic />}
                onClick={() => {
                  props.setAttributes([...props.attributes.slice(0, i),
                    ...props.attributes.slice(i + 1)]);
                }}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>
            <Button content="New" primary
              onClick={ () => {
                props.setAttributes([...props.attributes, {key: "", value: ""}]);
              }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};
