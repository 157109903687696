import { ConfirmSignIn, ConfirmSignUp, Greetings, Loading, SignUp, VerifyContact, withAuthenticator } from "aws-amplify-react";
import moment from "moment";
import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { HotKeys } from "react-hotkeys";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import FirstSignIn from "./Authorization/FirstTimeSignIn";
import ForgotPassword from "./Authorization/ForgotPassword";
import RequireNewPassword from "./Authorization/RequireNewPassword";
import SignIn from "./Authorization/SignIn";
import { AppContextParams, withAppContext } from "./Context";
import { configureAmplify } from "./Utilities/amplify";
const AuthorizedApp = lazy(() => import("./AuthorizedApp"));

import { Auth } from "aws-amplify";
import { EmployeeStatusEnum } from "./generated/graphql";
import "./styles/App.scss";
import "./styles/Login.scss";
import "./styles/sidebar.scss";
import "./styles/reactTags.scss";
import { TimeoutProvider } from "./TimeoutProvider";

interface IProps extends RouteComponentProps, AppContextParams { }

const lastActive = "lyra_last_active";

const App = (props: IProps) => {
  const timeout = 15 * 60 * 1000; // 15 minutes
  const keyMap = {
    SEARCH: "s",
  };

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(() => {
      try {
        const staySignedIn = localStorage.getItem("lyra_stay_signed_in") === "true";
        // if stayLoggedIn don't bother checking last active time.
        if (!staySignedIn) {
          // get lastActive value from localStorage.
          if (localStorage.getItem(lastActive)) {
            // if diff of now and lastActive > timeout then logout.
            if (moment().diff(moment.unix(parseInt(localStorage.getItem(lastActive), 10)), "milliseconds") > timeout) {
              props.appContext.logout();
            } else {
              // otherwise go ahead and hydrate the user.
              props.appContext.hydrateUser();
            }
          } else {
            // lastActive had no value so going to force a logout.
            props.appContext.logout();
          }
        } else {
          // else go ahead and hydrate the user.
          props.appContext.hydrateUser();
        }
      } catch {
        // if something fails going to play it safe a force a logout.
        props.appContext.logout();
      }
    });

    if (window.analytics) {
      if (window.analytics.load) {
        window.analytics.load(process.env.REACT_APP_LYRA_SEGMENT_KEY);
      }
      window.analytics.page(props.history.location.pathname, {
        path: props.history.location.pathname,
        url: "https://app.quikserve.com/",
      });
      props.history.listen(() => {
        window.analytics.page(props.history.location.pathname, {
          path: props.history.location.pathname,
          url: "https://app.quikserve.com/",
        });
      });
    }
  }, []);

  return (
    <HotKeys keyMap={keyMap}>
      <React.Fragment>
        <TimeoutProvider
          timeout={timeout}
          logout={props.appContext.logout}
          stayLoggedIn={localStorage.getItem("lyra_stay_signed_in") === "true"}
        />
        <Helmet>
          <title>QuikServe</title>
        </Helmet>
        {props.appContext.currentUser ?
          props.appContext.currentUser.employee.status === EmployeeStatusEnum.Invited ?
            <FirstSignIn /> :
            <Suspense fallback={
              <Dimmer active>
                <Loader>Loading Application</Loader>
              </Dimmer>
            }>
              <AuthorizedApp />
            </Suspense> :
          <Dimmer active={!props.appContext.error}>
            <Loader>Loading Application</Loader>
          </Dimmer>
        }
      </React.Fragment>
    </HotKeys>
  );
};

configureAmplify();
export default withAuthenticator(withRouter(withAppContext(App)), false, [
  <SignIn />,
  <ForgotPassword />,
  <RequireNewPassword />,
  <Greetings />,
  <ConfirmSignIn />,
  <SignUp />,
  <ConfirmSignUp />,
  <VerifyContact />,
  <Loading />,
], null);
