import React, { useState } from "react";
import { Segment, Header, Grid, Button, List, Confirm } from "semantic-ui-react";
import { QSFA } from "../Shared/QSFA";
import { ResourceIcon, getIcon } from "../Resources";
import { DeleteGrantComponent, ResourceType } from "../generated/graphql";
import { EditGrantButton } from "./EditGrantButton";

interface IProps {
  sourceId: string;
  sourceType: ResourceType;
  noTeamEditing?: boolean
  onUpdate?: () => void;
  loading: boolean;
  grants: Array<{
    actions: string[];
    policies: string[];
    target: {
      id: string;
      type: ResourceType;
      name: string;
      group?: {
        name: string
        type: string
      }
    };
  }>;
}

export const GrantList = (props: IProps) => {
  const [deleteGrant, setDeleteGrant] = useState<{id: string, type: ResourceType, name: string}>(null)
  const { grants } = props;
  return (
    <Segment>
      <DeleteGrantComponent>
        {(query, { loading }) => (
          <Confirm
            header="Revoke Permission"
            content={`You are about to revoke this employee's permissions on ${deleteGrant?.type} ${deleteGrant?.name}. Permission can be restored at any time. Are you sure?`}
            open={deleteGrant !== null}
            onCancel={() => {
              setDeleteGrant(null);
            }}
            onConfirm={() => {
              query({
                variables: {
                  source: {
                    id: props.sourceId,
                    type: props.sourceType
                  },
                  target: {
                    id: deleteGrant?.id,
                    type: deleteGrant?.type
                  }
                },
              }).then(() => {
                props.onUpdate()
                setDeleteGrant(null)
              });
            }}
          />
        )}
      </DeleteGrantComponent>
      <List selection className="list-view" >
        {grants.map((grant, i) => (
          <List.Item
            key={i}
          >
            <List.Content floated="right">
              {(grant.target.group?.type !== "team" || !props.noTeamEditing) && (
                <EditGrantButton
                  actorId={props.sourceId}
                  onUpdate={props.onUpdate}
                  resourceId={grant.target.id}
                  resourceName={grant.target.name}
                  resourceType={grant.target.type as ResourceType}
                />
              )}
              <Button
                loading={props.loading}
                icon={<QSFA icon="ban" />}
                content="Revoke"
                onClick={() => setDeleteGrant({id: grant.target.id, type: grant.target.type, name: grant.target.name})}
              />
            </List.Content>
            <List.Icon verticalAlign="middle">
              <QSFA icon={getIcon(grant.target.type)} padded="small-both" size="medium" />
            </List.Icon>
            <List.Content>
              <Header as="h4">
                {grant.target.name}
              </Header>
              <List.Description>
                {grant.policies.join(", ")}
                {grant.policies.length === 0 && "Read only access"}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  );
};
