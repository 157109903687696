import { withFormsy } from "formsy-react";
import { Form } from "formsy-semantic-ui-react";
import React, { cloneElement } from "react";
import ReactPhoneInput from "react-phone-input-2";

const PhoneInput = (props: any) => {
  const [allowError, setAllowError] = React.useState(false);

  React.useEffect(() => {
    if (props.isFormSubmitted) {
      setAllowError(true);
    }
  });

  const handleChange = (e: string) => {
    e = e.replace(/ |-/g, "").replace("(", "").replace(")", "");
    e = `+${e}`;
    props.setValue(e);
    if (props.onChange) { props.onChange(e); }
  };

  const error = !props.isPristine && !props.isValid && allowError;

  return (
    <Form.Field required={props.required} error={error}>
      {props.label &&
        <label>{props.label}</label>
      }
      <ReactPhoneInput
        containerClass="ui input"
        country={"us"}
        onlyCountries={["us"]}
        value={props.value}
        onChange={handleChange}
      />
      {error && props.errorLabel && cloneElement(props.errorLabel, {}, props.errorMessage)}
    </Form.Field>
  );
};

export default withFormsy(PhoneInput);
