import { actions } from "@quikserve/unityactions";
import { Form, Select } from "formsy-semantic-ui-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";

import { CreateUnitComponent, ResourceType } from "../generated/graphql";
import { OrgConceptSelect, OrgSelect } from "../Organization";
import { getIcon } from "../Resources";
import { countryOptions, ErrorLabel, PhoneInput, QSFA, usStateOptions } from "../Shared";

const UnitAdd = (props: RouteComponentProps) => {
  const [name, setName] = React.useState("");
  const [identifier, setIdentifier] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [street1, setStreet1] = React.useState("");
  const [street2, setStreet2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [organizationId, setOrganizationId] = React.useState("");
  const [conceptIds, setConceptIds] = React.useState(null);
  const [valid, setValid] = React.useState(false);

  return (
    <React.Fragment>
      <QSFA icon={getIcon(ResourceType.Unit)} size="small" />
      <Breadcrumb size="big">
        <Breadcrumb.Section>
          <Link to={"/config/units"}> Units</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>New</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />

      <Segment basic>
        <Header as="h1" dividing>
          <QSFA icon={getIcon(ResourceType.Unit)} />New Unit
        </Header>
        <Divider hidden />
        <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
          <Header as="h3" content="Details" />
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Name is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <Form.Input
              name="identifier"
              label="Identifier"
              onChange={(e) => setIdentifier(e.target.value)}
              required
              value={identifier}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Identifier is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Organization</label>
              <OrgSelect
                onChange={(v) => {
                  setOrganizationId(v);
                  setConceptIds(null);
                }}
                required
                value={organizationId}
                action={actions.units.write}
              />
            </Form.Field>
            <Form.Field required>
              <label>Concept</label>
              <OrgConceptSelect
                onChange={(v) => {
                  v && v.length > 0 ? setConceptIds(v) : setConceptIds(null);
                }}
                required
                value={conceptIds}
                action={actions.units.write}
                multiple
                orgId={organizationId}
              />
            </Form.Field>
          </Form.Group>
          <Header as="h3" content="Contact Information" />
          <Form.Group widths="equal">
            <PhoneInput
              name="phone"
              label="Phone Number"
              value={phone}
              onChange={(v) => setPhone(v)}
              required
              validations="isLength:12"
              validationErrors={{
                isDefaultRequiredValue: "Phone is Required",
                isLength: "Invalid phone number",
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="email"
              label="Email"
              icon="mail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validations="isEmail"
              validationErrors={{
                isEmail: "Invalid email address",
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="street1"
              label="Street 1"
              onChange={(e) => setStreet1(e.target.value)}
              value={street1}
              required
              errorLabel={ErrorLabel}
              validations="maxLength:100"
              validationErrors={{
                isDefaultRequiredValue: "Street 1 is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <Form.Input
              name="street2"
              label="Street 2"
              onChange={(e) => setStreet2(e.target.value)}
              value={street2}
              errorLabel={ErrorLabel}
              validations="maxLength:100"
              validationErrors={{
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="city"
              label="City"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "City is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <div className="required field">
              <label>State</label>
              <Select required selection
                name="state"
                label="State"
                onChange={(e, v) => setState(v.value as string)}
                value={state}
                options={usStateOptions}
                errorLabel={ErrorLabel}
                search
                validationErrors={{
                  isDefaultRequiredValue: "State is Required",
                }}
              />
            </div>
            <Form.Input
              required
              name="zip"
              label="Zip"
              onChange={(e) => setZip(e.target.value)}
              value={zip}
              validations="maxLength:10"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Zip is Required",
                maxLength: "Exceeded the max of 10 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="required field">
              <label>Country</label>
              <Select required selection
                name="country"
                label="Country"
                onChange={(e, v) => setCountry(v.value as string)}
                value="US"
                options={countryOptions}
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "Country is Required",
                }}
              />
            </div>
          </Form.Group>
          <Button.Group>
            <CreateUnitComponent>
              {(update, resp) => (
                <Button
                  loading={resp.loading}
                  content="Create Unit"
                  primary
                  onClick={() => {if (valid) {
                      update({variables: {
                      name,
                      identifier,
                      phone,
                      email,
                      street1,
                      street2,
                      city,
                      state,
                      zip,
                      country,
                      organizationId,
                      conceptIds,
                    }}).then((r) => {
                      props.history.push(`/config/units/${r.data.createUnit.id}`);
                    }); }}
                  }
                />
              )}
            </CreateUnitComponent>
            <Button
              onClick={() => props.history.push(`/config/units`)}
              content="Cancel" />
          </Button.Group>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

export default withRouter(UnitAdd);
