import { Form, Select } from "formsy-semantic-ui-react";
import _ from "lodash";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";

import { CreateOrganizationComponent, ResourceType } from "../generated/graphql";
import { getIcon } from "../Resources";
import { countryOptions, ErrorLabel, PhoneInput, QSFA, usStateOptions } from "../Shared";

type IProps = RouteComponentProps;

const OrganizationAdd = (props: IProps) => {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [street1, setStreet1] = React.useState("");
  const [street2, setStreet2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [valid, setValid] = React.useState(false);

  return (
    <React.Fragment>
      <QSFA icon={getIcon(ResourceType.Organization)} size="small" />
      <Breadcrumb size="big">
        <Breadcrumb.Section>
          <Link to={"/config/organizations"}> Organizations</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>New</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />

      <Segment basic >
        <Header as="h1" dividing>
          <QSFA icon={getIcon(ResourceType.Organization)} />New Organization
        </Header>
        <Divider hidden />
        <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
          <Header as="h3" content="Details" />
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Name is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Header as="h3" content="Contact Information" />
          <Form.Group widths="equal">
            <PhoneInput
              name="phone"
              label="Phone Number"
              value={phone}
              onChange={(v) => setPhone(v)}
              required
              validations="isLength:12"
              validationErrors={{
                isDefaultRequiredValue: "Phone is Required",
                isLength: "Invalid phone number",
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="street1"
              label="Street 1"
              onChange={(e) => setStreet1(e.target.value)}
              value={street1}
              required
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Street is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <Form.Input
              name="street2"
              label="Street 2"
              onChange={(e) => setStreet2(e.target.value)}
              value={street2}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="city"
              label="City"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "City is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <div className="required field">
              <label>State</label>
              <Select
                required
                search={!isMobileOnly}
                selection
                name="state"
                label="State"
                onChange={(e, v) => setState((v.value || "").toString())}
                value={state}
                options={usStateOptions}
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "State is Required",
                }}
              />
            </div>
            <Form.Input
              required
              name="zip"
              label="Zip"
              onChange={(e) => setZip(e.target.value)}
              value={zip}
              validations="maxLength:10"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Zip is Required",
                maxLength: "Exceeded the max of 10 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="required field">
              <label>Country</label>
              <Select
                required
                search={!isMobileOnly}
                selection
                name="country"
                onChange={(e, v) => setCountry((v.value || "").toString())}
                value={"US"}
                options={countryOptions}
                placeholder="Select a country"
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "Country is Required",
                }}
              />
            </div>
          </Form.Group>
          <CreateOrganizationComponent>
            {(query, { loading }) => (
              <Button.Group>
                <Button content="Create Organization" primary
                  loading={loading}
                  onClick={() => {if (valid) {
                    if (!valid) { return; }
                    query({
                      variables: {
                        city,
                        country,
                        name,
                        phone,
                        state,
                        street1,
                        street2,
                        zip,
                      },
                    }).then((r) => {
                      props.history.push(`/config/organizations/${r.data.createOrganization.id}`);
                    });
                  }}} />
                <Button
                  onClick={() => props.history.push(`/config/organizations`)}
                  content="Cancel" />
              </Button.Group>
            )}
          </CreateOrganizationComponent>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

export default withRouter(OrganizationAdd);
