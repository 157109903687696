import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { CreateTeamComponent, CreateTeamMutationFn, ResourceType } from "../generated/graphql";
import { ErrorLabel } from "../Shared";

interface IProps {
  parentId: string;
  parentType: ResourceType;
  open?: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
}

export default (props: IProps) => {
  const [name, setName] = React.useState("");
  const { parentId, parentType, open, onClose, onUpdate } = props;

  const handleClose = () => {
    onClose && onClose();
    setName("");
  };

  const handleSubmit = (mutation: CreateTeamMutationFn) => {
    mutation({
      variables: {
        name: name.trim(),
        parentId,
        parentType,
      },
    }).then(() => {
      handleClose();
      onUpdate && onUpdate();
    });
    handleClose();
    onUpdate && onUpdate();
  }


  return (
    <CreateTeamComponent>
      {(query, { loading }) => (
        <Modal
          open={open}
          onClose={onClose}
          size="tiny"
          closeOnDimmerClick={false}
          closeIcon
        >
          <Modal.Header>New Team</Modal.Header>
          <Modal.Content>
            <Form
              onValidSubmit={() => handleSubmit(query)}
            >
              <Form.Input
                autoFocus
                name="name"
                label="Team Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                validations="maxLength:100"
                errorLabel={ErrorLabel}
                instantValidation
                validationErrors={{
                  isDefaultRequiredValue: "Name is required",
                  maxLength: "Exceeded the max of 100 characters",
                }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group>
              <Button
                primary
                disabled={name?.trim().length < 1 || name?.trim().length > 100}
                loading={loading}
                content="Create"
                onClick={() => handleSubmit(query)}
              />
              <Button content="Cancel" onClick={() => handleClose()} />
            </Button.Group>
          </Modal.Actions>
        </Modal>
      )}
    </CreateTeamComponent>
  );
};
