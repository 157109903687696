import { actions } from "@quikserve/unityactions";
import { get } from "lodash";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Input,
  Label,
  List,
  Menu,
  Message,
  Modal,
  Placeholder,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";

import moment from "moment";
import { ActorManager } from "../Actors";
import {
  CreateSystemActorComponent,
  DeleteActorComponent,
  DisableUnitComponent,
  EnableUnitComponent,
  GetUnitComponent,
  ResourceType,
  UnitSystemPermissionsComponent,
} from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import { FormattedDate, FormattedPhone, KeyVal, QSFA } from "../Shared";
import { AvatarHeader } from "../Shared/AvatarHeader";

interface IParams {
  id: string;
}

const UnitDetail = (props: RouteComponentProps<IParams>) => {
  const [showChangeStatusModal, setShowChangeStatusModal] = React.useState(false);
  const [showRevokeModal, setShowRevokeModal] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState("Details");
  const [copy1Success, setCopy1Success] = React.useState("");
  const [copy2Success, setCopy2Success] = React.useState("");
  const textArea1Ref = React.useRef(null);
  const textArea2Ref = React.useRef(null);

  const handleItemClick = (_e, { name }) => setActiveItem(name);

  const copyToClipboard1 = (e) => {
    copyText(e, textArea1Ref);
    setCopy1Success("Copied!");
    setCopy2Success("");
  };
  const copyToClipboard2 = (e) => {
    copyText(e, textArea2Ref);
    setCopy1Success("");
    setCopy2Success("Copied!");
  };
  const copyText = (e, ref) => {
    ref.current.select();
    document.execCommand("copy");
    e.target.focus();
  };

  return (
    <GetUnitComponent variables={{ id: props.match.params.id }}>
      {({ data, loading, refetch }) => (
        <React.Fragment>

          <QSFA icon={getIcon(ResourceType.Unit)} size="small" />
          <Breadcrumb size="big">
            <Breadcrumb.Section>
              <Link to={"/config/units"}> Units</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              {data?.unit?.name}
            </Breadcrumb.Section>
          </Breadcrumb>
          <Divider />

          <Menu attached="top" tabular>
            <Menu.Item
              name="Details"
              active={activeItem === "Details"}
              onClick={handleItemClick}
            />
            <Restrict action={actions.actors.write} type={ResourceType.Unit} id={props.match.params.id}>
              <Menu.Item
                name="Permissions"
                active={activeItem === "Permissions"}
                onClick={handleItemClick}
              />
            </Restrict>
          </Menu>

          <AvatarHeader
            name={data?.unit?.name.toString()}
            icon={getIcon(ResourceType.Unit)}
            phone={data?.unit?.phone}
          />

          {(activeItem === "Details") &&

            <Segment basic vertical loading={loading}>
              <Restrict action={actions.units.write} type={ResourceType.Unit} id={props.match.params.id} >
                <Button primary onClick={() => props.history.push(`/config/units/${props.match.params.id}/edit`)}>
                  <QSFA icon="edit" />Edit Details
                  </Button>
              </Restrict>
              <Restrict action={actions.units.write} type={ResourceType.Unit} id={props.match.params.id}>
                <Button onClick={() => setShowChangeStatusModal(true)}>
                  {data?.unit?.deletedAt > 0 ?
                    <React.Fragment><QSFA icon="toggle-on" />Enable Unit</React.Fragment>
                    : <React.Fragment><QSFA icon="ban" />Disable Unit</React.Fragment>
                  }
                </Button>
              </Restrict>
              <Restrict action={actions.units.transfer_ownership} type={ResourceType.Unit} id={props.match.params.id}>
                <Button onClick={() => props.history.push(`/config/units/${props.match.params.id}/transfer`)} >
                  <QSFA icon="exchange" />Transfer Unit
                </Button>
              </Restrict>
              <Message
                warning
                content="This unit is currently disabled"
                hidden={!get(data, "unit.deletedAt")}
              />
              <Header as="h3">
                <QSFA icon={getIcon(ResourceType.Unit)} size="small" />Details
                </Header>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={4}>Name</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.name}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Identifier</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.identifier}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Organization</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.organization.name}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Concept</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.concepts.map((c) =>
                        c.name,
                      ).join(", ")}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Status</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.deletedAt > 0 ? "Disabled" : "Active"}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>POS Status</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      <Popup
                        content={<FormattedDate date={data?.unit?.lastSeenAt} />}
                        trigger={
                          <QSFA
                            icon="signal-stream"
                            color={data?.unit?.online ? "green" : "red"}
                            text={data?.unit?.online ? "Online" : `Offline for ${moment.unix(data?.unit?.lastSeenAt).fromNow()}`} />
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Header as="h3">
                <QSFA icon="address-card" size="small" />Contact Information
                </Header>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={4}>Email Address</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit?.email}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Phone Number</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit && <FormattedPhone phone={data.unit.phone} />}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>Address</Table.Cell>
                    <Table.Cell>
                      {loading && (
                        <Placeholder>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      )}
                      {data?.unit &&
                        `${data.unit.street1}
                              ${data.unit.street2}
                              ${data.unit.city}, ${data.unit.state}, ${data.unit.zip}
                            `
                      }
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Header as="h3">
                <QSFA icon="check-circle" size="small" />Custom Attributes
                </Header>
              <Table definition>
                <Table.Body>
                  {data?.unit?.customAttributes?.map((a, i) => (
                    <Table.Row key={i}>
                      <Table.Cell width={4}>{a.key}</Table.Cell>
                      <Table.Cell>{a.value}</Table.Cell>
                    </Table.Row>
                  ))}
                  {(data?.unit?.customAttributes.length < 1) &&
                    <Table.Row>
                      <Table.Cell>N/A</Table.Cell>
                    </Table.Row>
                  }
                </Table.Body>
              </Table>

              <Header as="h3">
                <QSFA icon="history" size="small" />History
                  </Header>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width="5">Account created</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={get(data, "unit.createdAt")} />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width="3">Account last updated</Table.Cell>
                    <Table.Cell>
                      <FormattedDate date={get(data, "unit.updatedAt")} />
                    </Table.Cell>
                  </Table.Row>
                  {data?.unit?.deletedAt > 0 && (
                    <Table.Row>
                      <Table.Cell width="3">Account disabled{" "}</Table.Cell>
                      <Table.Cell><FormattedDate date={get(data, "unit.deletedAt")} /></Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>

              <EnableUnitComponent>
                {(query) => (
                  <Confirm
                    header="Enable Unit"
                    open={showChangeStatusModal && data.unit.deletedAt > 0}
                    onCancel={() => setShowChangeStatusModal(false)}
                    onConfirm={() => {
                      query({
                        variables: {
                          id: props.match.params.id,
                        },
                      }).then(() => {
                        refetch();
                        setShowChangeStatusModal(false);
                      });
                    }} />
                )}
              </EnableUnitComponent>
              <DisableUnitComponent>
                {(query) => (
                  <Confirm
                    header="Disable Unit"
                    content="You are about to disable this unit.
                        The unit can be re-enabled at any time. Are you sure?"
                    open={showChangeStatusModal && data.unit.deletedAt === 0}
                    onCancel={() => setShowChangeStatusModal(false)}
                    onConfirm={() => {
                      query({
                        variables: {
                          id: props.match.params.id,
                        },
                      }).then(() => {
                        refetch();
                        setShowChangeStatusModal(false);
                      });
                    }} />
                )}
              </DisableUnitComponent>
              <Divider hidden />
            </Segment>
          }

          {(activeItem === "Permissions") &&

            <Segment basic vertical loading={loading}>
              <UnitSystemPermissionsComponent variables={{ id: props.match.params.id }}>
                {(pResp) => (
                  <React.Fragment>
                    {pResp?.data?.unit?.systemActor ?
                      <React.Fragment>
                        <DeleteActorComponent>
                          {(query, { loading }) => (
                            <Confirm
                              header="Revoke Access"
                              content="You are about to revoke this units API access.
                                  Access can be restored at any time. Are you sure?"
                              open={showRevokeModal}
                              onCancel={() => setShowRevokeModal(false)}
                              onConfirm={() => {
                                query({
                                  variables: {
                                    id: pResp.data.unit.systemActor.id,
                                  },
                                }).then(() => {
                                  pResp.refetch();
                                  setShowRevokeModal(false);
                                });
                              }} />
                          )}
                        </DeleteActorComponent>
                        <Header as="h3">
                          <QSFA icon="globe" size="small" />API Access
                        </Header>
                        <Grid>
                          <Grid.Column width={12}>
                            <Grid.Row>
                              <Label basic color="blue">
                                <Table basic="very">
                                  <Table.Body>
                                    <Table.Row>
                                      <Table.Cell>
                                        <Label.Detail>API Key</Label.Detail>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Label.Detail>
                                          <Input
                                            style={{ width: "180px" }}
                                            transparent
                                            readonly
                                            ref={textArea1Ref}
                                            value={pResp.data.unit.systemActor.id} />
                                        </Label.Detail>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Button floated="right" onClick={copyToClipboard1}>Copy</Button>
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              </Label>
                              {copy1Success &&
                                <Header as="span" color="blue">&nbsp;&nbsp;{copy1Success}</Header>
                              }
                            </Grid.Row>
                            <Grid.Row>
                              <br />
                              <Label basic color="blue">
                                <Table basic="very">
                                  <Table.Body>
                                    <Table.Row>
                                      <Table.Cell>
                                        <Label.Detail>API Secret</Label.Detail>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Label.Detail>
                                          <Input
                                            style={{ width: "310px" }}
                                            transparent
                                            readonly
                                            ref={textArea2Ref}
                                            value={pResp.data.unit.systemActor.secret} />
                                        </Label.Detail>
                                      </Table.Cell>
                                      <Table.Cell>
                                        <Button floated="right" onClick={copyToClipboard2}>Copy</Button>
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              </Label>
                              {copy2Success &&
                                <Header as="span" color="blue">&nbsp;&nbsp;{copy2Success}</Header>
                              }
                              <Divider hidden />
                            </Grid.Row>
                          </Grid.Column>
                          <Grid.Column textAlign="right" width={4}>
                            <Button onClick={() => setShowRevokeModal(true)}>
                              <QSFA icon="ban" />Revoke Access
                            </Button>
                          </Grid.Column>
                        </Grid>
                        <Header as="h3">
                          <QSFA icon="project-diagram" size="small" />Permissions
                        </Header>
                        <ActorManager
                          actor={pResp.data.unit.systemActor}
                          disableResourcePicker
                          resourceId={props.match.params.id}
                          resourceType={ResourceType.Unit}
                          loading={pResp.loading}
                          onChange={() => pResp.refetch()}
                        />
                      </React.Fragment> : !pResp.loading &&
                      <React.Fragment>
                        <p>API Access is not enabled.</p>
                        <CreateSystemActorComponent>
                          {(query, { loading: loadSave }) => (
                            <Button
                              content="Enable Access"
                              primary
                              loading={loadSave}
                              onClick={() => {
                                query({
                                  variables: {
                                    resourceId: props.match.params.id,
                                    resourceType: ResourceType.Unit,
                                  },
                                }).then(() => {
                                  pResp.refetch();
                                });
                              }}
                            />
                          )}
                        </CreateSystemActorComponent>
                      </React.Fragment>
                    }
                  </React.Fragment>
                )}
              </UnitSystemPermissionsComponent>
              <Divider hidden />
              <EnableUnitComponent>
                {(query) => (
                  <Confirm
                    header="Enable Unit"
                    open={showChangeStatusModal && data.unit.deletedAt > 0}
                    onCancel={() => setShowChangeStatusModal(false)}
                    onConfirm={() => {
                      query({
                        variables: {
                          id: props.match.params.id,
                        },
                      }).then(() => {
                        refetch();
                        setShowChangeStatusModal(false);
                      });
                    }} />
                )}
              </EnableUnitComponent>
              <DisableUnitComponent>
                {(query) => (
                  <Confirm
                    header="Disable Unit"
                    content="You are about to disable this unit.
                        The unit can be re-enabled at any time. Are you sure?"
                    open={showChangeStatusModal && data.unit.deletedAt === 0}
                    onCancel={() => setShowChangeStatusModal(false)}
                    onConfirm={() => {
                      query({
                        variables: {
                          id: props.match.params.id,
                        },
                      }).then(() => {
                        refetch();
                        setShowChangeStatusModal(false);
                      });
                    }} />
                )}
              </DisableUnitComponent>
            </Segment>
          }
        </React.Fragment>
      )}
    </GetUnitComponent>
  );
};

export default withRouter(UnitDetail);
