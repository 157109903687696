import Auth from "@aws-amplify/auth";
import moment from "moment";
import React from "react";
import { client } from "../apollo";
import hydrateQuery from "../Queries/hydrateActor.graphql";
import { logger, Logger } from "../Utilities/Logger";

interface IUser {
  id: string;
  name: string;
  resourceType: string;
  resourceId: string;
  employee: {
    id: string;
    firstName: string;
    lastName: string;
    organizationId: string;
    email: string;
    phone: string;
    status: string;
    updatedAt: number;
    createdAt: number;
    carbonIdentifier: string;
    carbonPin: string;
    carbonAccessToken: string;
  };
  permissions: {
    singleUnit: boolean,
    inventory: boolean,
    reports: boolean,
  };
}
type Unit = any;
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Bound<P> = React.SFC<Omit<P, keyof IAppContextParams>>;
type Incomming<P> = React.ComponentType<P>;

interface ISession {
  isLoggedIn: boolean;
}

interface IAppContextParams {
  appContext: AppContext;
}

interface IAppContext {
  currentModule: string;
  currentUser?: IUser;
  currentUnit?: Unit;
  logger: Logger;
  unitSelectorEnabled: boolean;
  disableUnitSelector: () => void;
  setCurrentUnit: (_: string) => void;
  hydrateUser: () => void;
  setCurrentUser: (_: IUser) => void;
  setCurrentModule: (_: string) => void;
  can: (a: string, b: string) => boolean;
  logout: () => void;
  touchEnabled: boolean;
  enableTouch: () => void;
  cache: [];
  error?: boolean;
}

export type AppContext = IAppContext;

const Context = React.createContext<AppContext>({} as AppContext);
const lastAuthId = "lyra_last_auth_id";
const lastActive = "lyra_last_active";

export const withAppContext = <P extends object>(Component: Incomming<P>): Bound<P> => (props) => (
  <Context.Consumer>
    {(value: AppContext) => <Component {...props as P} appContext={value} />}
  </Context.Consumer>
);
export class AppContextProvider extends React.Component {
  public state: AppContext = {
    can: (module: string, action: string) => {
      // todo
      return true;
    },
    currentModule: "",
    disableUnitSelector() {
      this.unitSelectorEnabled = false;
    },
    hydrateUser: () => {
      Auth.currentAuthenticatedUser().then((u) => {
        client.query({
          query: hydrateQuery,
          variables: {
            id: u.attributes.sub,
          },
        }).then((res) => {
          this.setState({
            ...this.state,
            currentUser: res.data.actor,
          });
          if (!localStorage.getItem(lastAuthId) || localStorage.getItem(lastAuthId) !== res.data.actor.id) {
            window.location.href = "/";
          }
          localStorage.setItem(lastAuthId, res.data.actor.id);
          logger.actor = res.data.actor;
          logger.info("Loading App");
        }).catch((err) => {
          this.setState({
            ...this.state,
            error: true,
          });
          // todo
          // window.location.href = "/error";
        });
      }).catch((e) => console.log(e));
    },
    logger,
    logout: async () => {
      Auth.signOut();
      window.location.href = "/";
      localStorage.removeItem(lastActive);
    },
    setCurrentModule: (currentModule: string) => {
      this.setState({
        ...this.state,
        currentModule,
      });
    },
    setCurrentUnit: (currentUnit: string) => {
      this.setState({
        ...this.state,
        currentUnit,
      });
    },
    setCurrentUser: (currentUser: IUser) => {
      this.setState({
        ...this.state,
        currentUser,
      });
    },
    unitSelectorEnabled: false,
    touchEnabled: false,
    enableTouch: () => {
      this.setState({
        ...this.state,
        touchEnabled: true,
      });
    },
    cache: [],
  };

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export type AppContextParams = IAppContextParams;
