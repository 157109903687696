import React from "react";
import { Button, List } from "semantic-ui-react";
import { FormattedName } from "../Shared";

interface IEmployee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface IProps {
  id: string;
  employees: IEmployee[];
  excludedEmployees: string[];
  setExcludedEmployees: (e: string[]) => void;
}

const EmployeePicker = (props: IProps) => {
  const [selectUsersActiveButton, setSelectUsersActiveButton] = React.useState("selectAll");

  return (
    <>
      <Button.Group>
        <Button
          basic
          compact
          active={selectUsersActiveButton === "selectAll"}
          onClick={() => {
            setSelectUsersActiveButton("selectAll");
            props.setExcludedEmployees([]);
          }}
        >
          Select All
        </Button>
        <Button
          basic
          compact
          active={selectUsersActiveButton === "deselectAll"}
          onClick={() => {
            setSelectUsersActiveButton("deselectAll");
            let excludedEmployeeArray = [];
            props.employees?.map((employee, i) => {
              excludedEmployeeArray.push(employee.id);
            });
            props.setExcludedEmployees(excludedEmployeeArray);
          }}
        >
          Deselect All
        </Button>
      </Button.Group>
      <List
        selection
        divided
        relaxed
        verticalAlign="middle"
      >
        {props.employees?.map((employee, i) => {
          return (
            <>
              <List.Item
                key={i + employee.id}
                onClick={() => {
                  setSelectUsersActiveButton("");
                  if (props.excludedEmployees.includes(employee.id)) {
                    props.setExcludedEmployees(
                      props.excludedEmployees.filter(
                        i => i !== employee.id,
                      )
                    );
                  } else {
                    // Exclude user
                    props.setExcludedEmployees([
                      employee.id,
                      ...props.excludedEmployees,
                    ]);
                  }
                }}
                active={props.excludedEmployees.includes(employee.id)}
              >
              <List.Icon
                color={
                  props.excludedEmployees.includes(employee.id)
                    ? "grey"
                    : "green"
                }
                size="large"
                name={
                  props.excludedEmployees.includes(employee.id)
                    ? "times circle outline"
                    : "check circle outline"
                }
                verticalAlign="middle"
              />
              <List.Content>
                <List.Header>
                  <FormattedName employee={employee} />
                  {`   `}
                  ({employee.email})
                </List.Header>
              </List.Content>
            </List.Item>
            </>
           );
         })}
      </List>
    </>
  );
};

export default EmployeePicker;
