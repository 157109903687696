import Auth from "@aws-amplify/auth";

export function configureAmplify() {
  Auth.configure({
    Auth: {
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_PASSWORD_AUTH",

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",

      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_LYRA_AWS_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: process.env.REACT_APP_LYRA_AWS_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_LYRA_COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_LYRA_COGNITO_CLIENT_ID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //     domain: ".yourdomain.com",
      // OPTIONAL - Cookie expiration in days
      //     expires: 365,
      // OPTIONAL - Cookie path
      //     path: "/",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //     secure: true,
      // },
    },
  });
}
