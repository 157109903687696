export const resources = (_: any, data: any) => {
  const resources = [];

  if (data.type == "unit") {
    resources.push({
      __typename: "Resource",
      id: "18",
      type: "unit",
      name: "1001"
    });
    resources.push({
      __typename: "Resource",
      id: "2",
      type: "unit",
      name: "Lario Lands"
    });
    resources.push({
      __typename: "Resource",
      id: "3",
      type: "unit",
      name: "1003"
    });
  }

  if (data.type == "employee") {
    resources.push({
      __typename: "Resource",
      id: "1",
      type: "employee",
      name: "Tommy Johns"
    });
    resources.push({
      __typename: "Resource",
      id: "2",
      type: "employee",
      name: "John Doe"
    });
    resources.push({
      __typename: "Resource",
      id: "3",
      type: "employee",
      name: "Test Admin"
    });
  }

  return resources;
};
