import React from "react";

interface IProps {
  phone: string;
}

export const FormattedPhone = (props: IProps) => {
  const { phone } = props;
  const formattedPhone = () => {
    let p = phone;
    p = p.replace(/-/g, "");
    p = p.replace(/\(/g, "");
    p = p.replace(/\)/g, "");
    p = p.replace(/\s/g, "");
    if (p.length === 10) {
      const area = p.substring(0, 3);
      const prefix = p.substring(3, 6);
      const lineNumber = p.substring(6);
      return `(${area}) ${prefix}-${lineNumber}`;
    }
    if (p.length === 11) {
      const country = p.substring(0, 1);
      const area = p.substring(1, 4);
      const prefix = p.substring(4, 7);
      const lineNumber = p.substring(7);
      return `${country} (${area}) ${prefix}-${lineNumber}`;
    }
    if (p.length === 12) {
      const country = p.substring(1, 2);
      const area = p.substring(2, 5);
      const prefix = p.substring(5, 8);
      const lineNumber = p.substring(8);
      return `${country} (${area}) ${prefix}-${lineNumber}`;
    }
    return "";
  };

  return (<React.Fragment>{formattedPhone()}</React.Fragment>);
};
