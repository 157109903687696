import React from "react"
import { Segment, List, Button, Header } from "semantic-ui-react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ResourceType, ConceptTeamsComponent } from "../generated/graphql"
import { NewTeamModal, TeamList } from "../Team"
import { QSFA } from "../Shared"

interface IProps extends RouteComponentProps<{ id: string }> {

}

export default withRouter((props) => {
  const [newTeamModal, setNewTeamModal] = React.useState(false);

  return (
    <Segment basic vertical>
      <Button primary content="Add Team" icon={<QSFA icon="edit" />} onClick={() => setNewTeamModal(true)} />

      {/* ToDo: Update button. Property deletedAt does not exist on Concept yet */}
      {/* <Button content="Disable Concept" icon="ban" onClick={() => setNewTeamModal(true)} /> */}
      
      <Header as="h3">
        <QSFA icon="project-diagram" size="small" />Team Access / Permissions
      </Header>
      <ConceptTeamsComponent variables={{ id: props.match.params.id }}>
        {({ data, loading, refetch }) => (
          <React.Fragment>
            <TeamList
              loading={loading}
              teams={data?.concept?.teams}
              onUpdate={refetch}
            />
            <NewTeamModal
              parentId={props.match.params.id}
              parentType={ResourceType.Concept}
              open={newTeamModal}
              onUpdate={refetch}
              onClose={() => setNewTeamModal(false)}
            />
          </React.Fragment>
        )}
      </ConceptTeamsComponent>
    </Segment>
  );
});
