import React from "react";
import { Link, NavLink, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Breadcrumb, Menu, Segment, Divider } from "semantic-ui-react";
import { ConceptComponent, ResourceType } from "../generated/graphql";
import { QSFA } from "../Shared";
import ConceptDetail from "./ConceptDetail";
import Access from "./Access";
import Teams from "./Teams";
import { getIcon } from "../Resources";
import { AvatarHeader } from "../Shared/AvatarHeader";

export default (props: RouteComponentProps<{ id: string }>) => {
  const [activeItem, setActiveItem] = React.useState();

  const handleItemClick = (_e, { name }) => setActiveItem(name);

  return (
    <React.Fragment>
      <ConceptComponent variables={{ id: props.match.params.id }}>
        {({ data, loading }) =>

          <>
            <QSFA icon={getIcon(ResourceType.Concept)} size="small" />
            <Breadcrumb size="big">
              <Breadcrumb.Section>
                <Link to={"/config/concepts"}> Concepts</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section active>
                {data?.concept?.name}
              </Breadcrumb.Section>
            </Breadcrumb>
            <Divider />

            <Menu attached="top" tabular>
              <Menu.Item
                exact
                as={NavLink}
                to={`${props.match.url}`}
                name="Details"
                active={activeItem === "Details"}
                onClick={handleItemClick}
              />
              <Menu.Item
                as={NavLink}
                to={`${props.match.url}/unit-groups`}
                name="Unit Groups"
                active={activeItem === "Unit Groups"}
                onClick={handleItemClick}
              />
              <Menu.Item
                as={NavLink}
                to={`${props.match.url}/teams`}
                name="Teams"
                active={activeItem === "Teams"}
                onClick={handleItemClick}
              />
            </Menu>

            <AvatarHeader
              name={data?.concept?.name.toString()}
              icon={getIcon(ResourceType.Concept)}
            />
          </>
        }
      </ConceptComponent>

      <>
        <Switch>
          <Route path={`${props.match.path}/teams`} component={Teams} />
          <Route path={`${props.match.path}/unit-groups`} component={Access} />
          <Route path={`${props.match.path}`} component={ConceptDetail} />
        </Switch>
      </>
    </React.Fragment>
  );
};
