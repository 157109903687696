import { Form } from "formsy-semantic-ui-react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
  Step,
  Loader,
  Message,
  Confirm,
} from "semantic-ui-react";
import { ResourceType, GetOrganizationComponent, GetUnitEmployeesComponent, TransferUnitComponent, GetUnitDocument } from "../generated/graphql";
import { getIcon } from "../Resources";
import { QSFA } from "../Shared";
import { OrgSelect } from "../Organization";
import { actions } from "@quikserve/unityactions";
import EmployeePicker from "../Employee/EmployeePicker";

interface IParams {
  id: string;
}

interface IProps extends RouteComponentProps {
  unit: IUnit;
  loading: boolean;
}

interface IUnit {
  id: string;
  name: string;
  organization: any;
}

type IPropsAll = RouteComponentProps<IParams> & IProps;

const UnitTransferForm = (props: IPropsAll) => {
  const [step, setStep] = React.useState(1);
  const [organizationId, setOrganizationId] = React.useState("");
  const [valid, setValid] = React.useState(false);
  const [excludedEmployees, setExcludedEmployees] = React.useState([]);
  const [createError, setCreateError] = React.useState("");
  const [showTransferModal, setShowTransferModal] = React.useState(false);

  const getExcludedResources = () => {
    if (!excludedEmployees.length) {
      return [];
    }
    const excludedResources = excludedEmployees.map((id, i) => {
      return (
        {
          id,
          type: ResourceType.Employee,
        }
      );
    });
    return excludedResources;
  };

  return (
    <React.Fragment>
      <QSFA icon={getIcon(ResourceType.Unit)} size="small" />
      <Breadcrumb size="big" >
        <Breadcrumb.Section>
          <Link to={"/config/units"}> Units</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>Transfer</Breadcrumb.Section>
        <Breadcrumb.Divider icon="exchange" />
        <Breadcrumb.Section active>{props.unit?.name}</Breadcrumb.Section>
      </Breadcrumb>

      <Segment basic vertical loading={props.loading}>
        <Step.Group fluid>
          <Step active={step === 1}>
            <QSFA icon={getIcon(ResourceType.Organization)} size="medium" />
            <Step.Content>
              <Step.Title>Choose an Organization</Step.Title>
              <Step.Description>Must be part of the same concept</Step.Description>
            </Step.Content>
          </Step>
          <Step active={step === 2}>
            <QSFA icon="user" size="large" />
            <Step.Content>
              <Step.Title>Select Users for Transfer</Step.Title>
              <Step.Description>Which employees or users should be assigned</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
      </Segment>
      {createError &&
        <Message negative>
          <Message.Header>Did the destination organization have the same concept(s)
            as this unit you are transferring is assigned to?</Message.Header>
          <p>{createError.toString()}</p>
        </Message>
      }

      {step === 1 &&
        <>
          <Header as="h3" >
            <QSFA icon={getIcon(ResourceType.Organization)} size="xsmall" />
            Select the Destination Organization
          </Header>
          <Divider hidden />
          <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
            <Form.Field>
              <label>Transfer from</label>
              <Button fluid disabled style={{ textAlign: "left" }}>
                {props?.unit?.organization?.name}
              </Button>
            </Form.Field>
            <Divider hidden />

            <Form.Field required>
              <label>Transfer to</label>
              <OrgSelect
                onChange={(v) => {
                  setOrganizationId(v);
                }}
                required
                value={organizationId}
                action={actions.units.transfer_ownership}
              />
            </Form.Field>

            <Divider hidden />

            <Button.Group>
              <Button
                primary
                disabled={!valid}
                onClick={() => {
                  setStep(2);
                }}
              >
                Select &amp; Continue
              </Button>
              <Button
                onClick={() => props.history.push(`/config/units/${props.unit.id}`)}
                content="Cancel" />
            </Button.Group>
          </Form>
        </>
      }
      {step === 2 &&
        <>
          <Header as="h3" >
            <QSFA icon={getIcon(ResourceType.Organization)} size="xsmall" />
            Select the Users to Transfer
          </Header>
          <Divider hidden />
          <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
            <GetOrganizationComponent variables={{ id: organizationId }}>
              {({ data, loading }) => {
                return (
                  <>
                    <Form.Field>
                      <label>Transfer to</label>
                      <Button fluid disabled style={{ textAlign: "left" }}>
                        {data?.organization?.name}
                      </Button>
                    </Form.Field>
                    <Divider hidden />
                  </>
                );
              }}
            </GetOrganizationComponent>

            <Form.Field required>
              <label>Select Users</label>
              <GetUnitEmployeesComponent variables={{ id: props?.unit?.id }}>
                {({ data, loading }) => {
                  return (
                    loading || !data?.unit ?
                      <Loader active inline="centered" content="loading" /> :
                      <EmployeePicker
                        id={props?.unit?.id}
                        employees={data?.unit?.employees?.nodes.sort((a, b) => a.firstName.localeCompare(b.firstName))}
                        excludedEmployees={excludedEmployees}
                        setExcludedEmployees={setExcludedEmployees}
                      />
                  );
                }}
              </GetUnitEmployeesComponent>
            </Form.Field>
            <Divider hidden />

            <TransferUnitComponent>
              {(update, resp) => (
                <Confirm
                  header="Transfer Users"
                  content="You are about to transfer unit users from one organization to another. Are you sure?"
                  open={showTransferModal}
                  onCancel={() => setShowTransferModal(false)}
                  onConfirm={() => {
                    setShowTransferModal(false);
                    const excludedResources = getExcludedResources();
                    update({
                      variables: {
                        unitId: props.unit?.id,
                        organizationId,
                        excludedResources,
                      },
                      refetchQueries: [
                        {
                          query: GetUnitDocument,
                          variables: { id: props.unit?.id }
                        },
                      ]
                    })
                      .then(() => {
                        setShowTransferModal(false);
                        props.history.push(`/config/units/${props.unit.id}`);
                      })
                      .catch((err) => {
                        setCreateError(err);
                      });
                  }} />
              )}
            </TransferUnitComponent>

            <Button.Group>
              <Button
                primary
                onClick={() => {
                  setShowTransferModal(true);
                }}
                content="Complete Transfer" />
              <Button
                onClick={() => props.history.push(`/config/units/${props.unit.id}`)}
                content="Cancel" />
            </Button.Group>
          </Form>
        </>
      }
    </React.Fragment>
  );
};

export default withRouter(UnitTransferForm);
