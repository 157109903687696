import React, { ReactNode, useRef } from "react";
import {Transition} from "react-spring/renderprops";

import "../styles/QSPanel.scss";

interface IProps {
  children: ReactNode;
  visible: boolean;
  hide?: () => void;
  rendered?: (rendered: boolean) => void;
}

const QSPanel = (props: IProps) => {

  React.useEffect(() => {
    if (props.visible) {
      if (props.hide) {
        document.addEventListener("mousedown", handleClick, false);
      }
      if (props.rendered) {
        props.rendered(true);
      }
      return () => {
        document.removeEventListener("mousedown", handleClick, false);
      };
    }
  }, [props.visible]);

  const handleClick = (e) => {
    if (!props.hide) { return; }
    if (contextRef && contextRef.current && contextRef.current.contains(e.target)) {
      return;
    }
    props.hide();
  };

  const contextRef = useRef(null);
  return(
    <div className={`qs-panel-container ${props.visible ? "visible" : ""}`}>
      <Transition
        items={props.visible}
        from={{ width: "0px" }}
        enter={{ width: "400px" }}
        leave={{ width: "0px" }}>
        {(show) => show && ((tProps) => <div style={tProps} className="qs-panel" ref={contextRef}>
          {props.children}
        </div>)}
      </Transition>
    </div>
  );
};

export default QSPanel;
