type MockResolver<T> = () => T;
type FactoryFunction<T extends { id: string }> = (props: Partial<T>) => T;

export function mockId(): string {
  return Math.floor((Math.random() * 1000000)).toString();
}

export function createFactory<T extends { id: string }>(defaults: (() => Omit<T, "id">)): FactoryFunction<T> {
  return (overrides: Partial<T> = {}) => {
    return {
      id: mockId(),
      ...defaults(),
      ...overrides,
    } as T;
  };
}

export function createRepository<T extends { id: string }>(factory: FactoryFunction<T>, startingRecords: number = 0) {
  return new Repository<T>(factory, startingRecords);
}

class Repository<T extends { id: string }> {
  public factory: FactoryFunction<T>;
  public items: T[] = [];
  constructor(factory: FactoryFunction<T>, startingRecords: number) {
    this.factory = factory;
    for (let x = 0; x < startingRecords; x++) {
      this.items.push(this.factory({}));
    }
  }
  public insert(props: Partial<T>): T {
    const itm = this.factory(props);
    this.items.push(itm);
    return itm;
  }

  public find(id: string): T {
    return this.items.find((i) => i.id === id);
  }
  public delete(id: string): T {
    const itm = this.find(id);
    this.items = this.items.filter((i) => i.id !== id);
    return itm;
  }
  public update(id: string, props: Partial<T>) {
    const item = this.find(id);
    this.items = this.items.filter((i) => i.id !== id);
    this.items.push({
      ...item,
      ...props,
    });
  }
}

export function mock<T>(data: T): MockResolver<T> {
  return () => data;
}
