import { ForgotPassword as AmplifyForgotPassword } from "aws-amplify-react";
import * as React from "react";
import { Button, Form, Grid, Header, Input } from "semantic-ui-react";
import { AuthContainer } from "./AuthContainer";

interface IProps {
  authState: string;
  hide: boolean;
  authData: any;
}

interface IState {
  delivery: any;
}

export default class ForgotPassword extends AmplifyForgotPassword {
  public _validAuthStates: string[];

  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  public showComponent() {
    const { authState, hide, authData= {} } = this.props;

    return (
      <AuthContainer>
        <Header as="h4" textAlign="left">
          { " " }
          Reset your password
        </Header>
        <Form>
          { this.state.delivery || authData.username ?
            <React.Fragment>
              <Form.Field>
                <Input
                  fluid
                  id="code"
                  key="code"
                  icon="code"
                  iconPosition="left"
                  placeholder="Enter reset code"
                  type="text"
                  name="code"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  fluid
                  id="password"
                  key="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Enter a new password"
                  type="password"
                  name="password"
                  autoComplete="off"
                  onChange={this.handleInputChange}
                />
              </Form.Field>
            </React.Fragment> :
            <Form.Field>
              <Input
                fluid
                id="username"
                key="username"
                icon="user"
                iconPosition="left"
                placeholder="Enter your email address"
                type="email"
                name="username"
                autoComplete="on"
                onChange={this.handleInputChange}
              />
            </Form.Field>
          }
          <Grid columns={2}>
            <Grid.Column textAlign="left" verticalAlign="bottom">
              { this.state.delivery || authData.username ?
                <a style={{ cursor: "pointer" }}
                  onClick={ () => this.send() }>
                  Resend Code
                </a> :
                <a style={{ cursor: "pointer" }}
                  onClick={ () => this.changeState("signIn") }>
                  Back to Sign In
                </a>
              }
            </Grid.Column>
            <Grid.Column textAlign="right">
              { this.state.delivery || authData.username ?
                <Button color="blue" type="submit" onClick={ () => this.submit() }>
                  Submit
                </Button> :
                <Button color="blue" type="submit" onClick={ () => this.send() }>
                  Send Code
                </Button>
              }
            </Grid.Column>
          </Grid>
        </Form>
      </AuthContainer>
    );
  }
}
