import * as React from "react";

interface IEmp {
  firstName: string;
  lastName: string;
}

interface IProps {
  employee?: IEmp;
  value?: string;
}

export const FormattedName = (props: IProps) => {
  const capitalizeFirstLetter = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const formattedName = () => {
    if (!props.employee && !props.value) return null;

    let formattedValue = "";
    if (props.value) {
      formattedValue = capitalizeFirstLetter(props.value);
      return formattedValue;
    }

    let fName = "";
    if (props.employee.firstName !== undefined) {
      fName = capitalizeFirstLetter(props.employee.firstName);
    }
    if (props.employee.lastName !== undefined) {
      fName += " ";
      fName += capitalizeFirstLetter(props.employee.lastName);
    }
    return fName;
  };

  return (<React.Fragment>{formattedName()}</React.Fragment>);
};
