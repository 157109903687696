import React, {PropsWithChildren} from "react";

interface IGridProps {
  style?: any;
  flexDirection?: "row" | "column";
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}

const FlexGrid = (props: PropsWithChildren<IGridProps>) => {
  const containerStyle: React.CSSProperties = {
    alignItems: props.alignItems ? props.alignItems : "center",
    display: "flex",
    flexDirection: props.flexDirection ? props.flexDirection : "row",
    justifyContent: props.justifyContent ? props.justifyContent : "space-between",
    ...props.style,
  };
  return (
    <div style={containerStyle}>
      {props.children}
    </div>
  );
};

interface IItemProps {
  flex?: number;
  fitted?: boolean;
  fittedRight?: boolean;
  verticallyFitted?: boolean;
  style?: any;
}

FlexGrid.Item = (props: PropsWithChildren<IItemProps>) => {
  const itemStyle: React.CSSProperties = {
    alignContent: "flex-end",
    margin: ".3em",
    ...props.style,
  };
  if (props.fitted) {
    itemStyle.margin = 0;
  }
  if (props.verticallyFitted) {
    itemStyle.marginTop = 0;
    itemStyle.marginBottom = 0;
  }
  if (props.fittedRight) {
    itemStyle.marginRight = 0;
  }
  if (props.flex) {
    itemStyle.flex = props.flex;
  }
  return <div style={itemStyle}>{props.children}</div>;
};

export default FlexGrid;
