import React from "react";
import { RouteComponentProps } from "react-router";
import { OrganizationGroupsComponent, ResourceType } from "../generated/graphql";
import { GroupManager } from "../Resources";
import { Button, Header } from "semantic-ui-react";
import { QSFA } from "../Shared/QSFA";
import NewGroupButton from "../Resources/NewGroupButton";

export default (props: RouteComponentProps<{ id: string }>) => {
  return (
    <OrganizationGroupsComponent variables={{ id: props.match.params.id }}>
      {({ data, loading, refetch }) => (
        <>
          {/* ToDo: update button to show added group */}
         <NewGroupButton
          onAdd={refetch}
          parentId={props.match.params.id}
          parentType={ResourceType.Organization} />

          {/* ToDo: disable/enable button. Property 'deletedAt' does not exist on organization. */}
          {/* <Button icon="ban">
            <QSFA icon="ban" />Disable Organization
          </Button> */}
          <Header as="h3">
            <QSFA icon="id-badge" size="small" />Unit Groups
          </Header>
          <GroupManager
            loading={loading}
            parentId={props.match.params.id}
            parentType={ResourceType.Organization}
            groups={data && data.organization && data.organization.groups}
            refetch={refetch} />
        </>
      )}
    </OrganizationGroupsComponent>
  );
};
