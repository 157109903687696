import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Actor = {
   __typename?: 'Actor';
  employee?: Maybe<Employee>;
  unit?: Maybe<Unit>;
  organization?: Maybe<Organization>;
  concept?: Maybe<Concept>;
  id: Scalars['ID'];
  name: Scalars['String'];
  grants: Array<Grant>;
  grant?: Maybe<Grant>;
  access?: Maybe<Grant>;
  resourceType: ResourceType;
  resourceId: Scalars['String'];
  resources: Array<Resource>;
  resourceTypes: Array<ResourceType>;
  lastUsedAt?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  secret: Scalars['String'];
  privileges: Array<Privilege>;
  permissions: ActorPermissions;
};


export type ActorGrantArgs = {
  target?: Maybe<InputResource>;
};


export type ActorAccessArgs = {
  target?: Maybe<InputResource>;
};


export type ActorResourcesArgs = {
  type?: Maybe<ResourceType>;
  action?: Maybe<Scalars['String']>;
};


export type ActorResourceTypesArgs = {
  action?: Maybe<Scalars['String']>;
};


export type ActorPrivilegesArgs = {
  actions: Array<Scalars['String']>;
};

export type ActorListEdge = {
   __typename?: 'ActorListEdge';
  cursor: Scalars['String'];
  nodes: Array<Actor>;
};

export type ActorPermissions = {
   __typename?: 'ActorPermissions';
  singleUnit?: Maybe<Scalars['String']>;
  inventory: Scalars['Boolean'];
  reports: Scalars['Boolean'];
  system: Scalars['Boolean'];
};

export type Break = {
   __typename?: 'Break';
  startedAt: Scalars['Int'];
  stoppedAt?: Maybe<Scalars['Int']>;
  breakType?: Maybe<BreakType>;
};

export enum BreakType {
  Paid = 'Paid',
  Unpaid = 'Unpaid'
}

export type CashDrawer = {
   __typename?: 'CashDrawer';
  terminal: Terminal;
  posId: Scalars['String'];
  employee?: Maybe<Employee>;
  employeeId: Scalars['ID'];
  employeePosId: Scalars['String'];
  beginningAmount: Scalars['Int'];
  endingAmount: Scalars['Int'];
  state: CashDrawerState;
  openedAt: Scalars['Int'];
  closedAt?: Maybe<Scalars['Int']>;
  closedById?: Maybe<Scalars['ID']>;
};

export enum CashDrawerState {
  Open = 'Open',
  Closed = 'Closed',
  Freed = 'Freed'
}

export type Concept = {
   __typename?: 'Concept';
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  groups: Array<Group>;
  privileges: Array<Privilege>;
  teams: Array<Team>;
};


export type ConceptPrivilegesArgs = {
  actions: Array<Scalars['String']>;
};

export type ConceptDetails = {
   __typename?: 'ConceptDetails';
  name: Scalars['String'];
};

export type ConceptListEdge = {
   __typename?: 'ConceptListEdge';
  cursor: Scalars['String'];
  nodes: Array<Concept>;
};

export type CreateItemDefinitionInput = {
  fragmentId: Scalars['ID'];
  plu: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  price: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type Employee = {
   __typename?: 'Employee';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  pin?: Maybe<Scalars['String']>;
  status: EmployeeStatusEnum;
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Int']>;
  loginActor?: Maybe<Actor>;
  relationships: EmployeeRelationships;
  privileges: Array<Privilege>;
  appAccessEnabled: Scalars['Boolean'];
  carbonIdentifier: Scalars['String'];
  carbonPin: Scalars['String'];
  carbonAccessToken: Scalars['String'];
};


export type EmployeePrivilegesArgs = {
  actions: Array<Scalars['String']>;
};

export type EmployeeListEdge = {
   __typename?: 'EmployeeListEdge';
  cursor: Scalars['String'];
  nodes: Array<Employee>;
};

export type EmployeeRelationships = {
   __typename?: 'EmployeeRelationships';
  parents: Array<Resource>;
  children: Array<Resource>;
};

export enum EmployeeStatusEnum {
  Invited = 'invited',
  Active = 'active',
  Disabled = 'disabled'
}

export enum EnabledDisabledState {
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export type Grant = {
   __typename?: 'Grant';
  target: Resource;
  policies: Array<Scalars['String']>;
  actions: Array<Scalars['String']>;
};

export type Group = {
   __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  resources: Array<Resource>;
  relationships: GroupRelationships;
  units: Array<Unit>;
};


export type GroupResourcesArgs = {
  resourceType: ResourceType;
};

export type GroupRelationships = {
   __typename?: 'GroupRelationships';
  parents: Array<Resource>;
  children: Array<Resource>;
};

export type GroupResource = {
   __typename?: 'GroupResource';
  name: Scalars['String'];
  type: GroupType;
};

export type GroupResourceInput = {
  resourceId: Scalars['String'];
  resourceType: ResourceType;
};

export type GroupResourcesInput = {
  groupId: Scalars['String'];
  resources: Array<GroupResourceInput>;
};

export enum GroupType {
  Team = 'team',
  UserDefined = 'user_defined',
  External = 'external'
}

export type InputLoyaltyMenuItem = {
  id: Scalars['String'];
  amount: Scalars['Int'];
  family?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  posId?: Maybe<Scalars['String']>;
};

export type InputResource = {
  id: Scalars['ID'];
  type: ResourceType;
};

export type ItemDefinition = {
   __typename?: 'ItemDefinition';
  id: Scalars['ID'];
  plu: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  price: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type LegacyInventoryCount = {
   __typename?: 'LegacyInventoryCount';
  id: Scalars['ID'];
  itemId: Scalars['String'];
  locationId: Scalars['String'];
  locationName: Scalars['String'];
  employeeId: Scalars['String'];
  count: Scalars['Float'];
  caseCount: Scalars['Float'];
  subcaseCount: Scalars['Float'];
  eachCount: Scalars['Float'];
  totalCount: Scalars['Float'];
  item: LegacyInventoryItem;
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  businessDate: Scalars['Int'];
  countedAt: Scalars['Int'];
};

export type LegacyInventoryCountCreateInput = {
  itemId: Scalars['String'];
  unitId: Scalars['String'];
  locationId: Scalars['String'];
  employeeId: Scalars['String'];
  count: Scalars['Float'];
  caseCount: Scalars['Float'];
  subcaseCount: Scalars['Float'];
  eachCount: Scalars['Float'];
  businessDate: Scalars['Int'];
  countedAt: Scalars['Int'];
};

export type LegacyInventoryCountCreateOutput = {
   __typename?: 'LegacyInventoryCountCreateOutput';
  itemId: Scalars['String'];
  unitId: Scalars['String'];
  locationId: Scalars['String'];
  employeeId: Scalars['String'];
  count: Scalars['Float'];
  caseCount: Scalars['Float'];
  subcaseCount: Scalars['Float'];
  eachCount: Scalars['Float'];
  businessDate: Scalars['Int'];
  countedAt: Scalars['Int'];
};

export type LegacyInventoryCountCreateResponse = {
   __typename?: 'LegacyInventoryCountCreateResponse';
  counts: Array<LegacyInventoryCountCreateOutput>;
  unitId: Scalars['String'];
};

export type LegacyInventoryCountListEdge = {
   __typename?: 'LegacyInventoryCountListEdge';
  cursor: Scalars['String'];
  nodes: Array<LegacyInventoryCount>;
};

export type LegacyInventoryCountSheet = {
   __typename?: 'LegacyInventoryCountSheet';
  locations: Array<LegacyInventoryCountSheetLocation>;
  items: Array<LegacyInventoryCountSheetItem>;
  count: Scalars['Int'];
  countedCount: Scalars['Int'];
  uncountedCount: Scalars['Int'];
};

export type LegacyInventoryCountSheetItem = {
   __typename?: 'LegacyInventoryCountSheetItem';
  locationId: Scalars['String'];
  sortIndex: Scalars['Int'];
  itemId: Scalars['String'];
  itemNumber: Scalars['String'];
  itemName: Scalars['String'];
  caseDescription: Scalars['String'];
  subcaseDescription: Scalars['String'];
  individualDescription: Scalars['String'];
  subcaseQuantity: Scalars['Float'];
  totalQuantity: Scalars['Float'];
  wasteEnabled: Scalars['Boolean'];
  frequency: Scalars['String'];
  isMaster: Scalars['Boolean'];
  isChild: Scalars['Boolean'];
  count: Scalars['Float'];
  caseCount: Scalars['Float'];
  subcaseCount: Scalars['Float'];
  eachCount: Scalars['Float'];
  formattedCount: Scalars['String'];
  formattedTotalCount: Scalars['String'];
  totalCount: Scalars['Float'];
  businessDate: Scalars['Int'];
  countedAt: Scalars['Int'];
  isCounted: Scalars['Boolean'];
};

export type LegacyInventoryCountSheetLocation = {
   __typename?: 'LegacyInventoryCountSheetLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
  itemCount: Scalars['Int'];
  countedCount: Scalars['Int'];
};

export type LegacyInventoryItem = {
   __typename?: 'LegacyInventoryItem';
  id: Scalars['ID'];
  itemNumber: Scalars['String'];
  name: Scalars['String'];
  caseDescription: Scalars['String'];
  subcaseDescription: Scalars['String'];
  individualDescription: Scalars['String'];
  subcaseQuantity: Scalars['Float'];
  totalQuantity: Scalars['Float'];
  wasteEnabled: Scalars['Boolean'];
  frequency: Scalars['String'];
  groupName: Scalars['String'];
  packageDescription: Scalars['String'];
  masterItemId: Scalars['String'];
  isMaster: Scalars['Boolean'];
  isChild: Scalars['Boolean'];
  state: Scalars['String'];
  sortIndex: Scalars['Int'];
};

export type LegacyInventoryItemListEdge = {
   __typename?: 'LegacyInventoryItemListEdge';
  nodes: Array<LegacyInventoryItem>;
};

export type LegacyInventoryItemLocation = {
   __typename?: 'LegacyInventoryItemLocation';
  id: Scalars['ID'];
  unitId: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LegacyInventoryLocation = {
   __typename?: 'LegacyInventoryLocation';
  id: Scalars['ID'];
  unitId: Scalars['String'];
  name: Scalars['String'];
  state: LegacyInventoryLocationState;
  items: Array<LegacyInventoryLocationItem>;
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LegacyInventoryLocationItem = {
   __typename?: 'LegacyInventoryLocationItem';
  locationId: Scalars['String'];
  itemId: Scalars['String'];
  sortIndex: Scalars['Int'];
};

export type LegacyInventoryLocationItemCreateInput = {
  itemId: Scalars['String'];
  sortIndex: Scalars['Int'];
};

export type LegacyInventoryLocationItemResponse = {
   __typename?: 'LegacyInventoryLocationItemResponse';
  locationId: Scalars['String'];
  unitId: Scalars['String'];
  id: Scalars['ID'];
  sortIndex: Scalars['Int'];
  state: EnabledDisabledState;
};

export type LegacyInventoryLocationListEdge = {
   __typename?: 'LegacyInventoryLocationListEdge';
  nodes: Array<LegacyInventoryLocation>;
};

export enum LegacyInventoryLocationState {
  Active = 'active',
  Disabled = 'disabled'
}

export type LegacyInventoryStat = {
   __typename?: 'LegacyInventoryStat';
  itemId: Scalars['String'];
  unitId: Scalars['String'];
  masterItemId?: Maybe<Scalars['String']>;
  businessDate: Scalars['Int'];
  beginningDate: Scalars['Int'];
  beginningCount: Scalars['Float'];
  waste: Scalars['Float'];
  purchases: Scalars['Float'];
  borrows: Scalars['Float'];
  loans: Scalars['Float'];
  usage: Scalars['Float'];
  currentCount: Scalars['Float'];
  expectedCount: Scalars['Float'];
  items: Array<LegacyInventoryStatItem>;
};

export type LegacyInventoryStatCount = {
   __typename?: 'LegacyInventoryStatCount';
  id: Scalars['ID'];
  unitId: Scalars['String'];
  itemId: Scalars['String'];
  employeeId: Scalars['String'];
  count: Scalars['Float'];
  businessDate: Scalars['Int'];
  countedAt: Scalars['Int'];
  locationId: Scalars['String'];
  locationName: Scalars['String'];
};

export type LegacyInventoryStatItem = {
   __typename?: 'LegacyInventoryStatItem';
  id: Scalars['ID'];
  itemNumber: Scalars['String'];
  name: Scalars['String'];
  individualDescription: Scalars['String'];
  counts: Array<LegacyInventoryStatCount>;
};

export type LegacyReport = {
   __typename?: 'LegacyReport';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  longDescription: Scalars['String'];
  definition: Scalars['String'];
  icon: Scalars['String'];
  createdAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  recordSetName: Scalars['String'];
};

export type LegacyReportOverview = {
   __typename?: 'LegacyReportOverview';
  unitCount: Scalars['Int'];
  unitsReporting: Scalars['Int'];
  netsaleAmount: Scalars['Float'];
  taxAmount: Scalars['Float'];
  grossAmount: Scalars['Float'];
  lastYearNetsaleAmount: Scalars['Float'];
  yoyNetsalePercent: Scalars['Float'];
  saleCount: Scalars['Int'];
  lastYearSaleCount: Scalars['Int'];
  yoySaleCountPercent: Scalars['Float'];
  voidAmount: Scalars['Float'];
  voidCount: Scalars['Int'];
  deleteAmount: Scalars['Float'];
  deleteCount: Scalars['Int'];
  cancelledOrderAmount: Scalars['Float'];
  cancelledOrderCount: Scalars['Int'];
  discountAmount: Scalars['Float'];
  discountCount: Scalars['Int'];
  clockedHours: Scalars['Float'];
  laborPercent: Scalars['Float'];
  salesPerManHour: Scalars['Float'];
  driveThruCount: Scalars['Int'];
  driveThruAverageTime: Scalars['Int'];
  detail: Array<LegacyReportOverviewDetail>;
};

export type LegacyReportOverviewDetail = {
   __typename?: 'LegacyReportOverviewDetail';
  unitId: Scalars['String'];
  unitName: Scalars['String'];
  netsaleAmount: Scalars['Float'];
  taxAmount: Scalars['Float'];
  grossAmount: Scalars['Float'];
  lastYearNetsaleAmount: Scalars['Float'];
  yoyNetsalePercent: Scalars['Float'];
  saleCount: Scalars['Int'];
  lastYearSaleCount: Scalars['Int'];
  yoySaleCountPercent: Scalars['Float'];
  voidAmount: Scalars['Float'];
  voidCount: Scalars['Int'];
  deleteAmount: Scalars['Float'];
  deleteCount: Scalars['Int'];
  cancelledOrderAmount: Scalars['Float'];
  cancelledOrderCount: Scalars['Int'];
  discountAmount: Scalars['Float'];
  discountCount: Scalars['Int'];
  clockedHours: Scalars['Float'];
  laborPercent: Scalars['Float'];
  salesPerManHour: Scalars['Float'];
  driveThruCount: Scalars['Int'];
  driveThruAverageTime: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type LegacyReportRecordSet = {
   __typename?: 'LegacyReportRecordSet';
  records: Scalars['String'];
};

export type LoyaltyMenuItem = {
   __typename?: 'LoyaltyMenuItem';
  id: Scalars['String'];
  amount: Scalars['Int'];
  family?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  posId: Scalars['String'];
};

export type LoyaltyUnitConfig = {
   __typename?: 'LoyaltyUnitConfig';
  apiKey: Scalars['String'];
  providerId: Scalars['String'];
  businessKey: Scalars['String'];
  locationId: Scalars['String'];
};

export type LoyaltyUser = {
   __typename?: 'LoyaltyUser';
  id: Scalars['String'];
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Int']>;
  age?: Maybe<Scalars['Int']>;
  anniversary?: Maybe<Scalars['Int']>;
  balance?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  rewards: Array<Reward>;
};

export type Menu = {
   __typename?: 'Menu';
  itemDefinitions: Array<ItemDefinition>;
};

export type MenuFragment = {
   __typename?: 'MenuFragment';
  id: Scalars['ID'];
  name: Scalars['String'];
  itemDefinitions: MenuFragmentItems;
  itemDefinition: ItemDefinition;
  linkedFragments: Array<MenuFragmentLink>;
  priceUpdates: Array<MenuPriceUpdate>;
  itemDefinitionCount: Scalars['Int'];
  pricingUpdateCount: Scalars['Int'];
  slotCount: Scalars['Int'];
  discountCount: Scalars['Int'];
  dynamicPriceCount: Scalars['Int'];
  categoryCount: Scalars['Int'];
};


export type MenuFragmentItemDefinitionArgs = {
  itemDefinitionId: Scalars['ID'];
};

export type MenuFragmentItems = {
   __typename?: 'MenuFragmentItems';
  internal: Array<ItemDefinition>;
  external: Array<ItemDefinition>;
};

export type MenuFragmentLink = {
   __typename?: 'MenuFragmentLink';
  fragmentId: Scalars['ID'];
  linkedFragmentId: Scalars['ID'];
  linkedFragmentName: Scalars['String'];
};

export type MenuFragmentListEdge = {
   __typename?: 'MenuFragmentListEdge';
  cursor: Scalars['String'];
  nodes: Array<MenuFragment>;
};

export type MenuPriceUpdate = {
   __typename?: 'MenuPriceUpdate';
  id: Scalars['ID'];
  fragmentId: Scalars['ID'];
  name: Scalars['String'];
  plu: Scalars['String'];
  price: Scalars['Int'];
};

export type Mutation = {
   __typename?: 'Mutation';
  createActor?: Maybe<Actor>;
  createSystemActor?: Maybe<Actor>;
  deleteActor?: Maybe<Scalars['String']>;
  createGrant?: Maybe<Scalars['Boolean']>;
  deleteGrant?: Maybe<Scalars['Boolean']>;
  createConcept: Concept;
  updateConcept?: Maybe<Concept>;
  createEmployee?: Maybe<Employee>;
  acceptInvite?: Maybe<Employee>;
  disableEmployee?: Maybe<Scalars['String']>;
  enableEmployee?: Maybe<Scalars['String']>;
  updateEmployee?: Maybe<Employee>;
  changePin?: Maybe<Scalars['Boolean']>;
  resendInvite?: Maybe<Scalars['Boolean']>;
  disableEmployeeAccess?: Maybe<Scalars['String']>;
  enableEmployeeAccess?: Maybe<Scalars['String']>;
  assignEmployeeToUnit?: Maybe<Scalars['Boolean']>;
  unassignEmployeeFromUnit?: Maybe<Scalars['Boolean']>;
  assignEmployeeToGroup?: Maybe<Scalars['Boolean']>;
  unassignEmployeeFromGroup?: Maybe<Scalars['Boolean']>;
  assignEmployeeToOrganization?: Maybe<Scalars['Boolean']>;
  unassignEmployeeFromOrganization?: Maybe<Scalars['Boolean']>;
  assignEmployeeToConcept?: Maybe<Scalars['Boolean']>;
  unassignEmployeeFromConcept?: Maybe<Scalars['Boolean']>;
  createLegacyInventoryCounts?: Maybe<LegacyInventoryCountCreateResponse>;
  createLegacyInventoryLocation?: Maybe<LegacyInventoryLocation>;
  updateLegacyInventoryLocation?: Maybe<LegacyInventoryLocation>;
  updateLegacyInventoryLocationItems?: Maybe<LegacyInventoryLocation>;
  updateLegacyInventoryLocationManageItem?: Maybe<LegacyInventoryLocationItemResponse>;
  createOrganization?: Maybe<Organization>;
  updateOrganization?: Maybe<Organization>;
  addConceptToOrganization?: Maybe<Scalars['Boolean']>;
  createGroup?: Maybe<Group>;
  deleteGroup?: Maybe<Group>;
  renameGroup?: Maybe<Group>;
  assignToGroup?: Maybe<Scalars['Boolean']>;
  unassignFromGroup?: Maybe<Scalars['Boolean']>;
  createTeam?: Maybe<Team>;
  deleteTeam?: Maybe<Team>;
  grantTeam?: Maybe<Team>;
  renameTeam?: Maybe<Team>;
  revokeTeam?: Maybe<Team>;
  addTeamMember?: Maybe<Team>;
  removeTeamMember?: Maybe<Team>;
  createUnit?: Maybe<Unit>;
  disableUnit?: Maybe<Scalars['String']>;
  enableUnit?: Maybe<Scalars['String']>;
  updateUnit?: Maybe<Unit>;
  heartbeatUnit?: Maybe<Scalars['Boolean']>;
  transferUnit: Unit;
  createSale?: Maybe<Sale>;
  createSales?: Maybe<Array<Maybe<Sale>>>;
  createExternalSale?: Maybe<Sale>;
  confirmSale?: Maybe<Scalars['String']>;
  validateSale?: Maybe<Sale>;
  updateLoyaltyConfig?: Maybe<LoyaltyUnitConfig>;
  carbonStatus?: Maybe<Scalars['Boolean']>;
  createMenuFragment?: Maybe<MenuFragment>;
  updateItemDefinitionPrice?: Maybe<Scalars['Boolean']>;
  createItemDefinition?: Maybe<ItemDefinition>;
  updateItemDefinition?: Maybe<ItemDefinition>;
};


export type MutationCreateActorArgs = {
  name: Scalars['String'];
  resourceType: ResourceType;
  resourceId: Scalars['String'];
};


export type MutationCreateSystemActorArgs = {
  resourceType: ResourceType;
  resourceId: Scalars['String'];
};


export type MutationDeleteActorArgs = {
  id: Scalars['String'];
};


export type MutationCreateGrantArgs = {
  source: InputResource;
  target: InputResource;
  policies: Array<Scalars['String']>;
};


export type MutationDeleteGrantArgs = {
  source: InputResource;
  target: InputResource;
};


export type MutationCreateConceptArgs = {
  name: Scalars['String'];
};


export type MutationUpdateConceptArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateEmployeeArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  parentId: Scalars['String'];
  parentType: ResourceType;
  sendInvite?: Maybe<Scalars['Boolean']>;
};


export type MutationAcceptInviteArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  pin: Scalars['String'];
};


export type MutationDisableEmployeeArgs = {
  id: Scalars['String'];
};


export type MutationEnableEmployeeArgs = {
  id: Scalars['String'];
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  carbonIdentifier: Scalars['String'];
  carbonPin: Scalars['String'];
  carbonAccessToken: Scalars['String'];
};


export type MutationChangePinArgs = {
  employeeId: Scalars['String'];
  pin: Scalars['String'];
};


export type MutationResendInviteArgs = {
  id: Scalars['String'];
};


export type MutationDisableEmployeeAccessArgs = {
  id: Scalars['String'];
};


export type MutationEnableEmployeeAccessArgs = {
  id: Scalars['String'];
};


export type MutationAssignEmployeeToUnitArgs = {
  employeeId: Scalars['String'];
  unitId: Scalars['String'];
};


export type MutationUnassignEmployeeFromUnitArgs = {
  employeeId: Scalars['String'];
  unitId: Scalars['String'];
};


export type MutationAssignEmployeeToGroupArgs = {
  employeeId: Scalars['String'];
  groupId: Scalars['String'];
};


export type MutationUnassignEmployeeFromGroupArgs = {
  employeeId: Scalars['String'];
  groupId: Scalars['String'];
};


export type MutationAssignEmployeeToOrganizationArgs = {
  employeeId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUnassignEmployeeFromOrganizationArgs = {
  employeeId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationAssignEmployeeToConceptArgs = {
  employeeId: Scalars['String'];
  conceptId: Scalars['String'];
};


export type MutationUnassignEmployeeFromConceptArgs = {
  employeeId: Scalars['String'];
  conceptId: Scalars['String'];
};


export type MutationCreateLegacyInventoryCountsArgs = {
  counts: Array<LegacyInventoryCountCreateInput>;
  unitId: Scalars['String'];
};


export type MutationCreateLegacyInventoryLocationArgs = {
  unitId: Scalars['String'];
  name: Scalars['String'];
  items?: Maybe<Array<LegacyInventoryLocationItemCreateInput>>;
};


export type MutationUpdateLegacyInventoryLocationArgs = {
  id: Scalars['String'];
  unitId: Scalars['String'];
  name: Scalars['String'];
  state: LegacyInventoryLocationState;
};


export type MutationUpdateLegacyInventoryLocationItemsArgs = {
  id: Scalars['String'];
  unitId: Scalars['String'];
  items?: Maybe<Array<LegacyInventoryLocationItemCreateInput>>;
};


export type MutationUpdateLegacyInventoryLocationManageItemArgs = {
  locationId: Scalars['String'];
  unitId: Scalars['String'];
  id: Scalars['String'];
  sortIndex: Scalars['Int'];
  state: EnabledDisabledState;
};


export type MutationCreateOrganizationArgs = {
  name: Scalars['String'];
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};


export type MutationAddConceptToOrganizationArgs = {
  id: Scalars['String'];
  conceptId: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  parentId: Scalars['String'];
  parentType: ResourceType;
  name: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['String'];
};


export type MutationRenameGroupArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationAssignToGroupArgs = {
  input: GroupResourcesInput;
};


export type MutationUnassignFromGroupArgs = {
  input: GroupResourcesInput;
};


export type MutationCreateTeamArgs = {
  parentId: Scalars['String'];
  parentType: ResourceType;
  name: Scalars['String'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['String'];
};


export type MutationGrantTeamArgs = {
  teamId: Scalars['String'];
  targetType: ResourceType;
  targetId: Scalars['String'];
  policies: Array<Scalars['String']>;
};


export type MutationRenameTeamArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRevokeTeamArgs = {
  teamId: Scalars['String'];
  targetType: ResourceType;
  targetId: Scalars['String'];
};


export type MutationAddTeamMemberArgs = {
  employeeId: Scalars['String'];
  teamId: Scalars['String'];
};


export type MutationRemoveTeamMemberArgs = {
  employeeId: Scalars['String'];
  teamId: Scalars['String'];
};


export type MutationCreateUnitArgs = {
  name: Scalars['String'];
  identifier: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  organizationId: Scalars['String'];
  conceptIds: Array<Scalars['String']>;
  customAttributes?: Maybe<Array<UnitCustomAttributeInput>>;
};


export type MutationDisableUnitArgs = {
  id: Scalars['String'];
};


export type MutationEnableUnitArgs = {
  id: Scalars['String'];
};


export type MutationUpdateUnitArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  identifier: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  customAttributes?: Maybe<Array<UnitCustomAttributeInput>>;
};


export type MutationHeartbeatUnitArgs = {
  id: Scalars['String'];
};


export type MutationTransferUnitArgs = {
  unitId: Scalars['String'];
  organizationId: Scalars['String'];
  excludedResources?: Maybe<Array<InputResource>>;
};


export type MutationCreateSaleArgs = {
  input: SaleInput;
};


export type MutationCreateSalesArgs = {
  input: Array<SaleInput>;
};


export type MutationCreateExternalSaleArgs = {
  input: SaleInput;
};


export type MutationConfirmSaleArgs = {
  saleId: Scalars['String'];
};


export type MutationValidateSaleArgs = {
  input: SaleInput;
};


export type MutationUpdateLoyaltyConfigArgs = {
  unitId: Scalars['String'];
  apiKey: Scalars['String'];
  providerId: Scalars['String'];
  businessKey: Scalars['String'];
  locationId: Scalars['String'];
};


export type MutationCarbonStatusArgs = {
  unitId: Scalars['String'];
  updateChannel: Scalars['String'];
  version: Scalars['String'];
  configHash: Scalars['String'];
  menuHash: Scalars['String'];
};


export type MutationCreateMenuFragmentArgs = {
  name: Scalars['String'];
  resource: InputResource;
};


export type MutationUpdateItemDefinitionPriceArgs = {
  fragmentId: Scalars['ID'];
  itemDefinitionId: Scalars['ID'];
  newPrice: Scalars['Int'];
};


export type MutationCreateItemDefinitionArgs = {
  input: CreateItemDefinitionInput;
};


export type MutationUpdateItemDefinitionArgs = {
  input: UpdateItemDefinitionInput;
};

export type Organization = {
   __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  groups: Array<Group>;
  privileges: Array<Privilege>;
  concepts: Array<Concept>;
  teams: Array<Team>;
};


export type OrganizationPrivilegesArgs = {
  actions: Array<Scalars['String']>;
};

export type OrganizationDetails = {
   __typename?: 'OrganizationDetails';
  name: Scalars['String'];
};

export type OrganizationListEdge = {
   __typename?: 'OrganizationListEdge';
  cursor: Scalars['String'];
  nodes: Array<Organization>;
};

export type Policy = {
   __typename?: 'Policy';
  id: Scalars['ID'];
  name: Scalars['String'];
  actions: Array<Scalars['String']>;
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
};

export type PolicyListEdge = {
   __typename?: 'PolicyListEdge';
  cursor: Scalars['String'];
  nodes: Array<Policy>;
};

export type Privilege = {
   __typename?: 'Privilege';
  action: Scalars['String'];
  allowed: Scalars['Boolean'];
};

export type Query = {
   __typename?: 'Query';
  resource: Resource;
  me: Actor;
  actor: Actor;
  actors: ActorListEdge;
  subsetPolicies: Array<Policy>;
  concept: Concept;
  concepts: ConceptListEdge;
  employee: Employee;
  employeeByEmail: Employee;
  employees: EmployeeListEdge;
  validatePin: ValidatePinEdge;
  legacyInventoryCountsInRange: LegacyInventoryCountListEdge;
  legacyInventoryCountSheet: LegacyInventoryCountSheet;
  legacyInventoryItems: LegacyInventoryItemListEdge;
  legacyInventoryLocations: LegacyInventoryLocationListEdge;
  legacyInventoryLocation: LegacyInventoryLocation;
  legacyInventoryOrphanedItems: Array<LegacyInventoryItem>;
  legacyInventoryStat: LegacyInventoryStat;
  legacyInventoryStats: Array<LegacyInventoryStat>;
  organization: Organization;
  organizations: OrganizationListEdge;
  checkResourcePrivileges: Array<ResourcePrivilege>;
  checkPrivileges: Array<Privilege>;
  groups: Array<Group>;
  group: Group;
  team: Team;
  teams: Array<Team>;
  unit: Unit;
  units: UnitListEdge;
  unitHealth: UnitHealth;
  legacyReportOverview: LegacyReportOverview;
  legacyReports: Array<LegacyReport>;
  legacyReport: LegacyReport;
  legacyReportRecordSet: LegacyReportRecordSet;
  getLoyaltyUser?: Maybe<LoyaltyUser>;
  proposeLoyaltyOrder?: Maybe<Array<Reward>>;
  proposeLoyaltyRedemption: Redemption;
  generateLoyaltyBarcode: Scalars['String'];
  menuFragments: MenuFragmentListEdge;
  menuFragment: MenuFragment;
};


export type QueryResourceArgs = {
  id: Scalars['String'];
  type: ResourceType;
};


export type QueryActorArgs = {
  id: Scalars['String'];
};


export type QueryActorsArgs = {
  resourceType: ResourceType;
  resourceId: Scalars['String'];
};


export type QuerySubsetPoliciesArgs = {
  policies: Array<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};


export type QueryConceptArgs = {
  id: Scalars['String'];
};


export type QueryConceptsArgs = {
  cursor?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
  action?: Maybe<Scalars['String']>;
};


export type QueryEmployeeArgs = {
  id: Scalars['String'];
};


export type QueryEmployeeByEmailArgs = {
  email: Scalars['String'];
};


export type QueryEmployeesArgs = {
  cursor?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
  disabled?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
};


export type QueryValidatePinArgs = {
  pin: Scalars['String'];
};


export type QueryLegacyInventoryCountsInRangeArgs = {
  unitId: Scalars['String'];
  startDate: Scalars['Int'];
  endDate?: Maybe<Scalars['Int']>;
};


export type QueryLegacyInventoryCountSheetArgs = {
  unitId: Scalars['String'];
  businessDate: Scalars['Int'];
  frequencies: Array<Scalars['String']>;
};


export type QueryLegacyInventoryItemsArgs = {
  unitId: Scalars['String'];
};


export type QueryLegacyInventoryLocationsArgs = {
  unitId: Scalars['String'];
  disabled?: Maybe<Scalars['Boolean']>;
};


export type QueryLegacyInventoryLocationArgs = {
  id: Scalars['String'];
  unitId: Scalars['String'];
};


export type QueryLegacyInventoryOrphanedItemsArgs = {
  unitId: Scalars['String'];
};


export type QueryLegacyInventoryStatArgs = {
  itemId: Scalars['String'];
  unitId: Scalars['String'];
  businessDate: Scalars['Int'];
};


export type QueryLegacyInventoryStatsArgs = {
  itemIds?: Maybe<Array<Scalars['String']>>;
  frequencies?: Maybe<Array<Scalars['String']>>;
  unitId: Scalars['String'];
  businessDate: Scalars['Int'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  cursor?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
  action?: Maybe<Scalars['String']>;
};


export type QueryCheckResourcePrivilegesArgs = {
  resource: InputResource;
  resources: Array<InputResource>;
  actions: Array<Scalars['String']>;
};


export type QueryCheckPrivilegesArgs = {
  resource: InputResource;
  actions: Array<Scalars['String']>;
};


export type QueryGroupArgs = {
  id: Scalars['String'];
};


export type QueryTeamArgs = {
  id: Scalars['String'];
};


export type QueryUnitArgs = {
  id: Scalars['String'];
};


export type QueryUnitsArgs = {
  cursor?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
  disabled?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
};


export type QueryUnitHealthArgs = {
  id: Scalars['String'];
};


export type QueryLegacyReportOverviewArgs = {
  unitIds?: Maybe<Array<Scalars['String']>>;
  orgId?: Maybe<Scalars['String']>;
};


export type QueryLegacyReportArgs = {
  id: Scalars['String'];
};


export type QueryLegacyReportRecordSetArgs = {
  recordSetName: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


export type QueryGetLoyaltyUserArgs = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  scan?: Maybe<Scalars['String']>;
};


export type QueryProposeLoyaltyOrderArgs = {
  email: Scalars['String'];
  receiptAmount: Scalars['Int'];
  timestamp: Scalars['Int'];
  items: Array<InputLoyaltyMenuItem>;
};


export type QueryProposeLoyaltyRedemptionArgs = {
  code: Scalars['String'];
  saleId: Scalars['String'];
  receiptAmount: Scalars['Int'];
  timestamp: Scalars['Int'];
  items: Array<InputLoyaltyMenuItem>;
};


export type QueryGenerateLoyaltyBarcodeArgs = {
  saleIdentifier: Scalars['String'];
};


export type QueryMenuFragmentArgs = {
  id: Scalars['ID'];
};

export type Redemption = {
   __typename?: 'Redemption';
  id: Scalars['String'];
  code: Scalars['String'];
  discountAmount: Scalars['Int'];
  allowed: Scalars['Boolean'];
  status: Scalars['String'];
  items: Array<LoyaltyMenuItem>;
};

export type Resource = {
   __typename?: 'Resource';
  id: Scalars['ID'];
  type: ResourceType;
  name: Scalars['String'];
  parents: Array<Resource>;
  group?: Maybe<GroupResource>;
};


export type ResourceParentsArgs = {
  type?: Maybe<ResourceType>;
};

export type ResourceListEdge = {
   __typename?: 'ResourceListEdge';
  cursor: Scalars['String'];
  nodes: Array<Resource>;
};

export type ResourcePrivilege = {
   __typename?: 'ResourcePrivilege';
  resource: Resource;
  privileges: Array<Privilege>;
};

export enum ResourceType {
  Actor = 'actor',
  Employee = 'employee',
  Concept = 'concept',
  Organization = 'organization',
  Unit = 'unit',
  Group = 'group'
}

export type Reward = {
   __typename?: 'Reward';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
  discountAmount: Scalars['Int'];
  items: Array<LoyaltyMenuItem>;
};

export enum RoundingOption {
  Up = 'Up',
  Middle = 'Middle',
  Down = 'Down'
}

export type Sale = {
   __typename?: 'Sale';
  id: Scalars['ID'];
  unitId: Scalars['String'];
  posType: Scalars['String'];
  posVersion: Scalars['String'];
  terminalId: Scalars['String'];
  terminalName: Scalars['String'];
  drawerId: Scalars['String'];
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  destinationId: Scalars['String'];
  destinationName: Scalars['String'];
  reference: Scalars['String'];
  saleNumber: Scalars['String'];
  orderNumber: Scalars['String'];
  status: SaleStatusEnum;
  nonTaxable: Scalars['Boolean'];
  taxId: Scalars['String'];
  voided: Scalars['Boolean'];
  voidedAt: Scalars['Int'];
  synced: Scalars['Boolean'];
  syncedAt: Scalars['Int'];
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  version: Scalars['String'];
  items: Array<SaleItem>;
  payments?: Maybe<Array<SalePayment>>;
  discounts?: Maybe<Array<SaleDiscount>>;
  customer: SaleCustomer;
  totals: SaleTotals;
  times: SaleTimes;
  metadata?: Maybe<Array<SaleMetadata>>;
  loyaltyEmail?: Maybe<Scalars['String']>;
  entryMethod: SaleEntryMethod;
  deliveryDetail: SaleDeliveryDetail;
  taxes?: Maybe<Array<SaleTax>>;
  giftCards?: Maybe<Array<SaleGiftCard>>;
};

export type SaleCustomer = {
   __typename?: 'SaleCustomer';
  reference: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  carMake?: Maybe<Scalars['String']>;
  carModel?: Maybe<Scalars['String']>;
  carColor?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleCustomerInput = {
  reference?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  carMake?: Maybe<Scalars['String']>;
  carModel?: Maybe<Scalars['String']>;
  carColor?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type SaleDeliveryDetail = {
   __typename?: 'SaleDeliveryDetail';
  deliveryProvider: Scalars['String'];
  deliveryReference: Scalars['String'];
};

export type SaleDeliveryDetailInput = {
  deliveryProvider: Scalars['String'];
  deliveryReference: Scalars['String'];
};

export type SaleDiscount = {
   __typename?: 'SaleDiscount';
  id: Scalars['ID'];
  discountType: Scalars['String'];
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  amount: Scalars['Int'];
  posId: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleDiscountInput = {
  discountType: Scalars['String'];
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  amount: Scalars['Int'];
  posId: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type SaleEntryMethod = {
   __typename?: 'SaleEntryMethod';
  methodId: Scalars['String'];
  methodType: Scalars['String'];
  externalReference: Scalars['String'];
};

export type SaleEntryMethodInput = {
  methodId: Scalars['String'];
  methodType: Scalars['String'];
  externalReference: Scalars['String'];
};

export type SaleGiftCard = {
   __typename?: 'SaleGiftCard';
  id: Scalars['String'];
  amount: Scalars['Int'];
  transactionId: Scalars['Int'];
  account: Scalars['String'];
  deviceId: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type SaleGiftCardInput = {
  id: Scalars['String'];
  amount: Scalars['Int'];
  transactionId: Scalars['Int'];
  account: Scalars['String'];
  deviceId: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type SaleInput = {
  id?: Maybe<Scalars['ID']>;
  unitId: Scalars['String'];
  posType: Scalars['String'];
  posVersion: Scalars['String'];
  terminalId?: Maybe<Scalars['String']>;
  terminalName?: Maybe<Scalars['String']>;
  drawerId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  destinationId?: Maybe<Scalars['String']>;
  destinationName?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  saleNumber: Scalars['String'];
  orderNumber: Scalars['String'];
  status: SaleStatusEnum;
  nonTaxable?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
  voided?: Maybe<Scalars['Boolean']>;
  voidedAt?: Maybe<Scalars['Int']>;
  version: Scalars['String'];
  items: Array<SaleItemInput>;
  payments?: Maybe<Array<SalePaymentInput>>;
  discounts?: Maybe<Array<SaleDiscountInput>>;
  customer?: Maybe<SaleCustomerInput>;
  totals: SaleTotalsInput;
  times: SaleTimesInput;
  metadata?: Maybe<Array<SaleMetadataInput>>;
  loyaltyEmail?: Maybe<Scalars['String']>;
  entryMethod: SaleEntryMethodInput;
  deliveryDetail?: Maybe<SaleDeliveryDetailInput>;
  taxes: Array<SaleTaxInput>;
  giftCards: Array<SaleGiftCardInput>;
};

export type SaleItem = {
   __typename?: 'SaleItem';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  voided: Scalars['Boolean'];
  items?: Maybe<Array<SaleItem>>;
  modifications?: Maybe<Array<SaleModifier>>;
  discounts?: Maybe<Array<SaleDiscount>>;
  slotId?: Maybe<Scalars['String']>;
  basePrice: Scalars['Int'];
  variationPrice?: Maybe<Scalars['Int']>;
  variation?: Maybe<Scalars['String']>;
  variationIdentifier?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  posId: Scalars['String'];
  tags: Array<Scalars['String']>;
  surchargeAmount?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleItemInput = {
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  voided: Scalars['Boolean'];
  items?: Maybe<Array<SaleItemInput>>;
  modifications?: Maybe<Array<SaleModifierInput>>;
  discounts?: Maybe<Array<SaleDiscountInput>>;
  slotId?: Maybe<Scalars['String']>;
  basePrice: Scalars['Int'];
  variationPrice?: Maybe<Scalars['Int']>;
  variation?: Maybe<Scalars['String']>;
  variationIdentifier?: Maybe<Scalars['String']>;
  variationId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  posId: Scalars['String'];
  tags: Array<Scalars['String']>;
  surchargeAmount?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type SaleListEdge = {
   __typename?: 'SaleListEdge';
  cursor?: Maybe<Scalars['String']>;
  nodes: Array<Sale>;
};

export type SaleMetadata = {
   __typename?: 'SaleMetadata';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SaleMetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type SaleModifier = {
   __typename?: 'SaleModifier';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  modifierName: Scalars['String'];
  modifierId: Scalars['String'];
  slotId?: Maybe<Scalars['String']>;
  posId: Scalars['String'];
  tags: Array<Scalars['String']>;
  surchargeAmount?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleModifierInput = {
  identifier: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  modifierName: Scalars['String'];
  modifierId: Scalars['String'];
  slotId?: Maybe<Scalars['String']>;
  posId: Scalars['String'];
  tags: Array<Scalars['String']>;
  surchargeAmount?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type SalePayment = {
   __typename?: 'SalePayment';
  id: Scalars['ID'];
  methodId: Scalars['String'];
  methodName: Scalars['String'];
  amount: Scalars['Int'];
  authCode: Scalars['String'];
  deviceId: Scalars['String'];
  transactionId: Scalars['Int'];
  referenceNumber: Scalars['String'];
  account: Scalars['String'];
  applicationName: Scalars['String'];
  applicationId: Scalars['String'];
  cryptogramType: Scalars['String'];
  cryptogram: Scalars['String'];
  entryMethod: Scalars['String'];
  balance: Scalars['Int'];
  expireDate: Scalars['String'];
  voided: Scalars['Boolean'];
  cardType: Scalars['String'];
  cardHolder: Scalars['String'];
  employeeId: Scalars['String'];
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SalePaymentInput = {
  methodId: Scalars['String'];
  methodName: Scalars['String'];
  amount: Scalars['Int'];
  authCode?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['Int']>;
  referenceNumber?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  applicationName?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  cryptogramType?: Maybe<Scalars['String']>;
  cryptogram?: Maybe<Scalars['String']>;
  entryMethod?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Int']>;
  expireDate?: Maybe<Scalars['String']>;
  voided?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardHolder?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export enum SalesSubscriptionResourceType {
  Unit = 'unit'
}

export enum SaleStatusEnum {
  Open = 'open',
  Complete = 'complete',
  Voided = 'voided',
  Completed = 'Completed'
}

export type SaleTax = {
   __typename?: 'SaleTax';
  id: Scalars['String'];
  name: Scalars['String'];
  amount: Scalars['Int'];
};

export type SaleTaxInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  amount: Scalars['Int'];
};

export type SaleTimes = {
   __typename?: 'SaleTimes';
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  pickupTime: Scalars['Int'];
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleTimesInput = {
  startedAt: Scalars['Int'];
  completedAt: Scalars['Int'];
  pickupTime?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type SaleTotals = {
   __typename?: 'SaleTotals';
  sub: Scalars['Int'];
  tax: Scalars['Int'];
  tip: Scalars['Int'];
  fee: Scalars['Int'];
  discount: Scalars['Int'];
  total: Scalars['Int'];
  metadata?: Maybe<Array<SaleMetadata>>;
};

export type SaleTotalsInput = {
  subtotal: Scalars['Int'];
  tax: Scalars['Int'];
  tip?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  metadata?: Maybe<Array<SaleMetadataInput>>;
};

export type Sort = {
  field: Scalars['String'];
  direction: SortDirection;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortOrderInput = {
  id: Scalars['ID'];
  sortIndex: Scalars['Int'];
};

export type Subscription = {
   __typename?: 'Subscription';
  sales?: Maybe<Sale>;
  externalSales?: Maybe<Sale>;
  unit?: Maybe<Unit>;
  configUpdate?: Maybe<Scalars['String']>;
  menuUpdate?: Maybe<Scalars['String']>;
  employeeUpdate?: Maybe<Scalars['String']>;
};


export type SubscriptionSalesArgs = {
  resourceType: SalesSubscriptionResourceType;
  resourceId: Scalars['String'];
};


export type SubscriptionExternalSalesArgs = {
  resourceType: SalesSubscriptionResourceType;
  resourceId: Scalars['String'];
};


export type SubscriptionUnitArgs = {
  id: Scalars['String'];
};


export type SubscriptionConfigUpdateArgs = {
  unitId: Scalars['String'];
};


export type SubscriptionMenuUpdateArgs = {
  unitId: Scalars['String'];
};


export type SubscriptionEmployeeUpdateArgs = {
  unitId: Scalars['ID'];
};

export type SystemConfig = {
   __typename?: 'SystemConfig';
  taxCards: Array<TaxCard>;
};

export type TaxCard = {
   __typename?: 'TaxCard';
  name: Scalars['String'];
  rate: Scalars['Float'];
  roundingOption: RoundingOption;
};

export type Team = {
   __typename?: 'Team';
  id: Scalars['String'];
  name: Scalars['String'];
  grants: Array<Grant>;
  granted: Array<Grant>;
  grantedEmployees: Array<Employee>;
  grant?: Maybe<Grant>;
  access?: Maybe<Grant>;
};


export type TeamGrantArgs = {
  target?: Maybe<InputResource>;
};


export type TeamAccessArgs = {
  target?: Maybe<InputResource>;
};

export type Terminal = {
   __typename?: 'Terminal';
  posId: Scalars['String'];
};

export type TimeclockRecord = {
   __typename?: 'TimeclockRecord';
  posId: Scalars['String'];
  employee: Employee;
  clockInAt: Scalars['Int'];
  clockOutAt?: Maybe<Scalars['Int']>;
  breaks: Array<Break>;
};

export type Transaction = {
   __typename?: 'Transaction';
  id: Scalars['String'];
  created_at: Scalars['Int'];
  updated_at: Scalars['Int'];
  pos_id: Scalars['String'];
  from_account: Scalars['String'];
  to_account: Scalars['String'];
  amount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Unit = {
   __typename?: 'Unit';
  loyaltyConfig?: Maybe<LoyaltyUnitConfig>;
  employees?: Maybe<EmployeeListEdge>;
  id: Scalars['ID'];
  name: Scalars['String'];
  identifier: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  disabled: Scalars['Boolean'];
  street1: Scalars['String'];
  street2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  organization: Organization;
  concepts: Array<Concept>;
  updatedAt: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  lastSeenAt: Scalars['Int'];
  online: Scalars['Boolean'];
  systemActor?: Maybe<Actor>;
  dailyOverview?: Maybe<LegacyReportOverviewDetail>;
  privileges: Array<Privilege>;
  customAttributes: Array<UnitCustomAttribute>;
  sales: Array<Sale>;
  externalSales: Array<Sale>;
  cashDrawers: Array<CashDrawer>;
  timeclockRecords: Array<TimeclockRecord>;
  transactions: Array<Transaction>;
  systemConfig: SystemConfig;
  menu?: Maybe<Menu>;
};


export type UnitPrivilegesArgs = {
  actions: Array<Scalars['String']>;
};


export type UnitSalesArgs = {
  startDate?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Int']>;
};


export type UnitCashDrawersArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


export type UnitTimeclockRecordsArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


export type UnitTransactionsArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};

export type UnitCustomAttribute = {
   __typename?: 'UnitCustomAttribute';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UnitCustomAttributeInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UnitHealth = {
   __typename?: 'UnitHealth';
  status: UnitHealthEnum;
};

export enum UnitHealthEnum {
  Healthy = 'healthy',
  Offline = 'offline'
}

export type UnitListEdge = {
   __typename?: 'UnitListEdge';
  cursor: Scalars['String'];
  nodes: Array<Unit>;
};

export type UpdateItemDefinitionInput = {
  id: Scalars['ID'];
  fragmentId: Scalars['ID'];
  plu: Scalars['String'];
  longName: Scalars['String'];
  shortName: Scalars['String'];
  price: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type ValidatePinEdge = {
   __typename?: 'ValidatePinEdge';
  message: Scalars['String'];
};

export type GetGrantQueryVariables = {
  actorId: Scalars['String'];
  resource?: Maybe<InputResource>;
};


export type GetGrantQuery = (
  { __typename?: 'Query' }
  & { actor: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id'>
    & { grant?: Maybe<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'actions' | 'policies'>
      & { target: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'name'>
      ) }
    )> }
  ) }
);

export type CreateGrantMutationVariables = {
  source: InputResource;
  target: InputResource;
  policies: Array<Scalars['String']>;
};


export type CreateGrantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGrant'>
);

export type DeleteGrantMutationVariables = {
  source: InputResource;
  target: InputResource;
};


export type DeleteGrantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGrant'>
);

export type GetActorResourceTypesQueryVariables = {
  actorId: Scalars['String'];
  action?: Maybe<Scalars['String']>;
};


export type GetActorResourceTypesQuery = (
  { __typename?: 'Query' }
  & { actor: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id' | 'resourceTypes'>
  ) }
);

export type GetActorResourcesQueryVariables = {
  actorId: Scalars['String'];
  type?: Maybe<ResourceType>;
};


export type GetActorResourcesQuery = (
  { __typename?: 'Query' }
  & { actor: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id'>
    & { resources: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'type' | 'name'>
    )> }
  ) }
);

export type CreateSystemActorMutationVariables = {
  resourceType: ResourceType;
  resourceId: Scalars['String'];
};


export type CreateSystemActorMutation = (
  { __typename?: 'Mutation' }
  & { createSystemActor?: Maybe<(
    { __typename?: 'Actor' }
    & Pick<Actor, 'id' | 'name'>
  )> }
);

export type DeleteActorMutationVariables = {
  id: Scalars['String'];
};


export type DeleteActorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActor'>
);

export type SubsetPoliciesQueryVariables = {
  policies: Array<Scalars['String']>;
  primary: Scalars['Boolean'];
};


export type SubsetPoliciesQuery = (
  { __typename?: 'Query' }
  & { subsetPolicies: Array<(
    { __typename?: 'Policy' }
    & Pick<Policy, 'name'>
  )> }
);

export type ListMenuFragmentsQueryVariables = {};


export type ListMenuFragmentsQuery = (
  { __typename?: 'Query' }
  & { menuFragments: (
    { __typename?: 'MenuFragmentListEdge' }
    & { nodes: Array<(
      { __typename?: 'MenuFragment' }
      & Pick<MenuFragment, 'id' | 'name'>
    )> }
  ) }
);

export type UpdateItemDefinitionPriceMutationVariables = {
  fragmentId: Scalars['ID'];
  itemDefinitionId: Scalars['ID'];
  newPrice: Scalars['Int'];
};


export type UpdateItemDefinitionPriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItemDefinitionPrice'>
);

export type CreateMenuFragmentMutationVariables = {
  name: Scalars['String'];
  resource: InputResource;
};


export type CreateMenuFragmentMutation = (
  { __typename?: 'Mutation' }
  & { createMenuFragment?: Maybe<(
    { __typename?: 'MenuFragment' }
    & Pick<MenuFragment, 'id' | 'name'>
  )> }
);

export type CreateItemDefinitionMutationVariables = {
  input: CreateItemDefinitionInput;
};


export type CreateItemDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { createItemDefinition?: Maybe<(
    { __typename?: 'ItemDefinition' }
    & Pick<ItemDefinition, 'id' | 'plu' | 'longName' | 'shortName'>
  )> }
);

export type UpdateItemDefinitionMutationVariables = {
  input: UpdateItemDefinitionInput;
};


export type UpdateItemDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateItemDefinition?: Maybe<(
    { __typename?: 'ItemDefinition' }
    & Pick<ItemDefinition, 'id' | 'plu' | 'longName' | 'shortName' | 'price' | 'tags'>
  )> }
);

export type MenuFragmentQueryVariables = {
  id: Scalars['ID'];
};


export type MenuFragmentQuery = (
  { __typename?: 'Query' }
  & { menuFragment: (
    { __typename?: 'MenuFragment' }
    & Pick<MenuFragment, 'id' | 'name' | 'itemDefinitionCount' | 'pricingUpdateCount' | 'slotCount' | 'discountCount' | 'dynamicPriceCount' | 'categoryCount'>
    & { linkedFragments: Array<(
      { __typename?: 'MenuFragmentLink' }
      & Pick<MenuFragmentLink, 'linkedFragmentId' | 'linkedFragmentName'>
    )> }
  ) }
);

export type MenuFragmentItemDefinitionsQueryVariables = {
  id: Scalars['ID'];
};


export type MenuFragmentItemDefinitionsQuery = (
  { __typename?: 'Query' }
  & { menuFragment: (
    { __typename?: 'MenuFragment' }
    & Pick<MenuFragment, 'id' | 'name'>
    & { itemDefinitions: (
      { __typename?: 'MenuFragmentItems' }
      & { internal: Array<(
        { __typename?: 'ItemDefinition' }
        & Pick<ItemDefinition, 'id' | 'plu' | 'longName'>
      )>, external: Array<(
        { __typename?: 'ItemDefinition' }
        & Pick<ItemDefinition, 'id' | 'plu' | 'longName'>
      )> }
    ) }
  ) }
);

export type MenuFragmentPriceUpdatesQueryVariables = {
  id: Scalars['ID'];
};


export type MenuFragmentPriceUpdatesQuery = (
  { __typename?: 'Query' }
  & { menuFragment: (
    { __typename?: 'MenuFragment' }
    & Pick<MenuFragment, 'id' | 'name'>
    & { priceUpdates: Array<(
      { __typename?: 'MenuPriceUpdate' }
      & Pick<MenuPriceUpdate, 'id' | 'fragmentId' | 'name' | 'plu' | 'price'>
    )> }
  ) }
);

export type ItemDefinitionQueryVariables = {
  fragmentId: Scalars['ID'];
  itemDefinitionId: Scalars['ID'];
};


export type ItemDefinitionQuery = (
  { __typename?: 'Query' }
  & { menuFragment: (
    { __typename?: 'MenuFragment' }
    & Pick<MenuFragment, 'id' | 'name'>
    & { itemDefinition: (
      { __typename?: 'ItemDefinition' }
      & Pick<ItemDefinition, 'id' | 'plu' | 'longName' | 'shortName' | 'price' | 'tags'>
    ) }
  ) }
);

export type ConceptQueryVariables = {
  id: Scalars['String'];
};


export type ConceptQuery = (
  { __typename?: 'Query' }
  & { concept: (
    { __typename?: 'Concept' }
    & Pick<Concept, 'id' | 'name' | 'updatedAt' | 'createdAt'>
  ) }
);

export type ConceptGroupsQueryVariables = {
  id: Scalars['String'];
};


export type ConceptGroupsQuery = (
  { __typename?: 'Query' }
  & { concept: (
    { __typename?: 'Concept' }
    & Pick<Concept, 'id' | 'name'>
    & { groups: Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )> }
  ) }
);

export type ConceptsQueryVariables = {
  cursor?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
};


export type ConceptsQuery = (
  { __typename?: 'Query' }
  & { concepts: (
    { __typename?: 'ConceptListEdge' }
    & Pick<ConceptListEdge, 'cursor'>
    & { nodes: Array<(
      { __typename?: 'Concept' }
      & Pick<Concept, 'id' | 'name'>
    )> }
  ) }
);

export type CreateConceptMutationVariables = {
  name: Scalars['String'];
};


export type CreateConceptMutation = (
  { __typename?: 'Mutation' }
  & { createConcept: (
    { __typename?: 'Concept' }
    & Pick<Concept, 'id' | 'name'>
  ) }
);

export type CreateLegacyInventoryLocationMutationVariables = {
  unitId: Scalars['String'];
  name: Scalars['String'];
};


export type CreateLegacyInventoryLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLegacyInventoryLocation?: Maybe<(
    { __typename?: 'LegacyInventoryLocation' }
    & Pick<LegacyInventoryLocation, 'id' | 'name' | 'state'>
  )> }
);

export type CreateOrganizationMutationVariables = {
  name: Scalars['String'];
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};


export type CreateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'phone' | 'street1' | 'street2' | 'city' | 'state' | 'zip' | 'country'>
  )> }
);

export type CreateUnitMutationVariables = {
  name: Scalars['String'];
  identifier: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  organizationId: Scalars['String'];
  conceptIds: Array<Scalars['String']>;
};


export type CreateUnitMutation = (
  { __typename?: 'Mutation' }
  & { createUnit?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name' | 'identifier' | 'phone' | 'email' | 'street1' | 'street2' | 'city' | 'state' | 'zip' | 'country' | 'updatedAt' | 'createdAt'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    ), concepts: Array<(
      { __typename?: 'Concept' }
      & Pick<Concept, 'name'>
    )> }
  )> }
);

export type DisableUnitMutationVariables = {
  id: Scalars['String'];
};


export type DisableUnitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableUnit'>
);

export type EmployeesQueryVariables = {
  cursor?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Sort>>;
};


export type EmployeesQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'EmployeeListEdge' }
    & Pick<EmployeeListEdge, 'cursor'>
    & { nodes: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'deletedAt' | 'status'>
    )> }
  ) }
);

export type GrantableEmployeesQueryVariables = {
  cursor?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Sort>>;
};


export type GrantableEmployeesQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'EmployeeListEdge' }
    & Pick<EmployeeListEdge, 'cursor'>
    & { nodes: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'appAccessEnabled'>
    )> }
  ) }
);

export type EmployeeQueryVariables = {
  id: Scalars['String'];
};


export type EmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'deletedAt' | 'updatedAt' | 'createdAt' | 'status'>
  ) }
);

export type ViewEmployeeQueryVariables = {
  id: Scalars['String'];
};


export type ViewEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'deletedAt' | 'updatedAt' | 'createdAt' | 'status' | 'carbonIdentifier' | 'carbonPin' | 'carbonAccessToken'>
  ) }
);

export type EmployeeLoginPermissionsQueryVariables = {
  id: Scalars['String'];
};


export type EmployeeLoginPermissionsQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'status'>
    & { loginActor?: Maybe<(
      { __typename?: 'Actor' }
      & Pick<Actor, 'id' | 'name'>
      & { grants: Array<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'actions' | 'policies'>
        & { target: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'name'>
          & { group?: Maybe<(
            { __typename?: 'GroupResource' }
            & Pick<GroupResource, 'name' | 'type'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type EmployeeRelationshipsQueryVariables = {
  id: Scalars['String'];
};


export type EmployeeRelationshipsQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { relationships: (
      { __typename?: 'EmployeeRelationships' }
      & { parents: Array<(
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'name'>
      )>, children: Array<(
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'name'>
      )> }
    ) }
  ) }
);

export type DisableEmployeeMutationVariables = {
  id: Scalars['String'];
};


export type DisableEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableEmployee'>
);

export type EnableEmployeeMutationVariables = {
  id: Scalars['String'];
};


export type EnableEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enableEmployee'>
);

export type ResendInviteMutationVariables = {
  id: Scalars['String'];
};


export type ResendInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendInvite'>
);

export type CreateEmployeeMutationVariables = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  parentType: ResourceType;
  parentId: Scalars['String'];
  sendInvite?: Maybe<Scalars['Boolean']>;
};


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { loginActor?: Maybe<(
      { __typename?: 'Actor' }
      & Pick<Actor, 'id'>
    )> }
  )> }
);

export type UpdateEmployeeMutationVariables = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  carbonIdentifier: Scalars['String'];
  carbonPin: Scalars['String'];
  carbonAccessToken: Scalars['String'];
};


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type ChangePinMutationVariables = {
  employeeId: Scalars['String'];
  pin: Scalars['String'];
};


export type ChangePinMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePin'>
);

export type AcceptInviteMutationVariables = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  pin: Scalars['String'];
};


export type AcceptInviteMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvite?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )> }
);

export type EnableEmployeeAccessMutationVariables = {
  id: Scalars['String'];
};


export type EnableEmployeeAccessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enableEmployeeAccess'>
);

export type DisableEmployeeAccessMutationVariables = {
  id: Scalars['String'];
};


export type DisableEmployeeAccessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableEmployeeAccess'>
);

export type AssignEmployeeToUnitMutationVariables = {
  employeeId: Scalars['String'];
  unitId: Scalars['String'];
};


export type AssignEmployeeToUnitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignEmployeeToUnit'>
);

export type UnassignEmployeeFromUnitMutationVariables = {
  employeeId: Scalars['String'];
  unitId: Scalars['String'];
};


export type UnassignEmployeeFromUnitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignEmployeeFromUnit'>
);

export type AssignEmployeeToGroupMutationVariables = {
  employeeId: Scalars['String'];
  groupId: Scalars['String'];
};


export type AssignEmployeeToGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignEmployeeToGroup'>
);

export type UnassignEmployeeFromGroupMutationVariables = {
  employeeId: Scalars['String'];
  groupId: Scalars['String'];
};


export type UnassignEmployeeFromGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignEmployeeFromGroup'>
);

export type AssignEmployeeToOrganizationMutationVariables = {
  employeeId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type AssignEmployeeToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignEmployeeToOrganization'>
);

export type UnassignEmployeeFromOrganizationMutationVariables = {
  employeeId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type UnassignEmployeeFromOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignEmployeeFromOrganization'>
);

export type AssignEmployeeToConceptMutationVariables = {
  employeeId: Scalars['String'];
  conceptId: Scalars['String'];
};


export type AssignEmployeeToConceptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignEmployeeToConcept'>
);

export type UnassignEmployeeFromConceptMutationVariables = {
  employeeId: Scalars['String'];
  conceptId: Scalars['String'];
};


export type UnassignEmployeeFromConceptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignEmployeeFromConcept'>
);

export type EnableUnitMutationVariables = {
  id: Scalars['String'];
};


export type EnableUnitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enableUnit'>
);

export type GetEmployeeByEmailQueryVariables = {
  email: Scalars['String'];
};


export type GetEmployeeByEmailQuery = (
  { __typename?: 'Query' }
  & { employeeByEmail: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'firstName' | 'lastName' | 'email'>
  ) }
);

export type GetOrganizationQueryVariables = {
  id: Scalars['String'];
};


export type GetOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'city' | 'street1' | 'street2' | 'state' | 'country' | 'phone' | 'zip' | 'updatedAt' | 'createdAt'>
  ) }
);

export type GetUnitEmployeesQueryVariables = {
  id: Scalars['String'];
};


export type GetUnitEmployeesQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, 'id'>
    & { employees?: Maybe<(
      { __typename?: 'EmployeeListEdge' }
      & Pick<EmployeeListEdge, 'cursor'>
      & { nodes: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email'>
      )> }
    )> }
  ) }
);

export type GetUnitQueryVariables = {
  id: Scalars['String'];
};


export type GetUnitQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name' | 'identifier' | 'phone' | 'email' | 'street1' | 'street2' | 'city' | 'state' | 'zip' | 'country' | 'updatedAt' | 'createdAt' | 'deletedAt' | 'online' | 'lastSeenAt'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    ), concepts: Array<(
      { __typename?: 'Concept' }
      & Pick<Concept, 'name'>
    )>, customAttributes: Array<(
      { __typename?: 'UnitCustomAttribute' }
      & Pick<UnitCustomAttribute, 'key' | 'value'>
    )> }
  ) }
);

export type UnitSystemPermissionsQueryVariables = {
  id: Scalars['String'];
};


export type UnitSystemPermissionsQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name'>
    & { systemActor?: Maybe<(
      { __typename?: 'Actor' }
      & Pick<Actor, 'id' | 'name' | 'secret'>
      & { grants: Array<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'actions' | 'policies'>
        & { target: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type GetUnitOverviewQueryVariables = {
  id: Scalars['String'];
};


export type GetUnitOverviewQuery = (
  { __typename?: 'Query' }
  & { unit: (
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name'>
    & { dailyOverview?: Maybe<(
      { __typename?: 'LegacyReportOverviewDetail' }
      & Pick<LegacyReportOverviewDetail, 'netsaleAmount' | 'yoyNetsalePercent' | 'saleCount' | 'yoySaleCountPercent' | 'voidAmount' | 'deleteAmount' | 'cancelledOrderAmount' | 'laborPercent' | 'salesPerManHour' | 'discountAmount' | 'driveThruAverageTime' | 'driveThruCount' | 'updatedAt'>
    )> }
  ) }
);

export type CreateGroupMutationVariables = {
  parentId: Scalars['String'];
  parentType: ResourceType;
  name: Scalars['String'];
};


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type RenameGroupMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type RenameGroupMutation = (
  { __typename?: 'Mutation' }
  & { renameGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type DeleteGroupMutationVariables = {
  id: Scalars['String'];
};


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type AssignToGroupMutationVariables = {
  input: GroupResourcesInput;
};


export type AssignToGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignToGroup'>
);

export type UnassignFromGroupMutationVariables = {
  input: GroupResourcesInput;
};


export type UnassignFromGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignFromGroup'>
);

export type GroupResourcesQueryVariables = {
  id: Scalars['String'];
  type: ResourceType;
};


export type GroupResourcesQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
    & { resources: Array<(
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'name' | 'type'>
    )> }
  ) }
);

export type GroupUnitsQueryVariables = {
  id: Scalars['String'];
};


export type GroupUnitsQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
    & { units: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, 'id' | 'name' | 'identifier'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'name'>
      ) }
    )> }
  ) }
);

export type GroupsQueryVariables = {};


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type HydrateActorQueryVariables = {
  id: Scalars['String'];
};


export type HydrateActorQuery = (
  { __typename?: 'Query' }
  & { actor: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id' | 'name' | 'resourceType' | 'resourceId' | 'lastUsedAt' | 'updatedAt' | 'createdAt'>
    & { permissions: (
      { __typename?: 'ActorPermissions' }
      & Pick<ActorPermissions, 'singleUnit' | 'inventory' | 'reports' | 'system'>
    ), employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'status' | 'createdAt' | 'updatedAt' | 'carbonIdentifier' | 'carbonPin' | 'carbonAccessToken'>
    )> }
  ) }
);

export type LegacyInventoryCountStatQueryVariables = {
  itemId: Scalars['String'];
  unitId: Scalars['String'];
  businessDate: Scalars['Int'];
};


export type LegacyInventoryCountStatQuery = (
  { __typename?: 'Query' }
  & { legacyInventoryStat: (
    { __typename?: 'LegacyInventoryStat' }
    & Pick<LegacyInventoryStat, 'beginningDate' | 'beginningCount' | 'waste' | 'purchases' | 'borrows' | 'loans' | 'usage' | 'currentCount' | 'expectedCount'>
    & { items: Array<(
      { __typename?: 'LegacyInventoryStatItem' }
      & Pick<LegacyInventoryStatItem, 'id' | 'itemNumber' | 'name' | 'individualDescription'>
      & { counts: Array<(
        { __typename?: 'LegacyInventoryStatCount' }
        & Pick<LegacyInventoryStatCount, 'locationName' | 'count'>
      )> }
    )> }
  ) }
);

export type LegacyInventoryCountSheetQueryVariables = {
  unitId: Scalars['String'];
  businessDate: Scalars['Int'];
  frequencies: Array<Scalars['String']>;
};


export type LegacyInventoryCountSheetQuery = (
  { __typename?: 'Query' }
  & { legacyInventoryCountSheet: (
    { __typename?: 'LegacyInventoryCountSheet' }
    & Pick<LegacyInventoryCountSheet, 'count' | 'countedCount' | 'uncountedCount'>
    & { locations: Array<(
      { __typename?: 'LegacyInventoryCountSheetLocation' }
      & Pick<LegacyInventoryCountSheetLocation, 'id' | 'name' | 'itemCount'>
    )>, items: Array<(
      { __typename?: 'LegacyInventoryCountSheetItem' }
      & Pick<LegacyInventoryCountSheetItem, 'locationId' | 'sortIndex' | 'itemId' | 'itemNumber' | 'itemName' | 'caseDescription' | 'subcaseDescription' | 'individualDescription' | 'subcaseQuantity' | 'totalQuantity' | 'frequency' | 'count' | 'formattedCount' | 'formattedTotalCount' | 'caseCount' | 'subcaseCount' | 'eachCount' | 'totalCount' | 'isCounted'>
    )> }
  ), legacyInventoryOrphanedItems: Array<(
    { __typename?: 'LegacyInventoryItem' }
    & Pick<LegacyInventoryItem, 'id' | 'itemNumber' | 'name'>
  )> }
);

export type LegacyInventoryCreateCountsMutationVariables = {
  counts: Array<LegacyInventoryCountCreateInput>;
  unitId: Scalars['String'];
};


export type LegacyInventoryCreateCountsMutation = (
  { __typename?: 'Mutation' }
  & { createLegacyInventoryCounts?: Maybe<(
    { __typename?: 'LegacyInventoryCountCreateResponse' }
    & { counts: Array<(
      { __typename?: 'LegacyInventoryCountCreateOutput' }
      & Pick<LegacyInventoryCountCreateOutput, 'itemId' | 'locationId' | 'count' | 'caseCount' | 'subcaseCount' | 'eachCount' | 'businessDate'>
    )> }
  )> }
);

export type LegacyInventoryItemsQueryVariables = {
  unitId: Scalars['String'];
};


export type LegacyInventoryItemsQuery = (
  { __typename?: 'Query' }
  & { legacyInventoryItems: (
    { __typename?: 'LegacyInventoryItemListEdge' }
    & { nodes: Array<(
      { __typename?: 'LegacyInventoryItem' }
      & Pick<LegacyInventoryItem, 'id' | 'itemNumber' | 'name' | 'frequency' | 'state' | 'isMaster'>
    )> }
  ) }
);

export type LegacyInventoryLocationQueryVariables = {
  unitId: Scalars['String'];
  id: Scalars['String'];
};


export type LegacyInventoryLocationQuery = (
  { __typename?: 'Query' }
  & { legacyInventoryLocation: (
    { __typename?: 'LegacyInventoryLocation' }
    & Pick<LegacyInventoryLocation, 'id' | 'name' | 'state'>
    & { items: Array<(
      { __typename?: 'LegacyInventoryLocationItem' }
      & Pick<LegacyInventoryLocationItem, 'itemId' | 'sortIndex'>
    )> }
  ) }
);

export type LegacyInventoryLocationsQueryVariables = {
  unitId: Scalars['String'];
  disabled: Scalars['Boolean'];
};


export type LegacyInventoryLocationsQuery = (
  { __typename?: 'Query' }
  & { legacyInventoryLocations: (
    { __typename?: 'LegacyInventoryLocationListEdge' }
    & { nodes: Array<(
      { __typename?: 'LegacyInventoryLocation' }
      & Pick<LegacyInventoryLocation, 'id' | 'name' | 'state'>
      & { items: Array<(
        { __typename?: 'LegacyInventoryLocationItem' }
        & Pick<LegacyInventoryLocationItem, 'itemId' | 'sortIndex'>
      )> }
    )> }
  ) }
);

export type ListOrganizationsQueryVariables = {
  cursor?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
};


export type ListOrganizationsQuery = (
  { __typename?: 'Query' }
  & { organizations: (
    { __typename?: 'OrganizationListEdge' }
    & Pick<OrganizationListEdge, 'cursor'>
    & { nodes: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'city' | 'state' | 'phone'>
    )> }
  ) }
);

export type ListUnitsQueryVariables = {
  cursor?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Sort>>;
};


export type ListUnitsQuery = (
  { __typename?: 'Query' }
  & { units: (
    { __typename?: 'UnitListEdge' }
    & Pick<UnitListEdge, 'cursor'>
    & { nodes: Array<(
      { __typename?: 'Unit' }
      & Pick<Unit, 'id' | 'name' | 'identifier' | 'city' | 'state' | 'phone' | 'lastSeenAt' | 'online'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'name'>
      ), concepts: Array<(
        { __typename?: 'Concept' }
        & Pick<Concept, 'name'>
      )> }
    )> }
  ) }
);

export type OrganizationGroupsQueryVariables = {
  id: Scalars['String'];
};


export type OrganizationGroupsQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { groups: Array<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )> }
  ) }
);

export type OrganizationConceptsQueryVariables = {
  id: Scalars['String'];
};


export type OrganizationConceptsQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { concepts: Array<(
      { __typename?: 'Concept' }
      & Pick<Concept, 'id' | 'name'>
    )> }
  ) }
);

export type AddConceptToOrganizationMutationVariables = {
  orgId: Scalars['String'];
  conceptId: Scalars['String'];
};


export type AddConceptToOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addConceptToOrganization'>
);

export type CheckResourcePrivilegesQueryVariables = {
  source: InputResource;
  targets: Array<InputResource>;
  actions: Array<Scalars['String']>;
};


export type CheckResourcePrivilegesQuery = (
  { __typename?: 'Query' }
  & { checkResourcePrivileges: Array<(
    { __typename?: 'ResourcePrivilege' }
    & { resource: (
      { __typename?: 'Resource' }
      & Pick<Resource, 'id' | 'type'>
    ), privileges: Array<(
      { __typename?: 'Privilege' }
      & Pick<Privilege, 'action' | 'allowed'>
    )> }
  )> }
);

export type CheckPrivilegesQueryVariables = {
  source: InputResource;
  actions: Array<Scalars['String']>;
};


export type CheckPrivilegesQuery = (
  { __typename?: 'Query' }
  & { checkPrivileges: Array<(
    { __typename?: 'Privilege' }
    & Pick<Privilege, 'action' | 'allowed'>
  )> }
);

export type ManageGrantsQueryVariables = {
  currentUser: Scalars['String'];
  sourceId: Scalars['String'];
  resource: InputResource;
};


export type ManageGrantsQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id'>
    & { access?: Maybe<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'policies'>
    )> }
  ), source: (
    { __typename?: 'Actor' }
    & Pick<Actor, 'id'>
    & { grant?: Maybe<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'policies'>
    )> }
  ) }
);

export type GetActorAccessQueryVariables = {
  actorId: Scalars['String'];
  resource?: Maybe<InputResource>;
};


export type GetActorAccessQuery = (
  { __typename?: 'Query' }
  & { actor: (
    { __typename?: 'Actor' }
    & { access?: Maybe<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'policies'>
    )> }
  ) }
);

export type ListReportsQueryVariables = {};


export type ListReportsQuery = (
  { __typename?: 'Query' }
  & { legacyReports: Array<(
    { __typename?: 'LegacyReport' }
    & Pick<LegacyReport, 'id' | 'name' | 'description' | 'icon'>
  )> }
);

export type GetReportQueryVariables = {
  id: Scalars['String'];
};


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { legacyReport: (
    { __typename?: 'LegacyReport' }
    & Pick<LegacyReport, 'id' | 'name' | 'description' | 'definition' | 'longDescription' | 'icon' | 'recordSetName'>
  ) }
);

export type GetRecordSetQueryVariables = {
  recordSetName: Scalars['String'];
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


export type GetRecordSetQuery = (
  { __typename?: 'Query' }
  & { legacyReportRecordSet: (
    { __typename?: 'LegacyReportRecordSet' }
    & Pick<LegacyReportRecordSet, 'records'>
  ) }
);

export type GetOverviewQueryVariables = {
  unitIds?: Maybe<Array<Scalars['String']>>;
  orgId?: Maybe<Scalars['String']>;
};


export type GetOverviewQuery = (
  { __typename?: 'Query' }
  & { legacyReportOverview: (
    { __typename?: 'LegacyReportOverview' }
    & Pick<LegacyReportOverview, 'unitCount' | 'netsaleAmount' | 'taxAmount' | 'grossAmount' | 'lastYearNetsaleAmount' | 'yoyNetsalePercent' | 'saleCount' | 'lastYearSaleCount' | 'yoySaleCountPercent' | 'voidAmount' | 'voidCount' | 'deleteAmount' | 'deleteCount' | 'cancelledOrderAmount' | 'cancelledOrderCount' | 'discountAmount' | 'discountCount' | 'clockedHours' | 'laborPercent' | 'salesPerManHour' | 'driveThruCount' | 'driveThruAverageTime'>
    & { detail: Array<(
      { __typename?: 'LegacyReportOverviewDetail' }
      & Pick<LegacyReportOverviewDetail, 'unitId' | 'unitName' | 'netsaleAmount' | 'taxAmount' | 'grossAmount' | 'lastYearNetsaleAmount' | 'yoyNetsalePercent' | 'saleCount' | 'lastYearSaleCount' | 'yoySaleCountPercent' | 'voidAmount' | 'voidCount' | 'deleteAmount' | 'deleteCount' | 'cancelledOrderAmount' | 'cancelledOrderCount' | 'discountAmount' | 'discountCount' | 'clockedHours' | 'laborPercent' | 'salesPerManHour' | 'driveThruCount' | 'driveThruAverageTime' | 'updatedAt'>
    )> }
  ) }
);

export type TeamsQueryVariables = {};


export type TeamsQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { granted: Array<(
      { __typename?: 'Grant' }
      & { target: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CreateTeamMutationVariables = {
  name: Scalars['String'];
  parentId: Scalars['String'];
  parentType: ResourceType;
};


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type DeleteTeamMutationVariables = {
  teamId: Scalars['String'];
};


export type DeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type RenameTeamMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type RenameTeamMutation = (
  { __typename?: 'Mutation' }
  & { renameTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type AddEmployeeToTeamMutationVariables = {
  employeeId: Scalars['String'];
  teamId: Scalars['String'];
};


export type AddEmployeeToTeamMutation = (
  { __typename?: 'Mutation' }
  & { addTeamMember?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )> }
);

export type RemoveEmployeeFromTeamMutationVariables = {
  employeeId: Scalars['String'];
  teamId: Scalars['String'];
};


export type RemoveEmployeeFromTeamMutation = (
  { __typename?: 'Mutation' }
  & { removeTeamMember?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
  )> }
);

export type GrantTeamMutationVariables = {
  teamId: Scalars['String'];
  targetType: ResourceType;
  targetId: Scalars['String'];
  policies: Array<Scalars['String']>;
};


export type GrantTeamMutation = (
  { __typename?: 'Mutation' }
  & { grantTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id'>
  )> }
);

export type RevokeTeamMutationVariables = {
  teamId: Scalars['ID'];
  targetType: ResourceType;
  targetId: Scalars['ID'];
};


export type RevokeTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGrant'>
);

export type TeamDetailsQueryVariables = {
  id: Scalars['String'];
};


export type TeamDetailsQuery = (
  { __typename?: 'Query' }
  & { team: (
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'name'>
    & { grants: Array<(
      { __typename?: 'Grant' }
      & Pick<Grant, 'actions' | 'policies'>
      & { target: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'type' | 'name'>
      ) }
    )>, granted: Array<(
      { __typename?: 'Grant' }
      & { target: (
        { __typename?: 'Resource' }
        & Pick<Resource, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type ConceptTeamsQueryVariables = {
  id: Scalars['String'];
};


export type ConceptTeamsQuery = (
  { __typename?: 'Query' }
  & { concept: (
    { __typename?: 'Concept' }
    & Pick<Concept, 'id'>
    & { teams: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { grants: Array<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'actions' | 'policies'>
        & { target: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'name'>
          & { group?: Maybe<(
            { __typename?: 'GroupResource' }
            & Pick<GroupResource, 'type'>
          )> }
        ) }
      )>, grantedEmployees: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type OrganizationTeamsQueryVariables = {
  id: Scalars['String'];
};


export type OrganizationTeamsQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'name'>
      & { grants: Array<(
        { __typename?: 'Grant' }
        & Pick<Grant, 'policies' | 'actions'>
        & { target: (
          { __typename?: 'Resource' }
          & Pick<Resource, 'id' | 'type' | 'name'>
          & { group?: Maybe<(
            { __typename?: 'GroupResource' }
            & Pick<GroupResource, 'type'>
          )> }
        ) }
      )>, grantedEmployees: Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  ) }
);

export type TransferUnitMutationVariables = {
  unitId: Scalars['String'];
  organizationId: Scalars['String'];
  excludedResources?: Maybe<Array<InputResource>>;
};


export type TransferUnitMutation = (
  { __typename?: 'Mutation' }
  & { transferUnit: (
    { __typename?: 'Unit' }
    & Pick<Unit, 'id'>
  ) }
);

export type UpdateConceptMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type UpdateConceptMutation = (
  { __typename?: 'Mutation' }
  & { updateConcept?: Maybe<(
    { __typename?: 'Concept' }
    & Pick<Concept, 'id' | 'name'>
  )> }
);

export type UpdateLegacyInventoryLocationMutationVariables = {
  id: Scalars['String'];
  unitId: Scalars['String'];
  name: Scalars['String'];
  state: LegacyInventoryLocationState;
};


export type UpdateLegacyInventoryLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLegacyInventoryLocation?: Maybe<(
    { __typename?: 'LegacyInventoryLocation' }
    & Pick<LegacyInventoryLocation, 'name' | 'state'>
  )> }
);

export type UpdateLegacyInventoryLocationItemsMutationVariables = {
  id: Scalars['String'];
  unitId: Scalars['String'];
  items?: Maybe<Array<LegacyInventoryLocationItemCreateInput>>;
};


export type UpdateLegacyInventoryLocationItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateLegacyInventoryLocationItems?: Maybe<(
    { __typename?: 'LegacyInventoryLocation' }
    & Pick<LegacyInventoryLocation, 'id' | 'unitId' | 'name' | 'state'>
    & { items: Array<(
      { __typename?: 'LegacyInventoryLocationItem' }
      & Pick<LegacyInventoryLocationItem, 'locationId' | 'itemId' | 'sortIndex'>
    )> }
  )> }
);

export type UpdateLegacyInventoryLocationManageItemMutationVariables = {
  locationId: Scalars['String'];
  unitId: Scalars['String'];
  id: Scalars['String'];
  sortIndex: Scalars['Int'];
  state: EnabledDisabledState;
};


export type UpdateLegacyInventoryLocationManageItemMutation = (
  { __typename?: 'Mutation' }
  & { updateLegacyInventoryLocationManageItem?: Maybe<(
    { __typename?: 'LegacyInventoryLocationItemResponse' }
    & Pick<LegacyInventoryLocationItemResponse, 'locationId' | 'unitId' | 'id' | 'sortIndex' | 'state'>
  )> }
);

export type UpdateOrganizationMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
};


export type UpdateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'phone' | 'street1' | 'street2' | 'city' | 'state' | 'zip' | 'country'>
  )> }
);

export type UpdateUnitMutationVariables = {
  id: Scalars['String'];
  name: Scalars['String'];
  identifier: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  country: Scalars['String'];
  attributes?: Maybe<Array<UnitCustomAttributeInput>>;
};


export type UpdateUnitMutation = (
  { __typename?: 'Mutation' }
  & { updateUnit?: Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name' | 'identifier' | 'phone' | 'email' | 'street1' | 'street2' | 'city' | 'state' | 'zip' | 'country' | 'updatedAt' | 'createdAt'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    ), concepts: Array<(
      { __typename?: 'Concept' }
      & Pick<Concept, 'name'>
    )>, customAttributes: Array<(
      { __typename?: 'UnitCustomAttribute' }
      & Pick<UnitCustomAttribute, 'key' | 'value'>
    )> }
  )> }
);


export const GetGrantDocument = gql`
    query GetGrant($actorId: String!, $resource: InputResource) {
  actor(id: $actorId) {
    id
    grant(target: $resource) {
      actions
      policies
      target {
        id
        type
        name
      }
    }
  }
}
    `;
export type GetGrantComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetGrantQuery, GetGrantQueryVariables>, 'query'> & ({ variables: GetGrantQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetGrantComponent = (props: GetGrantComponentProps) => (
      <ApolloReactComponents.Query<GetGrantQuery, GetGrantQueryVariables> query={GetGrantDocument} {...props} />
    );
    
export type GetGrantProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetGrantQuery, GetGrantQueryVariables>
    } & TChildProps;
export function withGetGrant<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetGrantQuery,
  GetGrantQueryVariables,
  GetGrantProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetGrantQuery, GetGrantQueryVariables, GetGrantProps<TChildProps, TDataName>>(GetGrantDocument, {
      alias: 'getGrant',
      ...operationOptions
    });
};
export type GetGrantQueryResult = ApolloReactCommon.QueryResult<GetGrantQuery, GetGrantQueryVariables>;
export const CreateGrantDocument = gql`
    mutation CreateGrant($source: InputResource!, $target: InputResource!, $policies: [String!]!) {
  createGrant(source: $source, target: $target, policies: $policies)
}
    `;
export type CreateGrantMutationFn = ApolloReactCommon.MutationFunction<CreateGrantMutation, CreateGrantMutationVariables>;
export type CreateGrantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGrantMutation, CreateGrantMutationVariables>, 'mutation'>;

    export const CreateGrantComponent = (props: CreateGrantComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGrantMutation, CreateGrantMutationVariables> mutation={CreateGrantDocument} {...props} />
    );
    
export type CreateGrantProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGrantMutation, CreateGrantMutationVariables>
    } & TChildProps;
export function withCreateGrant<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGrantMutation,
  CreateGrantMutationVariables,
  CreateGrantProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGrantMutation, CreateGrantMutationVariables, CreateGrantProps<TChildProps, TDataName>>(CreateGrantDocument, {
      alias: 'createGrant',
      ...operationOptions
    });
};
export type CreateGrantMutationResult = ApolloReactCommon.MutationResult<CreateGrantMutation>;
export type CreateGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGrantMutation, CreateGrantMutationVariables>;
export const DeleteGrantDocument = gql`
    mutation DeleteGrant($source: InputResource!, $target: InputResource!) {
  deleteGrant(source: $source, target: $target)
}
    `;
export type DeleteGrantMutationFn = ApolloReactCommon.MutationFunction<DeleteGrantMutation, DeleteGrantMutationVariables>;
export type DeleteGrantComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteGrantMutation, DeleteGrantMutationVariables>, 'mutation'>;

    export const DeleteGrantComponent = (props: DeleteGrantComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteGrantMutation, DeleteGrantMutationVariables> mutation={DeleteGrantDocument} {...props} />
    );
    
export type DeleteGrantProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteGrantMutation, DeleteGrantMutationVariables>
    } & TChildProps;
export function withDeleteGrant<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteGrantMutation,
  DeleteGrantMutationVariables,
  DeleteGrantProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteGrantMutation, DeleteGrantMutationVariables, DeleteGrantProps<TChildProps, TDataName>>(DeleteGrantDocument, {
      alias: 'deleteGrant',
      ...operationOptions
    });
};
export type DeleteGrantMutationResult = ApolloReactCommon.MutationResult<DeleteGrantMutation>;
export type DeleteGrantMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrantMutation, DeleteGrantMutationVariables>;
export const GetActorResourceTypesDocument = gql`
    query GetActorResourceTypes($actorId: String!, $action: String) {
  actor(id: $actorId) {
    id
    resourceTypes(action: $action)
  }
}
    `;
export type GetActorResourceTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetActorResourceTypesQuery, GetActorResourceTypesQueryVariables>, 'query'> & ({ variables: GetActorResourceTypesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetActorResourceTypesComponent = (props: GetActorResourceTypesComponentProps) => (
      <ApolloReactComponents.Query<GetActorResourceTypesQuery, GetActorResourceTypesQueryVariables> query={GetActorResourceTypesDocument} {...props} />
    );
    
export type GetActorResourceTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetActorResourceTypesQuery, GetActorResourceTypesQueryVariables>
    } & TChildProps;
export function withGetActorResourceTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetActorResourceTypesQuery,
  GetActorResourceTypesQueryVariables,
  GetActorResourceTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetActorResourceTypesQuery, GetActorResourceTypesQueryVariables, GetActorResourceTypesProps<TChildProps, TDataName>>(GetActorResourceTypesDocument, {
      alias: 'getActorResourceTypes',
      ...operationOptions
    });
};
export type GetActorResourceTypesQueryResult = ApolloReactCommon.QueryResult<GetActorResourceTypesQuery, GetActorResourceTypesQueryVariables>;
export const GetActorResourcesDocument = gql`
    query GetActorResources($actorId: String!, $type: ResourceType) {
  actor(id: $actorId) {
    id
    resources(type: $type) @client {
      id
      type
      name
    }
  }
}
    `;
export type GetActorResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetActorResourcesQuery, GetActorResourcesQueryVariables>, 'query'> & ({ variables: GetActorResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetActorResourcesComponent = (props: GetActorResourcesComponentProps) => (
      <ApolloReactComponents.Query<GetActorResourcesQuery, GetActorResourcesQueryVariables> query={GetActorResourcesDocument} {...props} />
    );
    
export type GetActorResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetActorResourcesQuery, GetActorResourcesQueryVariables>
    } & TChildProps;
export function withGetActorResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetActorResourcesQuery,
  GetActorResourcesQueryVariables,
  GetActorResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetActorResourcesQuery, GetActorResourcesQueryVariables, GetActorResourcesProps<TChildProps, TDataName>>(GetActorResourcesDocument, {
      alias: 'getActorResources',
      ...operationOptions
    });
};
export type GetActorResourcesQueryResult = ApolloReactCommon.QueryResult<GetActorResourcesQuery, GetActorResourcesQueryVariables>;
export const CreateSystemActorDocument = gql`
    mutation CreateSystemActor($resourceType: ResourceType!, $resourceId: String!) {
  createSystemActor(resourceType: $resourceType, resourceId: $resourceId) {
    id
    name
  }
}
    `;
export type CreateSystemActorMutationFn = ApolloReactCommon.MutationFunction<CreateSystemActorMutation, CreateSystemActorMutationVariables>;
export type CreateSystemActorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSystemActorMutation, CreateSystemActorMutationVariables>, 'mutation'>;

    export const CreateSystemActorComponent = (props: CreateSystemActorComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSystemActorMutation, CreateSystemActorMutationVariables> mutation={CreateSystemActorDocument} {...props} />
    );
    
export type CreateSystemActorProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSystemActorMutation, CreateSystemActorMutationVariables>
    } & TChildProps;
export function withCreateSystemActor<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSystemActorMutation,
  CreateSystemActorMutationVariables,
  CreateSystemActorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSystemActorMutation, CreateSystemActorMutationVariables, CreateSystemActorProps<TChildProps, TDataName>>(CreateSystemActorDocument, {
      alias: 'createSystemActor',
      ...operationOptions
    });
};
export type CreateSystemActorMutationResult = ApolloReactCommon.MutationResult<CreateSystemActorMutation>;
export type CreateSystemActorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSystemActorMutation, CreateSystemActorMutationVariables>;
export const DeleteActorDocument = gql`
    mutation DeleteActor($id: String!) {
  deleteActor(id: $id)
}
    `;
export type DeleteActorMutationFn = ApolloReactCommon.MutationFunction<DeleteActorMutation, DeleteActorMutationVariables>;
export type DeleteActorComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteActorMutation, DeleteActorMutationVariables>, 'mutation'>;

    export const DeleteActorComponent = (props: DeleteActorComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteActorMutation, DeleteActorMutationVariables> mutation={DeleteActorDocument} {...props} />
    );
    
export type DeleteActorProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteActorMutation, DeleteActorMutationVariables>
    } & TChildProps;
export function withDeleteActor<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteActorMutation,
  DeleteActorMutationVariables,
  DeleteActorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteActorMutation, DeleteActorMutationVariables, DeleteActorProps<TChildProps, TDataName>>(DeleteActorDocument, {
      alias: 'deleteActor',
      ...operationOptions
    });
};
export type DeleteActorMutationResult = ApolloReactCommon.MutationResult<DeleteActorMutation>;
export type DeleteActorMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteActorMutation, DeleteActorMutationVariables>;
export const SubsetPoliciesDocument = gql`
    query SubsetPolicies($policies: [String!]!, $primary: Boolean!) {
  subsetPolicies(policies: $policies, primary: $primary) {
    name
  }
}
    `;
export type SubsetPoliciesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubsetPoliciesQuery, SubsetPoliciesQueryVariables>, 'query'> & ({ variables: SubsetPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubsetPoliciesComponent = (props: SubsetPoliciesComponentProps) => (
      <ApolloReactComponents.Query<SubsetPoliciesQuery, SubsetPoliciesQueryVariables> query={SubsetPoliciesDocument} {...props} />
    );
    
export type SubsetPoliciesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubsetPoliciesQuery, SubsetPoliciesQueryVariables>
    } & TChildProps;
export function withSubsetPolicies<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubsetPoliciesQuery,
  SubsetPoliciesQueryVariables,
  SubsetPoliciesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubsetPoliciesQuery, SubsetPoliciesQueryVariables, SubsetPoliciesProps<TChildProps, TDataName>>(SubsetPoliciesDocument, {
      alias: 'subsetPolicies',
      ...operationOptions
    });
};
export type SubsetPoliciesQueryResult = ApolloReactCommon.QueryResult<SubsetPoliciesQuery, SubsetPoliciesQueryVariables>;
export const ListMenuFragmentsDocument = gql`
    query ListMenuFragments {
  menuFragments {
    nodes {
      id
      name
    }
  }
}
    `;
export type ListMenuFragmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListMenuFragmentsQuery, ListMenuFragmentsQueryVariables>, 'query'>;

    export const ListMenuFragmentsComponent = (props: ListMenuFragmentsComponentProps) => (
      <ApolloReactComponents.Query<ListMenuFragmentsQuery, ListMenuFragmentsQueryVariables> query={ListMenuFragmentsDocument} {...props} />
    );
    
export type ListMenuFragmentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ListMenuFragmentsQuery, ListMenuFragmentsQueryVariables>
    } & TChildProps;
export function withListMenuFragments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListMenuFragmentsQuery,
  ListMenuFragmentsQueryVariables,
  ListMenuFragmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ListMenuFragmentsQuery, ListMenuFragmentsQueryVariables, ListMenuFragmentsProps<TChildProps, TDataName>>(ListMenuFragmentsDocument, {
      alias: 'listMenuFragments',
      ...operationOptions
    });
};
export type ListMenuFragmentsQueryResult = ApolloReactCommon.QueryResult<ListMenuFragmentsQuery, ListMenuFragmentsQueryVariables>;
export const UpdateItemDefinitionPriceDocument = gql`
    mutation UpdateItemDefinitionPrice($fragmentId: ID!, $itemDefinitionId: ID!, $newPrice: Int!) {
  updateItemDefinitionPrice(fragmentId: $fragmentId, itemDefinitionId: $itemDefinitionId, newPrice: $newPrice)
}
    `;
export type UpdateItemDefinitionPriceMutationFn = ApolloReactCommon.MutationFunction<UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables>;
export type UpdateItemDefinitionPriceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables>, 'mutation'>;

    export const UpdateItemDefinitionPriceComponent = (props: UpdateItemDefinitionPriceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables> mutation={UpdateItemDefinitionPriceDocument} {...props} />
    );
    
export type UpdateItemDefinitionPriceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables>
    } & TChildProps;
export function withUpdateItemDefinitionPrice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateItemDefinitionPriceMutation,
  UpdateItemDefinitionPriceMutationVariables,
  UpdateItemDefinitionPriceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables, UpdateItemDefinitionPriceProps<TChildProps, TDataName>>(UpdateItemDefinitionPriceDocument, {
      alias: 'updateItemDefinitionPrice',
      ...operationOptions
    });
};
export type UpdateItemDefinitionPriceMutationResult = ApolloReactCommon.MutationResult<UpdateItemDefinitionPriceMutation>;
export type UpdateItemDefinitionPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemDefinitionPriceMutation, UpdateItemDefinitionPriceMutationVariables>;
export const CreateMenuFragmentDocument = gql`
    mutation CreateMenuFragment($name: String!, $resource: InputResource!) {
  createMenuFragment(name: $name, resource: $resource) {
    id
    name
  }
}
    `;
export type CreateMenuFragmentMutationFn = ApolloReactCommon.MutationFunction<CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables>;
export type CreateMenuFragmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables>, 'mutation'>;

    export const CreateMenuFragmentComponent = (props: CreateMenuFragmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables> mutation={CreateMenuFragmentDocument} {...props} />
    );
    
export type CreateMenuFragmentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables>
    } & TChildProps;
export function withCreateMenuFragment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateMenuFragmentMutation,
  CreateMenuFragmentMutationVariables,
  CreateMenuFragmentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables, CreateMenuFragmentProps<TChildProps, TDataName>>(CreateMenuFragmentDocument, {
      alias: 'createMenuFragment',
      ...operationOptions
    });
};
export type CreateMenuFragmentMutationResult = ApolloReactCommon.MutationResult<CreateMenuFragmentMutation>;
export type CreateMenuFragmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMenuFragmentMutation, CreateMenuFragmentMutationVariables>;
export const CreateItemDefinitionDocument = gql`
    mutation CreateItemDefinition($input: CreateItemDefinitionInput!) {
  createItemDefinition(input: $input) {
    id
    plu
    longName
    shortName
  }
}
    `;
export type CreateItemDefinitionMutationFn = ApolloReactCommon.MutationFunction<CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables>;
export type CreateItemDefinitionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables>, 'mutation'>;

    export const CreateItemDefinitionComponent = (props: CreateItemDefinitionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables> mutation={CreateItemDefinitionDocument} {...props} />
    );
    
export type CreateItemDefinitionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables>
    } & TChildProps;
export function withCreateItemDefinition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateItemDefinitionMutation,
  CreateItemDefinitionMutationVariables,
  CreateItemDefinitionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables, CreateItemDefinitionProps<TChildProps, TDataName>>(CreateItemDefinitionDocument, {
      alias: 'createItemDefinition',
      ...operationOptions
    });
};
export type CreateItemDefinitionMutationResult = ApolloReactCommon.MutationResult<CreateItemDefinitionMutation>;
export type CreateItemDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateItemDefinitionMutation, CreateItemDefinitionMutationVariables>;
export const UpdateItemDefinitionDocument = gql`
    mutation UpdateItemDefinition($input: UpdateItemDefinitionInput!) {
  updateItemDefinition(input: $input) {
    id
    plu
    longName
    shortName
    price
    tags
  }
}
    `;
export type UpdateItemDefinitionMutationFn = ApolloReactCommon.MutationFunction<UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables>;
export type UpdateItemDefinitionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables>, 'mutation'>;

    export const UpdateItemDefinitionComponent = (props: UpdateItemDefinitionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables> mutation={UpdateItemDefinitionDocument} {...props} />
    );
    
export type UpdateItemDefinitionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables>
    } & TChildProps;
export function withUpdateItemDefinition<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateItemDefinitionMutation,
  UpdateItemDefinitionMutationVariables,
  UpdateItemDefinitionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables, UpdateItemDefinitionProps<TChildProps, TDataName>>(UpdateItemDefinitionDocument, {
      alias: 'updateItemDefinition',
      ...operationOptions
    });
};
export type UpdateItemDefinitionMutationResult = ApolloReactCommon.MutationResult<UpdateItemDefinitionMutation>;
export type UpdateItemDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemDefinitionMutation, UpdateItemDefinitionMutationVariables>;
export const MenuFragmentDocument = gql`
    query MenuFragment($id: ID!) {
  menuFragment(id: $id) {
    id
    name
    linkedFragments {
      linkedFragmentId
      linkedFragmentName
    }
    itemDefinitionCount
    pricingUpdateCount
    slotCount
    discountCount
    dynamicPriceCount
    categoryCount
  }
}
    `;
export type MenuFragmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuFragmentQuery, MenuFragmentQueryVariables>, 'query'> & ({ variables: MenuFragmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuFragmentComponent = (props: MenuFragmentComponentProps) => (
      <ApolloReactComponents.Query<MenuFragmentQuery, MenuFragmentQueryVariables> query={MenuFragmentDocument} {...props} />
    );
    
export type MenuFragmentProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MenuFragmentQuery, MenuFragmentQueryVariables>
    } & TChildProps;
export function withMenuFragment<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MenuFragmentQuery,
  MenuFragmentQueryVariables,
  MenuFragmentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MenuFragmentQuery, MenuFragmentQueryVariables, MenuFragmentProps<TChildProps, TDataName>>(MenuFragmentDocument, {
      alias: 'menuFragment',
      ...operationOptions
    });
};
export type MenuFragmentQueryResult = ApolloReactCommon.QueryResult<MenuFragmentQuery, MenuFragmentQueryVariables>;
export const MenuFragmentItemDefinitionsDocument = gql`
    query MenuFragmentItemDefinitions($id: ID!) {
  menuFragment(id: $id) {
    id
    name
    itemDefinitions {
      internal {
        id
        plu
        longName
      }
      external {
        id
        plu
        longName
      }
    }
  }
}
    `;
export type MenuFragmentItemDefinitionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuFragmentItemDefinitionsQuery, MenuFragmentItemDefinitionsQueryVariables>, 'query'> & ({ variables: MenuFragmentItemDefinitionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuFragmentItemDefinitionsComponent = (props: MenuFragmentItemDefinitionsComponentProps) => (
      <ApolloReactComponents.Query<MenuFragmentItemDefinitionsQuery, MenuFragmentItemDefinitionsQueryVariables> query={MenuFragmentItemDefinitionsDocument} {...props} />
    );
    
export type MenuFragmentItemDefinitionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MenuFragmentItemDefinitionsQuery, MenuFragmentItemDefinitionsQueryVariables>
    } & TChildProps;
export function withMenuFragmentItemDefinitions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MenuFragmentItemDefinitionsQuery,
  MenuFragmentItemDefinitionsQueryVariables,
  MenuFragmentItemDefinitionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MenuFragmentItemDefinitionsQuery, MenuFragmentItemDefinitionsQueryVariables, MenuFragmentItemDefinitionsProps<TChildProps, TDataName>>(MenuFragmentItemDefinitionsDocument, {
      alias: 'menuFragmentItemDefinitions',
      ...operationOptions
    });
};
export type MenuFragmentItemDefinitionsQueryResult = ApolloReactCommon.QueryResult<MenuFragmentItemDefinitionsQuery, MenuFragmentItemDefinitionsQueryVariables>;
export const MenuFragmentPriceUpdatesDocument = gql`
    query MenuFragmentPriceUpdates($id: ID!) {
  menuFragment(id: $id) {
    id
    name
    priceUpdates {
      id
      fragmentId
      name
      plu
      price
    }
  }
}
    `;
export type MenuFragmentPriceUpdatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MenuFragmentPriceUpdatesQuery, MenuFragmentPriceUpdatesQueryVariables>, 'query'> & ({ variables: MenuFragmentPriceUpdatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MenuFragmentPriceUpdatesComponent = (props: MenuFragmentPriceUpdatesComponentProps) => (
      <ApolloReactComponents.Query<MenuFragmentPriceUpdatesQuery, MenuFragmentPriceUpdatesQueryVariables> query={MenuFragmentPriceUpdatesDocument} {...props} />
    );
    
export type MenuFragmentPriceUpdatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MenuFragmentPriceUpdatesQuery, MenuFragmentPriceUpdatesQueryVariables>
    } & TChildProps;
export function withMenuFragmentPriceUpdates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MenuFragmentPriceUpdatesQuery,
  MenuFragmentPriceUpdatesQueryVariables,
  MenuFragmentPriceUpdatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MenuFragmentPriceUpdatesQuery, MenuFragmentPriceUpdatesQueryVariables, MenuFragmentPriceUpdatesProps<TChildProps, TDataName>>(MenuFragmentPriceUpdatesDocument, {
      alias: 'menuFragmentPriceUpdates',
      ...operationOptions
    });
};
export type MenuFragmentPriceUpdatesQueryResult = ApolloReactCommon.QueryResult<MenuFragmentPriceUpdatesQuery, MenuFragmentPriceUpdatesQueryVariables>;
export const ItemDefinitionDocument = gql`
    query ItemDefinition($fragmentId: ID!, $itemDefinitionId: ID!) {
  menuFragment(id: $fragmentId) {
    id
    name
    itemDefinition(itemDefinitionId: $itemDefinitionId) {
      id
      plu
      longName
      shortName
      price
      tags
    }
  }
}
    `;
export type ItemDefinitionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ItemDefinitionQuery, ItemDefinitionQueryVariables>, 'query'> & ({ variables: ItemDefinitionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ItemDefinitionComponent = (props: ItemDefinitionComponentProps) => (
      <ApolloReactComponents.Query<ItemDefinitionQuery, ItemDefinitionQueryVariables> query={ItemDefinitionDocument} {...props} />
    );
    
export type ItemDefinitionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ItemDefinitionQuery, ItemDefinitionQueryVariables>
    } & TChildProps;
export function withItemDefinition<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ItemDefinitionQuery,
  ItemDefinitionQueryVariables,
  ItemDefinitionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ItemDefinitionQuery, ItemDefinitionQueryVariables, ItemDefinitionProps<TChildProps, TDataName>>(ItemDefinitionDocument, {
      alias: 'itemDefinition',
      ...operationOptions
    });
};
export type ItemDefinitionQueryResult = ApolloReactCommon.QueryResult<ItemDefinitionQuery, ItemDefinitionQueryVariables>;
export const ConceptDocument = gql`
    query Concept($id: String!) {
  concept(id: $id) {
    id
    name
    updatedAt
    createdAt
  }
}
    `;
export type ConceptComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConceptQuery, ConceptQueryVariables>, 'query'> & ({ variables: ConceptQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConceptComponent = (props: ConceptComponentProps) => (
      <ApolloReactComponents.Query<ConceptQuery, ConceptQueryVariables> query={ConceptDocument} {...props} />
    );
    
export type ConceptProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ConceptQuery, ConceptQueryVariables>
    } & TChildProps;
export function withConcept<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConceptQuery,
  ConceptQueryVariables,
  ConceptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ConceptQuery, ConceptQueryVariables, ConceptProps<TChildProps, TDataName>>(ConceptDocument, {
      alias: 'concept',
      ...operationOptions
    });
};
export type ConceptQueryResult = ApolloReactCommon.QueryResult<ConceptQuery, ConceptQueryVariables>;
export const ConceptGroupsDocument = gql`
    query ConceptGroups($id: String!) {
  concept(id: $id) {
    id
    name
    groups {
      id
      name
    }
  }
}
    `;
export type ConceptGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConceptGroupsQuery, ConceptGroupsQueryVariables>, 'query'> & ({ variables: ConceptGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConceptGroupsComponent = (props: ConceptGroupsComponentProps) => (
      <ApolloReactComponents.Query<ConceptGroupsQuery, ConceptGroupsQueryVariables> query={ConceptGroupsDocument} {...props} />
    );
    
export type ConceptGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ConceptGroupsQuery, ConceptGroupsQueryVariables>
    } & TChildProps;
export function withConceptGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConceptGroupsQuery,
  ConceptGroupsQueryVariables,
  ConceptGroupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ConceptGroupsQuery, ConceptGroupsQueryVariables, ConceptGroupsProps<TChildProps, TDataName>>(ConceptGroupsDocument, {
      alias: 'conceptGroups',
      ...operationOptions
    });
};
export type ConceptGroupsQueryResult = ApolloReactCommon.QueryResult<ConceptGroupsQuery, ConceptGroupsQueryVariables>;
export const ConceptsDocument = gql`
    query Concepts($cursor: String, $action: String, $sort: [Sort!]) {
  concepts(cursor: $cursor, action: $action, sort: $sort) {
    cursor
    nodes {
      id
      name
    }
  }
}
    `;
export type ConceptsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConceptsQuery, ConceptsQueryVariables>, 'query'>;

    export const ConceptsComponent = (props: ConceptsComponentProps) => (
      <ApolloReactComponents.Query<ConceptsQuery, ConceptsQueryVariables> query={ConceptsDocument} {...props} />
    );
    
export type ConceptsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ConceptsQuery, ConceptsQueryVariables>
    } & TChildProps;
export function withConcepts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConceptsQuery,
  ConceptsQueryVariables,
  ConceptsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ConceptsQuery, ConceptsQueryVariables, ConceptsProps<TChildProps, TDataName>>(ConceptsDocument, {
      alias: 'concepts',
      ...operationOptions
    });
};
export type ConceptsQueryResult = ApolloReactCommon.QueryResult<ConceptsQuery, ConceptsQueryVariables>;
export const CreateConceptDocument = gql`
    mutation CreateConcept($name: String!) {
  createConcept(name: $name) {
    id
    name
  }
}
    `;
export type CreateConceptMutationFn = ApolloReactCommon.MutationFunction<CreateConceptMutation, CreateConceptMutationVariables>;
export type CreateConceptComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateConceptMutation, CreateConceptMutationVariables>, 'mutation'>;

    export const CreateConceptComponent = (props: CreateConceptComponentProps) => (
      <ApolloReactComponents.Mutation<CreateConceptMutation, CreateConceptMutationVariables> mutation={CreateConceptDocument} {...props} />
    );
    
export type CreateConceptProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateConceptMutation, CreateConceptMutationVariables>
    } & TChildProps;
export function withCreateConcept<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateConceptMutation,
  CreateConceptMutationVariables,
  CreateConceptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateConceptMutation, CreateConceptMutationVariables, CreateConceptProps<TChildProps, TDataName>>(CreateConceptDocument, {
      alias: 'createConcept',
      ...operationOptions
    });
};
export type CreateConceptMutationResult = ApolloReactCommon.MutationResult<CreateConceptMutation>;
export type CreateConceptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConceptMutation, CreateConceptMutationVariables>;
export const CreateLegacyInventoryLocationDocument = gql`
    mutation CreateLegacyInventoryLocation($unitId: String!, $name: String!) {
  createLegacyInventoryLocation(unitId: $unitId, name: $name) {
    id
    name
    state
  }
}
    `;
export type CreateLegacyInventoryLocationMutationFn = ApolloReactCommon.MutationFunction<CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables>;
export type CreateLegacyInventoryLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables>, 'mutation'>;

    export const CreateLegacyInventoryLocationComponent = (props: CreateLegacyInventoryLocationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables> mutation={CreateLegacyInventoryLocationDocument} {...props} />
    );
    
export type CreateLegacyInventoryLocationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables>
    } & TChildProps;
export function withCreateLegacyInventoryLocation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateLegacyInventoryLocationMutation,
  CreateLegacyInventoryLocationMutationVariables,
  CreateLegacyInventoryLocationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables, CreateLegacyInventoryLocationProps<TChildProps, TDataName>>(CreateLegacyInventoryLocationDocument, {
      alias: 'createLegacyInventoryLocation',
      ...operationOptions
    });
};
export type CreateLegacyInventoryLocationMutationResult = ApolloReactCommon.MutationResult<CreateLegacyInventoryLocationMutation>;
export type CreateLegacyInventoryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLegacyInventoryLocationMutation, CreateLegacyInventoryLocationMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($name: String!, $phone: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $zip: String!, $country: String!) {
  createOrganization(name: $name, phone: $phone, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, country: $country) {
    id
    phone
    street1
    street2
    city
    state
    zip
    country
  }
}
    `;
export type CreateOrganizationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export type CreateOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>, 'mutation'>;

    export const CreateOrganizationComponent = (props: CreateOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables> mutation={CreateOrganizationDocument} {...props} />
    );
    
export type CreateOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>
    } & TChildProps;
export function withCreateOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  CreateOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOrganizationMutation, CreateOrganizationMutationVariables, CreateOrganizationProps<TChildProps, TDataName>>(CreateOrganizationDocument, {
      alias: 'createOrganization',
      ...operationOptions
    });
};
export type CreateOrganizationMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreateUnitDocument = gql`
    mutation CreateUnit($name: String!, $identifier: String!, $phone: String!, $email: String, $street1: String!, $street2: String!, $city: String!, $state: String!, $zip: String!, $country: String!, $organizationId: String!, $conceptIds: [String!]!) {
  createUnit(name: $name, identifier: $identifier, phone: $phone, email: $email, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, country: $country, organizationId: $organizationId, conceptIds: $conceptIds) {
    id
    name
    identifier
    phone
    email
    street1
    street2
    city
    state
    zip
    country
    updatedAt
    createdAt
    organization {
      name
    }
    concepts {
      name
    }
  }
}
    `;
export type CreateUnitMutationFn = ApolloReactCommon.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;
export type CreateUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUnitMutation, CreateUnitMutationVariables>, 'mutation'>;

    export const CreateUnitComponent = (props: CreateUnitComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUnitMutation, CreateUnitMutationVariables> mutation={CreateUnitDocument} {...props} />
    );
    
export type CreateUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>
    } & TChildProps;
export function withCreateUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUnitMutation,
  CreateUnitMutationVariables,
  CreateUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUnitMutation, CreateUnitMutationVariables, CreateUnitProps<TChildProps, TDataName>>(CreateUnitDocument, {
      alias: 'createUnit',
      ...operationOptions
    });
};
export type CreateUnitMutationResult = ApolloReactCommon.MutationResult<CreateUnitMutation>;
export type CreateUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUnitMutation, CreateUnitMutationVariables>;
export const DisableUnitDocument = gql`
    mutation DisableUnit($id: String!) {
  disableUnit(id: $id)
}
    `;
export type DisableUnitMutationFn = ApolloReactCommon.MutationFunction<DisableUnitMutation, DisableUnitMutationVariables>;
export type DisableUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableUnitMutation, DisableUnitMutationVariables>, 'mutation'>;

    export const DisableUnitComponent = (props: DisableUnitComponentProps) => (
      <ApolloReactComponents.Mutation<DisableUnitMutation, DisableUnitMutationVariables> mutation={DisableUnitDocument} {...props} />
    );
    
export type DisableUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DisableUnitMutation, DisableUnitMutationVariables>
    } & TChildProps;
export function withDisableUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisableUnitMutation,
  DisableUnitMutationVariables,
  DisableUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DisableUnitMutation, DisableUnitMutationVariables, DisableUnitProps<TChildProps, TDataName>>(DisableUnitDocument, {
      alias: 'disableUnit',
      ...operationOptions
    });
};
export type DisableUnitMutationResult = ApolloReactCommon.MutationResult<DisableUnitMutation>;
export type DisableUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableUnitMutation, DisableUnitMutationVariables>;
export const EmployeesDocument = gql`
    query Employees($cursor: String, $disabled: Boolean, $sort: [Sort!]) {
  employees(cursor: $cursor, disabled: $disabled, sort: $sort) {
    cursor
    nodes {
      id
      firstName
      lastName
      email
      phone
      deletedAt
      status
    }
  }
}
    `;
export type EmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeesQuery, EmployeesQueryVariables>, 'query'>;

    export const EmployeesComponent = (props: EmployeesComponentProps) => (
      <ApolloReactComponents.Query<EmployeesQuery, EmployeesQueryVariables> query={EmployeesDocument} {...props} />
    );
    
export type EmployeesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EmployeesQuery, EmployeesQueryVariables>
    } & TChildProps;
export function withEmployees<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeesQuery,
  EmployeesQueryVariables,
  EmployeesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeesQuery, EmployeesQueryVariables, EmployeesProps<TChildProps, TDataName>>(EmployeesDocument, {
      alias: 'employees',
      ...operationOptions
    });
};
export type EmployeesQueryResult = ApolloReactCommon.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const GrantableEmployeesDocument = gql`
    query GrantableEmployees($cursor: String, $disabled: Boolean, $sort: [Sort!]) {
  employees(cursor: $cursor, disabled: $disabled, sort: $sort) {
    cursor
    nodes {
      id
      firstName
      lastName
      appAccessEnabled
    }
  }
}
    `;
export type GrantableEmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GrantableEmployeesQuery, GrantableEmployeesQueryVariables>, 'query'>;

    export const GrantableEmployeesComponent = (props: GrantableEmployeesComponentProps) => (
      <ApolloReactComponents.Query<GrantableEmployeesQuery, GrantableEmployeesQueryVariables> query={GrantableEmployeesDocument} {...props} />
    );
    
export type GrantableEmployeesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GrantableEmployeesQuery, GrantableEmployeesQueryVariables>
    } & TChildProps;
export function withGrantableEmployees<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GrantableEmployeesQuery,
  GrantableEmployeesQueryVariables,
  GrantableEmployeesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GrantableEmployeesQuery, GrantableEmployeesQueryVariables, GrantableEmployeesProps<TChildProps, TDataName>>(GrantableEmployeesDocument, {
      alias: 'grantableEmployees',
      ...operationOptions
    });
};
export type GrantableEmployeesQueryResult = ApolloReactCommon.QueryResult<GrantableEmployeesQuery, GrantableEmployeesQueryVariables>;
export const EmployeeDocument = gql`
    query Employee($id: String!) {
  employee(id: $id) {
    id
    firstName
    lastName
    email
    phone
    deletedAt
    updatedAt
    createdAt
    status
  }
}
    `;
export type EmployeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeQuery, EmployeeQueryVariables>, 'query'> & ({ variables: EmployeeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeComponent = (props: EmployeeComponentProps) => (
      <ApolloReactComponents.Query<EmployeeQuery, EmployeeQueryVariables> query={EmployeeDocument} {...props} />
    );
    
export type EmployeeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EmployeeQuery, EmployeeQueryVariables>
    } & TChildProps;
export function withEmployee<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeQuery,
  EmployeeQueryVariables,
  EmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeQuery, EmployeeQueryVariables, EmployeeProps<TChildProps, TDataName>>(EmployeeDocument, {
      alias: 'employee',
      ...operationOptions
    });
};
export type EmployeeQueryResult = ApolloReactCommon.QueryResult<EmployeeQuery, EmployeeQueryVariables>;
export const ViewEmployeeDocument = gql`
    query ViewEmployee($id: String!) {
  employee(id: $id) {
    id
    firstName
    lastName
    email
    phone
    deletedAt
    updatedAt
    createdAt
    status
    carbonIdentifier
    carbonPin
    carbonAccessToken
  }
}
    `;
export type ViewEmployeeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewEmployeeQuery, ViewEmployeeQueryVariables>, 'query'> & ({ variables: ViewEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewEmployeeComponent = (props: ViewEmployeeComponentProps) => (
      <ApolloReactComponents.Query<ViewEmployeeQuery, ViewEmployeeQueryVariables> query={ViewEmployeeDocument} {...props} />
    );
    
export type ViewEmployeeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewEmployeeQuery, ViewEmployeeQueryVariables>
    } & TChildProps;
export function withViewEmployee<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewEmployeeQuery,
  ViewEmployeeQueryVariables,
  ViewEmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewEmployeeQuery, ViewEmployeeQueryVariables, ViewEmployeeProps<TChildProps, TDataName>>(ViewEmployeeDocument, {
      alias: 'viewEmployee',
      ...operationOptions
    });
};
export type ViewEmployeeQueryResult = ApolloReactCommon.QueryResult<ViewEmployeeQuery, ViewEmployeeQueryVariables>;
export const EmployeeLoginPermissionsDocument = gql`
    query EmployeeLoginPermissions($id: String!) {
  employee(id: $id) {
    id
    firstName
    lastName
    status
    loginActor {
      id
      name
      grants {
        actions
        policies
        target {
          id
          type
          name
          group {
            name
            type
          }
        }
      }
    }
  }
}
    `;
export type EmployeeLoginPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeLoginPermissionsQuery, EmployeeLoginPermissionsQueryVariables>, 'query'> & ({ variables: EmployeeLoginPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeLoginPermissionsComponent = (props: EmployeeLoginPermissionsComponentProps) => (
      <ApolloReactComponents.Query<EmployeeLoginPermissionsQuery, EmployeeLoginPermissionsQueryVariables> query={EmployeeLoginPermissionsDocument} {...props} />
    );
    
export type EmployeeLoginPermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EmployeeLoginPermissionsQuery, EmployeeLoginPermissionsQueryVariables>
    } & TChildProps;
export function withEmployeeLoginPermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeLoginPermissionsQuery,
  EmployeeLoginPermissionsQueryVariables,
  EmployeeLoginPermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeLoginPermissionsQuery, EmployeeLoginPermissionsQueryVariables, EmployeeLoginPermissionsProps<TChildProps, TDataName>>(EmployeeLoginPermissionsDocument, {
      alias: 'employeeLoginPermissions',
      ...operationOptions
    });
};
export type EmployeeLoginPermissionsQueryResult = ApolloReactCommon.QueryResult<EmployeeLoginPermissionsQuery, EmployeeLoginPermissionsQueryVariables>;
export const EmployeeRelationshipsDocument = gql`
    query EmployeeRelationships($id: String!) {
  employee(id: $id) {
    id
    relationships {
      parents {
        id
        type
        name
      }
      children {
        id
        type
        name
      }
    }
  }
}
    `;
export type EmployeeRelationshipsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmployeeRelationshipsQuery, EmployeeRelationshipsQueryVariables>, 'query'> & ({ variables: EmployeeRelationshipsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmployeeRelationshipsComponent = (props: EmployeeRelationshipsComponentProps) => (
      <ApolloReactComponents.Query<EmployeeRelationshipsQuery, EmployeeRelationshipsQueryVariables> query={EmployeeRelationshipsDocument} {...props} />
    );
    
export type EmployeeRelationshipsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EmployeeRelationshipsQuery, EmployeeRelationshipsQueryVariables>
    } & TChildProps;
export function withEmployeeRelationships<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EmployeeRelationshipsQuery,
  EmployeeRelationshipsQueryVariables,
  EmployeeRelationshipsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EmployeeRelationshipsQuery, EmployeeRelationshipsQueryVariables, EmployeeRelationshipsProps<TChildProps, TDataName>>(EmployeeRelationshipsDocument, {
      alias: 'employeeRelationships',
      ...operationOptions
    });
};
export type EmployeeRelationshipsQueryResult = ApolloReactCommon.QueryResult<EmployeeRelationshipsQuery, EmployeeRelationshipsQueryVariables>;
export const DisableEmployeeDocument = gql`
    mutation DisableEmployee($id: String!) {
  disableEmployee(id: $id)
}
    `;
export type DisableEmployeeMutationFn = ApolloReactCommon.MutationFunction<DisableEmployeeMutation, DisableEmployeeMutationVariables>;
export type DisableEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableEmployeeMutation, DisableEmployeeMutationVariables>, 'mutation'>;

    export const DisableEmployeeComponent = (props: DisableEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<DisableEmployeeMutation, DisableEmployeeMutationVariables> mutation={DisableEmployeeDocument} {...props} />
    );
    
export type DisableEmployeeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DisableEmployeeMutation, DisableEmployeeMutationVariables>
    } & TChildProps;
export function withDisableEmployee<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisableEmployeeMutation,
  DisableEmployeeMutationVariables,
  DisableEmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DisableEmployeeMutation, DisableEmployeeMutationVariables, DisableEmployeeProps<TChildProps, TDataName>>(DisableEmployeeDocument, {
      alias: 'disableEmployee',
      ...operationOptions
    });
};
export type DisableEmployeeMutationResult = ApolloReactCommon.MutationResult<DisableEmployeeMutation>;
export type DisableEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableEmployeeMutation, DisableEmployeeMutationVariables>;
export const EnableEmployeeDocument = gql`
    mutation EnableEmployee($id: String!) {
  enableEmployee(id: $id)
}
    `;
export type EnableEmployeeMutationFn = ApolloReactCommon.MutationFunction<EnableEmployeeMutation, EnableEmployeeMutationVariables>;
export type EnableEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableEmployeeMutation, EnableEmployeeMutationVariables>, 'mutation'>;

    export const EnableEmployeeComponent = (props: EnableEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<EnableEmployeeMutation, EnableEmployeeMutationVariables> mutation={EnableEmployeeDocument} {...props} />
    );
    
export type EnableEmployeeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EnableEmployeeMutation, EnableEmployeeMutationVariables>
    } & TChildProps;
export function withEnableEmployee<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnableEmployeeMutation,
  EnableEmployeeMutationVariables,
  EnableEmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EnableEmployeeMutation, EnableEmployeeMutationVariables, EnableEmployeeProps<TChildProps, TDataName>>(EnableEmployeeDocument, {
      alias: 'enableEmployee',
      ...operationOptions
    });
};
export type EnableEmployeeMutationResult = ApolloReactCommon.MutationResult<EnableEmployeeMutation>;
export type EnableEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableEmployeeMutation, EnableEmployeeMutationVariables>;
export const ResendInviteDocument = gql`
    mutation resendInvite($id: String!) {
  resendInvite(id: $id)
}
    `;
export type ResendInviteMutationFn = ApolloReactCommon.MutationFunction<ResendInviteMutation, ResendInviteMutationVariables>;
export type ResendInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendInviteMutation, ResendInviteMutationVariables>, 'mutation'>;

    export const ResendInviteComponent = (props: ResendInviteComponentProps) => (
      <ApolloReactComponents.Mutation<ResendInviteMutation, ResendInviteMutationVariables> mutation={ResendInviteDocument} {...props} />
    );
    
export type ResendInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ResendInviteMutation, ResendInviteMutationVariables>
    } & TChildProps;
export function withResendInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResendInviteMutation,
  ResendInviteMutationVariables,
  ResendInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResendInviteMutation, ResendInviteMutationVariables, ResendInviteProps<TChildProps, TDataName>>(ResendInviteDocument, {
      alias: 'resendInvite',
      ...operationOptions
    });
};
export type ResendInviteMutationResult = ApolloReactCommon.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInviteMutation, ResendInviteMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $parentType: ResourceType!, $parentId: String!, $sendInvite: Boolean) {
  createEmployee(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, parentType: $parentType, parentId: $parentId, sendInvite: $sendInvite) {
    id
    loginActor {
      id
    }
  }
}
    `;
export type CreateEmployeeMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export type CreateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>, 'mutation'>;

    export const CreateEmployeeComponent = (props: CreateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEmployeeMutation, CreateEmployeeMutationVariables> mutation={CreateEmployeeDocument} {...props} />
    );
    
export type CreateEmployeeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>
    } & TChildProps;
export function withCreateEmployee<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables,
  CreateEmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEmployeeMutation, CreateEmployeeMutationVariables, CreateEmployeeProps<TChildProps, TDataName>>(CreateEmployeeDocument, {
      alias: 'createEmployee',
      ...operationOptions
    });
};
export type CreateEmployeeMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($id: String!, $firstName: String!, $lastName: String!, $email: String!, $phone: String!, $carbonIdentifier: String!, $carbonPin: String!, $carbonAccessToken: String!) {
  updateEmployee(id: $id, firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, carbonIdentifier: $carbonIdentifier, carbonPin: $carbonPin, carbonAccessToken: $carbonAccessToken) {
    id
  }
}
    `;
export type UpdateEmployeeMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export type UpdateEmployeeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>, 'mutation'>;

    export const UpdateEmployeeComponent = (props: UpdateEmployeeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables> mutation={UpdateEmployeeDocument} {...props} />
    );
    
export type UpdateEmployeeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>
    } & TChildProps;
export function withUpdateEmployee<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEmployeeMutation,
  UpdateEmployeeMutationVariables,
  UpdateEmployeeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEmployeeMutation, UpdateEmployeeMutationVariables, UpdateEmployeeProps<TChildProps, TDataName>>(UpdateEmployeeDocument, {
      alias: 'updateEmployee',
      ...operationOptions
    });
};
export type UpdateEmployeeMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const ChangePinDocument = gql`
    mutation ChangePin($employeeId: String!, $pin: String!) {
  changePin(employeeId: $employeeId, pin: $pin)
}
    `;
export type ChangePinMutationFn = ApolloReactCommon.MutationFunction<ChangePinMutation, ChangePinMutationVariables>;
export type ChangePinComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePinMutation, ChangePinMutationVariables>, 'mutation'>;

    export const ChangePinComponent = (props: ChangePinComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePinMutation, ChangePinMutationVariables> mutation={ChangePinDocument} {...props} />
    );
    
export type ChangePinProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ChangePinMutation, ChangePinMutationVariables>
    } & TChildProps;
export function withChangePin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ChangePinMutation,
  ChangePinMutationVariables,
  ChangePinProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ChangePinMutation, ChangePinMutationVariables, ChangePinProps<TChildProps, TDataName>>(ChangePinDocument, {
      alias: 'changePin',
      ...operationOptions
    });
};
export type ChangePinMutationResult = ApolloReactCommon.MutationResult<ChangePinMutation>;
export type ChangePinMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePinMutation, ChangePinMutationVariables>;
export const AcceptInviteDocument = gql`
    mutation AcceptInvite($firstName: String!, $lastName: String!, $phoneNumber: String!, $pin: String!) {
  acceptInvite(lastName: $lastName, firstName: $firstName, phoneNumber: $phoneNumber, pin: $pin) {
    id
  }
}
    `;
export type AcceptInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;
export type AcceptInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AcceptInviteMutation, AcceptInviteMutationVariables>, 'mutation'>;

    export const AcceptInviteComponent = (props: AcceptInviteComponentProps) => (
      <ApolloReactComponents.Mutation<AcceptInviteMutation, AcceptInviteMutationVariables> mutation={AcceptInviteDocument} {...props} />
    );
    
export type AcceptInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>
    } & TChildProps;
export function withAcceptInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcceptInviteMutation,
  AcceptInviteMutationVariables,
  AcceptInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AcceptInviteMutation, AcceptInviteMutationVariables, AcceptInviteProps<TChildProps, TDataName>>(AcceptInviteDocument, {
      alias: 'acceptInvite',
      ...operationOptions
    });
};
export type AcceptInviteMutationResult = ApolloReactCommon.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const EnableEmployeeAccessDocument = gql`
    mutation EnableEmployeeAccess($id: String!) {
  enableEmployeeAccess(id: $id)
}
    `;
export type EnableEmployeeAccessMutationFn = ApolloReactCommon.MutationFunction<EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables>;
export type EnableEmployeeAccessComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables>, 'mutation'>;

    export const EnableEmployeeAccessComponent = (props: EnableEmployeeAccessComponentProps) => (
      <ApolloReactComponents.Mutation<EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables> mutation={EnableEmployeeAccessDocument} {...props} />
    );
    
export type EnableEmployeeAccessProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables>
    } & TChildProps;
export function withEnableEmployeeAccess<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnableEmployeeAccessMutation,
  EnableEmployeeAccessMutationVariables,
  EnableEmployeeAccessProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables, EnableEmployeeAccessProps<TChildProps, TDataName>>(EnableEmployeeAccessDocument, {
      alias: 'enableEmployeeAccess',
      ...operationOptions
    });
};
export type EnableEmployeeAccessMutationResult = ApolloReactCommon.MutationResult<EnableEmployeeAccessMutation>;
export type EnableEmployeeAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableEmployeeAccessMutation, EnableEmployeeAccessMutationVariables>;
export const DisableEmployeeAccessDocument = gql`
    mutation DisableEmployeeAccess($id: String!) {
  disableEmployeeAccess(id: $id)
}
    `;
export type DisableEmployeeAccessMutationFn = ApolloReactCommon.MutationFunction<DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables>;
export type DisableEmployeeAccessComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables>, 'mutation'>;

    export const DisableEmployeeAccessComponent = (props: DisableEmployeeAccessComponentProps) => (
      <ApolloReactComponents.Mutation<DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables> mutation={DisableEmployeeAccessDocument} {...props} />
    );
    
export type DisableEmployeeAccessProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables>
    } & TChildProps;
export function withDisableEmployeeAccess<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisableEmployeeAccessMutation,
  DisableEmployeeAccessMutationVariables,
  DisableEmployeeAccessProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables, DisableEmployeeAccessProps<TChildProps, TDataName>>(DisableEmployeeAccessDocument, {
      alias: 'disableEmployeeAccess',
      ...operationOptions
    });
};
export type DisableEmployeeAccessMutationResult = ApolloReactCommon.MutationResult<DisableEmployeeAccessMutation>;
export type DisableEmployeeAccessMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableEmployeeAccessMutation, DisableEmployeeAccessMutationVariables>;
export const AssignEmployeeToUnitDocument = gql`
    mutation AssignEmployeeToUnit($employeeId: String!, $unitId: String!) {
  assignEmployeeToUnit(employeeId: $employeeId, unitId: $unitId)
}
    `;
export type AssignEmployeeToUnitMutationFn = ApolloReactCommon.MutationFunction<AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables>;
export type AssignEmployeeToUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables>, 'mutation'>;

    export const AssignEmployeeToUnitComponent = (props: AssignEmployeeToUnitComponentProps) => (
      <ApolloReactComponents.Mutation<AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables> mutation={AssignEmployeeToUnitDocument} {...props} />
    );
    
export type AssignEmployeeToUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables>
    } & TChildProps;
export function withAssignEmployeeToUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignEmployeeToUnitMutation,
  AssignEmployeeToUnitMutationVariables,
  AssignEmployeeToUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables, AssignEmployeeToUnitProps<TChildProps, TDataName>>(AssignEmployeeToUnitDocument, {
      alias: 'assignEmployeeToUnit',
      ...operationOptions
    });
};
export type AssignEmployeeToUnitMutationResult = ApolloReactCommon.MutationResult<AssignEmployeeToUnitMutation>;
export type AssignEmployeeToUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignEmployeeToUnitMutation, AssignEmployeeToUnitMutationVariables>;
export const UnassignEmployeeFromUnitDocument = gql`
    mutation UnassignEmployeeFromUnit($employeeId: String!, $unitId: String!) {
  unassignEmployeeFromUnit(employeeId: $employeeId, unitId: $unitId)
}
    `;
export type UnassignEmployeeFromUnitMutationFn = ApolloReactCommon.MutationFunction<UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables>;
export type UnassignEmployeeFromUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables>, 'mutation'>;

    export const UnassignEmployeeFromUnitComponent = (props: UnassignEmployeeFromUnitComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables> mutation={UnassignEmployeeFromUnitDocument} {...props} />
    );
    
export type UnassignEmployeeFromUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables>
    } & TChildProps;
export function withUnassignEmployeeFromUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignEmployeeFromUnitMutation,
  UnassignEmployeeFromUnitMutationVariables,
  UnassignEmployeeFromUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables, UnassignEmployeeFromUnitProps<TChildProps, TDataName>>(UnassignEmployeeFromUnitDocument, {
      alias: 'unassignEmployeeFromUnit',
      ...operationOptions
    });
};
export type UnassignEmployeeFromUnitMutationResult = ApolloReactCommon.MutationResult<UnassignEmployeeFromUnitMutation>;
export type UnassignEmployeeFromUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignEmployeeFromUnitMutation, UnassignEmployeeFromUnitMutationVariables>;
export const AssignEmployeeToGroupDocument = gql`
    mutation AssignEmployeeToGroup($employeeId: String!, $groupId: String!) {
  assignEmployeeToGroup(employeeId: $employeeId, groupId: $groupId)
}
    `;
export type AssignEmployeeToGroupMutationFn = ApolloReactCommon.MutationFunction<AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables>;
export type AssignEmployeeToGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables>, 'mutation'>;

    export const AssignEmployeeToGroupComponent = (props: AssignEmployeeToGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables> mutation={AssignEmployeeToGroupDocument} {...props} />
    );
    
export type AssignEmployeeToGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables>
    } & TChildProps;
export function withAssignEmployeeToGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignEmployeeToGroupMutation,
  AssignEmployeeToGroupMutationVariables,
  AssignEmployeeToGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables, AssignEmployeeToGroupProps<TChildProps, TDataName>>(AssignEmployeeToGroupDocument, {
      alias: 'assignEmployeeToGroup',
      ...operationOptions
    });
};
export type AssignEmployeeToGroupMutationResult = ApolloReactCommon.MutationResult<AssignEmployeeToGroupMutation>;
export type AssignEmployeeToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignEmployeeToGroupMutation, AssignEmployeeToGroupMutationVariables>;
export const UnassignEmployeeFromGroupDocument = gql`
    mutation UnassignEmployeeFromGroup($employeeId: String!, $groupId: String!) {
  unassignEmployeeFromGroup(employeeId: $employeeId, groupId: $groupId)
}
    `;
export type UnassignEmployeeFromGroupMutationFn = ApolloReactCommon.MutationFunction<UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables>;
export type UnassignEmployeeFromGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables>, 'mutation'>;

    export const UnassignEmployeeFromGroupComponent = (props: UnassignEmployeeFromGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables> mutation={UnassignEmployeeFromGroupDocument} {...props} />
    );
    
export type UnassignEmployeeFromGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables>
    } & TChildProps;
export function withUnassignEmployeeFromGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignEmployeeFromGroupMutation,
  UnassignEmployeeFromGroupMutationVariables,
  UnassignEmployeeFromGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables, UnassignEmployeeFromGroupProps<TChildProps, TDataName>>(UnassignEmployeeFromGroupDocument, {
      alias: 'unassignEmployeeFromGroup',
      ...operationOptions
    });
};
export type UnassignEmployeeFromGroupMutationResult = ApolloReactCommon.MutationResult<UnassignEmployeeFromGroupMutation>;
export type UnassignEmployeeFromGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignEmployeeFromGroupMutation, UnassignEmployeeFromGroupMutationVariables>;
export const AssignEmployeeToOrganizationDocument = gql`
    mutation AssignEmployeeToOrganization($employeeId: String!, $organizationId: String!) {
  assignEmployeeToOrganization(employeeId: $employeeId, organizationId: $organizationId)
}
    `;
export type AssignEmployeeToOrganizationMutationFn = ApolloReactCommon.MutationFunction<AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables>;
export type AssignEmployeeToOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables>, 'mutation'>;

    export const AssignEmployeeToOrganizationComponent = (props: AssignEmployeeToOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables> mutation={AssignEmployeeToOrganizationDocument} {...props} />
    );
    
export type AssignEmployeeToOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables>
    } & TChildProps;
export function withAssignEmployeeToOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignEmployeeToOrganizationMutation,
  AssignEmployeeToOrganizationMutationVariables,
  AssignEmployeeToOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables, AssignEmployeeToOrganizationProps<TChildProps, TDataName>>(AssignEmployeeToOrganizationDocument, {
      alias: 'assignEmployeeToOrganization',
      ...operationOptions
    });
};
export type AssignEmployeeToOrganizationMutationResult = ApolloReactCommon.MutationResult<AssignEmployeeToOrganizationMutation>;
export type AssignEmployeeToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignEmployeeToOrganizationMutation, AssignEmployeeToOrganizationMutationVariables>;
export const UnassignEmployeeFromOrganizationDocument = gql`
    mutation UnassignEmployeeFromOrganization($employeeId: String!, $organizationId: String!) {
  unassignEmployeeFromOrganization(employeeId: $employeeId, organizationId: $organizationId)
}
    `;
export type UnassignEmployeeFromOrganizationMutationFn = ApolloReactCommon.MutationFunction<UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables>;
export type UnassignEmployeeFromOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables>, 'mutation'>;

    export const UnassignEmployeeFromOrganizationComponent = (props: UnassignEmployeeFromOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables> mutation={UnassignEmployeeFromOrganizationDocument} {...props} />
    );
    
export type UnassignEmployeeFromOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables>
    } & TChildProps;
export function withUnassignEmployeeFromOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignEmployeeFromOrganizationMutation,
  UnassignEmployeeFromOrganizationMutationVariables,
  UnassignEmployeeFromOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables, UnassignEmployeeFromOrganizationProps<TChildProps, TDataName>>(UnassignEmployeeFromOrganizationDocument, {
      alias: 'unassignEmployeeFromOrganization',
      ...operationOptions
    });
};
export type UnassignEmployeeFromOrganizationMutationResult = ApolloReactCommon.MutationResult<UnassignEmployeeFromOrganizationMutation>;
export type UnassignEmployeeFromOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignEmployeeFromOrganizationMutation, UnassignEmployeeFromOrganizationMutationVariables>;
export const AssignEmployeeToConceptDocument = gql`
    mutation AssignEmployeeToConcept($employeeId: String!, $conceptId: String!) {
  assignEmployeeToConcept(employeeId: $employeeId, conceptId: $conceptId)
}
    `;
export type AssignEmployeeToConceptMutationFn = ApolloReactCommon.MutationFunction<AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables>;
export type AssignEmployeeToConceptComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables>, 'mutation'>;

    export const AssignEmployeeToConceptComponent = (props: AssignEmployeeToConceptComponentProps) => (
      <ApolloReactComponents.Mutation<AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables> mutation={AssignEmployeeToConceptDocument} {...props} />
    );
    
export type AssignEmployeeToConceptProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables>
    } & TChildProps;
export function withAssignEmployeeToConcept<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignEmployeeToConceptMutation,
  AssignEmployeeToConceptMutationVariables,
  AssignEmployeeToConceptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables, AssignEmployeeToConceptProps<TChildProps, TDataName>>(AssignEmployeeToConceptDocument, {
      alias: 'assignEmployeeToConcept',
      ...operationOptions
    });
};
export type AssignEmployeeToConceptMutationResult = ApolloReactCommon.MutationResult<AssignEmployeeToConceptMutation>;
export type AssignEmployeeToConceptMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignEmployeeToConceptMutation, AssignEmployeeToConceptMutationVariables>;
export const UnassignEmployeeFromConceptDocument = gql`
    mutation UnassignEmployeeFromConcept($employeeId: String!, $conceptId: String!) {
  unassignEmployeeFromConcept(employeeId: $employeeId, conceptId: $conceptId)
}
    `;
export type UnassignEmployeeFromConceptMutationFn = ApolloReactCommon.MutationFunction<UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables>;
export type UnassignEmployeeFromConceptComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables>, 'mutation'>;

    export const UnassignEmployeeFromConceptComponent = (props: UnassignEmployeeFromConceptComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables> mutation={UnassignEmployeeFromConceptDocument} {...props} />
    );
    
export type UnassignEmployeeFromConceptProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables>
    } & TChildProps;
export function withUnassignEmployeeFromConcept<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignEmployeeFromConceptMutation,
  UnassignEmployeeFromConceptMutationVariables,
  UnassignEmployeeFromConceptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables, UnassignEmployeeFromConceptProps<TChildProps, TDataName>>(UnassignEmployeeFromConceptDocument, {
      alias: 'unassignEmployeeFromConcept',
      ...operationOptions
    });
};
export type UnassignEmployeeFromConceptMutationResult = ApolloReactCommon.MutationResult<UnassignEmployeeFromConceptMutation>;
export type UnassignEmployeeFromConceptMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignEmployeeFromConceptMutation, UnassignEmployeeFromConceptMutationVariables>;
export const EnableUnitDocument = gql`
    mutation EnableUnit($id: String!) {
  enableUnit(id: $id)
}
    `;
export type EnableUnitMutationFn = ApolloReactCommon.MutationFunction<EnableUnitMutation, EnableUnitMutationVariables>;
export type EnableUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableUnitMutation, EnableUnitMutationVariables>, 'mutation'>;

    export const EnableUnitComponent = (props: EnableUnitComponentProps) => (
      <ApolloReactComponents.Mutation<EnableUnitMutation, EnableUnitMutationVariables> mutation={EnableUnitDocument} {...props} />
    );
    
export type EnableUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EnableUnitMutation, EnableUnitMutationVariables>
    } & TChildProps;
export function withEnableUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnableUnitMutation,
  EnableUnitMutationVariables,
  EnableUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EnableUnitMutation, EnableUnitMutationVariables, EnableUnitProps<TChildProps, TDataName>>(EnableUnitDocument, {
      alias: 'enableUnit',
      ...operationOptions
    });
};
export type EnableUnitMutationResult = ApolloReactCommon.MutationResult<EnableUnitMutation>;
export type EnableUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableUnitMutation, EnableUnitMutationVariables>;
export const GetEmployeeByEmailDocument = gql`
    query GetEmployeeByEmail($email: String!) {
  employeeByEmail(email: $email) {
    firstName
    lastName
    email
  }
}
    `;
export type GetEmployeeByEmailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEmployeeByEmailQuery, GetEmployeeByEmailQueryVariables>, 'query'> & ({ variables: GetEmployeeByEmailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEmployeeByEmailComponent = (props: GetEmployeeByEmailComponentProps) => (
      <ApolloReactComponents.Query<GetEmployeeByEmailQuery, GetEmployeeByEmailQueryVariables> query={GetEmployeeByEmailDocument} {...props} />
    );
    
export type GetEmployeeByEmailProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetEmployeeByEmailQuery, GetEmployeeByEmailQueryVariables>
    } & TChildProps;
export function withGetEmployeeByEmail<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEmployeeByEmailQuery,
  GetEmployeeByEmailQueryVariables,
  GetEmployeeByEmailProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetEmployeeByEmailQuery, GetEmployeeByEmailQueryVariables, GetEmployeeByEmailProps<TChildProps, TDataName>>(GetEmployeeByEmailDocument, {
      alias: 'getEmployeeByEmail',
      ...operationOptions
    });
};
export type GetEmployeeByEmailQueryResult = ApolloReactCommon.QueryResult<GetEmployeeByEmailQuery, GetEmployeeByEmailQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: String!) {
  organization(id: $id) {
    id
    name
    city
    street1
    street2
    state
    country
    phone
    zip
    updatedAt
    createdAt
  }
}
    `;
export type GetOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationQuery, GetOrganizationQueryVariables>, 'query'> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationComponent = (props: GetOrganizationComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationQuery, GetOrganizationQueryVariables> query={GetOrganizationDocument} {...props} />
    );
    
export type GetOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetOrganizationQuery, GetOrganizationQueryVariables>
    } & TChildProps;
export function withGetOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetOrganizationQuery, GetOrganizationQueryVariables, GetOrganizationProps<TChildProps, TDataName>>(GetOrganizationDocument, {
      alias: 'getOrganization',
      ...operationOptions
    });
};
export type GetOrganizationQueryResult = ApolloReactCommon.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetUnitEmployeesDocument = gql`
    query GetUnitEmployees($id: String!) {
  unit(id: $id) {
    id
    employees {
      cursor
      nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
}
    `;
export type GetUnitEmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitEmployeesQuery, GetUnitEmployeesQueryVariables>, 'query'> & ({ variables: GetUnitEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnitEmployeesComponent = (props: GetUnitEmployeesComponentProps) => (
      <ApolloReactComponents.Query<GetUnitEmployeesQuery, GetUnitEmployeesQueryVariables> query={GetUnitEmployeesDocument} {...props} />
    );
    
export type GetUnitEmployeesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUnitEmployeesQuery, GetUnitEmployeesQueryVariables>
    } & TChildProps;
export function withGetUnitEmployees<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnitEmployeesQuery,
  GetUnitEmployeesQueryVariables,
  GetUnitEmployeesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnitEmployeesQuery, GetUnitEmployeesQueryVariables, GetUnitEmployeesProps<TChildProps, TDataName>>(GetUnitEmployeesDocument, {
      alias: 'getUnitEmployees',
      ...operationOptions
    });
};
export type GetUnitEmployeesQueryResult = ApolloReactCommon.QueryResult<GetUnitEmployeesQuery, GetUnitEmployeesQueryVariables>;
export const GetUnitDocument = gql`
    query GetUnit($id: String!) {
  unit(id: $id) {
    id
    name
    identifier
    phone
    email
    street1
    street2
    city
    state
    zip
    country
    updatedAt
    createdAt
    deletedAt
    online
    lastSeenAt
    organization {
      name
    }
    concepts {
      name
    }
    customAttributes {
      key
      value
    }
  }
}
    `;
export type GetUnitComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitQuery, GetUnitQueryVariables>, 'query'> & ({ variables: GetUnitQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnitComponent = (props: GetUnitComponentProps) => (
      <ApolloReactComponents.Query<GetUnitQuery, GetUnitQueryVariables> query={GetUnitDocument} {...props} />
    );
    
export type GetUnitProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUnitQuery, GetUnitQueryVariables>
    } & TChildProps;
export function withGetUnit<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnitQuery,
  GetUnitQueryVariables,
  GetUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnitQuery, GetUnitQueryVariables, GetUnitProps<TChildProps, TDataName>>(GetUnitDocument, {
      alias: 'getUnit',
      ...operationOptions
    });
};
export type GetUnitQueryResult = ApolloReactCommon.QueryResult<GetUnitQuery, GetUnitQueryVariables>;
export const UnitSystemPermissionsDocument = gql`
    query UnitSystemPermissions($id: String!) {
  unit(id: $id) {
    id
    name
    systemActor {
      id
      name
      secret
      grants {
        actions
        policies
        target {
          id
          type
          name
        }
      }
    }
  }
}
    `;
export type UnitSystemPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UnitSystemPermissionsQuery, UnitSystemPermissionsQueryVariables>, 'query'> & ({ variables: UnitSystemPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UnitSystemPermissionsComponent = (props: UnitSystemPermissionsComponentProps) => (
      <ApolloReactComponents.Query<UnitSystemPermissionsQuery, UnitSystemPermissionsQueryVariables> query={UnitSystemPermissionsDocument} {...props} />
    );
    
export type UnitSystemPermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UnitSystemPermissionsQuery, UnitSystemPermissionsQueryVariables>
    } & TChildProps;
export function withUnitSystemPermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnitSystemPermissionsQuery,
  UnitSystemPermissionsQueryVariables,
  UnitSystemPermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UnitSystemPermissionsQuery, UnitSystemPermissionsQueryVariables, UnitSystemPermissionsProps<TChildProps, TDataName>>(UnitSystemPermissionsDocument, {
      alias: 'unitSystemPermissions',
      ...operationOptions
    });
};
export type UnitSystemPermissionsQueryResult = ApolloReactCommon.QueryResult<UnitSystemPermissionsQuery, UnitSystemPermissionsQueryVariables>;
export const GetUnitOverviewDocument = gql`
    query GetUnitOverview($id: String!) {
  unit(id: $id) {
    id
    name
    dailyOverview {
      netsaleAmount
      yoyNetsalePercent
      saleCount
      yoySaleCountPercent
      voidAmount
      deleteAmount
      cancelledOrderAmount
      laborPercent
      salesPerManHour
      discountAmount
      driveThruAverageTime
      driveThruCount
      updatedAt
    }
  }
}
    `;
export type GetUnitOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUnitOverviewQuery, GetUnitOverviewQueryVariables>, 'query'> & ({ variables: GetUnitOverviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUnitOverviewComponent = (props: GetUnitOverviewComponentProps) => (
      <ApolloReactComponents.Query<GetUnitOverviewQuery, GetUnitOverviewQueryVariables> query={GetUnitOverviewDocument} {...props} />
    );
    
export type GetUnitOverviewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetUnitOverviewQuery, GetUnitOverviewQueryVariables>
    } & TChildProps;
export function withGetUnitOverview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUnitOverviewQuery,
  GetUnitOverviewQueryVariables,
  GetUnitOverviewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetUnitOverviewQuery, GetUnitOverviewQueryVariables, GetUnitOverviewProps<TChildProps, TDataName>>(GetUnitOverviewDocument, {
      alias: 'getUnitOverview',
      ...operationOptions
    });
};
export type GetUnitOverviewQueryResult = ApolloReactCommon.QueryResult<GetUnitOverviewQuery, GetUnitOverviewQueryVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($parentId: String!, $parentType: ResourceType!, $name: String!) {
  createGroup(parentId: $parentId, parentType: $parentType, name: $name) {
    id
  }
}
    `;
export type CreateGroupMutationFn = ApolloReactCommon.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;
export type CreateGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGroupMutation, CreateGroupMutationVariables>, 'mutation'>;

    export const CreateGroupComponent = (props: CreateGroupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGroupMutation, CreateGroupMutationVariables> mutation={CreateGroupDocument} {...props} />
    );
    
export type CreateGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>
    } & TChildProps;
export function withCreateGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGroupMutation,
  CreateGroupMutationVariables,
  CreateGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGroupMutation, CreateGroupMutationVariables, CreateGroupProps<TChildProps, TDataName>>(CreateGroupDocument, {
      alias: 'createGroup',
      ...operationOptions
    });
};
export type CreateGroupMutationResult = ApolloReactCommon.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const RenameGroupDocument = gql`
    mutation renameGroup($id: String!, $name: String!) {
  renameGroup(id: $id, name: $name) {
    id
  }
}
    `;
export type RenameGroupMutationFn = ApolloReactCommon.MutationFunction<RenameGroupMutation, RenameGroupMutationVariables>;
export type RenameGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameGroupMutation, RenameGroupMutationVariables>, 'mutation'>;

    export const RenameGroupComponent = (props: RenameGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RenameGroupMutation, RenameGroupMutationVariables> mutation={RenameGroupDocument} {...props} />
    );
    
export type RenameGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RenameGroupMutation, RenameGroupMutationVariables>
    } & TChildProps;
export function withRenameGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameGroupMutation,
  RenameGroupMutationVariables,
  RenameGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RenameGroupMutation, RenameGroupMutationVariables, RenameGroupProps<TChildProps, TDataName>>(RenameGroupDocument, {
      alias: 'renameGroup',
      ...operationOptions
    });
};
export type RenameGroupMutationResult = ApolloReactCommon.MutationResult<RenameGroupMutation>;
export type RenameGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameGroupMutation, RenameGroupMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($id: String!) {
  deleteGroup(id: $id) {
    id
  }
}
    `;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;
export type DeleteGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteGroupMutation, DeleteGroupMutationVariables>, 'mutation'>;

    export const DeleteGroupComponent = (props: DeleteGroupComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteGroupMutation, DeleteGroupMutationVariables> mutation={DeleteGroupDocument} {...props} />
    );
    
export type DeleteGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>
    } & TChildProps;
export function withDeleteGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
  DeleteGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteGroupMutation, DeleteGroupMutationVariables, DeleteGroupProps<TChildProps, TDataName>>(DeleteGroupDocument, {
      alias: 'deleteGroup',
      ...operationOptions
    });
};
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const AssignToGroupDocument = gql`
    mutation AssignToGroup($input: GroupResourcesInput!) {
  assignToGroup(input: $input)
}
    `;
export type AssignToGroupMutationFn = ApolloReactCommon.MutationFunction<AssignToGroupMutation, AssignToGroupMutationVariables>;
export type AssignToGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignToGroupMutation, AssignToGroupMutationVariables>, 'mutation'>;

    export const AssignToGroupComponent = (props: AssignToGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AssignToGroupMutation, AssignToGroupMutationVariables> mutation={AssignToGroupDocument} {...props} />
    );
    
export type AssignToGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AssignToGroupMutation, AssignToGroupMutationVariables>
    } & TChildProps;
export function withAssignToGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AssignToGroupMutation,
  AssignToGroupMutationVariables,
  AssignToGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AssignToGroupMutation, AssignToGroupMutationVariables, AssignToGroupProps<TChildProps, TDataName>>(AssignToGroupDocument, {
      alias: 'assignToGroup',
      ...operationOptions
    });
};
export type AssignToGroupMutationResult = ApolloReactCommon.MutationResult<AssignToGroupMutation>;
export type AssignToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignToGroupMutation, AssignToGroupMutationVariables>;
export const UnassignFromGroupDocument = gql`
    mutation UnassignFromGroup($input: GroupResourcesInput!) {
  unassignFromGroup(input: $input)
}
    `;
export type UnassignFromGroupMutationFn = ApolloReactCommon.MutationFunction<UnassignFromGroupMutation, UnassignFromGroupMutationVariables>;
export type UnassignFromGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnassignFromGroupMutation, UnassignFromGroupMutationVariables>, 'mutation'>;

    export const UnassignFromGroupComponent = (props: UnassignFromGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UnassignFromGroupMutation, UnassignFromGroupMutationVariables> mutation={UnassignFromGroupDocument} {...props} />
    );
    
export type UnassignFromGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnassignFromGroupMutation, UnassignFromGroupMutationVariables>
    } & TChildProps;
export function withUnassignFromGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnassignFromGroupMutation,
  UnassignFromGroupMutationVariables,
  UnassignFromGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnassignFromGroupMutation, UnassignFromGroupMutationVariables, UnassignFromGroupProps<TChildProps, TDataName>>(UnassignFromGroupDocument, {
      alias: 'unassignFromGroup',
      ...operationOptions
    });
};
export type UnassignFromGroupMutationResult = ApolloReactCommon.MutationResult<UnassignFromGroupMutation>;
export type UnassignFromGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignFromGroupMutation, UnassignFromGroupMutationVariables>;
export const GroupResourcesDocument = gql`
    query GroupResources($id: String!, $type: ResourceType!) {
  group(id: $id) {
    id
    name
    resources(resourceType: $type) {
      id
      name
      type
    }
  }
}
    `;
export type GroupResourcesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupResourcesQuery, GroupResourcesQueryVariables>, 'query'> & ({ variables: GroupResourcesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupResourcesComponent = (props: GroupResourcesComponentProps) => (
      <ApolloReactComponents.Query<GroupResourcesQuery, GroupResourcesQueryVariables> query={GroupResourcesDocument} {...props} />
    );
    
export type GroupResourcesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupResourcesQuery, GroupResourcesQueryVariables>
    } & TChildProps;
export function withGroupResources<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupResourcesQuery,
  GroupResourcesQueryVariables,
  GroupResourcesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupResourcesQuery, GroupResourcesQueryVariables, GroupResourcesProps<TChildProps, TDataName>>(GroupResourcesDocument, {
      alias: 'groupResources',
      ...operationOptions
    });
};
export type GroupResourcesQueryResult = ApolloReactCommon.QueryResult<GroupResourcesQuery, GroupResourcesQueryVariables>;
export const GroupUnitsDocument = gql`
    query GroupUnits($id: String!) {
  group(id: $id) {
    id
    name
    units {
      id
      name
      identifier
      organization {
        name
      }
    }
  }
}
    `;
export type GroupUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupUnitsQuery, GroupUnitsQueryVariables>, 'query'> & ({ variables: GroupUnitsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupUnitsComponent = (props: GroupUnitsComponentProps) => (
      <ApolloReactComponents.Query<GroupUnitsQuery, GroupUnitsQueryVariables> query={GroupUnitsDocument} {...props} />
    );
    
export type GroupUnitsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupUnitsQuery, GroupUnitsQueryVariables>
    } & TChildProps;
export function withGroupUnits<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupUnitsQuery,
  GroupUnitsQueryVariables,
  GroupUnitsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupUnitsQuery, GroupUnitsQueryVariables, GroupUnitsProps<TChildProps, TDataName>>(GroupUnitsDocument, {
      alias: 'groupUnits',
      ...operationOptions
    });
};
export type GroupUnitsQueryResult = ApolloReactCommon.QueryResult<GroupUnitsQuery, GroupUnitsQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  groups {
    id
    name
  }
}
    `;
export type GroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupsQuery, GroupsQueryVariables>, 'query'>;

    export const GroupsComponent = (props: GroupsComponentProps) => (
      <ApolloReactComponents.Query<GroupsQuery, GroupsQueryVariables> query={GroupsDocument} {...props} />
    );
    
export type GroupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupsQuery, GroupsQueryVariables>
    } & TChildProps;
export function withGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupsQuery,
  GroupsQueryVariables,
  GroupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupsQuery, GroupsQueryVariables, GroupsProps<TChildProps, TDataName>>(GroupsDocument, {
      alias: 'groups',
      ...operationOptions
    });
};
export type GroupsQueryResult = ApolloReactCommon.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const HydrateActorDocument = gql`
    query HydrateActor($id: String!) {
  actor(id: $id) {
    id
    name
    resourceType
    resourceId
    lastUsedAt
    updatedAt
    createdAt
    permissions {
      singleUnit
      inventory
      reports
      system
    }
    employee {
      id
      firstName
      lastName
      email
      phone
      status
      createdAt
      updatedAt
      carbonIdentifier
      carbonPin
      carbonAccessToken
    }
  }
}
    `;
export type HydrateActorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HydrateActorQuery, HydrateActorQueryVariables>, 'query'> & ({ variables: HydrateActorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HydrateActorComponent = (props: HydrateActorComponentProps) => (
      <ApolloReactComponents.Query<HydrateActorQuery, HydrateActorQueryVariables> query={HydrateActorDocument} {...props} />
    );
    
export type HydrateActorProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HydrateActorQuery, HydrateActorQueryVariables>
    } & TChildProps;
export function withHydrateActor<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HydrateActorQuery,
  HydrateActorQueryVariables,
  HydrateActorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HydrateActorQuery, HydrateActorQueryVariables, HydrateActorProps<TChildProps, TDataName>>(HydrateActorDocument, {
      alias: 'hydrateActor',
      ...operationOptions
    });
};
export type HydrateActorQueryResult = ApolloReactCommon.QueryResult<HydrateActorQuery, HydrateActorQueryVariables>;
export const LegacyInventoryCountStatDocument = gql`
    query LegacyInventoryCountStat($itemId: String!, $unitId: String!, $businessDate: Int!) {
  legacyInventoryStat(itemId: $itemId, unitId: $unitId, businessDate: $businessDate) {
    beginningDate
    beginningCount
    waste
    purchases
    borrows
    loans
    usage
    currentCount
    expectedCount
    items {
      id
      itemNumber
      name
      individualDescription
      counts {
        locationName
        count
      }
    }
  }
}
    `;
export type LegacyInventoryCountStatComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyInventoryCountStatQuery, LegacyInventoryCountStatQueryVariables>, 'query'> & ({ variables: LegacyInventoryCountStatQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyInventoryCountStatComponent = (props: LegacyInventoryCountStatComponentProps) => (
      <ApolloReactComponents.Query<LegacyInventoryCountStatQuery, LegacyInventoryCountStatQueryVariables> query={LegacyInventoryCountStatDocument} {...props} />
    );
    
export type LegacyInventoryCountStatProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyInventoryCountStatQuery, LegacyInventoryCountStatQueryVariables>
    } & TChildProps;
export function withLegacyInventoryCountStat<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryCountStatQuery,
  LegacyInventoryCountStatQueryVariables,
  LegacyInventoryCountStatProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyInventoryCountStatQuery, LegacyInventoryCountStatQueryVariables, LegacyInventoryCountStatProps<TChildProps, TDataName>>(LegacyInventoryCountStatDocument, {
      alias: 'legacyInventoryCountStat',
      ...operationOptions
    });
};
export type LegacyInventoryCountStatQueryResult = ApolloReactCommon.QueryResult<LegacyInventoryCountStatQuery, LegacyInventoryCountStatQueryVariables>;
export const LegacyInventoryCountSheetDocument = gql`
    query LegacyInventoryCountSheet($unitId: String!, $businessDate: Int!, $frequencies: [String!]!) {
  legacyInventoryCountSheet(unitId: $unitId, businessDate: $businessDate, frequencies: $frequencies) {
    count
    countedCount
    uncountedCount
    locations {
      id
      name
      itemCount
    }
    items {
      locationId
      sortIndex
      itemId
      itemNumber
      itemName
      caseDescription
      subcaseDescription
      individualDescription
      subcaseQuantity
      totalQuantity
      frequency
      count
      formattedCount
      formattedTotalCount
      caseCount
      subcaseCount
      eachCount
      totalCount
      isCounted
    }
  }
  legacyInventoryOrphanedItems(unitId: $unitId) {
    id
    itemNumber
    name
  }
}
    `;
export type LegacyInventoryCountSheetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyInventoryCountSheetQuery, LegacyInventoryCountSheetQueryVariables>, 'query'> & ({ variables: LegacyInventoryCountSheetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyInventoryCountSheetComponent = (props: LegacyInventoryCountSheetComponentProps) => (
      <ApolloReactComponents.Query<LegacyInventoryCountSheetQuery, LegacyInventoryCountSheetQueryVariables> query={LegacyInventoryCountSheetDocument} {...props} />
    );
    
export type LegacyInventoryCountSheetProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyInventoryCountSheetQuery, LegacyInventoryCountSheetQueryVariables>
    } & TChildProps;
export function withLegacyInventoryCountSheet<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryCountSheetQuery,
  LegacyInventoryCountSheetQueryVariables,
  LegacyInventoryCountSheetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyInventoryCountSheetQuery, LegacyInventoryCountSheetQueryVariables, LegacyInventoryCountSheetProps<TChildProps, TDataName>>(LegacyInventoryCountSheetDocument, {
      alias: 'legacyInventoryCountSheet',
      ...operationOptions
    });
};
export type LegacyInventoryCountSheetQueryResult = ApolloReactCommon.QueryResult<LegacyInventoryCountSheetQuery, LegacyInventoryCountSheetQueryVariables>;
export const LegacyInventoryCreateCountsDocument = gql`
    mutation LegacyInventoryCreateCounts($counts: [LegacyInventoryCountCreateInput!]!, $unitId: String!) {
  createLegacyInventoryCounts(counts: $counts, unitId: $unitId) {
    counts {
      itemId
      locationId
      count
      caseCount
      subcaseCount
      eachCount
      businessDate
    }
  }
}
    `;
export type LegacyInventoryCreateCountsMutationFn = ApolloReactCommon.MutationFunction<LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables>;
export type LegacyInventoryCreateCountsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables>, 'mutation'>;

    export const LegacyInventoryCreateCountsComponent = (props: LegacyInventoryCreateCountsComponentProps) => (
      <ApolloReactComponents.Mutation<LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables> mutation={LegacyInventoryCreateCountsDocument} {...props} />
    );
    
export type LegacyInventoryCreateCountsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables>
    } & TChildProps;
export function withLegacyInventoryCreateCounts<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryCreateCountsMutation,
  LegacyInventoryCreateCountsMutationVariables,
  LegacyInventoryCreateCountsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables, LegacyInventoryCreateCountsProps<TChildProps, TDataName>>(LegacyInventoryCreateCountsDocument, {
      alias: 'legacyInventoryCreateCounts',
      ...operationOptions
    });
};
export type LegacyInventoryCreateCountsMutationResult = ApolloReactCommon.MutationResult<LegacyInventoryCreateCountsMutation>;
export type LegacyInventoryCreateCountsMutationOptions = ApolloReactCommon.BaseMutationOptions<LegacyInventoryCreateCountsMutation, LegacyInventoryCreateCountsMutationVariables>;
export const LegacyInventoryItemsDocument = gql`
    query LegacyInventoryItems($unitId: String!) {
  legacyInventoryItems(unitId: $unitId) {
    nodes {
      id
      itemNumber
      name
      frequency
      state
      isMaster
    }
  }
}
    `;
export type LegacyInventoryItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyInventoryItemsQuery, LegacyInventoryItemsQueryVariables>, 'query'> & ({ variables: LegacyInventoryItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyInventoryItemsComponent = (props: LegacyInventoryItemsComponentProps) => (
      <ApolloReactComponents.Query<LegacyInventoryItemsQuery, LegacyInventoryItemsQueryVariables> query={LegacyInventoryItemsDocument} {...props} />
    );
    
export type LegacyInventoryItemsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyInventoryItemsQuery, LegacyInventoryItemsQueryVariables>
    } & TChildProps;
export function withLegacyInventoryItems<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryItemsQuery,
  LegacyInventoryItemsQueryVariables,
  LegacyInventoryItemsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyInventoryItemsQuery, LegacyInventoryItemsQueryVariables, LegacyInventoryItemsProps<TChildProps, TDataName>>(LegacyInventoryItemsDocument, {
      alias: 'legacyInventoryItems',
      ...operationOptions
    });
};
export type LegacyInventoryItemsQueryResult = ApolloReactCommon.QueryResult<LegacyInventoryItemsQuery, LegacyInventoryItemsQueryVariables>;
export const LegacyInventoryLocationDocument = gql`
    query LegacyInventoryLocation($unitId: String!, $id: String!) {
  legacyInventoryLocation(unitId: $unitId, id: $id) {
    id
    name
    state
    items {
      itemId
      sortIndex
    }
  }
}
    `;
export type LegacyInventoryLocationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyInventoryLocationQuery, LegacyInventoryLocationQueryVariables>, 'query'> & ({ variables: LegacyInventoryLocationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyInventoryLocationComponent = (props: LegacyInventoryLocationComponentProps) => (
      <ApolloReactComponents.Query<LegacyInventoryLocationQuery, LegacyInventoryLocationQueryVariables> query={LegacyInventoryLocationDocument} {...props} />
    );
    
export type LegacyInventoryLocationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyInventoryLocationQuery, LegacyInventoryLocationQueryVariables>
    } & TChildProps;
export function withLegacyInventoryLocation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryLocationQuery,
  LegacyInventoryLocationQueryVariables,
  LegacyInventoryLocationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyInventoryLocationQuery, LegacyInventoryLocationQueryVariables, LegacyInventoryLocationProps<TChildProps, TDataName>>(LegacyInventoryLocationDocument, {
      alias: 'legacyInventoryLocation',
      ...operationOptions
    });
};
export type LegacyInventoryLocationQueryResult = ApolloReactCommon.QueryResult<LegacyInventoryLocationQuery, LegacyInventoryLocationQueryVariables>;
export const LegacyInventoryLocationsDocument = gql`
    query LegacyInventoryLocations($unitId: String!, $disabled: Boolean!) {
  legacyInventoryLocations(unitId: $unitId, disabled: $disabled) {
    nodes {
      id
      name
      state
      items {
        itemId
        sortIndex
      }
    }
  }
}
    `;
export type LegacyInventoryLocationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyInventoryLocationsQuery, LegacyInventoryLocationsQueryVariables>, 'query'> & ({ variables: LegacyInventoryLocationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyInventoryLocationsComponent = (props: LegacyInventoryLocationsComponentProps) => (
      <ApolloReactComponents.Query<LegacyInventoryLocationsQuery, LegacyInventoryLocationsQueryVariables> query={LegacyInventoryLocationsDocument} {...props} />
    );
    
export type LegacyInventoryLocationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyInventoryLocationsQuery, LegacyInventoryLocationsQueryVariables>
    } & TChildProps;
export function withLegacyInventoryLocations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyInventoryLocationsQuery,
  LegacyInventoryLocationsQueryVariables,
  LegacyInventoryLocationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyInventoryLocationsQuery, LegacyInventoryLocationsQueryVariables, LegacyInventoryLocationsProps<TChildProps, TDataName>>(LegacyInventoryLocationsDocument, {
      alias: 'legacyInventoryLocations',
      ...operationOptions
    });
};
export type LegacyInventoryLocationsQueryResult = ApolloReactCommon.QueryResult<LegacyInventoryLocationsQuery, LegacyInventoryLocationsQueryVariables>;
export const ListOrganizationsDocument = gql`
    query ListOrganizations($cursor: String, $action: String, $sort: [Sort!]) {
  organizations(cursor: $cursor, action: $action, sort: $sort) {
    cursor
    nodes {
      id
      name
      city
      state
      phone
    }
  }
}
    `;
export type ListOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>, 'query'>;

    export const ListOrganizationsComponent = (props: ListOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<ListOrganizationsQuery, ListOrganizationsQueryVariables> query={ListOrganizationsDocument} {...props} />
    );
    
export type ListOrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ListOrganizationsQuery, ListOrganizationsQueryVariables>
    } & TChildProps;
export function withListOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables,
  ListOrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ListOrganizationsQuery, ListOrganizationsQueryVariables, ListOrganizationsProps<TChildProps, TDataName>>(ListOrganizationsDocument, {
      alias: 'listOrganizations',
      ...operationOptions
    });
};
export type ListOrganizationsQueryResult = ApolloReactCommon.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export const ListUnitsDocument = gql`
    query ListUnits($cursor: String, $disabled: Boolean, $action: String, $sort: [Sort!]) {
  units(cursor: $cursor, disabled: $disabled, action: $action, sort: $sort) {
    cursor
    nodes {
      id
      name
      identifier
      city
      state
      phone
      lastSeenAt
      online
      organization {
        name
      }
      concepts {
        name
      }
    }
  }
}
    `;
export type ListUnitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListUnitsQuery, ListUnitsQueryVariables>, 'query'>;

    export const ListUnitsComponent = (props: ListUnitsComponentProps) => (
      <ApolloReactComponents.Query<ListUnitsQuery, ListUnitsQueryVariables> query={ListUnitsDocument} {...props} />
    );
    
export type ListUnitsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ListUnitsQuery, ListUnitsQueryVariables>
    } & TChildProps;
export function withListUnits<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListUnitsQuery,
  ListUnitsQueryVariables,
  ListUnitsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ListUnitsQuery, ListUnitsQueryVariables, ListUnitsProps<TChildProps, TDataName>>(ListUnitsDocument, {
      alias: 'listUnits',
      ...operationOptions
    });
};
export type ListUnitsQueryResult = ApolloReactCommon.QueryResult<ListUnitsQuery, ListUnitsQueryVariables>;
export const OrganizationGroupsDocument = gql`
    query OrganizationGroups($id: String!) {
  organization(id: $id) {
    id
    name
    groups {
      id
      name
    }
  }
}
    `;
export type OrganizationGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationGroupsQuery, OrganizationGroupsQueryVariables>, 'query'> & ({ variables: OrganizationGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationGroupsComponent = (props: OrganizationGroupsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationGroupsQuery, OrganizationGroupsQueryVariables> query={OrganizationGroupsDocument} {...props} />
    );
    
export type OrganizationGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationGroupsQuery, OrganizationGroupsQueryVariables>
    } & TChildProps;
export function withOrganizationGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationGroupsQuery,
  OrganizationGroupsQueryVariables,
  OrganizationGroupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationGroupsQuery, OrganizationGroupsQueryVariables, OrganizationGroupsProps<TChildProps, TDataName>>(OrganizationGroupsDocument, {
      alias: 'organizationGroups',
      ...operationOptions
    });
};
export type OrganizationGroupsQueryResult = ApolloReactCommon.QueryResult<OrganizationGroupsQuery, OrganizationGroupsQueryVariables>;
export const OrganizationConceptsDocument = gql`
    query OrganizationConcepts($id: String!) {
  organization(id: $id) {
    id
    name
    concepts {
      id
      name
    }
  }
}
    `;
export type OrganizationConceptsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationConceptsQuery, OrganizationConceptsQueryVariables>, 'query'> & ({ variables: OrganizationConceptsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationConceptsComponent = (props: OrganizationConceptsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationConceptsQuery, OrganizationConceptsQueryVariables> query={OrganizationConceptsDocument} {...props} />
    );
    
export type OrganizationConceptsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationConceptsQuery, OrganizationConceptsQueryVariables>
    } & TChildProps;
export function withOrganizationConcepts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationConceptsQuery,
  OrganizationConceptsQueryVariables,
  OrganizationConceptsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationConceptsQuery, OrganizationConceptsQueryVariables, OrganizationConceptsProps<TChildProps, TDataName>>(OrganizationConceptsDocument, {
      alias: 'organizationConcepts',
      ...operationOptions
    });
};
export type OrganizationConceptsQueryResult = ApolloReactCommon.QueryResult<OrganizationConceptsQuery, OrganizationConceptsQueryVariables>;
export const AddConceptToOrganizationDocument = gql`
    mutation AddConceptToOrganization($orgId: String!, $conceptId: String!) {
  addConceptToOrganization(id: $orgId, conceptId: $conceptId)
}
    `;
export type AddConceptToOrganizationMutationFn = ApolloReactCommon.MutationFunction<AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables>;
export type AddConceptToOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables>, 'mutation'>;

    export const AddConceptToOrganizationComponent = (props: AddConceptToOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables> mutation={AddConceptToOrganizationDocument} {...props} />
    );
    
export type AddConceptToOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables>
    } & TChildProps;
export function withAddConceptToOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddConceptToOrganizationMutation,
  AddConceptToOrganizationMutationVariables,
  AddConceptToOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables, AddConceptToOrganizationProps<TChildProps, TDataName>>(AddConceptToOrganizationDocument, {
      alias: 'addConceptToOrganization',
      ...operationOptions
    });
};
export type AddConceptToOrganizationMutationResult = ApolloReactCommon.MutationResult<AddConceptToOrganizationMutation>;
export type AddConceptToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddConceptToOrganizationMutation, AddConceptToOrganizationMutationVariables>;
export const CheckResourcePrivilegesDocument = gql`
    query CheckResourcePrivileges($source: InputResource!, $targets: [InputResource!]!, $actions: [String!]!) {
  checkResourcePrivileges(resource: $source, resources: $targets, actions: $actions) {
    resource {
      id
      type
    }
    privileges {
      action
      allowed
    }
  }
}
    `;
export type CheckResourcePrivilegesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckResourcePrivilegesQuery, CheckResourcePrivilegesQueryVariables>, 'query'> & ({ variables: CheckResourcePrivilegesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckResourcePrivilegesComponent = (props: CheckResourcePrivilegesComponentProps) => (
      <ApolloReactComponents.Query<CheckResourcePrivilegesQuery, CheckResourcePrivilegesQueryVariables> query={CheckResourcePrivilegesDocument} {...props} />
    );
    
export type CheckResourcePrivilegesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CheckResourcePrivilegesQuery, CheckResourcePrivilegesQueryVariables>
    } & TChildProps;
export function withCheckResourcePrivileges<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckResourcePrivilegesQuery,
  CheckResourcePrivilegesQueryVariables,
  CheckResourcePrivilegesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CheckResourcePrivilegesQuery, CheckResourcePrivilegesQueryVariables, CheckResourcePrivilegesProps<TChildProps, TDataName>>(CheckResourcePrivilegesDocument, {
      alias: 'checkResourcePrivileges',
      ...operationOptions
    });
};
export type CheckResourcePrivilegesQueryResult = ApolloReactCommon.QueryResult<CheckResourcePrivilegesQuery, CheckResourcePrivilegesQueryVariables>;
export const CheckPrivilegesDocument = gql`
    query CheckPrivileges($source: InputResource!, $actions: [String!]!) {
  checkPrivileges(resource: $source, actions: $actions) {
    action
    allowed
  }
}
    `;
export type CheckPrivilegesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckPrivilegesQuery, CheckPrivilegesQueryVariables>, 'query'> & ({ variables: CheckPrivilegesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckPrivilegesComponent = (props: CheckPrivilegesComponentProps) => (
      <ApolloReactComponents.Query<CheckPrivilegesQuery, CheckPrivilegesQueryVariables> query={CheckPrivilegesDocument} {...props} />
    );
    
export type CheckPrivilegesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CheckPrivilegesQuery, CheckPrivilegesQueryVariables>
    } & TChildProps;
export function withCheckPrivileges<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckPrivilegesQuery,
  CheckPrivilegesQueryVariables,
  CheckPrivilegesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CheckPrivilegesQuery, CheckPrivilegesQueryVariables, CheckPrivilegesProps<TChildProps, TDataName>>(CheckPrivilegesDocument, {
      alias: 'checkPrivileges',
      ...operationOptions
    });
};
export type CheckPrivilegesQueryResult = ApolloReactCommon.QueryResult<CheckPrivilegesQuery, CheckPrivilegesQueryVariables>;
export const ManageGrantsDocument = gql`
    query ManageGrants($currentUser: String!, $sourceId: String!, $resource: InputResource!) {
  currentUser: actor(id: $currentUser) {
    id
    access(target: $resource) {
      policies
    }
  }
  source: actor(id: $sourceId) {
    id
    grant(target: $resource) {
      policies
    }
  }
}
    `;
export type ManageGrantsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ManageGrantsQuery, ManageGrantsQueryVariables>, 'query'> & ({ variables: ManageGrantsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ManageGrantsComponent = (props: ManageGrantsComponentProps) => (
      <ApolloReactComponents.Query<ManageGrantsQuery, ManageGrantsQueryVariables> query={ManageGrantsDocument} {...props} />
    );
    
export type ManageGrantsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ManageGrantsQuery, ManageGrantsQueryVariables>
    } & TChildProps;
export function withManageGrants<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ManageGrantsQuery,
  ManageGrantsQueryVariables,
  ManageGrantsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ManageGrantsQuery, ManageGrantsQueryVariables, ManageGrantsProps<TChildProps, TDataName>>(ManageGrantsDocument, {
      alias: 'manageGrants',
      ...operationOptions
    });
};
export type ManageGrantsQueryResult = ApolloReactCommon.QueryResult<ManageGrantsQuery, ManageGrantsQueryVariables>;
export const GetActorAccessDocument = gql`
    query GetActorAccess($actorId: String!, $resource: InputResource) {
  actor(id: $actorId) {
    access(target: $resource) {
      policies
    }
  }
}
    `;
export type GetActorAccessComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetActorAccessQuery, GetActorAccessQueryVariables>, 'query'> & ({ variables: GetActorAccessQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetActorAccessComponent = (props: GetActorAccessComponentProps) => (
      <ApolloReactComponents.Query<GetActorAccessQuery, GetActorAccessQueryVariables> query={GetActorAccessDocument} {...props} />
    );
    
export type GetActorAccessProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetActorAccessQuery, GetActorAccessQueryVariables>
    } & TChildProps;
export function withGetActorAccess<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetActorAccessQuery,
  GetActorAccessQueryVariables,
  GetActorAccessProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetActorAccessQuery, GetActorAccessQueryVariables, GetActorAccessProps<TChildProps, TDataName>>(GetActorAccessDocument, {
      alias: 'getActorAccess',
      ...operationOptions
    });
};
export type GetActorAccessQueryResult = ApolloReactCommon.QueryResult<GetActorAccessQuery, GetActorAccessQueryVariables>;
export const ListReportsDocument = gql`
    query ListReports {
  legacyReports {
    id
    name
    description
    icon
  }
}
    `;
export type ListReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListReportsQuery, ListReportsQueryVariables>, 'query'>;

    export const ListReportsComponent = (props: ListReportsComponentProps) => (
      <ApolloReactComponents.Query<ListReportsQuery, ListReportsQueryVariables> query={ListReportsDocument} {...props} />
    );
    
export type ListReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ListReportsQuery, ListReportsQueryVariables>
    } & TChildProps;
export function withListReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListReportsQuery,
  ListReportsQueryVariables,
  ListReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ListReportsQuery, ListReportsQueryVariables, ListReportsProps<TChildProps, TDataName>>(ListReportsDocument, {
      alias: 'listReports',
      ...operationOptions
    });
};
export type ListReportsQueryResult = ApolloReactCommon.QueryResult<ListReportsQuery, ListReportsQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($id: String!) {
  legacyReport(id: $id) {
    id
    name
    description
    definition
    longDescription
    icon
    recordSetName
  }
}
    `;
export type GetReportComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReportQuery, GetReportQueryVariables>, 'query'> & ({ variables: GetReportQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetReportComponent = (props: GetReportComponentProps) => (
      <ApolloReactComponents.Query<GetReportQuery, GetReportQueryVariables> query={GetReportDocument} {...props} />
    );
    
export type GetReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetReportQuery, GetReportQueryVariables>
    } & TChildProps;
export function withGetReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReportQuery,
  GetReportQueryVariables,
  GetReportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetReportQuery, GetReportQueryVariables, GetReportProps<TChildProps, TDataName>>(GetReportDocument, {
      alias: 'getReport',
      ...operationOptions
    });
};
export type GetReportQueryResult = ApolloReactCommon.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetRecordSetDocument = gql`
    query GetRecordSet($recordSetName: String!, $startDate: Int!, $endDate: Int!) {
  legacyReportRecordSet(recordSetName: $recordSetName, startDate: $startDate, endDate: $endDate) {
    records
  }
}
    `;
export type GetRecordSetComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetRecordSetQuery, GetRecordSetQueryVariables>, 'query'> & ({ variables: GetRecordSetQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetRecordSetComponent = (props: GetRecordSetComponentProps) => (
      <ApolloReactComponents.Query<GetRecordSetQuery, GetRecordSetQueryVariables> query={GetRecordSetDocument} {...props} />
    );
    
export type GetRecordSetProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetRecordSetQuery, GetRecordSetQueryVariables>
    } & TChildProps;
export function withGetRecordSet<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetRecordSetQuery,
  GetRecordSetQueryVariables,
  GetRecordSetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetRecordSetQuery, GetRecordSetQueryVariables, GetRecordSetProps<TChildProps, TDataName>>(GetRecordSetDocument, {
      alias: 'getRecordSet',
      ...operationOptions
    });
};
export type GetRecordSetQueryResult = ApolloReactCommon.QueryResult<GetRecordSetQuery, GetRecordSetQueryVariables>;
export const GetOverviewDocument = gql`
    query GetOverview($unitIds: [String!], $orgId: String) {
  legacyReportOverview(unitIds: $unitIds, orgId: $orgId) {
    unitCount
    netsaleAmount
    taxAmount
    grossAmount
    lastYearNetsaleAmount
    yoyNetsalePercent
    saleCount
    lastYearSaleCount
    yoySaleCountPercent
    voidAmount
    voidCount
    deleteAmount
    deleteCount
    cancelledOrderAmount
    cancelledOrderCount
    discountAmount
    discountCount
    clockedHours
    laborPercent
    salesPerManHour
    driveThruCount
    driveThruAverageTime
    detail {
      unitId
      unitName
      netsaleAmount
      taxAmount
      grossAmount
      lastYearNetsaleAmount
      yoyNetsalePercent
      saleCount
      lastYearSaleCount
      yoySaleCountPercent
      voidAmount
      voidCount
      deleteAmount
      deleteCount
      cancelledOrderAmount
      cancelledOrderCount
      discountAmount
      discountCount
      clockedHours
      laborPercent
      salesPerManHour
      driveThruCount
      driveThruAverageTime
      updatedAt
    }
  }
}
    `;
export type GetOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOverviewQuery, GetOverviewQueryVariables>, 'query'>;

    export const GetOverviewComponent = (props: GetOverviewComponentProps) => (
      <ApolloReactComponents.Query<GetOverviewQuery, GetOverviewQueryVariables> query={GetOverviewDocument} {...props} />
    );
    
export type GetOverviewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetOverviewQuery, GetOverviewQueryVariables>
    } & TChildProps;
export function withGetOverview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetOverviewQuery,
  GetOverviewQueryVariables,
  GetOverviewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetOverviewQuery, GetOverviewQueryVariables, GetOverviewProps<TChildProps, TDataName>>(GetOverviewDocument, {
      alias: 'getOverview',
      ...operationOptions
    });
};
export type GetOverviewQueryResult = ApolloReactCommon.QueryResult<GetOverviewQuery, GetOverviewQueryVariables>;
export const TeamsDocument = gql`
    query teams {
  teams {
    id
    name
    granted {
      target {
        id
        name
      }
    }
  }
}
    `;
export type TeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsQuery, TeamsQueryVariables>, 'query'>;

    export const TeamsComponent = (props: TeamsComponentProps) => (
      <ApolloReactComponents.Query<TeamsQuery, TeamsQueryVariables> query={TeamsDocument} {...props} />
    );
    
export type TeamsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamsQuery, TeamsQueryVariables>
    } & TChildProps;
export function withTeams<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamsQuery,
  TeamsQueryVariables,
  TeamsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamsQuery, TeamsQueryVariables, TeamsProps<TChildProps, TDataName>>(TeamsDocument, {
      alias: 'teams',
      ...operationOptions
    });
};
export type TeamsQueryResult = ApolloReactCommon.QueryResult<TeamsQuery, TeamsQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($name: String!, $parentId: String!, $parentType: ResourceType!) {
  createTeam(name: $name, parentId: $parentId, parentType: $parentType) {
    id
  }
}
    `;
export type CreateTeamMutationFn = ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTeamMutation, CreateTeamMutationVariables>, 'mutation'>;

    export const CreateTeamComponent = (props: CreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTeamMutation, CreateTeamMutationVariables> mutation={CreateTeamDocument} {...props} />
    );
    
export type CreateTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>
    } & TChildProps;
export function withCreateTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  CreateTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTeamMutation, CreateTeamMutationVariables, CreateTeamProps<TChildProps, TDataName>>(CreateTeamDocument, {
      alias: 'createTeam',
      ...operationOptions
    });
};
export type CreateTeamMutationResult = ApolloReactCommon.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation DeleteTeam($teamId: String!) {
  deleteGroup(id: $teamId) {
    id
  }
}
    `;
export type DeleteTeamMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;
export type DeleteTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTeamMutation, DeleteTeamMutationVariables>, 'mutation'>;

    export const DeleteTeamComponent = (props: DeleteTeamComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTeamMutation, DeleteTeamMutationVariables> mutation={DeleteTeamDocument} {...props} />
    );
    
export type DeleteTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>
    } & TChildProps;
export function withDeleteTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
  DeleteTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTeamMutation, DeleteTeamMutationVariables, DeleteTeamProps<TChildProps, TDataName>>(DeleteTeamDocument, {
      alias: 'deleteTeam',
      ...operationOptions
    });
};
export type DeleteTeamMutationResult = ApolloReactCommon.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const RenameTeamDocument = gql`
    mutation RenameTeam($id: String!, $name: String!) {
  renameTeam(id: $id, name: $name) {
    id
  }
}
    `;
export type RenameTeamMutationFn = ApolloReactCommon.MutationFunction<RenameTeamMutation, RenameTeamMutationVariables>;
export type RenameTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameTeamMutation, RenameTeamMutationVariables>, 'mutation'>;

    export const RenameTeamComponent = (props: RenameTeamComponentProps) => (
      <ApolloReactComponents.Mutation<RenameTeamMutation, RenameTeamMutationVariables> mutation={RenameTeamDocument} {...props} />
    );
    
export type RenameTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RenameTeamMutation, RenameTeamMutationVariables>
    } & TChildProps;
export function withRenameTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameTeamMutation,
  RenameTeamMutationVariables,
  RenameTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RenameTeamMutation, RenameTeamMutationVariables, RenameTeamProps<TChildProps, TDataName>>(RenameTeamDocument, {
      alias: 'renameTeam',
      ...operationOptions
    });
};
export type RenameTeamMutationResult = ApolloReactCommon.MutationResult<RenameTeamMutation>;
export type RenameTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameTeamMutation, RenameTeamMutationVariables>;
export const AddEmployeeToTeamDocument = gql`
    mutation AddEmployeeToTeam($employeeId: String!, $teamId: String!) {
  addTeamMember(employeeId: $employeeId, teamId: $teamId) {
    id
    name
  }
}
    `;
export type AddEmployeeToTeamMutationFn = ApolloReactCommon.MutationFunction<AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables>;
export type AddEmployeeToTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables>, 'mutation'>;

    export const AddEmployeeToTeamComponent = (props: AddEmployeeToTeamComponentProps) => (
      <ApolloReactComponents.Mutation<AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables> mutation={AddEmployeeToTeamDocument} {...props} />
    );
    
export type AddEmployeeToTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables>
    } & TChildProps;
export function withAddEmployeeToTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddEmployeeToTeamMutation,
  AddEmployeeToTeamMutationVariables,
  AddEmployeeToTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables, AddEmployeeToTeamProps<TChildProps, TDataName>>(AddEmployeeToTeamDocument, {
      alias: 'addEmployeeToTeam',
      ...operationOptions
    });
};
export type AddEmployeeToTeamMutationResult = ApolloReactCommon.MutationResult<AddEmployeeToTeamMutation>;
export type AddEmployeeToTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<AddEmployeeToTeamMutation, AddEmployeeToTeamMutationVariables>;
export const RemoveEmployeeFromTeamDocument = gql`
    mutation RemoveEmployeeFromTeam($employeeId: String!, $teamId: String!) {
  removeTeamMember(employeeId: $employeeId, teamId: $teamId) {
    id
    name
  }
}
    `;
export type RemoveEmployeeFromTeamMutationFn = ApolloReactCommon.MutationFunction<RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables>;
export type RemoveEmployeeFromTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables>, 'mutation'>;

    export const RemoveEmployeeFromTeamComponent = (props: RemoveEmployeeFromTeamComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables> mutation={RemoveEmployeeFromTeamDocument} {...props} />
    );
    
export type RemoveEmployeeFromTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables>
    } & TChildProps;
export function withRemoveEmployeeFromTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveEmployeeFromTeamMutation,
  RemoveEmployeeFromTeamMutationVariables,
  RemoveEmployeeFromTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables, RemoveEmployeeFromTeamProps<TChildProps, TDataName>>(RemoveEmployeeFromTeamDocument, {
      alias: 'removeEmployeeFromTeam',
      ...operationOptions
    });
};
export type RemoveEmployeeFromTeamMutationResult = ApolloReactCommon.MutationResult<RemoveEmployeeFromTeamMutation>;
export type RemoveEmployeeFromTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveEmployeeFromTeamMutation, RemoveEmployeeFromTeamMutationVariables>;
export const GrantTeamDocument = gql`
    mutation GrantTeam($teamId: String!, $targetType: ResourceType!, $targetId: String!, $policies: [String!]!) {
  grantTeam(teamId: $teamId, targetType: $targetType, targetId: $targetId, policies: $policies) {
    id
  }
}
    `;
export type GrantTeamMutationFn = ApolloReactCommon.MutationFunction<GrantTeamMutation, GrantTeamMutationVariables>;
export type GrantTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GrantTeamMutation, GrantTeamMutationVariables>, 'mutation'>;

    export const GrantTeamComponent = (props: GrantTeamComponentProps) => (
      <ApolloReactComponents.Mutation<GrantTeamMutation, GrantTeamMutationVariables> mutation={GrantTeamDocument} {...props} />
    );
    
export type GrantTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GrantTeamMutation, GrantTeamMutationVariables>
    } & TChildProps;
export function withGrantTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GrantTeamMutation,
  GrantTeamMutationVariables,
  GrantTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GrantTeamMutation, GrantTeamMutationVariables, GrantTeamProps<TChildProps, TDataName>>(GrantTeamDocument, {
      alias: 'grantTeam',
      ...operationOptions
    });
};
export type GrantTeamMutationResult = ApolloReactCommon.MutationResult<GrantTeamMutation>;
export type GrantTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<GrantTeamMutation, GrantTeamMutationVariables>;
export const RevokeTeamDocument = gql`
    mutation RevokeTeam($teamId: ID!, $targetType: ResourceType!, $targetId: ID!) {
  deleteGrant(source: {id: $teamId, type: group}, target: {id: $targetId, type: $targetType})
}
    `;
export type RevokeTeamMutationFn = ApolloReactCommon.MutationFunction<RevokeTeamMutation, RevokeTeamMutationVariables>;
export type RevokeTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeTeamMutation, RevokeTeamMutationVariables>, 'mutation'>;

    export const RevokeTeamComponent = (props: RevokeTeamComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeTeamMutation, RevokeTeamMutationVariables> mutation={RevokeTeamDocument} {...props} />
    );
    
export type RevokeTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeTeamMutation, RevokeTeamMutationVariables>
    } & TChildProps;
export function withRevokeTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeTeamMutation,
  RevokeTeamMutationVariables,
  RevokeTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeTeamMutation, RevokeTeamMutationVariables, RevokeTeamProps<TChildProps, TDataName>>(RevokeTeamDocument, {
      alias: 'revokeTeam',
      ...operationOptions
    });
};
export type RevokeTeamMutationResult = ApolloReactCommon.MutationResult<RevokeTeamMutation>;
export type RevokeTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeTeamMutation, RevokeTeamMutationVariables>;
export const TeamDetailsDocument = gql`
    query TeamDetails($id: String!) {
  team(id: $id) {
    id
    name
    grants {
      target {
        id
        type
        name
      }
      actions
      policies
    }
    granted {
      target {
        id
        name
      }
    }
  }
}
    `;
export type TeamDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamDetailsQuery, TeamDetailsQueryVariables>, 'query'> & ({ variables: TeamDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamDetailsComponent = (props: TeamDetailsComponentProps) => (
      <ApolloReactComponents.Query<TeamDetailsQuery, TeamDetailsQueryVariables> query={TeamDetailsDocument} {...props} />
    );
    
export type TeamDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamDetailsQuery, TeamDetailsQueryVariables>
    } & TChildProps;
export function withTeamDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamDetailsQuery,
  TeamDetailsQueryVariables,
  TeamDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamDetailsQuery, TeamDetailsQueryVariables, TeamDetailsProps<TChildProps, TDataName>>(TeamDetailsDocument, {
      alias: 'teamDetails',
      ...operationOptions
    });
};
export type TeamDetailsQueryResult = ApolloReactCommon.QueryResult<TeamDetailsQuery, TeamDetailsQueryVariables>;
export const ConceptTeamsDocument = gql`
    query ConceptTeams($id: String!) {
  concept(id: $id) {
    id
    teams {
      id
      name
      grants {
        target {
          id
          type
          name
          group {
            type
          }
        }
        actions
        policies
      }
      grantedEmployees {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type ConceptTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ConceptTeamsQuery, ConceptTeamsQueryVariables>, 'query'> & ({ variables: ConceptTeamsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ConceptTeamsComponent = (props: ConceptTeamsComponentProps) => (
      <ApolloReactComponents.Query<ConceptTeamsQuery, ConceptTeamsQueryVariables> query={ConceptTeamsDocument} {...props} />
    );
    
export type ConceptTeamsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ConceptTeamsQuery, ConceptTeamsQueryVariables>
    } & TChildProps;
export function withConceptTeams<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ConceptTeamsQuery,
  ConceptTeamsQueryVariables,
  ConceptTeamsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ConceptTeamsQuery, ConceptTeamsQueryVariables, ConceptTeamsProps<TChildProps, TDataName>>(ConceptTeamsDocument, {
      alias: 'conceptTeams',
      ...operationOptions
    });
};
export type ConceptTeamsQueryResult = ApolloReactCommon.QueryResult<ConceptTeamsQuery, ConceptTeamsQueryVariables>;
export const OrganizationTeamsDocument = gql`
    query OrganizationTeams($id: String!) {
  organization(id: $id) {
    id
    teams {
      id
      name
      grants {
        target {
          id
          type
          name
          group {
            type
          }
        }
        policies
        actions
      }
      grantedEmployees {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type OrganizationTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>, 'query'> & ({ variables: OrganizationTeamsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationTeamsComponent = (props: OrganizationTeamsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationTeamsQuery, OrganizationTeamsQueryVariables> query={OrganizationTeamsDocument} {...props} />
    );
    
export type OrganizationTeamsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>
    } & TChildProps;
export function withOrganizationTeams<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationTeamsQuery,
  OrganizationTeamsQueryVariables,
  OrganizationTeamsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationTeamsQuery, OrganizationTeamsQueryVariables, OrganizationTeamsProps<TChildProps, TDataName>>(OrganizationTeamsDocument, {
      alias: 'organizationTeams',
      ...operationOptions
    });
};
export type OrganizationTeamsQueryResult = ApolloReactCommon.QueryResult<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>;
export const TransferUnitDocument = gql`
    mutation transferUnit($unitId: String!, $organizationId: String!, $excludedResources: [InputResource!]) {
  transferUnit(unitId: $unitId, organizationId: $organizationId, excludedResources: $excludedResources) {
    id
  }
}
    `;
export type TransferUnitMutationFn = ApolloReactCommon.MutationFunction<TransferUnitMutation, TransferUnitMutationVariables>;
export type TransferUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferUnitMutation, TransferUnitMutationVariables>, 'mutation'>;

    export const TransferUnitComponent = (props: TransferUnitComponentProps) => (
      <ApolloReactComponents.Mutation<TransferUnitMutation, TransferUnitMutationVariables> mutation={TransferUnitDocument} {...props} />
    );
    
export type TransferUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TransferUnitMutation, TransferUnitMutationVariables>
    } & TChildProps;
export function withTransferUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TransferUnitMutation,
  TransferUnitMutationVariables,
  TransferUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TransferUnitMutation, TransferUnitMutationVariables, TransferUnitProps<TChildProps, TDataName>>(TransferUnitDocument, {
      alias: 'transferUnit',
      ...operationOptions
    });
};
export type TransferUnitMutationResult = ApolloReactCommon.MutationResult<TransferUnitMutation>;
export type TransferUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferUnitMutation, TransferUnitMutationVariables>;
export const UpdateConceptDocument = gql`
    mutation UpdateConcept($id: String!, $name: String!) {
  updateConcept(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateConceptMutationFn = ApolloReactCommon.MutationFunction<UpdateConceptMutation, UpdateConceptMutationVariables>;
export type UpdateConceptComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateConceptMutation, UpdateConceptMutationVariables>, 'mutation'>;

    export const UpdateConceptComponent = (props: UpdateConceptComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateConceptMutation, UpdateConceptMutationVariables> mutation={UpdateConceptDocument} {...props} />
    );
    
export type UpdateConceptProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateConceptMutation, UpdateConceptMutationVariables>
    } & TChildProps;
export function withUpdateConcept<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateConceptMutation,
  UpdateConceptMutationVariables,
  UpdateConceptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateConceptMutation, UpdateConceptMutationVariables, UpdateConceptProps<TChildProps, TDataName>>(UpdateConceptDocument, {
      alias: 'updateConcept',
      ...operationOptions
    });
};
export type UpdateConceptMutationResult = ApolloReactCommon.MutationResult<UpdateConceptMutation>;
export type UpdateConceptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConceptMutation, UpdateConceptMutationVariables>;
export const UpdateLegacyInventoryLocationDocument = gql`
    mutation UpdateLegacyInventoryLocation($id: String!, $unitId: String!, $name: String!, $state: LegacyInventoryLocationState!) {
  updateLegacyInventoryLocation(id: $id, unitId: $unitId, name: $name, state: $state) {
    name
    state
  }
}
    `;
export type UpdateLegacyInventoryLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables>;
export type UpdateLegacyInventoryLocationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables>, 'mutation'>;

    export const UpdateLegacyInventoryLocationComponent = (props: UpdateLegacyInventoryLocationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables> mutation={UpdateLegacyInventoryLocationDocument} {...props} />
    );
    
export type UpdateLegacyInventoryLocationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables>
    } & TChildProps;
export function withUpdateLegacyInventoryLocation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLegacyInventoryLocationMutation,
  UpdateLegacyInventoryLocationMutationVariables,
  UpdateLegacyInventoryLocationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables, UpdateLegacyInventoryLocationProps<TChildProps, TDataName>>(UpdateLegacyInventoryLocationDocument, {
      alias: 'updateLegacyInventoryLocation',
      ...operationOptions
    });
};
export type UpdateLegacyInventoryLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLegacyInventoryLocationMutation>;
export type UpdateLegacyInventoryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLegacyInventoryLocationMutation, UpdateLegacyInventoryLocationMutationVariables>;
export const UpdateLegacyInventoryLocationItemsDocument = gql`
    mutation UpdateLegacyInventoryLocationItems($id: String!, $unitId: String!, $items: [LegacyInventoryLocationItemCreateInput!]) {
  updateLegacyInventoryLocationItems(id: $id, unitId: $unitId, items: $items) {
    id
    unitId
    name
    state
    items {
      locationId
      itemId
      sortIndex
    }
  }
}
    `;
export type UpdateLegacyInventoryLocationItemsMutationFn = ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables>;
export type UpdateLegacyInventoryLocationItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables>, 'mutation'>;

    export const UpdateLegacyInventoryLocationItemsComponent = (props: UpdateLegacyInventoryLocationItemsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables> mutation={UpdateLegacyInventoryLocationItemsDocument} {...props} />
    );
    
export type UpdateLegacyInventoryLocationItemsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables>
    } & TChildProps;
export function withUpdateLegacyInventoryLocationItems<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLegacyInventoryLocationItemsMutation,
  UpdateLegacyInventoryLocationItemsMutationVariables,
  UpdateLegacyInventoryLocationItemsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables, UpdateLegacyInventoryLocationItemsProps<TChildProps, TDataName>>(UpdateLegacyInventoryLocationItemsDocument, {
      alias: 'updateLegacyInventoryLocationItems',
      ...operationOptions
    });
};
export type UpdateLegacyInventoryLocationItemsMutationResult = ApolloReactCommon.MutationResult<UpdateLegacyInventoryLocationItemsMutation>;
export type UpdateLegacyInventoryLocationItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLegacyInventoryLocationItemsMutation, UpdateLegacyInventoryLocationItemsMutationVariables>;
export const UpdateLegacyInventoryLocationManageItemDocument = gql`
    mutation UpdateLegacyInventoryLocationManageItem($locationId: String!, $unitId: String!, $id: String!, $sortIndex: Int!, $state: EnabledDisabledState!) {
  updateLegacyInventoryLocationManageItem(locationId: $locationId, unitId: $unitId, id: $id, sortIndex: $sortIndex, state: $state) {
    locationId
    unitId
    id
    sortIndex
    state
  }
}
    `;
export type UpdateLegacyInventoryLocationManageItemMutationFn = ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables>;
export type UpdateLegacyInventoryLocationManageItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables>, 'mutation'>;

    export const UpdateLegacyInventoryLocationManageItemComponent = (props: UpdateLegacyInventoryLocationManageItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables> mutation={UpdateLegacyInventoryLocationManageItemDocument} {...props} />
    );
    
export type UpdateLegacyInventoryLocationManageItemProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables>
    } & TChildProps;
export function withUpdateLegacyInventoryLocationManageItem<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLegacyInventoryLocationManageItemMutation,
  UpdateLegacyInventoryLocationManageItemMutationVariables,
  UpdateLegacyInventoryLocationManageItemProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables, UpdateLegacyInventoryLocationManageItemProps<TChildProps, TDataName>>(UpdateLegacyInventoryLocationManageItemDocument, {
      alias: 'updateLegacyInventoryLocationManageItem',
      ...operationOptions
    });
};
export type UpdateLegacyInventoryLocationManageItemMutationResult = ApolloReactCommon.MutationResult<UpdateLegacyInventoryLocationManageItemMutation>;
export type UpdateLegacyInventoryLocationManageItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLegacyInventoryLocationManageItemMutation, UpdateLegacyInventoryLocationManageItemMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($id: String!, $name: String!, $phone: String!, $street1: String!, $street2: String, $city: String!, $state: String!, $zip: String!, $country: String!) {
  updateOrganization(id: $id, name: $name, phone: $phone, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, country: $country) {
    id
    name
    phone
    street1
    street2
    city
    state
    zip
    country
  }
}
    `;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export type UpdateOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>, 'mutation'>;

    export const UpdateOrganizationComponent = (props: UpdateOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> mutation={UpdateOrganizationDocument} {...props} />
    );
    
export type UpdateOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
    } & TChildProps;
export function withUpdateOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
  UpdateOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationMutation, UpdateOrganizationMutationVariables, UpdateOrganizationProps<TChildProps, TDataName>>(UpdateOrganizationDocument, {
      alias: 'updateOrganization',
      ...operationOptions
    });
};
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($id: String!, $name: String!, $identifier: String!, $phone: String!, $email: String, $street1: String!, $street2: String!, $city: String!, $state: String!, $zip: String!, $country: String!, $attributes: [UnitCustomAttributeInput!]) {
  updateUnit(id: $id, name: $name, identifier: $identifier, phone: $phone, email: $email, street1: $street1, street2: $street2, city: $city, state: $state, zip: $zip, country: $country, customAttributes: $attributes) {
    id
    name
    identifier
    phone
    email
    street1
    street2
    city
    state
    zip
    country
    updatedAt
    createdAt
    organization {
      name
    }
    concepts {
      name
    }
    customAttributes {
      key
      value
    }
  }
}
    `;
export type UpdateUnitMutationFn = ApolloReactCommon.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;
export type UpdateUnitComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUnitMutation, UpdateUnitMutationVariables>, 'mutation'>;

    export const UpdateUnitComponent = (props: UpdateUnitComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUnitMutation, UpdateUnitMutationVariables> mutation={UpdateUnitDocument} {...props} />
    );
    
export type UpdateUnitProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>
    } & TChildProps;
export function withUpdateUnit<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUnitMutation,
  UpdateUnitMutationVariables,
  UpdateUnitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUnitMutation, UpdateUnitMutationVariables, UpdateUnitProps<TChildProps, TDataName>>(UpdateUnitDocument, {
      alias: 'updateUnit',
      ...operationOptions
    });
};
export type UpdateUnitMutationResult = ApolloReactCommon.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>;

      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
      export default result;
    