import { debounce, omit } from "lodash";
import React from "react";
import { FormInputProps, Icon, Input } from "semantic-ui-react";

interface IProps extends FormInputProps {
  value?: string;
  onNotify?: (v: string) => void;
  delay?: number;
  loading?: boolean;
  complete?: boolean;
}

let flush = (v: string) => { };

const DelayedInput = (props: IProps) => {
  const [valueBuffer, setValueBuffer] = React.useState(props.value || "");
  const [buffering, setBuffering] = React.useState(false);

  React.useEffect(() => {
    flush = debounce((v: string) => {
      if (props.onNotify) {
        props.onNotify(v);
        setBuffering(false);
      }
    }, (props.delay || 500), { trailing: true, leading: false });
  }, []);

  React.useEffect(() => {
    if (valueBuffer !== props.value) {
      flush(valueBuffer);
    }
  }, [valueBuffer]);

  React.useEffect(() => {
    if (props.value) {
      setValueBuffer(props.value);
    } else {
      setValueBuffer("");
    }
  }, [props.value]);

  const isLoading = props.loading || buffering;

  const loadingIcon = <Icon loading name="circle notch" color="yellow" />;
  const completeIcon = <Icon name="check circle" color="green" />;

  return (
    <Input
      {...omit(props, "onNotify")}
      icon={(isLoading && loadingIcon) || (props.complete && completeIcon) || props.icon}
      value={valueBuffer}
      onChange={(e, d) => {
        setBuffering(true);
        setValueBuffer(e.target.value);
        if (!!props.onChange) { props.onChange(e, d); }
      }} />
  );
};

export default DelayedInput;
