import _ from "lodash";
import moment from "moment";
import React, { lazy, Suspense } from "react";
import { Dimmer, Divider, Loader, Message, Segment } from "semantic-ui-react";

import { GetRecordSetComponent } from "../generated/graphql";
import { ViewType } from "../QSFM/QSFM";
import { QSFA } from "../Shared";
import ReportMenu from "./ReportMenu";

const QSFM = lazy(() => import("../QSFM/QSFM"));

export interface IDefinition {
  unity: any;
  dataSource: any;
  slice: any;
  options: any;
}

export interface ISettings {
  startDate: moment.Moment;
  endDate: moment.Moment;
  dateCompMethod: "dayvsday" | "datevsdate";
  includeZeroSales: boolean;
}

interface IProps {
  definition: IDefinition;
  mobile?: boolean;
  reportName: string;
  reportIcon: string;
  longDescription: string;
  recordSetName: string;
}

export default (props: IProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [viewOptions, setViewOptions] = React.useState([{ icon: "table", text: "grid", value: "grid" }]);
  const [view, setView] = React.useState<ViewType>("grid");
  const [settings, setSettings] = React.useState<ISettings>({
    startDate: moment().startOf("day"),
    endDate: moment().startOf("day"),
    dateCompMethod: "dayvsday",
    includeZeroSales: false,
  });
  const [definition, setDefinition] = React.useState<IDefinition | null>(null);
  const fmRef = React.useRef(null);

  const charts = [
    "bar",
    "bar_h",
    "line",
    "scatter",
    "pie",
    "bar_stack",
    "bar_line",
  ];

  React.useEffect(() => {
    if (props.definition.unity.reportOptions) {
      const opts = props.definition.unity.reportOptions;
      let start = null;
      let end = null;
      if (opts.defaultStartDateOffset !== undefined) {
        start = moment().subtract(opts.defaultStartDateOffset, "days").startOf("day");
      }
      if (opts.defaultEndDateOffset !== undefined) {
        end = moment().subtract(opts.defaultEndDateOffset, "days").startOf("day");
      }
      if (start) {
        if ((end && end < start) || !end) {
          end = start;
        }
      }
    }

    if (props.definition.unity.chartOptions) {
      const views = [{ icon: "table", text: "grid", value: "grid" }];
      props.definition.unity.chartOptions.forEach((c) => {
        if (charts.includes(c.value)) {
          views.push(c);
        }
      });
      setViewOptions(views);
    }

    setDefinition({
      ...props.definition,
      options: { configuratorButton: false, ...props.definition.options },
      slice: {
        ...props.definition.slice,
        measures: getFilteredMeasures(props.definition.slice, settings.dateCompMethod, settings.includeZeroSales),
      },
    });
  }, [props.definition]);

  React.useEffect(() => {
    expanded ? fmRef?.current?.expand() : fmRef?.current?.collapse();
  }, [expanded]);

  React.useEffect(() => {
    fmRef?.current?.changeView(view);
  }, [view]);

  // getSlice takes an existing slice and removes any reportFilters, rows,
  // columns or measures that have a reportOptions object that does not contain
  // the current dateComp method and includeZeroSales selection. If the
  // reportOptions object is not there it is assumed to always display the
  // object.
  const getFilteredMeasures = (s: any, dateCompMethod: "dayvsday" | "datevsdate" = "dayvsday", includeZeroSales: boolean) => {
    return s.measures.filter((m) => {
      if (!m.reportOptions) {
        return true;
      } else {
        let includeDate = false;
        if ("dateComp" in m.reportOptions) {
          if (m.reportOptions.dateComp.includes(dateCompMethod)) {
            includeDate = true;
          } else {
            includeDate = false;
          }
        } else {
          includeDate = true;
        }
        let includeSales = false;
        if ("includeZeroSales" in m.reportOptions) {
          if (m.reportOptions.includeZeroSales === includeZeroSales) {
            includeSales = true;
          } else {
            includeSales = false;
          }
        } else {
          includeSales = true;
        }
        return includeSales && includeDate;
      }
    });
  };

  const getExportFilename = () => {
    const name = props.reportName;
    const start = settings.startDate.format("YYYYMMDD");
    const end = settings.endDate.format("YYYYMMDD");
    return `${name}-${start}-${end}`;
  };

  return (
    <div style={{ height: "98%", overflow: "hidden", padding: props.mobile ? "0" : "1em" }}>
      <ReportMenu
        mobile={props.mobile}
        startDate={settings.startDate}
        endDate={settings.endDate}
        reportName={props.reportName}
        reportIcon={props.reportIcon}
        expanded={expanded}
        expandReport={(e) => {
          setExpanded(e);
        }}
        longDescription={props.longDescription}
        definition={props.definition}
        view={view}
        viewOptions={viewOptions}
        handleViewChange={(v) => {
          setView(v);
        }}
        handleExport={(e) => {
          fmRef?.current?.export(e, getExportFilename());
        }}
        applyConfig={(cfg) => {
          if (cfg.startDate !== settings.startDate || cfg.endDate !== settings.endDate) {
            setSettings(cfg);
          }
          if (cfg.dateCompMethod !== settings.dateCompMethod || cfg.includeZeroSales !== settings.includeZeroSales) {
            setSettings(cfg);
            const d = {
              ...props.definition,
              options: { configuratorButton: false },
              slice: {
                ...props.definition.slice,
                measures: getFilteredMeasures(props.definition.slice, cfg.dateCompMethod, cfg.includeZeroSales),
              },
            };
            setDefinition(d);
          }
        }}
      />
      <Divider fitted hidden />
      <GetRecordSetComponent variables={
        {
          recordSetName: props.recordSetName,
          startDate: moment.utc(settings.startDate).startOf("day").unix(),
          endDate: moment.utc(settings.endDate).startOf("day").unix(),
        }
      }>
        {({ data, loading }) => {
          let d = null;
          try {
            d = JSON.parse(data?.legacyReportRecordSet?.records);
          } catch (ex) {
            console.log(ex);
          }
          if (d) {
            if (definition?.unity && definition?.unity?.types) {
              d.unshift(definition.unity.types);
            }
          }
          return (
            <React.Fragment>
              <Dimmer active={loading}>
                <Loader content="One moment while we generate your report" />
              </Dimmer>
              {!d ? !loading &&
                <Segment basic>
                  <Message
                    size="huge"
                    warning
                    icon={<QSFA icon="info-circle" size="big" />}
                    header="No data for this date"
                    content={`
                      It appears we do not have any data for the selected date.
                      Use the date selector above to select a new date.
                    ` }
                  />
                </Segment> :
                <Suspense fallback={
                  <Dimmer active>
                    <Loader content="One moment while we generate your report" />
                  </Dimmer>
                }>
                  <QSFM
                    ref={fmRef}
                    data={{ data: d }}
                    definition={definition}
                    height="calc(98% - 50px)"
                  />
                </Suspense>
              }
            </React.Fragment>
          );
        }}
      </GetRecordSetComponent>
    </div>
  );
};
