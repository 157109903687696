import { Select } from "formsy-semantic-ui-react";
import React from "react";

import { actions, UnityAction } from "@quikserve/unityactions";
import { ListOrganizationsComponent, SortDirection } from "../generated/graphql";
import SearchQuery, {IOrganization} from "../Search/SearchQuery";
import { ErrorLabel } from "../Shared";

interface IProps {
  value?: string;
  onChange?: (v: any) => void;
  required?: boolean;
  action?: UnityAction;
}

const OrgSelect = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  let timeout = null;

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <ListOrganizationsComponent variables={{action: props.action, sort: [{field: "name", direction: SortDirection.Asc}]}}>
      {({data, loading}) => {
        const lorgOptions = data?.organizations?.nodes.map((org) => {
          return {
            key: org.id,
            text: org.name,
            value: org.id,
          };
        });
        if (data?.organizations?.nodes.length === 1 && !props.value) {
          props.onChange(data.organizations.nodes[0].id);
          setDisabled(true);
        }
        return (
          <SearchQuery namespaces={[{ namespace: "organizations", action: props.action || actions.organizations.read }]} filter={filter}>
            {({ data, loading }) => {
              const orgOptions = data && data.hits.map((h) => {
                const org = h.source as IOrganization;
                return {
                  key: org.id,
                  text: org.name,
                  value: org.id,
                };
              });
              return (
                <Select
                  fluid
                  loading={loading}
                  disabled={disabled}
                  name="organizationId"
                  label="Organization"
                  value={props.value}
                  options={filter ? orgOptions : lorgOptions ? lorgOptions : []}
                  onChange={(e, v) => {
                    props.onChange && props.onChange(v.value)
                  }}
                  onSearchChange={(e, {searchQuery}) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      searchQuery === "" ? setFilter(null) : setFilter({
                        multi_match: {
                          fields: [
                            "name^5",
                            "name.ngram",
                          ],
                          query: searchQuery,
                          type: "phrase_prefix",
                        },
                      });
                    }, 500);
                  }}
                  placeholder="Begin typing to search for an Organization"
                  selection
                  search
                  required={props.required}
                  validationErrors={{
                    isDefaultRequiredValue: "Organization is Required",
                  }}
                  errorLabel={ErrorLabel}
                />
              );
            }}
          </SearchQuery>
        );
      }}
    </ListOrganizationsComponent>
  );
};

export default OrgSelect;
