import React from "react";
import { Message } from "semantic-ui-react";
import { QSFA } from "./QSFA";

interface IProps {
  errors?: Array<{
    message: string;
  }>;
}

export default (props: IProps) => {

  return (
    <Message error content={<span><QSFA icon="exclamation-triangle" />{props.errors && props.errors.map((e) => e.message)}</span>} />
  );
};
