import React from "react";

interface IProps {
  value: number;
}

export const FormattedCurrency = (props: IProps) => {
  const { value } = props;
  const formattedCurrency = () => {
    return `$${(value / 100).toFixed(2)}`;
  };

  return (<React.Fragment>{formattedCurrency()}</React.Fragment>);
};
