import { get } from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Header,
  Segment,
  Table,
} from "semantic-ui-react";

import { actions } from "@quikserve/unityactions";
import { ConceptComponent, ResourceType } from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import { FormattedDate, QSFA } from "../Shared";

interface IParams {
  id: string;
}

interface IProps extends RouteComponentProps<IParams> {
  id: string;
}

const ConceptDetail = (props: IProps) => {

  return (
    <ConceptComponent variables={{ id: props.match.params.id }}>
      {({ loading, data }) => (
        <React.Fragment>
          <Segment basic vertical loading={loading}>
            <Restrict action={actions.concepts.write} type={ResourceType.Concept} id={props.match.params.id}>
              <Button primary onClick={() => props.history.push(`${props.match.url}/edit`)}>
                <QSFA icon="edit" />Edit Details
              </Button>
            </Restrict>
            <Header as="h3">
              <QSFA icon={getIcon(ResourceType.Concept)} size="small" />Details
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={4}>Name</Table.Cell>
                  <Table.Cell>
                    {data?.concept?.name}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {data?.concept && (
              <React.Fragment>
                <Header as="h3">
                  <QSFA icon="history" size="small" />History
                </Header>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="4">Account created</Table.Cell>
                      <Table.Cell>
                        <FormattedDate date={get(data, "concept.createdAt")} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width="4">Account last updated</Table.Cell>
                      <Table.Cell>
                        <FormattedDate date={get(data, "concept.updatedAt")} />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </React.Fragment>
            )}
          </Segment>
        </React.Fragment>
      )}
    </ConceptComponent>
  );
};

export default withRouter(ConceptDetail);
