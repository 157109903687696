import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, withRouter } from "react-router-dom";

import { AppContextParams, withAppContext } from "../Context";
import ConceptAdd from "./ConceptAdd";
import ConceptEdit from "./ConceptEdit";
import ConceptList from "./ConceptList";
import ViewConcept from "./ViewConcept";

type IProps = AppContextParams;

export const Concept = (props: IProps) => {

  React.useEffect(() => {
    props.appContext.setCurrentModule("Concepts");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Concepts</title>
      </Helmet>
      <Switch>
        <Route exact path="/config/concepts" component={ConceptList} />
        <Route exact path="/config/concepts/add" component={ConceptAdd} />
        <Route exact path="/config/concepts/:id/edit" component={ConceptEdit} />
        <Route path="/config/concepts/:id" component={ViewConcept} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(withAppContext(Concept));
