import React from "react";
import { Button, Form, Input, Modal, ModalContent } from "semantic-ui-react";
import { CreateGroupComponent, CreateGroupMutationFn, ResourceType } from "../generated/graphql";

interface IProps {
  parentType: ResourceType;
  parentId: string;
  onAdd: () => void;
}

export default (props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const groupNameRef = React.useRef<Input>();
  React.useEffect(() => {
    if (groupNameRef.current) {
      groupNameRef.current.focus();
    }
  }, [groupNameRef.current, open]);

  const handleSubmit = (mutation: CreateGroupMutationFn) => {
    return mutation({
      variables: {
        name,
        parentId: props.parentId,
        parentType: props.parentType,
      },
    }).then(() => {
      setOpen(false);
      setName("");
      props.onAdd();
    });
  }

  return (
    <React.Fragment>
      <Button
        primary
        icon="edit"
        onClick={() => setOpen(true)}
        content="Add Group" />
      <Modal open={open} onClose={() => setOpen(false)} siz="tiny" closeOnDimmerClick={false} closeIcon>
        <CreateGroupComponent>
          {(query, { loading }) => (
            <React.Fragment>

              <Modal.Header content="Add Group" />
              <Modal.Content>
                <Form onSubmit={() => handleSubmit(query)}>
                  <Form.Field>
                    <label>Name</label>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      ref={(ref) => groupNameRef.current = ref} />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button.Group>
                  <Button primary content="Create Group" loading={loading} onClick={() => handleSubmit(query)} />
                  <Button content="Cancel" onClick={() => setOpen(false)} />
                </Button.Group>
              </Modal.Actions>
            </React.Fragment>
          )}
        </CreateGroupComponent>
      </Modal>
    </React.Fragment>
  );
};
