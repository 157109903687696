import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";
import { OrganizationTeamsComponent, ResourceType } from "../generated/graphql";
import { QSFA } from "../Shared";
import { NewTeamModal, TeamList } from "../Team";

interface IProps extends RouteComponentProps<{ id: string }> { }

export default withRouter((props) => {
  const [newTeamModal, setNewTeamModal] = React.useState(false);

  return (
    <React.Fragment>
      {/* ToDo: update button */}
      <Button
        primary
        content="Add Team"
        icon={<QSFA icon="edit" />}
        onClick={() => setNewTeamModal(true)}
      />

      {/* ToDo: update disable/enable button, deletedAt is not available yet */}
      {/* <Button content="Disable Organization" icon="ban" onClick={() => setNewTeamModal(true)} /> */}

      <Header as="h3">
        <QSFA icon="project-diagram" size="small" />
        Team Access / Permissions
      </Header>
      <OrganizationTeamsComponent
        variables={{ id: props.match.params.id }}
        fetchPolicy="network-only"
      >
        {({ data, loading, refetch }) => (
          <React.Fragment>
            <TeamList
              loading={loading}
              teams={data?.organization?.teams}
              onUpdate={refetch}
            />
            <NewTeamModal
              parentId={props.match.params.id}
              parentType={ResourceType.Organization}
              open={newTeamModal}
              onUpdate={refetch}
              onClose={() => setNewTeamModal(false)}
            />
          </React.Fragment>
        )}
      </OrganizationTeamsComponent>
    </React.Fragment>
  );
});
