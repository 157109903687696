import { UnityAction } from "@quikserve/unityactions";
import React, { useRef } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Divider, Grid, Header, Input, Table } from "semantic-ui-react";
import { ResourceType } from "../generated/graphql";
import { getIcon } from "../Resources";
import SearchQuery, { IHit, IOrganization, IUnit, ResultTypeName } from "../Search/SearchQuery";
import { QSFA } from "../Shared";
import DelayedInput from "../Shared/DelayedInput";

export interface ISelectedSearchOption {
  type: string;
  id: string;
  name: string;
  identifier?: string;
  icon: string;
}

interface IProps extends RouteComponentProps {
  selectedSearchOption: ISelectedSearchOption;
  searchQueryNamespace?: Array<{
    namespace: ResultTypeName;
    action?: UnityAction;
    includeParentOfType?: ResourceType;
    mapParentOfType?: ResourceType;
  }>;
  onSelect?: (selectedSearchOption: ISelectedSearchOption | null) => void;
}

const Search = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (props.selectedSearchOption) {
      setValue(props.selectedSearchOption.name);
    } else {
      setValue("");
    }
  }, [props.selectedSearchOption]);

  const handleSearchNav = (selectedSearchOption: ISelectedSearchOption) => () => {
    setFilter(null);
    // for Operations only (UnitContainer)
    if (props?.onSelect) {
      props.onSelect(selectedSearchOption);
    }
  };

  const clearSearchOption = () => {
    setFilter(null);
    // for Operations only (UnitContainer)
    if (props?.onSelect) {
      props.onSelect(null);
    }
  };

  const getResource = (r: IHit, i: number) => {
    let name, sub, icon, onClick, id, type, selectedSearchOption;
    switch (r.index) {
      case "units":
        const unit = r.source as IUnit;
        id = unit.id;
        name = unit.name;
        sub = unit.identifier;
        icon = ResourceType.Unit;
        break;
      case "organizations":
        const org = r.source as IOrganization;
        id = org.id;
        name = org.name;
        icon = ResourceType.Organization;
        break;
    }
    type = r.index;
    selectedSearchOption = { type, id, name, identifier: sub, icon };
    onClick = handleSearchNav(selectedSearchOption);

    return (
      <Table.Row key={r.key + i.toString()} style={{ cursor: "pointer" }}>
        <Table.Cell width={2} onClick={onClick} textAlign="left">
          <QSFA icon={getIcon(icon)} size="xsmall" className="resource-icon" />
        </Table.Cell>
        <Table.Cell width={10} onClick={onClick}>
          <Header as="h5" content={name} subheader={sub} />
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <div className="select-wrap">
        <div className={`nav-search ${props?.selectedSearchOption?.name ? "selection" : ""}`}>
          <SearchQuery
            namespaces={props.searchQueryNamespace}
            filter={filter}
          >
            {({ data, loading }) => (
              <>
                <DelayedInput
                  fluid
                  transparent
                  value={value || ""}
                  icon={<QSFA icon="filter" className="left-icon" size="xsmall" />}
                  iconPosition="left"
                  placeholder="Type to filter data..."
                  loading={loading}
                  onNotify={(v) => {
                    v === "" ? setFilter(null) : setFilter({
                      multi_match: {
                        fields: [
                          "name^5",
                          "name.ngram^6",
                          "identifier^5",
                          "identifier.ngram",
                          "organizationName^4",
                          "organizationName.ngram",
                        ], query: v, type: "phrase_prefix",
                      },
                    });
                  }}
                />
                {props?.selectedSearchOption?.name &&
                  <>
                    <QSFA icon={"thumbtack"} size="xsmall" className="pinned-tack" />
                    {/* // ToDo: Operations only - hide the clear button if user has only one unit. Keep elsewhere.  */}
                    <QSFA icon="times" className="clear-button" onClick={ clearSearchOption } />
                  </>
                }
                {filter &&
                  data?.hits?.length > 0 ? (
                    <>
                      <Divider fitted />
                      <div className="scroll-container">
                        <Table basic="very" selectable>
                          <Table.Body>
                            {data.hits.map((h, i) => (getResource(h, i)))}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (filter?.multi_match?.query?.length > 0 && !loading) &&
                  <Grid className="no-results" textAlign="left" >
                    <Grid.Row basic="very">
                      <Header as="h4"
                        subheader="No results found." />
                    </Grid.Row>
                  </Grid>
                }
              </>
            )}
          </SearchQuery>
        </div>
      </div>
    </>
  );
};

export default withRouter(Search);
