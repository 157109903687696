import { createFactory, createRepository, mockId } from "./util";
import {
  Team, EmployeeStatusEnum, ResourceType
} from "../generated/graphql";
import { actions } from "@quikserve/unityactions";

const teamFactory = createFactory<Team>((): any => {
  return {
    __typename: "Team",
    name: "All Staff",
    employees: [
      {
        __typename: "Employee",
        id: "1",
        firstName: "Jim",
        lastName: "bob",
        email: "",
        status: EmployeeStatusEnum.Active,
        phone: "",
        updatedAt: new Date().getTime(),
        createdAt: new Date().getTime(),
        relationships: [],
        privileges: []
      }
    ] as any,
    grants: [
      {
        __typename: "Grant",
        actions: [actions.units.read],
        policies: ["Store Manager"],
        source: {
          __typename: "Resource",
          id: "2",
          type: ResourceType.Group,
          name: "1102",
          parents: []
        },
        target: {
          __typename: "Resource",
          id: "1",
          type: ResourceType.Unit,
          name: "1101",
          parents: [],
        },
      }
    ],
    granted: [],
  }
});

const teamRepo = createRepository<Team>(teamFactory, 5);

export const team = (): Team => {
  return teamFactory({});
};

export const teams = (): Team[] => {
  return [teamFactory({})]
};

export const conceptTeam = () => {
  return {
    __typename: "Concept",
    id: "1",
    teams: [teamFactory({})],
  }
}
