/*

Handles intercepting and injecting search results into the SearchQuery component.
This is used for mock data in local environments.

Use register interceptor to register a function that will be called during
queries. This function receives the search string and returns a list of results.
These results are merged with the live results.

Trivial example:

const mockConcepts = [{
  id: "13",
  name: "tesT"
}]

if (process.env.NODE_ENV === "development") {
  registerInterceptor("concepts", (s: string) => {
    return mockConcpets.filter(i => i.name.indexOf(s) > -1)
  });
}

*/

import { IHit, ResultType, ResultTypeName } from "./SearchQuery";

type InterceptHandler = (search: string) => ResultType[];
interface ISearchInterceptor {
  resultType: ResultTypeName;
  handler: InterceptHandler;
}

export const searchInterceptors: ISearchInterceptor[] = [];

export const registerInterceptor = (resultType: ResultTypeName, handler: InterceptHandler) => {
  searchInterceptors.push({ resultType, handler });
};

export const getInterceptors = (name: ResultTypeName) =>
  searchInterceptors.filter((i) => i.resultType === name);

export function processInterceptors(search: string, name?: ResultTypeName): IHit[] {
  return (name ? getInterceptors(name) : searchInterceptors).reduce<IHit[]>((acc, i) => {
    return [
      ...acc,
      ...i.handler(search).map((r) => ({
        id: r.id,
        index: i.resultType,
        key: r.id,
        score: 1,
        source: r,
        type: i.resultType,
      })),
    ];
  }, []);
}
