import moment from "moment";
// import { isSameDay } from 'react-date-range';

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    const startDate = moment(range.startDate);
    const endDate = moment(range.endDate);
    return (
      startDate.diff(definedRange.startDate, "days") === 0 &&
      endDate.diff(definedRange.endDate, "days") === 0
    );
    // return (
    //   isSameDay(range.startDate, definedRange.startDate.toDate()) &&
    //   isSameDay(range.endDate, definedRange.endDate.toDate())
    // );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const staticRanges = createStaticRanges([
  {
    label: "Yesterday",
    range: () => ({
      startDate: moment()
        .subtract(1, "days")
        .startOf("day"),
      endDate: moment()
        .subtract(1, "days")
        .startOf("day"),
    }),
  },
  {
    label: "Last 7 Days",
    range: () => ({
      startDate: moment()
        .subtract(7, "days")
        .startOf("day"),
      endDate: moment()
        .subtract(1, "days")
        .startOf("day"),
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: moment()
        .subtract(1, "weeks")
        .startOf("week"),
      endDate: moment()
        .subtract(1, "weeks")
        .endOf("week"),
    }),
  },
  {
    label: "Last 30 Days",
    range: () => ({
      startDate: moment()
        .subtract(30, "days")
        .startOf("day"),
      endDate: moment()
        .subtract(1, "days")
        .startOf("day"),
    }),
  },
  {
    label: "This Month",
    range: () => ({
      startDate: moment()
        .subtract(1, "days")
        .startOf("month"),
      endDate: moment()
        .subtract(1, "days")
        .startOf("day"),
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: moment()
        .subtract(1, "months")
        .startOf("month"),
      endDate: moment()
        .subtract(1, "months")
        .endOf("month"),
    }),
  },
]);

export const inputRanges = [
  {
    label: "days up to yesterday",
    range(value) {
      return {
        startDate: moment()
          .subtract(value, "days")
          .startOf("day")
          .toDate(),
        endDate: moment()
          .subtract(1, "days")
          .startOf("day")
          .toDate(),
      };
    },
    getCurrentValue(range) {
      const start = moment(range.startDate);
      const end = moment(range.endDate);
      const ye = moment()
        .subtract(1, "days")
        .startOf("day")
        .toDate();
      if (end.toDate().toString() !== ye.toString()) {
        return "-";
      }
      if (!range.startDate) {
        return "∞";
      }
      return end.diff(start, "days") + 1;
    },
  },
];
