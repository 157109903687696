import React, { useRef } from "react";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Header,
  Loader,
  Sticky,
  Table,
} from "semantic-ui-react";
import titleize from "titleize";

import { actions } from "@quikserve/unityactions";
import { ConceptsComponent, ResourceType, SortDirection } from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import SearchQuery, { IConcept } from "../Search/SearchQuery";
import { FlexGrid, LoadMore, QSFA } from "../Shared";
import DelayedInput from "../Shared/DelayedInput";

type ColumnNames = "name" | "id" | null;

type IProps = RouteComponentProps;

export default (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  // const [statusFilter, setStatusFilter] = React.useState<"active" | "disabled" | null>("active");
  const conceptNav = (s: string) => () =>
    props.history.push(`/config/concepts/${s}`);
  const contextRef = useRef(null);
  const timeout = null;
  const [column, setColumn] = React.useState<ColumnNames>("name");
  const [direction, setDirection] = React.useState<"ascending" | "descending">("ascending");

  const [sortValue, setSortValue] = React.useState([{ field: "name", direction: SortDirection.Asc }]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleSort = (clickedColumn: ColumnNames) => {
    setFilter(null);

    if (column !== clickedColumn) {
      const directionVal = SortDirection.Asc;
      setSortValue([{ field: clickedColumn, direction: directionVal }]);
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
    const directionVal = direction === "ascending" ? SortDirection.Desc : SortDirection.Asc;
    setSortValue([{ field: clickedColumn, direction: directionVal }]);
  };

  // ToDo: Add disable property

  return (
    <ConceptsComponent fetchPolicy="network-only" variables={{
      sort: sortValue,
    }}>
      {({ loading, error, data, fetchMore }) => {
        let concepts = data?.concepts?.nodes;
        let listData = concepts;
        return (
          <SearchQuery namespaces={[{ namespace: "concepts", action: actions.concepts.read }]} filter={filter}>
            {(sResp) => {
              if (filter) {
                concepts = sResp?.data?.hits?.map((h) => {
                  const con = h.source as IConcept;
                  return {
                    id: con.id,
                    name: con.name,
                  };
                });
                listData = concepts;

                setColumn(null);
                setDirection(null);
              }
              return (
                <div ref={contextRef}>
                  <Header dividing as="h1">
                    <QSFA icon={getIcon(ResourceType.Concept)} />Concepts
                  </Header>
                  <Dimmer inverted active={loading || sResp.loading}>
                    <Loader>Loading</Loader>
                  </Dimmer>
                  <Sticky context={contextRef} offset={50} styleElement={{ backgroundColor: "#fff" }}>
                    <FlexGrid>
                      <FlexGrid.Item flex={1}>
                        <DelayedInput
                          fluid
                          loading={sResp.loading}
                          placeholder="Start typing to filter results..."
                          icon="search"
                          iconPosition="left"
                          onNotify={(value) => {
                            value === ""
                              ? setFilter(null)
                              : setFilter({
                                multi_match: {
                                  fields: [
                                    "name^5",
                                    "name.ngram",
                                  ],
                                  query: value,
                                  type: "phrase_prefix",
                                },
                              });
                          }}
                        />
                      </FlexGrid.Item>
                      <FlexGrid.Item>
                        <Restrict action={actions.organizations.write}>
                          <Button
                            size="small"
                            primary
                            onClick={() => props.history.push(`/config/concepts/add`)}
                          >
                            <QSFA icon="plus-circle" padded="regular" />Add Concept
                          </Button>
                        </Restrict>
                      </FlexGrid.Item>
                    </FlexGrid>
                  </Sticky>
                  <Container fluid>
                    <Table sortable fixed selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={column === "name" ? direction : null}
                            onClick={() => handleSort("name")}
                          >
                            Name
                          </Table.HeaderCell>
                          <Table.HeaderCell width={1}>&nbsp;</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {listData?.map(({ name, id }, index) => (
                          <Table.Row
                            key={id + index}
                            style={{ cursor: "pointer" }}
                            onClick={conceptNav(id)}
                          >
                            <Table.Cell>{titleize(name || "")}</Table.Cell>
                            <Table.Cell width={1} textAlign="right">
                              <QSFA icon="chevron-right" padded />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Container>
                  <Divider hidden />
                  {!filter && (
                    <>
                      <LoadMore
                        fetchMore={fetchMore}
                        nodeName="concepts"
                        cursor={data?.concepts?.cursor}
                        hasMore={data?.concepts?.cursor !== ""}
                      />
                      <Divider hidden />
                    </>
                  )}
                </div>
              );
            }}
          </SearchQuery>
        );
      }}
    </ConceptsComponent>
  );
};
