import React, { useEffect, useState, useRef } from "react";
import { Button, Divider, Header, Icon, Input, List, Message, Modal, Segment, Table } from "semantic-ui-react";
import { AssignToGroupComponent, DeleteGroupComponent, GroupResourcesComponent, GroupUnitsComponent, RenameGroupComponent, ResourceType, UnassignFromGroupComponent } from "../generated/graphql";
import { QSFA } from "../Shared";
import { UnitPicker } from "../Unit";

interface IGroup {
  id: string;
  name: string;
}

interface IProps {
  group: IGroup;
  open?: boolean;
  scrolling?: boolean;
  onClose?: () => void;
  onUpdate?: () => void;
}
export default (props: IProps) => {
  const [editing, setEditing] = useState(false);
  const [editingUnits, setEditingUnits] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(true)
  const inputRef = useRef<Input>()


  useEffect(() => {
    if(inputRef.current){
      inputRef.current.focus()
    }
  }, [inputRef.current, editing])


  useEffect(() => {
    if (props.group) {
      setName(props.group.name);
    }
  }, [props.group]);

  useEffect(() => {
    if(name.trim().length < 1 || name.trim().length > 100){
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [name])

  const handleClose = () => {
    props.onClose();
    setSelectedUnits([]);
    setEditingUnits(false);
    setEditing(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => handleClose()}
      size="small"
      style={{ height: "80%", minHeight: "690px" }}
      closeOnDimmerClick={false}
      closeIcon>
      <Modal.Header content="Edit Group" />
      <Modal.Content style={{ height: "80%" }}>
        <Header as="h3" color="orange">Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={3}>Name</Table.Cell>
              <Table.Cell>
                <React.Fragment>
                  {editing ? (
                  <Input 
                  size="small" 
                  value={name} 
                  onChange={(e, v) => setName(v.value)} 
                  ref={(ref) => inputRef.current = ref}
                  />
                  ) : name}
                </React.Fragment>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <RenameGroupComponent>
                  {(query, resp) => (
                    <Button
                      disabled={!isValid}
                      loading={resp.loading}
                      size="tiny"
                      onClick={() => {
                        if (editing) {
                          query({
                            variables: {
                              id: props.group.id,
                              name,
                            },
                          }).then(() => {
                            props.onUpdate();
                          });
                        }
                        setEditing(!editing);
                      }}
                      primary={editing}
                    >
                      <QSFA icon={editing ? "check" : "edit"} padded={false} />
                    </Button>
                  )}
                </RenameGroupComponent>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3" color="orange">Units</Header>
        <GroupUnitsComponent variables={{ id: props.group && props.group.id }}>
          {(gResp) => (editingUnits ?
            <React.Fragment>
              <AssignToGroupComponent>
                {(query, { loading }) => (
                  <UnitPicker
                    loading={loading}
                    onChange={(id) => {
                      if (selectedUnits.includes(id)) {
                        setSelectedUnits(selectedUnits.filter((p) => p !== id));
                      } else {
                        setSelectedUnits([id, ...selectedUnits]);
                      }
                    }}
                    scrolling={true}
                    onClose={() => { setEditingUnits(false), setSelectedUnits([]); }}
                    onSave={() => {
                      query({
                        variables: {
                          input: {
                            groupId: props.group.id,
                            resources: selectedUnits.map((u) => ({
                              resourceId: u,
                              resourceType: ResourceType.Unit,
                            })),
                          },
                        },
                      }).then(() => {
                        gResp.refetch().then(() => {
                          setSelectedUnits([]);
                          setEditingUnits(false);
                        });
                      });
                    }}
                    disabledUnits={gResp.data && gResp.data.group && gResp.data.group.units.map((u) => u.id)}
                    value={selectedUnits} />
                )}
              </AssignToGroupComponent>
            </React.Fragment> :
            <Segment basic loading={gResp.loading}>
              {gResp.data && gResp.data.group && gResp.data.group.units.length > 0 ?
                <React.Fragment>
                  {selectedUnits.length > 0 ?
                    <React.Fragment>
                      <UnassignFromGroupComponent>
                        {(query, { loading }) => (
                          <Button primary content="Save" loading={loading} onClick={() => {
                            query({
                              variables: {
                                input: {
                                  groupId: props.group.id,
                                  resources: selectedUnits.map((u) => ({
                                    resourceId: u,
                                    resourceType: ResourceType.Unit,
                                  })),
                                },
                              },
                            }).then(() => {
                              gResp.refetch().then(() => {
                                setSelectedUnits([]);
                                setEditingUnits(false);
                              });
                            });
                          }} />
                        )}
                      </UnassignFromGroupComponent>
                      <Button content="Cancel" onClick={() => {
                        setSelectedUnits([]);
                        setEditingUnits(false);
                      }} />
                      <Divider hidden />
                      <p>Removing ({selectedUnits.length})</p>
                    </React.Fragment> :
                    <Button onClick={() => setEditingUnits(true)}><QSFA icon="plus" />Add units</Button>
                  }
                  <Divider hidden />
                  <div style={{ overflowY: "scroll", height: "300px" }}>
                    <List selection>
                      {gResp.data.group.units.filter((r) => !selectedUnits.includes(r.id)).map((r) => (
                        <List.Item key={r.id} onClick={() => setSelectedUnits([r.id, ...selectedUnits])}>
                          <List.Content floated="right">
                            <QSFA icon="times" padded={false} size="medium" />
                          </List.Content>
                          <List.Content>
                            <Header as="h4" content={`${r.name} (${r.identifier})`} subheader={r.organization.name} />
                          </List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </div>
                </React.Fragment>
                : !gResp.loading &&
                <Segment placeholder>
                  <Header icon>
                    <Icon><QSFA icon="store" padded={false} /></Icon>
                    This group does not have any units assigned.
                    </Header>
                  <Button primary content="Add units" onClick={() => setEditingUnits(true)} />
                </Segment>
              }
            </Segment>
          )}
        </GroupUnitsComponent>
      </Modal.Content>
      <Modal.Actions>
        <DeleteGroupComponent>
          {(query, { loading }) => (
            <Button negative
              floated="left"
              loading={loading}
              onClick={() => {
                query({
                  variables: {
                    id: props.group.id,
                  },
                }).then(() => {
                  handleClose();
                  if (props.onUpdate) { props.onUpdate(); }
                });
              }}
              content="Delete Group" />
          )}
        </DeleteGroupComponent>
        <Button
          onClick={() => handleClose()}
          content="Done" />
      </Modal.Actions>
    </Modal>
  );
};
