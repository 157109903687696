import { ResourceType } from "generated/graphql";
import React from "react";
import {
  Permission,
  PrivilegesContextParams,
  withPrivileges,
} from "./Context";

interface IProps extends PrivilegesContextParams {
  type?: ResourceType;
  id?: string;
  action?: string;
  permission?: Permission;
}

const Restrict = (props: React.PropsWithChildren<IProps>) => {
  const {
    type,
    id,
    action,
    Privileges: { cache },
    permission,
  } = props;
  const [allowed, setAllowed] = React.useState(false);

  React.useEffect(() => {
    switch (true) {
      case !!permission:
        setAllowed(!!props.Privileges.hasPermission(permission));
        break;
      case !!type && !!id:
        props.Privileges.isAllowed({ type, id }, action).then((a) => setAllowed(a));
        break;
      case !!action && !type:
        props.Privileges.isAllowedAny(action).then((a) => setAllowed(a));
        break;
    }
  }, [props.Privileges.cache]);

  return <React.Fragment>{allowed && props.children}</React.Fragment>;
};

export default withPrivileges(Restrict);
