import React from "react";
import { Divider, Header, Menu } from "semantic-ui-react";
import { QSFA } from "../Shared/QSFA";
import "../styles/components/Shared/Avatar.scss";
import "../styles/components/Shared/AvatarHeader.scss";
import { Avatar } from "./Avatar";
import { FormattedName } from "./FormattedName";
import { FormattedPhone } from "./FormattedPhone";
import { QSLink } from "./QSLink";

interface IEmp {
  firstName: string;
  lastName: string;
}

interface IProps {
  employee?: IEmp;
  email?: string;
  name?: any;
  icon?: string;
  phone?: string;
}

export const AvatarHeader = ({ employee, email, name, icon, phone }: IProps) => {
  return (
    <>
    <Menu secondary stackable className="avatar-header">
      <Menu.Item>
        {icon &&
          <QSFA icon={icon} avatar />
        }
        {employee &&
          <Avatar employee={employee} size="sm-medium"/>
        }
      </Menu.Item>
      <Menu.Item fitted style={{ display: "block" }}>
        {icon &&
          <>
            <Header as="h2" >
              {name}
            </Header>
            {phone &&
              <a className="contact-info">
                <FormattedPhone phone={phone} />
              </a>
            }
          </>
        }
        {employee &&
          <>
            <Header as="h2" >
              <FormattedName employee={employee} />
            </Header>
            <QSLink content={email} padded="minus-small" />
          </>
        }
      </Menu.Item>
    </Menu>
    <Divider fitted />
    </>
  );

};
