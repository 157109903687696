import { Form, Select } from "formsy-semantic-ui-react";
import { get } from "lodash";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";

import { ResourceType, UnitCustomAttribute, UpdateUnitComponent } from "../generated/graphql";
import { getIcon } from "../Resources";
import { countryOptions, ErrorLabel, PhoneInput, QSFA, usStateOptions } from "../Shared";
import CustomAttributesEditor from "./CustomAttributesEditor";

interface IProps extends RouteComponentProps {
  unit: IUnit;
  loading: boolean;
}

interface IUnit {
  id: string;
  name: string;
  identifier: string;
  phone: string;
  email?: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  customAttributes: Array<{key: string, value: string}>;
}

const UnitEditForm = (props: IProps) => {
  const [name, setName] = React.useState(props.unit?.name);
  const [identifier, setIdentifier] = React.useState(props.unit?.identifier);
  const [phone, setPhone] = React.useState(props.unit?.phone);
  const [email, setEmail] = React.useState(props.unit?.email);
  const [street1, setStreet1] = React.useState(props.unit?.street1);
  const [street2, setStreet2] = React.useState(props.unit?.street2);
  const [city, setCity] = React.useState(props.unit?.city);
  const [state, setState] = React.useState(props.unit?.state);
  const [zip, setZip] = React.useState(props.unit?.zip);
  const [country, setCountry] = React.useState(props.unit?.country);
  const [attributes, setAttributes] = React.useState<Array<{key: string, value: string}>>(
    props.unit?.customAttributes?.map((a) => ({
      key: a.key,
      value: a.value,
    })),
  );
  const [valid, setValid] = React.useState(false);

  return (
    <React.Fragment>
      <QSFA icon={getIcon(ResourceType.Unit)} size="small" />
      <Breadcrumb size="big" >
        <Breadcrumb.Section>
          <Link to={"/config/units"}> Units</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section>
          <Link to={`/config/units/${props?.unit?.id}`}>
            {name}
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>Edit</Breadcrumb.Section>
      </Breadcrumb>
      <Divider />

      <Segment basic loading={props.loading}>
        <Header as="h1" dividing >
          <QSFA icon={getIcon(ResourceType.Unit)} />{props?.unit?.name}
        </Header>
        <Divider hidden />
        <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
          <Header as="h3" content="Details" />
          <Form.Group widths="equal">
            <Form.Input
              name="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Name is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <Form.Input
              name="identifier"
              label="Identifier"
              onChange={(e) => setIdentifier(e.target.value)}
              required
              value={identifier}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Identifier is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Header as="h3" content="Contact Information" />
          <Form.Group widths="equal">
            <PhoneInput
              name="phone"
              label="Phone Number"
              value={phone}
              onChange={(v) => setPhone(v)}
              required
              validations="isLength:12"
              validationErrors={{
                isDefaultRequiredValue: "Phone is Required",
                isLength: "Invalid phone number",
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="email"
              label="Email"
              icon="mail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validations="isEmail"
              validationErrors={{
                isEmail: "Invalid email address",
              }}
              errorLabel={ErrorLabel}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="street1"
              label="Street 1"
              onChange={(e) => setStreet1(e.target.value)}
              value={street1}
              required
              errorLabel={ErrorLabel}
              validations="maxLength:100"
              validationErrors={{
                isDefaultRequiredValue: "Street 1 is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <Form.Input
              name="street2"
              label="Street 2"
              onChange={(e) => setStreet2(e.target.value)}
              value={street2}
              errorLabel={ErrorLabel}
              validations="maxLength:100"
              validationErrors={{
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              required
              name="city"
              label="City"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              validations="maxLength:100"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "City is Required",
                maxLength: "Exceeded the max of 100 characters",
              }}
            />
            <div className="required field">
              <label>State</label>
              <Select required selection
                name="state"
                label="State"
                onChange={(e, v) => setState(v.value as string)}
                value={state}
                options={usStateOptions}
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "State is Required",
                }}
              />
            </div>
            <Form.Input
              required
              name="zip"
              label="Zip"
              onChange={(e) => setZip(e.target.value)}
              value={zip}
              validations="maxLength:10"
              errorLabel={ErrorLabel}
              validationErrors={{
                isDefaultRequiredValue: "Zip is Required",
                maxLength: "Exceeded the max of 10 characters",
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <div className="required field">
              <label>Country</label>
              <Select required selection
                name="country"
                label="Country"
                onChange={(e, v) => setCountry(v.value as string)}
                value="US"
                options={countryOptions}
                errorLabel={ErrorLabel}
                validationErrors={{
                  isDefaultRequiredValue: "Country is Required",
                }}
              />
            </div>
          </Form.Group>
          <Header as="h3" content="Custom Attributes" />
          <CustomAttributesEditor attributes={attributes} setAttributes={setAttributes}/>
          <Button.Group>
            <UpdateUnitComponent>
              {(update, resp) => (
                <Button
                  loading={resp.loading}
                  content="Save Unit"
                  primary
                  onClick={() => {
                    if (valid) {
                      update({
                        variables: {
                          id: props.unit.id,
                          name,
                          identifier,
                          phone,
                          email,
                          street1,
                          street2,
                          city,
                          state,
                          zip,
                          country,
                          attributes,
                        },
                      }).then(() => {
                        props.history.push(`/config/units/${props.unit.id}`);
                      });
                    }
                  }
                  }
                />
              )}
            </UpdateUnitComponent>
            <Button
              onClick={() => props.history.push(`/config/units/${props.unit.id}`)}
              content="Cancel" />
          </Button.Group>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

export default withRouter(UnitEditForm);
