import React from "react";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import { ResourceType } from "../generated/graphql";
import { QSFA } from "../Shared";

interface IProps {
  type: string;
  size: IconSizeProp;
}

export function getIcon(type: ResourceType) {
  switch (type) {
    case ResourceType.Employee:
      return "user";
    case ResourceType.Unit:
      return "store";
    case ResourceType.Organization:
      return "sitemap";
    case ResourceType.Concept:
      return "city";
    case ResourceType.Group:
      return "layer-group";
    case ResourceType.Actor:
      return "user";
    default:
      return "question";
  }
}

export const ResourceIcon = (props: IProps) => {
  const iconName = "";
  return (
    <QSFA
      icon={getIcon(props.type as ResourceType)}
      size={props.size || "large"}
    />
  );
};
