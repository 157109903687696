import React from "react";
import { Button } from "semantic-ui-react";
import { QSFA } from "./QSFA";

interface IProps {
  fetchMore: any;
  nodeName: string;
  cursor: string;
  hasMore: boolean;
  className?: string;
  customText?: string;
}

export const LoadMore = (props: IProps) => {
  const [loading, setLoading] = React.useState(false);
  if (!props.hasMore) { return null; }
  return (
    <Button
      fluid
      basic
      loading={loading}
      className={props.className}
      onClick={() => {
        setLoading(true);
        props.fetchMore({
          updateQuery: (prevRes, { fetchMoreResult }) => {
            const prevEntry = prevRes[props.nodeName];
            const newNodes = fetchMoreResult[props.nodeName].nodes;
            const newCursor = fetchMoreResult[props.nodeName].cursor;
            const obj = {};
            obj[props.nodeName] = {
              __typename: prevEntry.__typename,
              cursor: newCursor,
              nodes: [...prevEntry.nodes, ...newNodes],
            };
            setLoading(false);
            return obj;
          },
          variables: { cursor: props.cursor },
        });
      }}
    >
      <QSFA icon="arrow-circle-down" />{props.customText || `Load more ${props.nodeName}...`}
    </Button>
  );
};
