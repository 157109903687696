import { Select } from "formsy-semantic-ui-react";
import React from "react";

import { OrganizationConceptsComponent } from "../generated/graphql";
import { ErrorLabel } from "../Shared";

interface IProps {
  value?: string[] | string;
  onChange?: (v: any) => void;
  required?: boolean;
  action?: string;
  multiple?: boolean;
  orgId?: string;
}

const OrgConceptSelect = (props: IProps) => {
  const [disabled, setDisabled] = React.useState(false);

  const ph = props.multiple ? "Select one or more concepts" : "Select a concept";

  if (props.orgId) {
    return (
      <OrganizationConceptsComponent variables={{ id: props.orgId }}>
        {({ data, loading }) => {
          const conOptions = data?.organization?.concepts?.map((con) => {
            return {
              key: con.id,
              text: con.name,
              value: con.id,
            };
          });
          return (
            <Select
              fluid
              loading={loading}
              disabled={disabled}
              name="conceptId"
              label="Concept"
              value={props.value}
              options={conOptions ? conOptions : []}
              onChange={(e, v) => props.onChange(v.value)}
              placeholder={ph}
              selection
              multiple={props.multiple}
              required={props.required}
              validationErrors={{
                isDefaultRequiredValue: "Concept is Required",
              }}
              errorLabel={ErrorLabel}
            />
          );
        }}
      </OrganizationConceptsComponent>
    );
  } else {
    return (
      <Select placeholder="Select an organization" options={[]} name="conceptId" disabled/>
    );
  }
};

export default OrgConceptSelect;
