import React from "react";

interface IProps {
  content: string;
  padded?: boolean | "minus-small" | "none" | "regular";
  color?: string;
}

import "../styles/components/Shared/QSLink.scss";

export const QSLink = ({ content, padded, color }: IProps) => {
  let iconStr = `qs-link`;
  let colorVal = color ? color : "#1863d6";

  if (padded === undefined) {
    padded = "regular";
  } else if (!padded) {
    padded = "none";
  }

  let paddingClass = " qs-icon-padded";

  switch (padded) {
    case "minus-small":
      paddingClass = " qs-link-negative-small";
      break;
    case "none":
      paddingClass = " qs-icon-none";
      break;
    case "regular":
      paddingClass = " qs-icon-padded";
      break;
  }
  iconStr += paddingClass;

  const style = {
    color: colorVal,
  };

  return (
    <a href="" style={style} className={iconStr}>
      {content}
    </a>
  );
};
