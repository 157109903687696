import React from 'react';
import { ResourceType, RevokeTeamComponent } from '../generated/graphql';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import EditPermissionButton from './EditPermissionButton';
import { ResourceIcon } from '../Resources';
import { QSFA } from '../Shared';

interface IProps {
  grant: {
    name: string,
    type: ResourceType,
    id: string,
  };
  policies: string[];
  teamId: string;
  isReport: boolean;
  onUpdate: () => void;
}

export default ({ grant, teamId, isReport, onUpdate, policies }: IProps) => {
  return (
    <Segment tertiary key={grant.name}>
      <Grid>
        <Grid.Column width={1} verticalAlign="middle">
          {ResourceIcon({ type: grant?.type, size: "small" })}
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle">
          <Header size="tiny">
            <Header.Content>
              {grant.name}
              <Header.Subheader>
                {policies.join(", ")}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>

        <Grid.Column
          textAlign="right"
          width={7}
          verticalAlign="middle"
        >
          {!isReport ?
            <EditPermissionButton
              team={null}
              targetId={grant.id}
              targetType={grant.type}
              onUpdate={onUpdate}
              policy={policies[0]}
              teamId={teamId}
            /> :
            ""
          }
          <RevokeTeamComponent>
            {(query, { loading }) => (
              <Button
                loading={loading}
                content={<QSFA icon="trash" basic />}
                negative
                onClick={() => {
                  query({
                    variables: {
                      targetId: grant.id,
                      targetType: grant.type,
                      teamId,
                    },
                  }).then(() => {
                    if (onUpdate) {
                      onUpdate();
                    }
                  });
                }}
              />
            )}
          </RevokeTeamComponent>
        </Grid.Column>
      </Grid>
    </Segment>
  )
}
