import "qs-react-date-range/dist/styles.css"; // main style file
import "qs-react-date-range/dist/theme/default.css"; // theme css file
import "../styles/components/Reporting/ReportMenu.scss";
import "../styles/components/SemanticOverrides.scss";

import moment, { Moment } from "moment";
import { DateRangePicker } from "qs-react-date-range";
import React from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Card, Checkbox, Container, Divider, Dropdown, Header, Input, Label, Menu, Message, Modal, Segment, Tab, Table } from "semantic-ui-react";
import titleize from "titleize";

import { ExportType, ViewType } from "../QSFM/QSFM";
import { QSFA } from "../Shared";
import { inputRanges, staticRanges } from "./ranges";
import { IDefinition, ISettings } from "./ReportView";

interface IOptions {
  dateCompSelector?: boolean;
  salesCompSelector?: boolean;
  disableDateSelector?: boolean;
}

interface IViewOptions {
  value: string;
  text: string;
  icon: string;
}

interface IProps extends RouteComponentProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  reportName: string;
  reportIcon: string;
  expanded: boolean;
  mobile?: boolean;
  expandReport: (expand: boolean) => void;
  definition: IDefinition;
  longDescription?: string;
  applyConfig: (cfg: ISettings) => void;
  view: ViewType;
  viewOptions: IViewOptions[];
  handleViewChange: (v: ViewType) => void;
  handleExport: (e: ExportType) => void;
}

const ReportMenu = (props: IProps) => {
  const [options, setOptions] = React.useState<IOptions>({
    dateCompSelector: false,
    salesCompSelector: false,
    disableDateSelector: false,
  });

  const [activeTab, setActiveTab] = React.useState<string | null>(null);
  const [dateCompMethod, setDateCompMethod] = React.useState<
    "dayvsday" | "datevsdate"
  >("dayvsday");
  const [includeZeroSales, setIncludeZeroSales] = React.useState(false);
  const [startDate, setStartDate] = React.useState(moment());
  const [endDate, setEndDate] = React.useState(moment());
  const [mobileDateRange, setMobileDateRange] = React.useState(null);

  React.useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
    if (props.definition) {
      try {
        const settings = props.definition.unity;
        if (settings.reportOptions) {
          setOptions(Object.assign(options, settings.reportOptions));
        }
      } catch { }
    }
  }, [props.definition, props.startDate, props.endDate]);

  const panes = [
    {
      menuItem: "Date",
      render: () =>
        !options.disableDateSelector && (
          <DateRangePicker
            ranges={[
              {
                startDate: startDate.local().toDate(),
                endDate: endDate.local().toDate(),
              },
            ]}
            onChange={(e) => {
              setStartDate(moment(e.range1.startDate)),
                setEndDate(moment(e.range1.endDate));
            }}
            maxDate={moment()
              .subtract(0, "days")
              .toDate()}
            minDate={moment()
              .subtract(1, "years")
              .toDate()}
            scroll={{ enabled: false }}
            inputRanges={inputRanges}
            staticRanges={staticRanges}
          />
        ),
    },
    {
      menuItem: "Options",
      render: () => (
        <div>
          {options.dateCompSelector && (
            <div>
              <Header as="h3" content="Date Comparison" />
              <p>There are two ways date comparisons can be viewed.</p>
              <ul>
                <li>
                  Day compares the same day of last year to today (The second
                  Tuesday of January last year vs the second Tuesday of January
                  this year).
                </li>
                <li>{`
                Date compares the same date last year to today's date (Jan
                15th of last year vs Jan 15th of this year).
              `}</li>
              </ul>
              <Segment basic>
                <Button.Group>
                  <Button
                    content="Day"
                    primary={dateCompMethod === "dayvsday"}
                    onClick={() => setDateCompMethod("dayvsday")}
                  />
                  <Button
                    content="Date"
                    primary={dateCompMethod === "datevsdate"}
                    onClick={() => setDateCompMethod("datevsdate")}
                  />
                </Button.Group>
              </Segment>
              <Divider />
            </div>
          )}
          {options.salesCompSelector && (
            <div>
              <Header as="h3" content="Sales Comparison" />
              <p>
                You can decide if you want to include records from last year
                that had zero sales when performing comparisons. For example, if
                a unit was closed due to a weather event or construction during
                the comparison period your sales comparison may be skewed.
            </p>
              <Segment basic>
                <Table basic="very" collapsing verticalAlign="middle">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Checkbox
                          fitted
                          toggle
                          checked={includeZeroSales}
                          onChange={() =>
                            setIncludeZeroSales(!includeZeroSales)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <p>Include records with zero sales</p>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Segment>
            </div>
          )}
          {!options.salesCompSelector && !options.dateCompSelector && (
            <Message
              size="large"
              info
              icon={<QSFA icon="info-circle" size="big" />}
              header="No options"
              content={`
              The ${props.reportName} Report does not have any available options.
            `}
            />
          )}
        </div>
      ),
    },
    {
      menuItem: "About",
      render: () => (
        <div>
          {!props.longDescription ? (
            <Message
              size="large"
              info
              icon={<QSFA icon="info-circle" size="big" />}
              header="No information"
              content={`
                There is no data available for this report.
              `}
            />
          ) : (
              <ReactMarkdown source={props.longDescription} />
            )}
        </div>
      ),
    },
  ];

  // Used for mobile date picker
  enum DateSelectOptions {
    Yesterday = "Yesterday",
    LastWeek = "Last Week",
    Last7Days = "Last 7 Days",
    Last30Days = "Last 30 Days",
    ThisMonth = "This Month",
    LastMonth = "Last Month",
  }

  const inputDateFormat = "YYYY-MM-DD";

  const handleDateChange = (date: DateSelectOptions) => {

    let startDate: Moment = null;
    let endDate: Moment = null;

    switch (date) {
      case DateSelectOptions.Yesterday: {
        startDate = moment().subtract(1, "day").startOf("day");
        endDate = moment().subtract(1, "day").startOf("day");
        break;
      }
      case DateSelectOptions.LastWeek: {
        // Week starts on Monday
        startDate = moment().subtract(7, "days").startOf("isoWeek");
        endDate = moment().subtract(7, "days").endOf("isoWeek").startOf("day");
        break;
      }
      case DateSelectOptions.Last7Days: {
        startDate = moment().subtract(7, "days").startOf("day");
        endDate = moment().subtract(1, "days").startOf("day");
        break;
      }
      case DateSelectOptions.Last30Days: {
        startDate = moment().subtract(30, "days").startOf("day");
        endDate = moment().subtract(1, "days").startOf("day");
        break;
      }
      case DateSelectOptions.ThisMonth: {
        startDate = moment().startOf("month");
        endDate = moment().startOf("day");
        break;
      }
      case DateSelectOptions.LastMonth: {
        startDate = moment().subtract(1, "month").startOf("month");
        endDate = moment().subtract(1, "month").endOf("month").startOf("day");
        break;
      }
      default:
        console.error("Option not implmeneted:", date);
    }

    setMobileDateRange(date);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const applyChanges = () => {
    props.applyConfig({
      startDate,
      endDate,
      dateCompMethod,
      includeZeroSales,
    });

    setActiveTab(null);
  };

  const mobilePanes = [
    {
      menuItem: "Date Range", render: () => (
        <div className="pane with-header">
          <div className="pane-container">
            <Header as="h2">
              Select a Date Range
              <Header sub>Select a quick reporting period:</Header>
            </Header>
            <div className="pane-body">
              {
                Object.values(DateSelectOptions).map((dateOption) => {
                  return (
                    <Button fluid
                      key={dateOption}
                      onClick={() => handleDateChange(dateOption)}
                      className={dateOption === mobileDateRange ? "selected" : ""}>
                      {dateOption}
                    </Button>
                  );
                })
              }
            </div>
            <Button
              content="Apply Changes"
              className={"large " + (startDate.diff(endDate) > 0 ? "disabled" : "blue")}
              onClick={() => applyChanges()} />
          </div>
        </div>
      ),
    },
    {
      menuItem: "Custom Date", render: () => (
        <div className="pane with-header">
          <div className="pane-container">
            <Header as="h2">
              Custom Date Range
              <Header sub>
                Pick two dates to build a report with a custom date range.
              </Header>
            </Header>
            <div className="pane-body">
              <Header as="h5">Starting date for custom date range:</Header>
              <Input fluid
                type="date"
                // Note - max doesn't currently work on iOS
                max={moment().format(inputDateFormat)}
                icon="calendar"
                iconPosition="left"
                value={startDate.format(inputDateFormat)}
                onChange={(e, data) => {
                  setStartDate(moment(data.value, inputDateFormat).startOf("day"));
                  setMobileDateRange(null);
                }}
              />
              <Header as="h5">Ending date for custom date range:</Header>
              <Input fluid
                type="date"
                // Note - max doesn't currently work on iOS
                max={moment().format(inputDateFormat)}
                icon="calendar"
                iconPosition="left"
                value={endDate.format(inputDateFormat)}
                onChange={(e, data) => {
                  setEndDate(moment(data.value, inputDateFormat).startOf("day"));
                  setMobileDateRange(null);
                }}
              />
              {
                startDate.diff(endDate) > 0 &&
                <Label basic color="red" pointing="above" content="End must come after start date" />
              }
            </div>
            <Button
              content="Apply Changes"
              className={"large " + (startDate.diff(endDate) > 0 ? "disabled" : "blue")}
              onClick={() => applyChanges()} />
          </div>
        </div>
      ),
    },
    {
      menuItem: "Options", render: () => (
        <div className="pane with-header">
          <div className="pane-container">
            <Header as="h2">
              Data Comparison
              <Header sub>
                There are two ways date comparisons can be viewed.
              </Header>
            </Header>
            <div className="pane-body">
              <Button fluid
                className={dateCompMethod === "dayvsday" ? "selected" : ""}
                onClick={() => setDateCompMethod("dayvsday")}
                content="Day-to-Day" />
              <Button fluid
                className={dateCompMethod === "datevsdate" ? "selected" : ""}
                onClick={() => setDateCompMethod("datevsdate")}
                content="Date-to-Date" />
              <Header as="h2">
                Sales Comparison
                <Header sub>
                  You can decide if you want to include records from last year that had zero sales when performing comparisons.
                </Header>
                <Checkbox
                  toggle
                  checked={includeZeroSales}
                  onChange={(e, data) => setIncludeZeroSales(data.checked)}
                  label="Include records with zero sales" />
              </Header>
            </div>
            <Button
              content="Apply Changes"
              className={"large " + (startDate.diff(endDate) > 0 ? "disabled" : "blue")}
              onClick={() => applyChanges()} />
          </div>
        </div>
      ),
    },
  ];

  const reportModal = !props.mobile
    ?
    (
      // Non Mobile Modal
      <Modal
        open={activeTab !== null}
        onClose={() => setActiveTab(null)}
      >
        <Header content={props.reportName} />
        <Modal.Content
          scrolling style={{ minHeight: "550px", maxHeight: "550px" }} >
          <Container>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={panes}
              activeIndex={activeTab}
              onTabChange={(e, v) => setActiveTab(v.activeIndex.toString())}
            />
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Apply" primary
            onClick={() => {
              props.applyConfig({
                startDate,
                endDate,
                dateCompMethod,
                includeZeroSales,
              });
              setActiveTab(null);
            }} />
          <Button content="Cancel" onClick={() => setActiveTab(null)} />
        </Modal.Actions>
      </Modal>
    )
    :
    (
      // Mobile Modal
      <div
        className="mobile-modal"
        hidden={activeTab == null}
      >
        {activeTab === "0" && <Tab menu={{ secondary: true, pointing: true }} panes={mobilePanes} />}
        {activeTab === "1" && (
          <div className="pane">
            <div className="pane-container">
              <div className="pane-body scroll">
                <p>The Sales Report displays sales, transaction counts, comparisons, check average, petty cash, and cash variance.</p>

                <label className="padded">Filters</label>
                <p>The Sales Report is laid out with your units and dates as rows. You can expand your unit to view sales by each date you have selected.</p>

                <div>
                  <span className="label">Unit</span><span>Filters store locations</span>
                </div>
                <div>
                  <span className="label">Date</span><span>Displays all individual dates selected from Date Range</span>
                </div>

                <label className="padded">Columns</label>

                <div>
                  <span className="label">Sales</span><span>Net sales from the unit</span>
                </div>
                <div>
                  <span className="label">Transactions</span><span>The number of transactions served</span>
                </div>
                <div>
                  <span className="label">Avg Check</span><span>The average check for the day</span>
                </div>
                <div>
                  <span className="label">Sales Comp</span><span>The sales comparison over the same day as last year (by default)</span>
                </div>
                <div>
                  <span className="label">Guest Comp</span><span>The number of transactions over the same day as last year (by default)</span>
                </div>
                <div>
                  <span className="label">Petty Cash</span><span>The net sum of Paid in and Paid out</span>
                </div>
                <div>
                  <span className="label">Gift Cards</span><span>The value of gift cards sold</span>
                </div>
                <div>
                  <span className="label">OLO Sales</span><span>Net online sales</span>
                </div>
                <div>
                  <span className="label">OLO Trans</span><span>The number of online transactions</span>
                </div>
                <div>
                  <span className="label">OLO Avg Chk</span><span>The average check for online sales</span>
                </div>

                <label className="padded">Filtering</label>
                <p>Filtering Store Hierarchy Filters - FBC, COOP, ORG, Company, Market, and DM appear at the top of reports when relevant</p>
                <p>All Filter Groups can be organized by desired hierarchy via drag &amp; drop</p>
                <p>If only 1 option is available in a filter, the Filter Group will not appear (as it’s automatically applied) Some filter groups only appear as an option on reports that they’re relevant to or to users with specific permissions</p>
              </div>
              <div className="actions">
                <Button fluid onClick={() => setActiveTab(null)}>Back</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );

  return (
    <React.Fragment>
      {reportModal}
      {!props.mobile && (
        <>
          <Header dividing as="h1">
            <QSFA icon={props.reportIcon} />
            {titleize(props.reportName)} Report
          </Header>
          <Menu secondary style={{ height: "50px", margin: 0 }}>
            <Menu.Item fitted="horizontally">
              <Button
                basic
                onClick={() => setActiveTab("0")}
                disabled={options && options.disableDateSelector}
              >
                <QSFA icon="calendar" />
                {props.startDate.format("MMM D, YYYY")} - {props.endDate.format("MMM D, YYYY")}
              </Button>
            </Menu.Item>
            <Menu.Item fitted="horizontally">
              <Button
                basic
                icon
                onClick={() => setActiveTab("1")}
              >
                <QSFA icon="cog" padded={false} />
              </Button>
            </Menu.Item>
            <Menu.Item fitted="horizontally">
              <Button
                basic
                icon
                onClick={() => setActiveTab("2")}
              >
                <QSFA icon="info" padded={false} />
              </Button>
            </Menu.Item>
            <Menu.Item fitted="horizontally">
              <Button
                basic
                icon
                onClick={() => props.expandReport(!props.expanded)}
              >
                <QSFA
                  icon={props.expanded ? "compress" : "expand"}
                  padded={false}
                />
              </Button>
            </Menu.Item>
            <Menu.Item fitted="horizontally">
              <Menu
                compact
                size="small"
                style={{ boxShadow: "none" }}
              >
                <Dropdown
                  item
                  trigger={
                    <QSFA icon={props.viewOptions.find((v) => v.value === props.view).icon} padded={false} />}
                >
                  <Dropdown.Menu>
                    {props.viewOptions.map((v) =>
                      <Dropdown.Item
                        key={v.value}
                        icon={<QSFA icon={v.icon} />}
                        text={titleize(v.text)}
                        value={v.value}
                        active={props.view === v.value}
                        onClick={(e, v) => props.handleViewChange(v.value as ViewType)}
                      />,
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            </Menu.Item>
            <Menu.Item fitted="horizontally">
              <Menu
                compact
                size="small"
                style={{ boxShadow: "none" }}
              >
                <Dropdown
                  item
                  trigger={
                    <span>
                      <QSFA icon="download" padded={false} />
                    </span>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="Print"
                      icon={<QSFA icon="print" />}
                      onClick={() => props.handleExport("print")}
                    />
                    <Dropdown.Item
                      text="Excel"
                      icon={<QSFA icon="file-excel" />}
                      onClick={() => props.handleExport("excel")}
                    />
                    <Dropdown.Item
                      text="PDF"
                      icon={<QSFA icon="file-pdf" />}
                      onClick={() => props.handleExport("pdf")}
                    />
                    <Dropdown.Item
                      text="CSV"
                      icon={<QSFA icon="file-csv" />}
                      onClick={() => props.handleExport("csv")}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            </Menu.Item>
          </Menu>
        </>
      )}
      {props.mobile && (
        <div className="mobile-report-menu" >
          <Button onClick={() => setActiveTab("0")} className="date-btn" disabled={options && options.disableDateSelector}>
            <QSFA icon="calendar" size="medium" />
            <span>
              {props.startDate.format("MM/DD/YYYY")} - {props.endDate.format("MM/DD/YYYY")}
            </span>
            <QSFA icon="chevron-circle-down" size="medium" padded={false} />
          </Button>
          <Button compact onClick={() => setActiveTab("1")} className="info-btn">
            <QSFA icon="info-square" padded={false} size="medium" />
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(ReportMenu);
