import { ResourceType } from "generated/graphql";
import React from "react";
import {
  PrivilegesContextParams,
  withPrivileges,
} from "./Context";

interface IProps extends PrivilegesContextParams {
  resourceType: ResourceType;
  resources: string[];
  actions: string[];
  children?: React.ReactNode;
}

const PrivilegesLoader = (props: IProps) => {
  const [loading, setLoading] = React.useState(true);
  const { resources, actions } = props;
  React.useEffect(() => {
    props
      .Privileges
      .queryPrivileges(resources.map((i) => ({ id: i, type: props.resourceType })), actions)
      .then(() => setLoading(false));
  }, []);
  if (loading) {
    return (
      <React.Fragment></React.Fragment>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default withPrivileges(PrivilegesLoader);
