import { Form, Select } from "formsy-semantic-ui-react";
import _ from "lodash";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";

import { GetOrganizationComponent, ResourceType, UpdateOrganizationComponent } from "../generated/graphql";
import { getIcon } from "../Resources";
import { countryOptions, ErrorLabel, PhoneInput, QSFA, usStateOptions } from "../Shared";

interface IParams {
  id: string;
}

type IProps = RouteComponentProps<IParams>;

const OrgnaizationEdit = (props: IProps) => {
  const id = props.match.params.id;
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [street1, setStreet1] = React.useState("");
  const [street2, setStreet2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [country, setCountry] = React.useState("US");
  const [valid, setValid] = React.useState(false);

  return (
    <GetOrganizationComponent variables={{ id: props.match.params.id }} onCompleted={(data) => {
      const o = data.organization;
      if (o) {
        setName(o.name);
        setPhone(o.phone);
        setStreet1(o.street1);
        setStreet2(o.street2);
        setCity(o.city);
        setState(o.state);
        setZip(o.zip);
        setCountry(o.country);
      }
    }}>
      {({ data, loading }) => (
        <React.Fragment>
          <QSFA icon={getIcon(ResourceType.Organization)} size="small" />
          <Breadcrumb size="big">
            <Breadcrumb.Section>
              <Link to={"/config/organizations"}> Organizations</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/config/organizations/${props.match.params.id}`}>
                {data?.organization?.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Edit</Breadcrumb.Section>
          </Breadcrumb>
          <Divider />

          <Segment basic>
            <Header as="h1" dividing>
              <QSFA icon={getIcon(ResourceType.Organization)} />{data?.organization.name}
            </Header>
            <Divider hidden />
            <Form
              onValid={() => setValid(true)}
              onInvalid={() => setValid(false)}
            >
              <Header as="h3" content="Details" />
              <Form.Group widths="equal">
                <Form.Input
                  name="name"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  value={name}
                  validations="maxLength:100"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: "Name is Required",
                    maxLength: "Exceeded the max of 100 characters",
                  }}
                />
              </Form.Group>
              <Header as="h3" content="Contact Information" />
              <Form.Group widths="equal">
                <PhoneInput
                  name="phone"
                  label="Phone Number"
                  value={phone}
                  onChange={(v) => setPhone(v)}
                  required
                  validations="isLength:12"
                  validationErrors={{
                    isDefaultRequiredValue: "Phone is Required",
                    isLength: "Invalid phone number",
                  }}
                  errorLabel={ErrorLabel}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  name="street1"
                  label="Street 1"
                  onChange={(e) => setStreet1(e.target.value)}
                  value={street1}
                  required
                  validations="maxLength:100"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: "Street is Required",
                    maxLength: "Exceeded the max of 100 characters",
                  }}
                />
                <Form.Input
                  name="street2"
                  label="Street 2"
                  onChange={(e) => setStreet2(e.target.value)}
                  value={street2}
                  validations="maxLength:100"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    maxLength: "Exceeded the max of 100 characters",
                  }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  name="city"
                  label="City"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  validations="maxLength:100"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: "City is Required",
                    maxLength: "Exceeded the max of 100 characters",
                  }}
                />
                <div className="required field">
                  <label>State</label>
                  <Select
                    required
                    selection
                    name="state"
                    label="State"
                    onChange={(e, v) => setState((v.value || "").toString())}
                    value={state}
                    options={usStateOptions}
                    errorLabel={ErrorLabel}
                    validationErrors={{
                      isDefaultRequiredValue: "State is Required",
                    }}
                  />
                </div>
                <Form.Input
                  required
                  name="zip"
                  label="Zip"
                  onChange={(e) => setZip(e.target.value)}
                  value={zip}
                  validations="maxLength:10"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: "Zip is Required",
                    maxLength: "Exceeded the max of 10 characters",
                  }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <div className="required field">
                  <label>Country</label>
                  <Select
                    required
                    search={!isMobileOnly}
                    selection
                    name="country"
                    onChange={(e, v) => setCountry((v.value || "").toString())}
                    value={"US"}
                    options={countryOptions}
                    placeholder="Select a country"
                    errorLabel={ErrorLabel}
                    validationErrors={{
                      isDefaultRequiredValue: "Country is Required",
                    }}
                  />
                </div>
              </Form.Group>
              <UpdateOrganizationComponent>
                {(query, { loading: updating }) => (
                  <Button.Group>
                    <Button content="Save Organization" primary
                      loading={updating}
                      onClick={() => {
                        if (!valid) { return; }
                        query({
                          variables: {
                            id,
                            city,
                            country,
                            name,
                            phone,
                            state,
                            street1,
                            street2,
                            zip,
                          },
                        }).then(() => {
                          props.history.push(`/config/organizations/${props.match.params.id}`);
                        });
                      }} />
                    <Button
                      onClick={() => props.history.push(`/config/organizations/${props.match.params.id}`)}
                      content="Cancel" />
                  </Button.Group>
                )}
              </UpdateOrganizationComponent>
            </Form>
          </Segment>
        </React.Fragment>
      )}
    </GetOrganizationComponent>
  );
};

export default withRouter(OrgnaizationEdit);
