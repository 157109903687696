import { actions } from "@quikserve/unityactions";
import React from "react";
import { Button, Divider, Header, Input, List, Menu, Segment } from "semantic-ui-react";
import { ListUnitsComponent, SortDirection } from "../generated/graphql";
import SearchQuery, {IUnit as ISearchUnit} from "../Search/SearchQuery";
import { LoadMore } from "../Shared";

interface IUnit {
  id: string;
  name: string;
  identifier: string;
  organizationName: string;
}

interface IProps {
  loading?: boolean;
  onChange: (id: string) => void;
  onClose: () => void;
  onSave: () => void;
  value: string[];
  disabledUnits?: string[];
  scrolling?: boolean;
}

export default (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  let timeout = null;
  const listScroll = props.scrolling ? "scroll" : "visible";
  const listHeight = props.scrolling ? "300px" : "auto";

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <ListUnitsComponent variables={{ sort: [{ field: "name", direction: SortDirection.Asc }] }}>
      {({ data, loading, fetchMore }) => {
        let units: IUnit[] = data && data.units && data.units.nodes.map((n) => ({
          id: n.id,
          name: n.name,
          identifier: n.identifier,
          organizationName: n.organization.name,
        }));
        return (
          <SearchQuery namespaces={[{ namespace: "units", action: actions.units.read }]} filter={filter}>
            {(sResp) => {
              if (filter) {
                units = sResp.data && sResp.data.hits && sResp.data.hits.map((h) => {
                  const unit = h.source as ISearchUnit;
                  return {
                    id: unit.id,
                    name: unit.name,
                    identifier: unit.identifier,
                    organizationName: unit.organizationName,
                  };
                });
              }
              return (
                <React.Fragment>
                  <Menu secondary>
                    <Menu.Item>
                      <Input
                        transparent
                        placeholder="Search..."
                        icon="search"
                        iconPosition="left"
                        onChange={(e, { value }) => {
                          clearTimeout(timeout);
                          timeout = setTimeout(() => {
                            value === "" ? setFilter(null) : setFilter({
                              multi_match: {
                                fields: [
                                  "identifier^5",
                                  "identifier.ngram",
                                  "name^5",
                                  "name.ngram",
                                  "organizationName^4",
                                  "organizationName.ngram",
                                  "phone",
                                ],
                                query: value,
                                type: "phrase_prefix",
                              },
                            });
                          }, 500);
                        }}
                      />
                    </Menu.Item>
                    <Menu.Menu position="right">
                      <Menu.Item>
                        {props.value.length > 0 &&
                          <React.Fragment>
                            Adding ({props.value.length})
                          </React.Fragment>
                        }
                      </Menu.Item>
                      <Menu.Item>
                        <Button.Group>
                          <Button primary
                            content="Save"
                            disabled={props.value.length === 0}
                            loading={props.loading}
                            onClick={() => props.onSave()} />
                          <Button content="Cancel" onClick={() => props.onClose()} />
                        </Button.Group>
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                  <div style={{ overflowY: listScroll, height: listHeight }}>
                    <Segment basic loading={loading || sResp.loading}>
                      <List selection>
                        {units && units.map((u) => {
                          const disabled = props.disabledUnits && props.disabledUnits.includes(u.id);
                          const active = props.value.includes(u.id);
                          return (
                            <List.Item key={u.id} onClick={() => props.onChange(u.id)}
                              disabled={disabled}
                              active={active} >
                              <List.Icon
                                color={active || disabled ? "green" : "grey"}
                                size="large"
                                name={active || disabled ? "check circle outline" : "times circle outline"}
                                verticalAlign="middle" />
                              <List.Content>
                                <Header as="h4" content={`${u.name} (${u.identifier})`} subheader={u.organizationName} />
                              </List.Content>
                            </List.Item>
                          );
                        })}
                      </List>
                    </Segment>
                    <Divider hidden />
                    {!filter &&
                      <LoadMore
                        fetchMore={fetchMore}
                        nodeName="units"
                        cursor={data && data.units && data.units.cursor}
                        hasMore={data && data.units && data.units.cursor !== ""}
                      />
                    }
                  </div>
                </React.Fragment>
              );
            }}
          </SearchQuery>
        );
      }}
    </ListUnitsComponent>
  );
};
