import { Form } from "formsy-semantic-ui-react";
import React from "react";
import { Button, Modal, Dropdown, Header } from "semantic-ui-react";

import { AppContextParams, withAppContext } from "../Context";
import {
  GetActorAccessComponent,
  GrantTeamComponent,
  ResourceType,
} from "../generated/graphql";
import { ResourcePicker } from "../Resources";
import { QSFA } from "../Shared";

interface IProps extends AppContextParams {
  teamId: string;
  team: {
    id: string;
    name: string;
    grants: any;
  };
  onUpdate: () => void;
}

interface IResource {
  id: string;
  type: ResourceType;
}

type SelectedResource = IResource | undefined;

export default withAppContext((props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const [selectedResource, setSelectedResource] = React.useState<
    SelectedResource
  >();

  const [activeGrant, setActiveGrant] = React.useState<string | undefined>();

  const handleClose = () => {
    setSelectedResource(null);
    setActiveGrant(null);
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button
        primary
        icon={<QSFA icon="plus" />}
        content="Add Permission"
        onClick={() => setOpen(true)}
      />
      <Modal size="tiny" open={open} onClose={() => handleClose()} closeOnDimmerClick={false} closeIcon>
        <Modal.Header>Grant Permission</Modal.Header>
        <Modal.Content>
          <Form>
            <ResourcePicker
              actorId={props.appContext?.currentUser?.id}
              resourceType={selectedResource?.type}
              resourceValue={selectedResource?.id}
              onChange={(type, id) => setSelectedResource({ id, type })}
              noActor
              noEmployee
              noReport
            />
          </Form>
          {selectedResource?.id && selectedResource?.type && (
            <GetActorAccessComponent
              fetchPolicy="no-cache"
              variables={{
                actorId: props.appContext.currentUser.id,
                resource: selectedResource,
              }}
            >
              {({ data }) => (
                <>
                  <Header>
                    <Header.Subheader>
                      Select role from the list to add the appropriate permissions.
                    </Header.Subheader>
                  </Header>
                  <Dropdown
                    fluid
                    selection
                    onChange={(_, { value }) => setActiveGrant(value as string)}
                    placeholder="Resource Type"
                    options={data?.actor?.access?.policies?.map((i) => ({
                      text: i,
                      value: i,
                      key: i,
                    }))}
                  />
                </>
              )}
            </GetActorAccessComponent>
          )}
        </Modal.Content>
        <Modal.Actions>
          <GrantTeamComponent>
            {(query) => (
              <Button
                primary
                content="Grant"
                onClick={() => {
                  query({
                    variables: {
                      policies: [activeGrant],
                      targetId: selectedResource.id,
                      targetType: selectedResource.type,
                      teamId: props.team.id,
                    },
                  }).then(() => {
                    handleClose();
                    if (props.onUpdate) {
                      props.onUpdate();
                    }
                  });
                }}
              />
            )}
          </GrantTeamComponent>
          <Button content="Close" onClick={() => handleClose()} />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
});
