import moment from "moment";
import React from "react";

interface IProps {
    date: number;
}

export default (props: IProps) => {
    const formatDate = (d) => moment.unix(d).format("MMMM Do YYYY");
    const formatTime = (d) => moment.unix(d).fromNow();
    const formatDateTime = (d) => `${formatDate(d)} - ${formatTime(d)}`;
    return (
        <React.Fragment>
            {props.date && formatDateTime(props.date)}
        </React.Fragment>
    );
};
