import React from "react";
import UserAvatar from "react-user-avatar";
import "../styles/components/Shared/Avatar.scss";

interface IEmp {
  firstName: string;
  lastName: string;
}

interface IProps {
  employee?: IEmp;
  size?: "xsmall" | "small" | "sm-medium" | "medium" | "large";
  location?: "top-nav" | null;
}

export const Avatar = ({ employee, size, location }: IProps) => {
  let s = 40;
  let fs = "1em";
  let className = "";
  switch (size) {
    case "xsmall":
      s = 32;
      fs = ".8em";
      break;
    case "small":
      s = 40;
      break;
    case "sm-medium":
      s = 55;
      fs = "1.6em";
      break;
    case "medium":
      s = 60;
      fs = "1.5em";
      break;
    case "large":
      s = 80;
      fs = "2em";
      break;
  }
  switch (location) {
    case "top-nav":
      className = "top-nav";
      break;
  }
  if (employee) {
    const name = `${employee.firstName} ${employee.lastName}`;
    return (
      <div className="qs-avatar">
        <UserAvatar
          style={{fontSize: fs}}
          size={s}
          name={name}
          color={"#151983"}
          location={location}
          className={className} />
      </div>
    );
  }
  return null;
};
