import React from "react";
import { ResourceType } from "../generated/graphql";

import { List, Message, Segment } from "semantic-ui-react";

import EditGroupModal from "./EditGroupModal";
import NewGroupButton from "./NewGroupButton";

interface IGroup {
  id: string;
  name: string;
}

interface IProps {
  loading?: boolean;
  groups: IGroup[];
  refetch?: () => void;
  parentId: string;
  parentType: ResourceType;
}

export default (props: IProps) => {
  const [group, setGroup] = React.useState(null);

  return (
    <React.Fragment>
      <Segment loading={props.loading}>
        {props.groups && props.groups.length > 0 ? (
          <List selection animated>
            {props.groups.map((g) => (
              <List.Item onClick={() => setGroup(g)} key={g.id}>
                <List.Icon name="circle outline" />
                <List.Content verticalAlign="middle">
                  {g.name}
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : (
            <Message color="blue"
              content={`No groups have been created yet. Click the New Group button above to create one.`} />
          )}
      </Segment>
      <EditGroupModal
        group={group}
        open={!!group}
        onClose={() => setGroup(null)}
        onUpdate={props.refetch} />
    </React.Fragment>
  );
};
