import { Form } from "formsy-semantic-ui-react";
import React from "react";
import { Button, Dropdown, Header, Modal, Segment } from "semantic-ui-react";
import { AppContextParams, withAppContext } from "../Context";
import {
  ResourceType,
  AssignEmployeeToUnitComponent,
  UnassignEmployeeFromUnitComponent,
  AssignEmployeeToConceptComponent,
  UnassignEmployeeFromConceptComponent,
  AssignEmployeeToOrganizationComponent,
  UnassignEmployeeFromOrganizationComponent,
  AssignEmployeeToGroupComponent,
  UnassignEmployeeFromGroupComponent,
  EmployeeRelationshipsDocument,
} from "../generated/graphql";
import { actions } from "@quikserve/unityactions";
import { ResourcePicker } from ".";

interface IProps extends AppContextParams {
  employeeId: string;
  open: boolean;
  onUpdate?: () => void;
  setOpen: (boolean) => void;
}

interface Resource {
  id: string;
  type: ResourceType;
}

const BaseAddAccessModal = (props: IProps) => {
  const [selectedResource, setSelectedResource] = React.useState<
    Resource | undefined
  >();

  const reset = () => {
    setSelectedResource(undefined);
  };
  const onClose = () => {
    reset();
    props.setOpen(false);
  };
  const closeButton = <Button content="Close" onClick={onClose} />;

  return (
    <>
      <Modal open={props.open} onClose={onClose} size="tiny" closeOnDimmerClick={false} closeIcon>
        <Modal.Header>Assign Resource</Modal.Header>
        <Modal.Content>
          <Form>
            <ResourcePicker
              only={["Organization", "Group", "Unit", "Concept"]}
              action={actions.structure.write}
              resourceType={selectedResource?.type}
              resourceValue={selectedResource?.id}
              onChange={(type, id) => {
                setSelectedResource({ id, type });
                props.onUpdate();
              }}
            />
          </Form>

          {!selectedResource?.id && <SelectionPlaceholder />}
        </Modal.Content>
        <Modal.Actions>
          {!selectedResource && <>{closeButton}</>}
          {selectedResource?.type === "unit" && (
            <>
              <AssignEmployeeToUnitComponent>
                {(query, { loading }) => (
                  <Button
                    primary
                    loading={loading}
                    content="Save Access"
                    disabled={!selectedResource?.id}
                    onClick={() => {
                      query({
                        variables: {
                          employeeId: props.employeeId,
                          unitId: selectedResource.id,
                        },
                        refetchQueries: [{
                          query: EmployeeRelationshipsDocument,
                          variables: { id: props.employeeId }
                        }]
                      }).then((res) => {
                        props.onUpdate && props.onUpdate();
                        props.setOpen(false);
                        reset();
                      });
                    }}
                  />
                )}
              </AssignEmployeeToUnitComponent>
              {closeButton}
              <UnassignEmployeeFromUnitComponent>
                {(query, { loading }) => (
                  <Button
                    negative
                    loading={loading}
                    disabled={!selectedResource}
                    floated="right"
                    content="Delete Access"
                    onClick={() => {
                      query({
                        variables: {
                          employeeId: props.employeeId,
                          unitId: selectedResource?.id,
                        },
                      }).then(() => {
                        props.setOpen(false);
                        reset();
                        props?.onUpdate();
                      });
                    }}
                  />
                )}
              </UnassignEmployeeFromUnitComponent>
            </>
          )}
          {selectedResource?.type === "concept" && (
            <>
              <AssignEmployeeToConceptComponent>
                {(mutation, { loading }) => (
                  <Button
                    primary
                    loading={loading}
                    content="Save Access"
                    disabled={!selectedResource?.id}
                    onClick={() => {
                      mutation({
                        variables: {
                          employeeId: props.employeeId,
                          conceptId: selectedResource?.id,
                        },
                      }).then(() => {
                        props?.onUpdate();
                        props.setOpen(false);
                        reset();
                      });
                    }}
                  />
                )}
              </AssignEmployeeToConceptComponent>
              {closeButton}
              <UnassignEmployeeFromConceptComponent>
                {(query, { loading }) => (
                  <Button
                    negative
                    loading={loading}
                    disabled={!selectedResource}
                    floated="right"
                    content="Delete Access"
                    onClick={() => {
                      query({
                        variables: {
                          employeeId: props.employeeId,
                          conceptId: selectedResource?.id,
                        },
                      }).then(() => {
                        props.setOpen(false);
                        reset();
                        props?.onUpdate();
                      });
                    }}
                  />
                )}
              </UnassignEmployeeFromConceptComponent>
            </>
          )}
          {selectedResource?.type === "organization" && (
            <>
              <AssignEmployeeToOrganizationComponent>
                {(mutation, { loading }) => (
                  <Button
                    primary
                    loading={loading}
                    content="Save Access"
                    disabled={!selectedResource?.id}
                    onClick={() => {
                      mutation({
                        variables: {
                          employeeId: props.employeeId,
                          organizationId: selectedResource?.id,
                        },
                      }).then(() => {
                        props?.onUpdate();
                        props.setOpen(false);
                        reset();
                      });
                    }}
                  />
                )}
              </AssignEmployeeToOrganizationComponent>
              {closeButton}
              <UnassignEmployeeFromOrganizationComponent>
                {(query, { loading }) => (
                  <Button
                    negative
                    loading={loading}
                    disabled={!selectedResource}
                    floated="right"
                    content="Delete Access"
                    onClick={() => {
                      query({
                        variables: {
                          employeeId: props.employeeId,
                          organizationId: selectedResource?.id,
                        },
                      }).then(() => {
                        props.setOpen(false);
                        reset();
                        props?.onUpdate();
                      });
                    }}
                  />
                )}
              </UnassignEmployeeFromOrganizationComponent>
            </>
          )}
          {selectedResource?.type === "group" && (
            <>
              <AssignEmployeeToGroupComponent>
                {(mutation, { loading }) => (
                  <Button
                    primary
                    loading={loading}
                    content="Save Access"
                    disabled={!selectedResource?.id}
                    onClick={() => {
                      mutation({
                        variables: {
                          employeeId: props.employeeId,
                          groupId: selectedResource?.id,
                        },
                      }).then(() => {
                        props?.onUpdate();
                        props.setOpen(false);
                        reset();
                      });
                    }}
                  />
                )}
              </AssignEmployeeToGroupComponent>
              {closeButton}
              <UnassignEmployeeFromGroupComponent>
                {(query, { loading }) => (
                  <Button
                    negative
                    loading={loading}
                    disabled={!selectedResource}
                    floated="right"
                    content="Delete Access"
                    onClick={() => {
                      query({
                        variables: {
                          employeeId: props.employeeId,
                          groupId: selectedResource?.id,
                        },
                      }).then(() => {
                        props.setOpen(false);
                        reset();
                        props?.onUpdate();
                      });
                    }}
                  />
                )}
              </UnassignEmployeeFromGroupComponent>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SelectionPlaceholder = () => (
  <Segment secondary padded="very">
    <Header textAlign="center">
      <Header.Content>
        Select a Resouce
        <Header.Subheader>Select a resource to assign user to</Header.Subheader>
      </Header.Content>
    </Header>
  </Segment>
);

export const AddAccessModal = withAppContext(BaseAddAccessModal);
