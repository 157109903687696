import { Select } from "formsy-semantic-ui-react";
import React from "react";

import { UnityAction } from "@quikserve/unityactions";
import { ConceptGroupsComponent, GroupsComponent, OrganizationGroupsComponent, ResourceType} from "../generated/graphql";
import { ErrorLabel } from "../Shared";

interface IProps {
  value?: string;
  onChange?: (v: any) => void;
  required?: boolean;
  action?: UnityAction;
  parentType?: ResourceType;
  parentId?: string;
}

const GroupSelect = (props: IProps) => {
  const [disabled, setDisabled] = React.useState(false);
  const timeout = null;

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  if (props.parentType === ResourceType.Organization) {
    return (
      <OrganizationGroupsComponent variables={{ id: props.parentId }}>
        {({ data, loading }) => {
          const orgOptions = data?.organization?.groups?.map((g) => {
            return {
              key: g.id,
              text: g.name,
              value: g.id,
            };
          });
          if (data?.organization?.groups.length === 1 && !props.value) {
            props.onChange(data.organization.groups[0].id);
          }
          return (
            <Select
              fluid
              loading={loading}
              disabled={disabled}
              name="groupId"
              label="Group"
              value={props.value}
              options={orgOptions || []}
              onChange={(e, v) => props.onChange && props.onChange(v.value)}
              selection
              required={props.required}
              validationErrors={{
                isDefaultRequiredValue: "Group is Required",
              }}
              errorLabel={ErrorLabel}
            />
          );
        }}
      </OrganizationGroupsComponent>
    );
  } else if (props.parentType === ResourceType.Concept) {
    return (
      <ConceptGroupsComponent variables={{ id: props.parentId }}>
        {({ data, loading }) => {
          const orgOptions = data?.concept?.groups?.map((g) => {
            return {
              key: g.id,
              text: g.name,
              value: g.id,
            };
          });
          if (data?.concept?.groups.length === 1 && !props.value) {
            props.onChange(data.concept.groups[0].id);
          }
          return (
            <Select
              fluid
              loading={loading}
              disabled={disabled}
              name="groupId"
              label="Group"
              value={props.value}
              options={orgOptions || []}
              onChange={(e, v) => props.onChange && props.onChange(v.value)}
              selection
              required={props.required}
              validationErrors={{
                isDefaultRequiredValue: "Group is Required",
              }}
              errorLabel={ErrorLabel}
            />
          );
        }}
      </ConceptGroupsComponent>
    );
  } else if (!props.parentType) {
    return (
      <GroupsComponent>
        {({ data, loading }) => {
          const opts = data?.groups?.map((g) => {
            return {
              key: g.id,
              text: g.name,
              value: g.id,
            };
          });
          if (data?.groups.length === 1 && !props.value) {
            props.onChange(data.groups[0].id);
          }
          return (
            <Select
              fluid
              loading={loading}
              disabled={disabled}
              name="groupId"
              label="Group"
              value={props.value}
              options={opts || []}
              onChange={(e, v) => props.onChange && props.onChange(v.value)}
              selection
              required={props.required}
              validationErrors={{
                isDefaultRequiredValue: "Group is Required",
              }}
              errorLabel={ErrorLabel}
            />
          );
        }}
      </GroupsComponent>
    );
  }

  return null;
};

export default GroupSelect;
