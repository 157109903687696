import React, { useRef } from "react";
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Header,
  Loader,
  Popup,
  Sticky,
  Table,
} from "semantic-ui-react";
import titleize from "titleize";

import { actions } from "@quikserve/unityactions";
import { RouteComponentProps, withRouter } from "react-router";
import { AppContextParams, withAppContext } from "../Context";
import { ListUnitsComponent, ResourceType, SortDirection } from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import SearchQuery, { IUnit } from "../Search/SearchQuery";
import { FlexGrid, FormattedDate, FormattedPhone, QSFA } from "../Shared";
import DelayedInput from "../Shared/DelayedInput";
import { LoadMore } from "../Shared/LoadMore";

type ColumnNames = "name" | "identifier" | "organizationName" | "city" | "state" | "phone" | null;

type IProps = RouteComponentProps & AppContextParams;

interface IListUnit {
  id: string;
  name: string;
  identifier: string;
  city: string;
  state: string;
  phone: string;
  organizationName: string;
  online: boolean;
  lastSeenAt: number;
}

const UnitList = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState<"active" | "disabled">("active");
  const contextRef = useRef(null);
  const [column, setColumn] = React.useState<ColumnNames>("identifier");
  const [direction, setDirection] = React.useState<"ascending" | "descending">("ascending");
  const [sortValue, setSortValue] = React.useState([{ field: "identifier", direction: SortDirection.Asc }]);

  const handleSort = (clickedColumn: ColumnNames) => {
    setFilter(null);

    if (column !== clickedColumn) {
      const directionVal = SortDirection.Asc;
      setSortValue([{ field: clickedColumn, direction: directionVal }]);
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
    const directionVal = direction === "ascending" ? SortDirection.Desc : SortDirection.Asc;
    setSortValue([{ field: clickedColumn, direction: directionVal }]);
  };

  return (
    <ListUnitsComponent fetchPolicy="network-only" variables={{
      cursor: "",
      disabled: statusFilter === "disabled",
      sort: sortValue,
    }}>
      {({ data, loading, fetchMore }) => {
        let units: IListUnit[] = data?.units?.nodes?.map((n) => ({
          id: n.id,
          name: n.name,
          identifier: n.identifier,
          city: n.city,
          state: n.state,
          phone: n.phone,
          organizationName: n.organization.name,
          online: n.online,
          lastSeenAt: n.lastSeenAt,
        }));
        let listData = units;

        return (
          <SearchQuery
            namespaces={[{ namespace: "units", action: actions.units.read }]}
            filter={filter}
            disabled={statusFilter === "disabled"}
          >
            {(sResp) => {
              if (filter) {
                units = sResp?.data?.hits?.map((h) => {
                  const unit = h.source as IUnit;
                  return {
                    id: unit.id,
                    name: unit.name,
                    identifier: unit.identifier,
                    city: unit.city,
                    state: unit.state,
                    phone: unit.phone,
                    organizationName: unit.organizationName,
                    online: false,
                    lastSeenAt: 0,
                  };
                });
                listData = units;
                setColumn(null);
                setDirection(null);
              }

              return (
                <div ref={contextRef}>
                  <Header dividing as="h1">
                    <QSFA icon={getIcon(ResourceType.Unit)} />Units
                  </Header>
                  <Dimmer inverted active={loading || sResp.loading}>
                    <Loader>Loading</Loader>
                  </Dimmer>
                  <Sticky context={contextRef} offset={50} styleElement={{ backgroundColor: "#fff" }}>
                    <FlexGrid>
                      <FlexGrid.Item flex={1}>
                        <DelayedInput
                          fluid
                          value={filter?.multi_match?.query || ""}
                          loading={sResp.loading}
                          placeholder="Start typing to filter results..."
                          icon="search"
                          iconPosition="left"
                          onNotify={(value) => {
                            value === ""
                              ? setFilter(null)
                              : setFilter({
                                multi_match: {
                                  fields: [
                                    "identifier^5",
                                    "identifier.ngram",
                                    "name^5",
                                    "name.ngram",
                                    "organizationName^4",
                                    "organizationName.ngram",
                                    "phone",
                                    "city",
                                  ],
                                  query: value,
                                  type: "phrase_prefix",
                                },
                              });
                          }}
                        />
                      </FlexGrid.Item>
                      <FlexGrid.Item>
                        <FlexGrid>
                          <FlexGrid.Item verticallyFitted>
                            <Button.Group>
                              <Button
                                active={statusFilter === "active"}
                                basic={statusFilter !== "active"}
                                onClick={() => {
                                  setFilter(null);
                                  setStatusFilter("active");
                                }}
                              >
                                Active
                          </Button>
                              <Button
                                active={statusFilter === "disabled"}
                                basic={statusFilter !== "disabled"}
                                onClick={() => {
                                  setFilter(null);
                                  setStatusFilter("disabled");
                                }}
                              >
                                Disabled
                          </Button>
                            </Button.Group>
                          </FlexGrid.Item>
                          <FlexGrid.Item verticallyFitted fittedRight>
                            <Restrict action={actions.units.create}>
                              <Button
                                size="small"
                                primary
                                onClick={() => props.history.push(`/config/units/add`)}
                              >
                                <QSFA icon="plus-circle" padded="regular" />Add Unit
                              </Button>
                            </Restrict>
                          </FlexGrid.Item>
                        </FlexGrid>
                      </FlexGrid.Item>
                    </FlexGrid>
                  </Sticky>
                  <Container fluid>
                    <Table sortable fixed selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            sorted={column === "identifier" ? direction : null}
                            onClick={() => handleSort("identifier")}
                          >
                            Identifier
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "name" ? direction : null}
                            onClick={() => handleSort("name")}
                          >
                            Name
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={3}
                          >
                            Organization
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            sorted={column === "city" ? direction : null}
                            onClick={() => handleSort("city")}
                          >
                            City
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={2}
                            sorted={column === "state" ? direction : null}
                            onClick={() => handleSort("state")}
                          >
                            State
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            width={3}
                            sorted={column === "phone" ? direction : null}
                            onClick={() => handleSort("phone")}
                          >
                            Phone
                          </Table.HeaderCell>
                          {!filter &&
                            <Table.HeaderCell>
                              Hub Status
                            </Table.HeaderCell>
                          }
                          <Table.HeaderCell width={1}>&nbsp;</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {listData?.map(({ name, identifier, organizationName, city, state, phone, id, online, lastSeenAt }, index) => (
                          <Table.Row key={id + index}
                            onClick={() => props.history.push(`/config/units/${id}`)}
                            style={{ cursor: "pointer" }}
                          >
                            <Table.Cell>{identifier}</Table.Cell>
                            <Table.Cell
                              width={4}
                            >
                              {titleize(name || "")}
                            </Table.Cell>
                            <Table.Cell>
                              {organizationName}
                            </Table.Cell>
                            <Table.Cell>
                              {titleize(city || "")}
                            </Table.Cell>
                            <Table.Cell>
                              {state?.toUpperCase()}
                            </Table.Cell>
                            <Table.Cell
                              width={4}
                            >
                              {phone && <FormattedPhone phone={phone} />}
                            </Table.Cell>
                            {!filter &&
                              <Table.Cell width={1}>
                                <Popup
                                  content={<FormattedDate date={lastSeenAt} />}
                                  trigger={<QSFA icon="signal-stream" color={online ? "green" : "red"} />}
                                />
                              </Table.Cell>
                            }
                            <Table.Cell width={1} textAlign="right">
                              <QSFA icon="chevron-right" padded />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Container>
                  <Divider hidden />
                  {!filter && (
                    <>
                      <LoadMore
                        fetchMore={fetchMore}
                        nodeName="units"
                        cursor={data?.units?.cursor}
                        hasMore={data?.units?.cursor !== ""}
                      />
                      <Divider hidden />
                    </>
                  )}
                </div>
              );
            }}
          </SearchQuery>
        );
      }}
    </ListUnitsComponent>
  );
};

export default withRouter(withAppContext(UnitList));
