import React from "react";
import { Container, Divider, Grid, Image, Segment } from "semantic-ui-react";

interface IProps {
  children: React.ReactNode;
}

export const AuthContainer = (props: IProps) => {
  return (
    <div className="qs-login-container">
      <Grid
        textAlign="center"
        style={ { height: "100%", margin: 0 } }
        verticalAlign="top"
      >
        <Grid.Column style={ { maxWidth: 450, marginTop: "10%" } }>
          <Image src="https://cdn.quikserve.com/logos/quikserve/qs-app-logo-standard.svg" size="small" spaced centered />
          <Divider hidden />
          <Container textAlign="left" fluid>
            <Segment>
              { props.children }
            </Segment>
          </Container>
        </Grid.Column>
      </Grid>
    </div>
  );
};
