import { get } from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Header,
  Table,
} from "semantic-ui-react";

import { actions } from "@quikserve/unityactions";
import { GetOrganizationComponent, ResourceType } from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import { FormattedDate, FormattedPhone, QSFA } from "../Shared";

interface IParams {
  id: string;
}

type IProps = RouteComponentProps<IParams>;

const OrganizationDetail = (props: IProps) => {
  return (
    <GetOrganizationComponent variables={{ id: props.match.params.id }}>
      {({ data, loading }) => (
        <>
          <Restrict action={actions.organizations.write} type={ResourceType.Organization} id={props.match.params.id}>
            <Button primary
              onClick={() => props.history.push(`${props.match.url}/edit`)}
            >
              <QSFA icon="edit" />Edit Details
            </Button>
          </Restrict>
          <Header as="h3">
            <QSFA icon={getIcon(ResourceType.Organization)} size="small" />Details
          </Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Name</Table.Cell>
                <Table.Cell>
                  {data?.organization?.name}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Header as="h3">
            <QSFA icon="address-card" size="small" />Contact Information
          </Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Phone Number</Table.Cell>
                <Table.Cell>
                  {data?.organization && <FormattedPhone phone={data.organization.phone} />}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Address</Table.Cell>
                <Table.Cell>
                  {data?.organization &&
                    `${data.organization.street1} ${data.organization.street2}, ${data.organization.city} ${data.organization.state}`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h3">
            <QSFA icon="history" size="small" />History
          </Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width="4">Account created</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={get(data, "organization.createdAt")} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width="4">Account last updated</Table.Cell>
                <Table.Cell>
                  <FormattedDate date={get(data, "organization.updatedAt")} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </GetOrganizationComponent>
  );
};

export default withRouter(OrganizationDetail);
