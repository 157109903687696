import React from "react";
import { Helmet } from "react-helmet";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";

import { AppContextParams, withAppContext } from "../Context";
import UnitAdd from "./UnitAdd";
import UnitDetail from "./UnitDetail";
import UnitEdit from "./UnitEdit";
import UnitTransfer from "./UnitTransfer";
import UnitList from "./UnitList";

interface IProps extends RouteComponentProps, AppContextParams { }

const Unit = (props: IProps) => {

  React.useEffect(() => {
    props.appContext.setCurrentModule("units");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Units</title>
      </Helmet>
      <Switch>
        <Route exact path="/config/units" component={UnitList}/>
        <Route exact path="/config/units/add" component={UnitAdd}/>
        <Route exact path="/config/units/:id/edit" component={UnitEdit}/>
        <Route exact path="/config/units/:id/transfer" component={UnitTransfer}/>
        <Route exact path="/config/units/:id" component={UnitDetail}/>
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(withAppContext(Unit));
