import { Form } from "formsy-semantic-ui-react";
import _ from "lodash";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
} from "semantic-ui-react";

import { ConceptComponent, UpdateConceptComponent, ResourceType } from "../generated/graphql";
import { ErrorLabel, QSFA } from "../Shared";
import { getIcon } from "../Resources";

interface IParams {
  id: string;
}

type IProps = RouteComponentProps<IParams>;

const OrgnaizationEdit = (props: IProps) => {
  const id = props.match.params.id;
  const [name, setName] = React.useState("");

  return (
    <ConceptComponent variables={{ id: props.match.params.id }} onCompleted={(data) => {
      const o = data.concept;
      if (o) {
        setName(o.name);
      }
    }}>
      {({ data, loading }) => (
        <React.Fragment>
          <QSFA icon={getIcon(ResourceType.Concept)} size="small" />
          <Breadcrumb size="big">
            <Breadcrumb.Section>
              <Link to={"/config/concepts"}> Concepts</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section>
              <Link to={`/config/concepts/${props.match.params.id}`}>
                {data?.concept?.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Edit</Breadcrumb.Section>
          </Breadcrumb>
          <Divider />

          <Segment basic>
            <Header as="h1" dividing>
              <QSFA icon={getIcon(ResourceType.Concept)} />{data?.concept?.name}
            </Header>
            <Divider hidden />
            <Form>
              <Header as="h3" content="Details" />
              <Form.Group widths="equal">
                <Form.Input
                  name="name"
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  value={name}
                  validations="maxLength:100"
                  errorLabel={ErrorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: "Name is Required",
                    maxLength: "Exceeded the max of 100 characters",
                  }}
                />
              </Form.Group>
              <UpdateConceptComponent>
                {(query, { loading: updating }) => (
                  <Button.Group>
                    <Button content="Save Concept" primary
                      loading={updating}
                      onClick={() => {
                        query({
                          variables: {
                            id,
                            name,
                          },
                        }).then(() => {
                          props.history.push(`/config/concepts/${props.match.params.id}`);
                        });
                      }} />
                    <Button
                      onClick={() => props.history.push(`/config/concepts/${props.match.params.id}`)}
                      content="Cancel" />
                  </Button.Group>
                )}
              </UpdateConceptComponent>
            </Form>
          </Segment>
        </React.Fragment>
      )}
    </ConceptComponent>
  );
};

export default withRouter(OrgnaizationEdit);
