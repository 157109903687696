import { actions } from "@quikserve/unityactions";
import { Form } from "formsy-semantic-ui-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, ButtonGroup, Dimmer, Divider, List, Loader, Message, Placeholder, Segment } from "semantic-ui-react";
import { ConceptSelect } from "../Concept";
import { AddConceptToOrganizationComponent, OrganizationConceptsComponent } from "../generated/graphql";

interface IParams {
  id: string;
}

type IProps = RouteComponentProps<IParams>;

export default (props: IProps) => {
  const [showAddSelect, setShowAddSelect] = React.useState(false);
  const [conceptId, setConceptId] = React.useState(null);

  return (
    <OrganizationConceptsComponent variables={{id: props.match.params.id}}>
      {({data, loading, refetch}) => (
        <>
          <Dimmer inverted active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Message info>
            Concepts may be linked to an organization. New units created inside
            the organization can then be assigned to one of these concepts. By
            default actors within the concept will then have access to the
            linked units.
          </Message>
          {!showAddSelect &&
            <Button primary content="Link a Concept"
              onClick={() => setShowAddSelect(true)}
            />
          }
          {showAddSelect &&
            <>
              <Divider hidden />
              <Form>
                <Form.Field>
                  <ConceptSelect
                    action={actions.concepts.write}
                    value={conceptId}
                    onChange={(v) => setConceptId(v)}
                    required
                  />
                </Form.Field>
                <ButtonGroup>
                  <AddConceptToOrganizationComponent>
                  {(update, {loading: l}) => (
                    <Button primary content="Add Concept" loading={l}
                      onClick={() => update({
                        variables: {
                          conceptId,
                          orgId: props.match.params.id,
                        },
                      }).then(() => {
                        setConceptId(null);
                        setShowAddSelect(false);
                        refetch();
                      })}
                    />
                  )}
                  </AddConceptToOrganizationComponent>
                  <Button content="Cancel"
                    onClick={() => {
                      setShowAddSelect(false);
                      setConceptId(null);
                    }}
                  />
                </ButtonGroup>
              </Form>
            </>
          }
          <Segment loading={loading}>
            <List>
              {data?.organization?.concepts?.map((c) => (
                <List.Item key={c.id} content={c.name} />
              ))}
            </List>
          </Segment>
        </>
      )}
    </OrganizationConceptsComponent>
  );
};
