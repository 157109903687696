import React from "react";
import { client } from "../apollo";
import { AppContextParams, withAppContext } from "../Context";
import { CheckPrivilegesDocument, CheckResourcePrivilegesDocument, ResourceType } from "../generated/graphql";

interface IPrivilegesContextParams {
  Privileges: PrivilegesContext;
}
export type PrivilegesContextParams = IPrivilegesContextParams;
export type Permission = "inventory" | "reports" | "singleUnit" | "system";

interface IResource {
  type: ResourceType;
  id: string;
}

interface IPrivilegesContext {
  cache: ICache;
  queryPrivileges: (resources: IResource[], actions: string[]) => Promise<void>;
  hasPermission: (p: Permission) => boolean;
  isAllowed: (resource: IResource, action: string) => Promise<boolean>;
  isAllowedAny: (action: string) => Promise<boolean>;
}

type Unit = any;
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Bound<P> = React.SFC<Omit<P, keyof IPrivilegesContextParams>>;
type Incomming<P> = React.ComponentType<P>;

export type PrivilegesContext = IPrivilegesContext;

const Context = React.createContext<PrivilegesContext>({} as PrivilegesContext);

export const withPrivileges = <P extends Object>(Component: Incomming<P>): Bound<P> => (props) => (
  <Context.Consumer>
    {(value: IPrivilegesContext) => <Component {...props as P} Privileges={value} />}
  </Context.Consumer>
);

interface IActionList {
  [index: string]: boolean;
}

interface ICache {
  [index: string]: IActionList;
}

export const PrivilegesProvider = withAppContext(class PrivilegesProvider extends React.Component<AppContextParams> {
  public state: PrivilegesContext = {
    cache: {},
    hasPermission: this.hasPermission.bind(this),
    isAllowed: this.isAllowed.bind(this),
    isAllowedAny: this.isAllowedAny.bind(this),
    queryPrivileges: this.queryPrivileges.bind(this),
  };

  public async queryPrivileges(resources: IResource[], actions: string[]): Promise<void> {
    await client.resetStore();
    const res = await client.query({
      query: CheckResourcePrivilegesDocument,
      fetchPolicy: "no-cache",
      variables: {
        source: { id: this.props.appContext.currentUser.id, type: ResourceType.Actor },
        targets: resources,
        actions,
      },
    });
    const cache = this.state.cache;
    res?.data?.checkResourcePrivileges[0]?.privileges?.forEach((p, i) => {
      if (!cache[`${resources[i].id}-${resources[i].type}`]) {
        cache[`${resources[i].id}-${resources[i].type}`] = {};
      }
      cache[`${resources[i].id}-${resources[i].type}`][p.action] = p.allowed;
    });
    this.setState({ cache });
  }

  public hasPermission(permission: Permission): boolean {
    return !!this.props.appContext.currentUser.permissions[permission];
  }

  public async queryAnyPrivileges(actions: string[]): Promise<void> {
    await client.resetStore();
    const res = await client.query({
      query: CheckPrivilegesDocument,
      fetchPolicy: "no-cache",
      variables: {
        source: { id: this.props.appContext.currentUser.id, type: ResourceType.Actor },
        actions,
      },
    });

    const cache = {};
    res.data.checkPrivileges.forEach((itm) => {
      cache[itm.action] = itm.allowed;
    });

    this.setState({
      cache: {
        ...this.state.cache,
        ...cache,
      },
    });
  }

  public async isAllowedAny(action: string): Promise<boolean> {
    if (this.state.cache[action] === undefined) {
      await this.queryAnyPrivileges([action]);
    }
    return !!this.state.cache[action];
  }

  public async isAllowed(resource: IResource, action: string): Promise<boolean> {
    const { id, type } = resource;
    if (this.state.cache[`${id}-${type}`] === undefined || this.state.cache[`${id}-${type}`][action] === undefined) {
      await this.queryPrivileges([resource], [action]);
    }
    return !!this.state.cache[`${id}-${type}`][action];
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
});
