import Auth from "@aws-amplify/auth";
import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { resolvers } from "./Mock";
import { logger } from "./Utilities/Logger";

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_UNITY_GRAPHQL_URL ||
    "https://api.quikserve.com/graphql",
});

let token;
const authLink = setContext((_, { headers }) => {
  if (new URLSearchParams(window.location.search).has("t")) {
    const authorization = atob(new URLSearchParams(window.location.search).get("t"));
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  }
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  }
  return Auth.currentAuthenticatedUser().then((u) => {
    const authorization = u.signInUserSession.accessToken.jwtToken;
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });
});

const reportErrors = onError(({ graphQLErrors, networkError = {} as any }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      logger.error("graphql error", { message, locations, path });
    });
  }
  if (networkError) {
    logger.error("network error", { message: networkError });
    if (
      networkError.name === "ServerError" &&
      networkError.statusCode === 401
    ) {
      // todo what to do here?
      Auth.signOut();
    }
  }
});

const startLink = new ApolloLink((operation, forward) => {
  logger.info("starting operation", { operation: operation.operationName });
  operation.setContext({ start: new Date() });
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const time = new Date().getTime() - operation.getContext().start;
    logger.info("completed operation", {
      operation: operation.operationName,
      time,
    });
    return data;
  });
});

// removes '__typename' field without affecting cache
const cleanTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  };
  return forward(operation).map((data) => {
    return data;
  });
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        // @ts-ignore
        case "Resource": return `${object.type}-${object.id}`; // use the `key` field as the identifier
        default: return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
  }),
  link: ApolloLink.from([
    cleanTypename,
    startLink,
    reportErrors,
    authLink,
    logTimeLink,
    httpLink,
  ]),
  resolvers,
});

export type Client = ApolloClient<NormalizedCacheObject>;
