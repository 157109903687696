import React from "react";
import { Sticky } from "semantic-ui-react";

interface IProps {
  context: React.RefObject<any>;
  children: React.ReactNode;
  offset?: number;
}

export default (props: IProps) => {
  const topNavHeight = 50;
  return (
    <Sticky context={props.context} offset={props.offset ? topNavHeight + props.offset : topNavHeight}>
      {props.children}
    </Sticky>
  );
};
