import { Select } from "formsy-semantic-ui-react";
import React from "react";

import { actions, UnityAction } from "@quikserve/unityactions";
import { ConceptsComponent, SortDirection } from "../generated/graphql";
import SearchQuery, { IConcept } from "../Search/SearchQuery";
import { ErrorLabel } from "../Shared";

interface IProps {
  value?: string[] | string;
  onChange?: (v: any) => void;
  required?: boolean;
  action?: UnityAction;
  multiple?: boolean;
}

const ConceptSelect = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  let timeout = null;

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const ph = props.multiple ? "Select one or more concepts" : "Select a concept";
  return (
    <ConceptsComponent variables={{ action: props.action, sort: [{field: "name", direction: SortDirection.Asc}] }}>
      {({ data, loading }) => {
        const conOptions = data?.concepts?.nodes.map((con) => {
          return {
            key: con.id,
            text: con.name,
            value: con.id,
          };
        });
        if (data?.concepts?.nodes.length === 1 && !props.value) {
          props.onChange(data.concepts.nodes[0].id);
          setDisabled(true);
        }
        return (
          <SearchQuery namespaces={[{namespace: "concepts", action: props.action || actions.concepts.read}]} filter={filter}>
            {({ data, loading }) => {
              const sconOptions = data ? data.hits.map((h) => {
                const org = h.source as IConcept;
                return {
                  key: org.id,
                  text: org.name,
                  value: org.id,
                };
              }) : [];
              return (
                <Select
                  fluid
                  loading={loading}
                  disabled={disabled}
                  name="conceptId"
                  label="Concept"
                  value={props.value}
                  options={filter ? sconOptions : conOptions ? conOptions : []}
                  onChange={(e, v) => props.onChange(v.value)}
                  onSearchChange={(e, {searchQuery}) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      searchQuery === "" ? setFilter(null) : setFilter({
                        multi_match: {fields: ["name"], query: searchQuery, type: "phrase_prefix"},
                      });
                    }, 500);
                  }}
                  placeholder={ph}
                  selection
                  search
                  multiple={props.multiple}
                  required={props.required}
                  validationErrors={{
                    isDefaultRequiredValue: "Concept is Required",
                  }}
                  errorLabel={ErrorLabel}
                />
              );
            }}
          </SearchQuery>
        );
      }}
    </ConceptsComponent>
  );
};

export default ConceptSelect;
