import { Form } from "formsy-semantic-ui-react";
import React from "react";
import { Button, Dropdown, Header, Modal, Segment } from "semantic-ui-react";
import { AppContextParams, withAppContext } from "../Context";
import {
  CreateGrantComponent,
  DeleteGrantComponent,
  ManageGrantsComponent,
  ResourceType,
} from "../generated/graphql";
import { ResourcePicker } from "../Resources";

interface IProps extends AppContextParams {
  actorId: string;
  onUpdate?: () => void;
  open: boolean;
  setOpen: (boolean) => void;
}

interface Resource { id: string; type: ResourceType; }

const BaseAddGrantModal = (props: IProps) => {
  const { currentUser } = props.appContext;
  const [selectedResource, setSelectedResource] = React.useState<
    Resource | undefined
  >();

  const [activeGrant, setActiveGrant] = React.useState<string | undefined>();
  const reset = () => {
    setSelectedResource(undefined);
  };
  const onClose = () => {
    reset();
    props.setOpen(false);
  };

  return (
    <>
      <Modal open={props.open} onClose={onClose} size="tiny" closeOnDimmerClick={false} closeIcon>
        <Modal.Header>Add Permission</Modal.Header>
        <Modal.Content>
          <Form>
            <ResourcePicker
              actorId={props.appContext.currentUser.id}
              resourceType={selectedResource?.type}
              resourceValue={selectedResource?.id}
              onChange={(type, id) => setSelectedResource({ id, type })}
              noActor
              noEmployee
              noReport
            />
          </Form>

          {!selectedResource?.id && <SelectionPlaceholder />}
          {selectedResource?.id && (
            <ManageGrantsComponent
              fetchPolicy="no-cache"
              variables={{
                sourceId: props.actorId,
                resource: selectedResource,
                currentUser: currentUser.id,
              }}
            >
              {({ data, loading }) => (
                <>
                  <Header>
                    <Header.Subheader>
                      Select roles from the list to add the appropriate permissions.
                    </Header.Subheader>
                  </Header>
                  <Dropdown
                    fluid
                    selection
                    onChange={(_, { value }) => setActiveGrant(value as string)}
                    placeholder="Resource Type"
                    options={data?.currentUser?.access?.policies?.map((i) => ({
                      text: i,
                      value: i,
                      key: i,
                    }))}
                  />
                </>
              )}
            </ManageGrantsComponent>
          )}
        </Modal.Content>
        <Modal.Actions>
          <CreateGrantComponent>
            {(query, { loading }) => (
              <Button
                primary
                loading={loading}
                content="Save Permission"
                disabled={!activeGrant}
                onClick={() => {
                  query({
                    variables: {
                      policies: [activeGrant],
                      source: {
                        id: props.actorId,
                        type: ResourceType.Actor,
                      },
                      target: {
                        id: selectedResource.id,
                        type: selectedResource.type,
                      },
                    },
                  }).then((data) => {
                    props.onUpdate && props.onUpdate();
                    props.setOpen(false);
                    reset();
                  });
                }}
              />
            )}
          </CreateGrantComponent>
          <Button content="Close" onClick={onClose} />
          <DeleteGrantComponent>
            {(query, { loading }) => (
              <Button
                negative
                loading={loading}
                disabled={!selectedResource}
                floated="right"
                content="Delete Permission"
                onClick={() => {
                  query({
                    variables: {
                      source: {
                        id: props.actorId,
                        type: ResourceType.Actor,
                      },
                      target: {
                        id: selectedResource.id,
                        type: selectedResource.type,
                      },
                    },
                  }).then(() => {
                    props.setOpen(false);
                    reset();
                    props.onUpdate && props.onUpdate();
                  });
                }}
              />
            )}
          </DeleteGrantComponent>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SelectionPlaceholder = () => (
  <Segment secondary padded="very">
    <Header textAlign="center">
      <Header.Content>
        Select a Resouce
        <Header.Subheader>
          Select a resource to assign policies
        </Header.Subheader>
      </Header.Content>
    </Header>
  </Segment>
);

export const AddGrantModal = withAppContext(BaseAddGrantModal);
