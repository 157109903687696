import { Select } from "formsy-semantic-ui-react";
import React from "react";

import { actions, UnityAction } from "@quikserve/unityactions";
import { ListUnitsComponent, SortDirection } from "../generated/graphql";
import SearchQuery, { IUnit } from "../Search/SearchQuery";
import { ErrorLabel } from "../Shared";

interface IProps {
  value?: string[] | string;
  onChange?: (v: any) => void;
  required?: boolean;
  action?: UnityAction;
  multiple?: boolean;
  placeholder?: string;
  icon?: any;
  history?: any;
}

const UnitSelect = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  let timeout = null;

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  const unitsMessage = props.multiple ? "Select one or more units" : "Select a unit";
  const ph = props.placeholder ? props.placeholder : unitsMessage;
  return (
    <ListUnitsComponent variables={{action: props.action,
      sort: [{ field: "name", direction: SortDirection.Asc }]}}>
      {({ data, loading }) => {
        const conOptions = data && data.units && data.units.nodes.map((con) => {
          return {
            key: con.id,
            text: `${con.name} (${con.identifier})`,
            value: con.id,
          };
        });
        if (data?.units?.nodes.length === 1 && !props.value) {
          props.onChange(data.units.nodes[0].id);
          setDisabled(true);
        }
        return (
          <SearchQuery namespaces={[{ namespace: "units", action: props.action || actions.units.read }]} filter={filter}>
            {({data, loading}) => {
              const unitOptions = data && data.hits.map((h) => {
                const u = h.source as IUnit;
                return {
                  key: u.id,
                  text: `${u.name} (${u.identifier})`,
                  value: u.id,
                };
              });
              return (
                <Select
                  fluid
                  loading={loading}
                  disabled={disabled}
                  name="unitId"
                  label="Unit"
                  icon={props.icon}
                  value={props.value}
                  options={filter ? unitOptions : conOptions ? conOptions : []}
                  onChange={(e, v) => {
                    props.onChange && props.onChange(v.value);
                  }}
                  onSearchChange={(e, {searchQuery}) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      searchQuery === "" ? setFilter(null) : setFilter({
                        multi_match: {fields: ["name", "identifier"], query: searchQuery, type: "phrase_prefix"},
                      });
                    }, 500);
                  }}
                  placeholder={ph}
                  selection
                  search
                  multiple={props.multiple}
                  required={props.required}
                  validationErrors={{
                    isDefaultRequiredValue: "Unit is Required",
                  }}
                  errorLabel={ErrorLabel}
                />
              );
            }}
          </SearchQuery>
        );
      }}
    </ListUnitsComponent>
  );
};

export default UnitSelect;
