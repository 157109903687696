import { Resolvers } from "apollo-client";
import { Mutation } from "./Mutation";
import { Query } from "./Query";
import { resources } from "./Resources";
import { grant } from "./Grant";

export const resolvers: Resolvers = {
  Mutation,
  Query,
  Actor: {
    resources,
    grant
  }
};
