import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import "../styles/components/Shared/KeyVal.scss";

interface IProps {
  k: string;
  v: boolean | string | number | Element | JSX.Element;
  plain?: boolean;
  valuecolor?: "none" | "bright-blue";
}

export const KeyVal = ({ k, v, plain, valuecolor }: IProps) => {
  const isPlain = plain ? "plain-grid" : "key-value";

  return (
    <>
      <Grid padded={ false } verticalAlign="middle" className={`${isPlain}`}>
        <Grid.Row>
          <Grid.Column width={ 5 } >
            <Segment basic className="key" >{ k }</Segment>
          </Grid.Column>
          <Grid.Column width={ 11 }>
            <Segment basic className={`value ${valuecolor}`}>{ v }</Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
