import Auth from "@aws-amplify/auth";
import { UnityAction } from "@quikserve/unityactions";
import _ from "lodash";
import React from "react";
import { withAppContext } from "../Context";
import { ResourceType } from "../generated/graphql";
import { getInterceptors, processInterceptors } from "./SearchInterceptor";

export type ResultTypeName =
  | "units"
  | "employees"
  | "organizations"
  | "concepts"
  | "components"
  | "products"
  | "reports"
  | "reportgroups"
  | "configs";
export type ResultType =
  | (IUnit & IOrganization)
  | IConcept
  | IEmployee
  | IComponent
  | IProductGroupProduct
  | IReport
  | IReportGroup
  | IConfig;

interface IProps {
  children: (result: IResult) => JSX.Element | null;
  namespaces?: Array<{
    namespace: ResultTypeName;
    action?: UnityAction;
    includeParentOfType?: ResourceType;
    mapParentOfType?: ResourceType;
  }>;
  filter: any;
  disabled?: boolean;
  blacklist?: Array<{
    namespace: ResultTypeName;
    id: string;
  }>;
}

interface IResult {
  data?: IData;
  error?: any;
  loading: boolean;
}

export interface IData {
  took: number;
  timedOut: boolean;
  hits: IHit[];
}

export interface IHit {
  index: string;
  type: string;
  id: string;
  score: number;
  source:
  | IUnit
  | IOrganization
  | IConcept
  | IEmployee
  | IProductGroupProduct
  | IComponent
  | IReport
  | IReportGroup
  | IConfig;
  key: string;
}

export interface IComponent {
  id: string;
  shortName: string;
  longName: string;
}

export interface IUnit {
  id: string;
  name: string;
  identifier: string;
  city: string;
  state: string;
  phone: string;
  organizationId: string;
  organizationName: string;
  conceptIds: string[];
  conceptNames: string[];
}

export interface IOrganization {
  id: string;
  name: string;
  identifier: string;
  city: string;
  state: string;
  phone: string;
}

export interface IConcept {
  id: string;
  name: string;
}

export interface IEmployee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  appAccessEnabled: boolean;
}

export interface IProductGroupProduct {
  id;
  sortIndex;
  product: {
    id;
    longName;
    shortName;
    plu;
  };
}
export interface IProduct {
  id: string;
  longName: string;
  shortName: string;
  plu: string;
}

export interface IReport {
  id: string;
  name: string;
}

export interface IReportGroup {
  id: string;
  name: string;
  reports?: [IReport];
}

export interface IConfig {
  id: string;
  name: string;
  parent_name: string;
  parent_id?: string;
}

interface IBody {
  unity?: {
    disabled?: boolean;
    namespaces?: Array<{
      namespace: ResultTypeName;
      action?: UnityAction;
    }>;
    includeParentOfType?: ResourceType;
    mapParentOfType?: ResourceType;
  };
}

const searchUrl =
  process.env.REACT_APP_UNITY_SEARCH_URL || "https://api.quikserve.com/search";

const SearchQuery = (props: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [token, setToken] = React.useState(null);


  React.useEffect(() => {
    if (!token) {
      Auth.currentAuthenticatedUser().then((u) => {
        setToken(u.signInUserSession.accessToken.jwtToken);
      });
    }
    if (token && props.filter && props.filter !== "") {
      setLoading(true);

      const body: IBody = { unity: { disabled: props.disabled } };
      Object.assign(body, props.filter);
      if (props.namespaces) {
        body.unity.namespaces = props.namespaces.map((ns) => ({
          action: ns.action || undefined,
          namespace: ns.namespace,
          includeParentOfType: ns.includeParentOfType,
          mapParentOfType: ns.mapParentOfType,
        }));
      }

      const url = searchUrl + "/resources/";
      fetch(url, {
        body: JSON.stringify(body),
        headers: {
          "Authorization": token,
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((resp) => {

          setLoading(false);
          resp
            .json()
            .then((d) =>
              setData(
                parseData(
                  (props.filter.multi_match &&
                    props.filter.multi_match.query) ||
                  [],
                  d,
                  props.blacklist,
                ),
              ),
            );
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }
  }, [props.filter, props.namespaces]);

  return props.children ? props.children({ data, loading, error }) : null;
};

export default withAppContext(SearchQuery);

const parseData = (search: string, v: any, blacklist: Array<{namespace: ResultTypeName, id: string}>): IData => {
  return ({
  hits: [
    ...((v && v.hits && blacklist?.length ? v.hits.hits.filter((hit) => {
      const index = hit._index.split("-")[0]
      const id = hit._id.split(":")[1]
      return blacklist?.find(b => !(b.id === id && b.namespace === index))
    }).map(parseHit) : v.hits.hits.map(parseHit)) || []),
    ...processInterceptors(search),
  ],
  timedOut: v && v.timed_out,
  took: v && v.took,
})};

const parseSearchObject = <T extends {}>(data: {
  _id: string;
  _source: T;
}): T => ({
  ...data._source,
  id: data._id.split(":")[1],
});

const parseHit = (data: any): IHit => ({
  id: data._id,
  index: data._index.split("-")[0],
  key: data._id,
  score: data._score,
  type: data._type,
  ...(data._index.split("-")[0] === "units" && {
    source: parseSearchObject<IUnit>(data),
  }),
  ...(data._index.split("-")[0] === "organizations" && {
    source: parseSearchObject<IOrganization>(data),
  }),
  ...(data._index.split("-")[0] === "concepts" && {
    source: parseSearchObject<IConcept>(data),
  }),
  ...(data._index.split("-")[0] === "employees" && {
    source: parseSearchObject<IEmployee>(data),
  }),
  ...(data._index.split("-")[0] === "products" && {
    source: parseSearchObject<IProductGroupProduct>(data),
  }),
  ...(data._index.split("-")[0] === "reports" && {
    source: parseSearchObject<IReport>(data),
  }),
  ...(data._index.split("-")[0] === "reportgroups" && {
    source: parseSearchObject<IReportGroup>(data),
  }),
  ...(data._index.split("-")[0] === "configs" && {
    source: parseSearchObject<IConfig>(data)
  }),
});
