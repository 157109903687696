import { RequireNewPassword as AmplifyRequireNewPassword } from "aws-amplify-react";
import * as React from "react";
import { Button, Form, Grid, Header, Input } from "semantic-ui-react";
import { AuthContainer } from "./AuthContainer";

interface IProps {
  authData: any;
}

export default class RequireNewPassword extends AmplifyRequireNewPassword {
  public _validAuthStates: string[];

  constructor(props) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  public showComponent() {
    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <AuthContainer>
        <Header as="h4" textAlign="left">
          { " " }
          Change Password
        </Header>
        <Form>
          <Form.Field>
            <Input
              fluid
              id="password"
              key="password"
              icon="lock"
              iconPosition="left"
              placeholder="Enter a new password"
              type="password"
              name="password"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </Form.Field>
          {requiredAttributes.map((attribute) => (
            <Form.Field>
              <Input
                fluid
                placeholder={ attribute }
                key={ attribute }
                name={ attribute }
                type="text"
                onChange={this.handleInputChange}
              />
            </Form.Field>
          ))}
          <Grid columns={2}>
            <Grid.Column textAlign="left" verticalAlign="bottom">
              <a style={{ cursor: "pointer" }}
                onClick={ () => this.changeState("signIn") }>
                Back to Sign In
              </a>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button color="blue" type="submit" onClick={ () => this.change() }>
                Submit
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      </AuthContainer>
    );
  }
}
