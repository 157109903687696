import React from "react";
import {
  Modal,
  Button,
  Segment,
  Dropdown,
  Header,
  List,
  Message,
} from "semantic-ui-react";
import { QSFA } from "../Shared";
import { ResourcePicker, ISelectedResource } from "./ResourcePicker";
import { AppContextParams, withAppContext } from "../Context";
import {
  ManageGrantsComponent,
  CreateGrantComponent,
  ResourceType,
  DeleteGrantComponent,
} from "../generated/graphql";
import { ResourceIcon } from "../Resources";

interface IProps extends AppContextParams {
  actorId: string;
  resourceId: string;
  resourceType: ResourceType;
  resourceName: string;
  onUpdate?: () => void;
}

const BaseEditGrantButton = (props: IProps) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  const { currentUser } = props.appContext;

  const [activeGrant, setActiveGrant] = React.useState<string | undefined>();

  return (
    <>
      <Button
        icon={<QSFA icon="edit" />}
        content="Edit"
        onClick={() => setOpen(true)}
      />
      <Modal open={open} onClose={onClose} size="tiny" closeOnDimmerClick={false} closeIcon>
        <Modal.Header>Grant Access</Modal.Header>
        <Modal.Content>
          <Segment>
            <Header>
              <Header.Content>
                <ResourceIcon size="small" type={props.resourceType} />{" "}
                {props.resourceName}
              </Header.Content>
            </Header>
          </Segment>
          <ManageGrantsComponent
            fetchPolicy="no-cache"
            variables={{
              sourceId: props.actorId,
              resource: { id: props.resourceId, type: props.resourceType },
              currentUser: currentUser.id,
            }}
            onCompleted={(data) => {
              console.log(data);
              setActiveGrant(data?.source?.grant?.policies[0]);
            }}
          >
            {({ data, loading }) => (
              <>
                <Header>
                  <Header.Subheader>
                    Select roles from the list to add the appropriate access
                    rights.
                  </Header.Subheader>
                </Header>
                <Dropdown
                  fluid
                  selection
                  value={activeGrant}
                  loading={loading}
                  onChange={(_, { value }) => setActiveGrant(value as string)}
                  placeholder="Resource Type"
                  options={
                    data?.currentUser?.access?.policies.map((i) => ({
                      text: i,
                      value: i,
                      key: i,
                    })) || []
                  }
                />
              </>
            )}
          </ManageGrantsComponent>
        </Modal.Content>
        <Modal.Actions>
          <CreateGrantComponent>
            {(query, { loading }) => (
              <Button
                primary
                loading={loading}
                content="Save Permission"
                onClick={() => {
                  query({
                    variables: {
                      policies: [activeGrant],
                      source: {
                        id: props.actorId,
                        type: ResourceType.Actor,
                      },
                      target: {
                        id: props.resourceId,
                        type: props.resourceType,
                      },
                    },
                  }).then(() => {
                    setOpen(false);
                    props.onUpdate && props.onUpdate();
                  });
                }}
              />
            )}
          </CreateGrantComponent>
          <Button content="Close" onClick={() => setOpen(false)} />
          <DeleteGrantComponent>
            {(query, { loading }) => (
              <Button
                negative
                loading={loading}
                floated="right"
                content="Delete Permission"
                onClick={() => {
                  query({
                    variables: {
                      source: {
                        id: props.actorId,
                        type: ResourceType.Actor,
                      },
                      target: {
                        id: props.resourceId,
                        type: props.resourceType,
                      },
                    },
                  }).then(() => {
                    setOpen(false);
                    props.onUpdate && props.onUpdate();
                  });
                }}
              />
            )}
          </DeleteGrantComponent>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const SelectionPlaceholder = () => (
  <Segment secondary padded="very">
    <Header textAlign="center">
      <Header.Content>
        Select a Resouce
        <Header.Subheader>
          Select a resource to assign policies
        </Header.Subheader>
      </Header.Content>
    </Header>
  </Segment>
);

export const EditGrantButton = withAppContext(BaseEditGrantButton);
