import "./style.scss";

import React from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { Button, Container, Divider, Header, Segment } from "semantic-ui-react";

const deviceImage = require("./device.png");
const IOS_APP_URL = "itms-apps://apps.apple.com/us/app/quikserve/id1360023192?mt=8";
const ANDROID_APP_URL = "market://details?id=com.quikserve.quikserve";

export default () => {
  return (
    <div className="mobile-advert">
      <Container>
        <div className="toplogo"></div>
        <Segment basic textAlign="center">
          <Header>
            THE ONLY <span className="italic">COMPLETE</span> QSR TECHNOLOGY SOLUTION
          </Header>
        </Segment>
        <Segment basic textAlign="center">
          <Header color="grey" as="h4" content="DOWNLOAD THE NATIVE APP" />
        </Segment>
        <Segment basic textAlign="center">
          {isAndroid && (
            <Button basic icon="android" color="blue" content="For Android" as="a" href={ANDROID_APP_URL} />
          )}
          {isIOS && (
            <Button icon="apple" basic color="blue" content="For iOS" as="a" href={IOS_APP_URL} />
          )}
        </Segment>
        <div className="device-image" style={{ backgroundImage: `url(${deviceImage})` }}>
        </div>
      </Container>
    </div>
  );
}