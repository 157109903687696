import {Form, Input } from "formsy-semantic-ui-react";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {Button, Container, Divider, Header, Image, Segment, Table} from "semantic-ui-react";
import titleize from "titleize";
import {AppContextParams, withAppContext} from "../Context";
import {AcceptInviteComponent, EmployeeStatusEnum} from "../generated/graphql";
import {ErrorLabel, PhoneInput} from "../Shared";
type IProps = RouteComponentProps<IParams> & AppContextParams;
interface IParams {
    id: string;
}

const FirstSignIn = (props: IProps) => {
    const [firstName, setFirstName] = React.useState(props?.appContext?.currentUser?.employee?.firstName || "");
    const [lastName, setLastName] = React.useState(props?.appContext?.currentUser?.employee?.lastName || "");
    const [phoneNumber, setPhoneNumber] = React.useState(props?.appContext?.currentUser?.employee?.phone || "");
    const [pin, setPin] = React.useState("");
    const [valid, setValid] = React.useState(false);

    return(
      <React.Fragment>
        <Container className="account-screen">
          <div>
            <Image
              centered
              className="account-logo-medium"
              src="https://cdn.quikserve.com/logos/quikserve/qs-logo-standard.svg"
            />
          </div>
          <Segment className="info-box">
            <Header as="h2" className="account-header" align="center">Confirm Your Account Information</Header>
            <div className="form-container">
              <Divider />
              <Container textAlign="left">
                <p>
                  Please make sure that your name and phone number are correct,
                  then add a 4-6 digit PIN for quick access to cash registers, etc.
                </p>
                <Divider hidden />
              </Container>
              <Form onValid={() => setValid(true)} onInvalid={() => setValid(false)}>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="4">First Name</Table.Cell>
                      <Table.Cell>
                        <Form.Input name="firstname"
                          onChange={(e, v) => setFirstName(v?.value)}
                          value={titleize(firstName)}
                          validations={{maxLength: 100}}
                          errorLabel={ErrorLabel}
                          validationErrors={{
                            maxLength: "A maximum of 100 characters is allowed.",
                            isDefaultRequiredValue: "First Name is required.",
                          }}
                          required
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="4">Last Name</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          name="lastname"
                          onChange={(e, v) => setLastName(v?.value)}
                          value={titleize(lastName)}
                          validations={{maxLength: 100}}
                          errorLabel={ErrorLabel}
                          validationErrors={{
                            maxLength: "A maximum of 100 characters is allowed.",
                            isDefaultRequiredValue: "Last Name is required.",
                          }}
                          required
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="4">Phone number</Table.Cell>
                      <Table.Cell>
                        <PhoneInput
                          name="phonenumber"
                          value={phoneNumber}
                          onChange={(v) => setPhoneNumber(v)}
                          validations={{ isLength: 12 }}
                          errorLabel={ErrorLabel}
                          validationErrors={{
                            isLength: "Invalid Phone Number",
                            isDefaultRequiredValue: "Phone is required.",
                          }}
                          required
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width="4">PIN*</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          name="pin"
                          type="password"
                          onChange={(e, v) => setPin(v?.value)}
                          validations={{isNumeric: true, minLength: 4, maxLength: 6}}
                          errorLabel={ErrorLabel}
                          validationErrors={{
                            isDefaultRequiredValue: "PIN is required.",
                            isNumeric: "PIN can contain numeric values only",
                            maxLength: "A maximum of 6 numbers is allowed.",
                            minLength: "A minimum of 4 numbers is required.",
                          }}
                          value={pin}
                          required
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Divider />

                <AcceptInviteComponent>
                  {(update, resp) => (
                    <Button
                      loading={resp.loading}
                      content="Confirm"
                      primary
                      onClick={() => {
                        if (!valid) { return; }
                        update({variables: {
                          firstName,
                          lastName,
                          pin,
                          phoneNumber,
                        }}).then(() => {
                          props.appContext.currentUser.employee.status = EmployeeStatusEnum.Active;
                          location.reload();
                          props.history.push(`/`);
                        }); }
                      }
                    />
                  )}
                  </AcceptInviteComponent>
              </Form>
            </div>
        </Segment>
      </Container>
    </React.Fragment>);
};

export default withRouter(withAppContext(FirstSignIn));
