import _ from "lodash";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Container, Divider, Grid, Header, Input, Table } from "semantic-ui-react";
import titleize from "titleize";

import { actions } from "@quikserve/unityactions";
import { ListUnitsComponent, ResourceType, SortDirection } from "../generated/graphql";
import { getIcon } from "../Resources";
import SearchQuery, { IUnit } from "../Search/SearchQuery";
import { LoadMore } from "./LoadMore";
import { QSFA } from "./QSFA";

type ColumnNames = "name" | "identifier" | "organizationName" | "city" | "state" | null;

type IProps = RouteComponentProps;

interface IListUnit {
  id: string;
  name: string;
  identifier: string;
  city: string;
  state: string;
  organizationName: string;
  conceptNames: string[];
}

const UnitSelect = (props: IProps) => {
  const [filter, setFilter] = React.useState(null);
  let timeout = null;
  const [column, setColumn] = React.useState<ColumnNames>("identifier");
  const [direction, setDirection] = React.useState<"ascending" | "descending">("ascending");

  const [sortValue, setSortValue] = React.useState([{ field: "identifier", direction: SortDirection.Asc }]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  });

  const handleSort = (clickedColumn: ColumnNames) => {
    setFilter(null);

    if (column !== clickedColumn) {
      setSortValue([{ field: clickedColumn, direction: SortDirection.Asc }]);
      setColumn(clickedColumn);
      setDirection("ascending");
      return;
    }

    setDirection(direction === "ascending" ? "descending" : "ascending");
    const directionVal = direction === "ascending" ? SortDirection.Desc : SortDirection.Asc;
    setSortValue([{ field: clickedColumn, direction: directionVal }]);
  };

  const nav = (id: string) => () => {
    props.history.push(`${props.match.url}/${id}`);
  };

  return (
    <Container style={{ paddingTop: "20px" }}>
      <Grid
        textAlign="center"
        verticalAlign="top"
      >
        <Grid.Column textAlign="left"
          style={{ maxWidth: "1220px" }}
        >
          <Header as="h1">
            <QSFA icon={getIcon(ResourceType.Unit)} />
            Select a Unit:
          </Header>
          <Divider />
          <Grid.Column
            style={{ paddingLeft: "25px", paddingRight: "25px" }}
          >
            <p>
              Select a unit to operate on. All actions will be implemented by you for the selected unit.
            </p>
            <Input placeholder="Search..." fluid onChange={(e, v) => {
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                v.value === "" ? setFilter(null) : setFilter({
                  multi_match: {
                    fields: [
                      "identifier^5",
                      "identifier.ngram",
                      "name^5",
                      "name.ngram",
                      "organizationName^4",
                      "organizationName.ngram",
                      "phone",
                    ],
                    query: v.value,
                    type: "phrase_prefix",
                  },
                });
              }, 500);
            }} />
          </Grid.Column>
          <Divider />

          <Grid.Column
            style={{ paddingLeft: "25px", paddingRight: "25px" }}
          >
            <ListUnitsComponent fetchPolicy="network-only" variables={{
              sort: sortValue,
            }}>
              {({ data, loading, fetchMore }) => {
                if (data?.units?.nodes?.length === 1) {
                  nav(data.units.nodes[0].id);
                }
                const cursor = data?.units?.cursor;
                const lunits: IListUnit[] = data?.units?.nodes?.map((n) => ({
                  id: n.id,
                  name: n.name,
                  identifier: n.identifier,
                  city: n.city,
                  state: n.state,
                  organizationName: n.organization.name,
                  conceptNames: n.concepts.map((c) => c.name),
                }));
                let listData = lunits;
                return (
                  <SearchQuery namespaces={[{ namespace: "units", action: actions.organizations.read }]} filter={filter}>
                    {({ data, loading }) => {
                      if (filter) {
                        const units = data?.hits?.map((h) => {
                          const u = h.source as IUnit;
                          return {
                            id: u.id,
                            name: u.name,
                            identifier: u.identifier,
                            city: u.city,
                            state: u.state,
                            organizationName: u.organizationName,
                            conceptNames: u.conceptNames,
                          };
                        });
                        listData = units;

                        setColumn(null);
                        setDirection(null);
                      }
                      return (
                        <React.Fragment>
                          <Table sortable fixed selectable unstackable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell
                                  sorted={column === "identifier" ? direction : null}
                                  onClick={() => handleSort("identifier")}
                                >
                                  Id
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === "name" ? direction : null}
                                  onClick={() => handleSort("name")}
                                >
                                  Name
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  Organization
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === "city" ? direction : null}
                                  onClick={() => handleSort("city")}
                                >
                                  City
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                  sorted={column === "state" ? direction : null}
                                  onClick={() => handleSort("state")}
                                >
                                  State
                                </Table.HeaderCell>
                                <Table.HeaderCell width={1}>
                                  &nbsp;
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {listData?.map(({ identifier, name, organizationName, city, state, id }, index) => (
                                <Table.Row
                                  key={id + index}
                                  onClick={nav(id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Table.Cell>{identifier}</Table.Cell>
                                  <Table.Cell>{titleize(name || "")}</Table.Cell>
                                  <Table.Cell>
                                    {organizationName}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {titleize(city || "")}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {state?.toUpperCase()}
                                  </Table.Cell>
                                  <Table.Cell width={1} textAlign="right">
                                    <QSFA icon="chevron-right" padded />
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                          <Divider hidden />
                          {!filter &&
                            <>
                              <LoadMore
                                fetchMore={fetchMore}
                                nodeName="units"
                                cursor={cursor}
                                hasMore={cursor !== ""}
                                className="light"
                              />
                              <Divider hidden />
                            </>
                          }
                        </React.Fragment>
                      );
                    }}
                  </SearchQuery>
                );
              }}
            </ListUnitsComponent>
          </Grid.Column>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default withRouter(UnitSelect);

