import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Dimmer, Loader } from "semantic-ui-react";
import { GetReportComponent } from "../generated/graphql";
import ReportView from "./ReportView";

interface IParams {
  id: string;
}

const Reporting = (props: RouteComponentProps<IParams>) => {
  return (
    <GetReportComponent variables={{ id: props.match.params.id }}>
      {({ data, loading }) => {
        let def = null;
        try {
          def = JSON.parse(data.legacyReport.definition);
        } catch { }
        return (
          <React.Fragment>
            {!data || !data.legacyReport || loading ?
              <Dimmer active={loading}>
                <Loader content="One moment while we generate your report" />
              </Dimmer> :
              <ReportView mobile
                definition={def}
                reportName={data && data.legacyReport && data.legacyReport.name}
                reportIcon={data && data.legacyReport && data.legacyReport.icon}
                longDescription={data && data.legacyReport && data.legacyReport.longDescription}
                recordSetName={data && data.legacyReport && data.legacyReport.recordSetName}
              />
            }
          </React.Fragment>
        );
      }}
    </GetReportComponent>
  );
};

export default withRouter(Reporting);
