import { actions, UnityAction } from "@quikserve/unityactions";
import { Form as FormsyForm, Select } from "formsy-semantic-ui-react";
import { startCase } from "lodash";
import React from "react";
import { Form, Placeholder } from "semantic-ui-react";
import { ConceptSelect } from "../Concept";
import { AppContextParams, withAppContext } from "../Context";
import {
  GetActorResourceTypesComponent,
  ResourceType,
} from "../generated/graphql";
import { OrgSelect } from "../Organization";
import { ErrorLabel, GroupSelect } from "../Shared";
import { UnitSelect } from "../Unit";
import { ResourceIcon } from "./ResourceIcon";


type ResourceString =
  | "Actor"
  | "Employee"
  | "Concept"
  | "Organization"
  | "Unit"
  | "Group"
  | "Report"
  | "SystemConfig"
  | "SystemConfigGroup"
interface IProps extends AppContextParams {
  actorId?: string;
  action?: string;
  onChange?: (t, v) => void;
  resourceType?: string;
  resourceValue?: string;
  required?: boolean;
  noActor?: boolean;
  noEmployee?: boolean;
  noReport?: boolean;
  onlyGroups?: boolean;
  only?: Array<ResourceString>
}

const ResourcePicker = (props: IProps) => {
  const [disabled, setDisabled] = React.useState(false);
  const [groupType, setGroupType] = React.useState<undefined | ResourceType>();
  const [groupParent, setGroupParent] = React.useState<undefined | string>();

  const filterTypes = (tps: ResourceType[]): ResourceType[] => {
    let res = [...tps];
    if (props.noEmployee) {
      res = res.filter((i) => i !== ResourceType.Employee);
    }
    if (props.noActor) {
      res = res.filter((i) => i !== ResourceType.Actor);
    }
    if (props.only) {
      const displayedResources = props.only.map(entry => ResourceType[entry])
      res = res.filter((i) => displayedResources.includes(i))
    }
    return res;
  };

  const actorId = props.actorId || props.appContext.currentUser.id;

  return (
    <React.Fragment>
      <GetActorResourceTypesComponent
        variables={{ actorId, action: props.action }}
      >
        {({ data, loading }) => (
          <>
            <Form.Field required>
              <label>Resource Type</label>
              <Select
                name="resourceType"
                loading={loading}
                disabled={disabled}
                options={
                  (data?.actor &&
                    filterTypes(data.actor.resourceTypes).map((t) => ({
                      icon: ResourceIcon({ type: t, size: "small" }),
                      text: startCase(t),
                      value: t,
                    }))
                  ) || []
                }
                value={props.resourceType}
                onChange={(e, v) => {
                  props.onChange && props.onChange(v.value, null);
                  if (data?.actor?.resourceTypes?.includes(ResourceType.Organization)) {
                    setGroupType(ResourceType.Organization);
                  } else if (data?.actor?.resourceTypes?.includes(ResourceType.Concept)) {
                    setGroupType(ResourceType.Concept);
                  }
                }}
                placeholder="Select a resource type"
                required={props.required}
                validationErrors={{
                  isDefaultRequiredValue: "Select a type",
                }}
                errorLabel={ErrorLabel}
              />
            </Form.Field>
            {!props.resourceType && (
              <Form.Field required>
                <Placeholder />
              </Form.Field>
            )}
            {props.resourceType &&
              props.resourceType === ResourceType.Organization && (
                <Form.Field required>
                  <label>Organization</label>
                  <OrgSelect
                    value={props.resourceValue}
                    onChange={(v) =>
                      props.onChange && props.onChange(props.resourceType, v)
                    }
                    required={true}
                    action={props.action as UnityAction}
                  />
                </Form.Field>
              )}
            {props.resourceType && props.resourceType === ResourceType.Concept && (
              <Form.Field required>
                <label>Concept</label>
                <ConceptSelect
                  value={props.resourceValue}
                  onChange={(v) =>
                    props.onChange && props.onChange(props.resourceType, v)
                  }
                  required={true}
                  action={props.action as UnityAction}
                />
              </Form.Field>
            )}
            {props.resourceType && props.resourceType === ResourceType.Unit && (
              <Form.Field required>
                <label>Unit</label>
                <UnitSelect
                  value={props.resourceValue}
                  onChange={(v) =>
                    props.onChange && props.onChange(props.resourceType, v)
                  }
                  required={true}
                  action={props.action as UnityAction}
                />
              </Form.Field>
            )}
            {props.resourceType && props.resourceType === ResourceType.Group && (
              <>
                {data?.actor?.resourceTypes.filter((r) => r === ResourceType.Concept || r === ResourceType.Organization).length === 0 &&
                  <Form.Field required>
                    <label>Group</label>
                    <GroupSelect
                      required
                      value={props.resourceValue}
                      onChange={(v) => props.onChange(props.resourceType, v)}
                    />
                  </Form.Field>
                }
                {data?.actor?.resourceTypes.filter((r) =>
                  r === ResourceType.Concept || r === ResourceType.Organization).length > 1 &&
                  <Form.Field required>
                    <label>Group Parent Type</label>
                    <Select
                      name="groupParentType"
                      required
                      validationErrors={{
                        isDefaultRequiredValue: "Select a type",
                      }}
                      errorLabel={ErrorLabel}
                      value={groupType}
                      onChange={(e, v) => {
                        setGroupType(v.value as ResourceType);
                        setGroupParent(undefined);
                        props.onChange(props.resourceType, undefined);
                      }}
                      options={
                        data?.actor?.resourceTypes
                          .filter((r) => r === ResourceType.Concept || r === ResourceType.Organization)
                          .map((r) => ({
                            icon: ResourceIcon({ type: r, size: "small" }),
                            text: startCase(r),
                            value: r,
                          }))
                      }
                    />
                  </Form.Field>
                }
                {groupType ? (
                  <>
                    {groupType === ResourceType.Concept &&
                      <Form.Field required>
                        <label>Group Parent</label>
                        <ConceptSelect
                          value={groupParent}
                          onChange={(v) => {
                            setGroupParent(v);
                            props.onChange(props.resourceType, undefined);
                          }}
                          required={true}
                          action={props.action as UnityAction}
                        />
                      </Form.Field>}
                    {groupType === ResourceType.Organization &&
                      <Form.Field required>
                        <label>Group Parent</label>
                        <OrgSelect
                          value={groupParent}
                          onChange={(v) => {
                            setGroupParent(v);
                            props.onChange(props.resourceType, undefined);
                          }}
                          required={true}
                          action={props.action as UnityAction}
                        />
                      </Form.Field>}
                  </>
                ) :
                  <Form.Field required>
                    <Placeholder />
                  </Form.Field>
                }
                {groupParent ?
                  <Form.Field required>
                    <label>Group</label>
                    <GroupSelect
                      required
                      action={props.action as UnityAction}
                      value={props.resourceValue}
                      onChange={(v) => props.onChange(props.resourceType, v)}
                      parentType={groupType as ResourceType}
                      parentId={groupParent}
                    />
                  </Form.Field> :
                  <Form.Field required>
                    <Placeholder />
                  </Form.Field>
                }
              </>
            )}
          </>
        )}
      </GetActorResourceTypesComponent>
    </React.Fragment>
  );
};

export default withAppContext(ResourcePicker);
