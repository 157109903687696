import React from "react";
import { Button, Container, Divider, Grid, Header, Image, Segment } from "semantic-ui-react";
import { AppContextParams, withAppContext } from "./Context";
import { QSFA } from "./Shared";

export const Error = (props: AppContextParams) => {

  const close = () => {
    props.appContext.logout();
    window.location.href = "/";
  };

  return (
    <Container>
      <Grid
        style={ { height: "100%", marginTop: "1em" } }
        verticalAlign="top"
        divided="vertically"
      >
        <Grid.Row>
          <Grid.Column>
            <Image src="assets/qslogo.png" size="small" spaced centered />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={ 5 }>
            <Header color="red">
              <QSFA icon="bug" size="giant"/>
            </Header>
          </Grid.Column>
          <Grid.Column width={11}>
            <Grid.Row verticalAlign="top">
              <Header as="h1" color="red">Error</Header>
            </Grid.Row>
            <Grid.Row>
              <Divider hidden/>
              <p>An error occurred while processing your request. Please try your request again or contact us at help@quikserve.com.</p>
            </Grid.Row>
            <Divider hidden/>
            <Button onClick={ close }>Close</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default withAppContext(Error);
