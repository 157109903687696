import React from "react";
import { Helmet } from "react-helmet";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { AppContextParams, withAppContext } from "../Context";
import OrganizationAdd from "./OrganizationAdd";
import OrganizationEdit from "./OrganizationEdit";
import OrganizationList from "./OrganizationList";
import ViewOrganization from "./ViewOrganization";

type IProps = RouteComponentProps & AppContextParams;

export const Organization = ({ appContext }: IProps) => {

  React.useEffect(() => {
    appContext.setCurrentModule("Organizations");
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <Switch>
        <Route exact path="/config/organizations" component={OrganizationList}/>
        <Route exact path="/config/organizations/add" component={OrganizationAdd}/>
        <Route exact path="/config/organizations/:id/edit" component={OrganizationEdit}/>
        <Route path="/config/organizations/:id" component={ViewOrganization}/>
      </Switch>
    </React.Fragment>
  );
};

export default withAppContext(Organization);
