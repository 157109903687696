import { actions } from "@quikserve/unityactions";
import React from "react";
import { Link, NavLink, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Breadcrumb, Divider, Menu, Segment } from "semantic-ui-react";
import { GetOrganizationComponent, ResourceType } from "../generated/graphql";
import { Restrict } from "../Privileges";
import { getIcon } from "../Resources";
import { QSFA } from "../Shared";
import { AvatarHeader } from "../Shared/AvatarHeader";
import Access from "./Access";
import Concepts from "./Concepts";
import OrganizationDetail from "./OrganizationDetail";
import Teams from "./Teams";

export default (props: RouteComponentProps<{ id: string }>) => {
  const [activeItem, setActiveItem] = React.useState();

  const handleItemClick = (_e, { name }) => setActiveItem(name);

  return (
    <React.Fragment>
      <GetOrganizationComponent variables={{ id: props.match.params.id }}>
        {({ data, loading }) =>
        <>
          <QSFA icon={getIcon(ResourceType.Organization)} size="small" />
          <Breadcrumb size="big">
            <Breadcrumb.Section>
              <Link to={"/config/organizations"}> Organizations</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              <span>{data?.organization?.name}</span>
            </Breadcrumb.Section>
          </Breadcrumb>
          <Divider />

          <Menu attached="top" tabular>
            <Menu.Item
              exact
              as={NavLink}
              to={`${props.match.url}`}
              name="Details"
              active={activeItem === "Details"}
              onClick={handleItemClick}
            />
            <Menu.Item
              as={NavLink}
              to={`${props.match.url}/unit-groups`}
              name="Unit Groups"
              active={activeItem === "Unit Groups"}
              onClick={handleItemClick}
            />
            <Menu.Item
              as={NavLink}
              to={`${props.match.url}/Teams`}
              name="Teams"
              active={activeItem === "Teams"}
              onClick={handleItemClick}
            />
            <Restrict action={actions.organizations.manage_concepts} type={ResourceType.Organization} id = {props.match.params.id}>
              <Menu.Item
                as={NavLink}
                to={`${props.match.url}/concepts`}
                name="Concepts"
                active={activeItem === "concepts"}
                onClick={handleItemClick}
              />
            </Restrict>
          </Menu>

          <AvatarHeader
            name={data?.organization?.name.toString()}
            icon={getIcon(ResourceType.Organization)}
            phone={data?.organization?.phone}
          />
          <Segment basic vertical loading={loading}>
            <Switch>
              <Route path={`${props.match.path}/unit-groups`} component={Access} />
              <Route path={`${props.match.path}/teams`} component={Teams} />
              <Route path={`${props.match.path}/concepts`} component={Concepts} />
              <Route path={`${props.match.path}`} component={OrganizationDetail} />
            </Switch>
          </Segment>
        </>
        }
      </GetOrganizationComponent>
    </React.Fragment>
  );
};
