import { Form } from "formsy-semantic-ui-react";
import React from "react";
import {
  Button,
  Confirm,
  Divider,
  Header,
  Icon,
  List,
  Message,
  Modal,
  Placeholder,
  Segment
} from "semantic-ui-react";
import { AppContextParams, withAppContext } from "../Context";
import {
  CreateGrantComponent,
  DeleteGrantComponent,
  GetGrantComponent,
  ResourceType,
  SubsetPoliciesComponent
} from "../generated/graphql";
import { getIcon, ResourcePicker } from "../Resources";
import { QSFA } from "../Shared";

interface ITarget {
  id: string;
  name: string;
  type: ResourceType;
}

interface IGrant {
  policies: string[];
  target: ITarget;
}

interface IActor {
  id: string;
  name: string;
  grants: IGrant[];
}

interface IProps extends AppContextParams {
  actor?: IActor;
  loading?: boolean;
  actorId?: string;
  disableResourcePicker?: boolean;
  resourceId?: string;
  resourceType?: ResourceType;
  onChange?: () => void;
}

const ActorManager = (props: IProps) => {
  const [selectedPolicies, setSelectedPolicies] = React.useState(
    new Array<string>()
  );
  const [showAddPermission, setShowAddPermission] = React.useState(false);
  const [createError, setCreateError] = React.useState("");
  const [parentResourceType, setParentResourceType] = React.useState(props.resourceType);
  const [parentResourceId, setParentResourceId] = React.useState(props.resourceId);
  const [showRevokeModal, setShowRevokeModal] = React.useState(false);

  const resetPermissionModal = () => {
    setShowAddPermission(false);
    setCreateError("");
    setSelectedPolicies(new Array<string>());
    setParentResourceType(props.resourceType);
    setParentResourceId(props.resourceId);
  };

  return (
    <React.Fragment>
      <Segment loading={props.loading}>
        {props.loading && (
          <Placeholder>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
            <Placeholder.Line length="full" />
          </Placeholder>
        )}
        {props.actor && props.actor.grants.length > 0 ? (
          <List selection animated className="list-view">
            {props.actor.grants.map((g, i) => (
              <List.Item
                key={i}
                onClick={() => {
                  setSelectedPolicies(g.policies);
                  setParentResourceType(g.target.type);
                  setParentResourceId(g.target.id);
                }}
              >
                <List.Content floated="right">
                  <Button onClick={() => setShowAddPermission(true)}>
                    <QSFA icon={"edit"} />
                    Edit
                  </Button>
                  <Button onClick={() => setShowRevokeModal(true)}>
                    <QSFA icon="ban" />
                    Revoke Access
                  </Button>
                </List.Content>
                <List.Icon verticalAlign="middle">
                  <QSFA icon={getIcon(g.target.type)} padded="small-both" size="medium" />
                </List.Icon>
                <List.Content>
                  <List.Header>
                    {g.target.name}
                  </List.Header>
                  <List.Description>
                    {g && g.policies.length === 0 && "read only access"}
                    {g && g.policies.join(", ")}
                  </List.Description>
                </List.Content>
              </List.Item>
            ))}
            {!props.disableResourcePicker && (
              <React.Fragment>
                <Divider hidden />
                <List.Item onClick={() => setShowAddPermission(true)}>
                  <List.Icon>
                    <QSFA icon="plus" />
                  </List.Icon>
                  <List.Content>Add Permission</List.Content>
                </List.Item>
              </React.Fragment>
            )}
          </List>
        ) : (
            <Segment placeholder>
              <Header icon>
                <Icon name="shield" />
              No permissions have been added yet.
            </Header>
              <Button
                primary
                content="Grant Permission"
                onClick={() => setShowAddPermission(true)}
              />
            </Segment>
          )}
      </Segment>
      <Modal
        open={showAddPermission}
        onClose={() => resetPermissionModal()}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header>Grant Permission</Modal.Header>
        <Modal.Content scrolling style={{ height: "35vh" }}>
          {createError && <Message negative>{createError.toString()}</Message>}
          {!props.disableResourcePicker && (
            <Form>
              <p>Select a resource to grant.</p>
              <Form.Group widths="equal">
                <ResourcePicker
                  actorId={props.actorId || props.appContext.currentUser.id}
                  resourceType={parentResourceType}
                  resourceValue={parentResourceId}
                  onChange={(t, v) => {
                    setParentResourceType(t);
                    setParentResourceId(v);
                  }}
                  required
                />
              </Form.Group>
            </Form>
          )}
          {parentResourceType && parentResourceId ? (
            <React.Fragment>
              <p>Select one or more policies from the list below to grant.</p>
              <GetGrantComponent
                variables={{
                  actorId: props.appContext.currentUser.id,
                  resource: { id: parentResourceId, type: parentResourceType }
                }}
              >
                {gResp => (
                  <Segment
                    loading={gResp.loading}
                    style={{ minHeight: "18rem" }}
                  >
                    {!gResp.loading && gResp.data && gResp.data.actor && (
                      <SubsetPoliciesComponent
                        variables={{
                          policies:
                            gResp.data &&
                            gResp.data.actor &&
                            gResp.data.actor.grant.policies,
                          primary: true
                        }}
                      >
                        {pResp => (
                          <List
                            selection
                            divided
                            relaxed
                            verticalAlign="middle"
                          >
                            {pResp.data &&
                              pResp.data.subsetPolicies &&
                              pResp.data.subsetPolicies.map((p, i) => (
                                <List.Item
                                  key={i}
                                  onClick={() => {
                                    if (selectedPolicies.includes(p.name)) {
                                      setSelectedPolicies(
                                        selectedPolicies.filter(
                                          i => i !== p.name
                                        )
                                      );
                                    } else {
                                      setSelectedPolicies([
                                        p.name,
                                        ...selectedPolicies
                                      ]);
                                    }
                                  }}
                                  active={selectedPolicies.includes(p.name)}
                                >
                                  <List.Icon
                                    color={
                                      selectedPolicies.includes(p.name)
                                        ? "green"
                                        : "grey"
                                    }
                                    size="large"
                                    name={
                                      selectedPolicies.includes(p.name)
                                        ? "check circle outline"
                                        : "times circle outline"
                                    }
                                    verticalAlign="middle"
                                  />
                                  <List.Content>
                                    <List.Header>{p.name}</List.Header>
                                  </List.Content>
                                </List.Item>
                              ))}
                          </List>
                        )}
                      </SubsetPoliciesComponent>
                    )}
                  </Segment>
                )}
              </GetGrantComponent>
            </React.Fragment>
          ) : (
              <Segment placeholder>
                <Header icon>Select a resource above to begin.</Header>
              </Segment>
            )}
        </Modal.Content>
        <Modal.Actions>
          <CreateGrantComponent>
            {(query, { loading: createLoading }) => (
              <Button
                primary
                onClick={() =>
                  query({
                    variables: {
                      policies: selectedPolicies,
                      source: {
                        id: props.actor.id,
                        type: ResourceType.Actor
                      },
                      target: {
                        id: parentResourceId,
                        type: parentResourceType
                      }
                    }
                  })
                    .then(() => {
                      resetPermissionModal();
                      props.onChange();
                    })
                    .catch(err => {
                      setCreateError(err);
                    })
                }
                loading={createLoading}
                content="Save Permission"
              />
            )}
          </CreateGrantComponent>
          <Button content="Cancel" onClick={() => resetPermissionModal()} />
          <DeleteGrantComponent>
            {(query, { loading }) => (
              <Button
                floated="right"
                content="Delete Permission"
                negative
                loading={loading}
                onClick={() =>
                  query({
                    variables: {
                      source: {
                        id: props.actor.id,
                        type: ResourceType.Actor
                      },
                      target: {
                        id: parentResourceId,
                        type: parentResourceType
                      }
                    }
                  })
                    .then(() => {
                      resetPermissionModal();
                      props.onChange();
                    })
                    .catch(err => {
                      setCreateError(err);
                    })
                }
              />
            )}
          </DeleteGrantComponent>
        </Modal.Actions>
      </Modal>
      <DeleteGrantComponent>
        {(query) => (
          <Confirm
            header="Revoke Grant"
            content="You are about to revoke this grant. Are you sure?"
            open={showRevokeModal}
            onCancel={() => setShowRevokeModal(false)}
            onConfirm={() => {
              query({
                variables: {
                  source: {
                    id: props.actor.id,
                    type: ResourceType.Actor
                  },
                  target: {
                    id: parentResourceId,
                    type: parentResourceType
                  }
                }
              }).then(() => {
                setShowRevokeModal(false);
                props.onChange();
              }).catch(err => {
                setCreateError(err);
              })
            }}
          />
        )}
      </DeleteGrantComponent>
    </React.Fragment>
  );
};

export default withAppContext(ActorManager);
